const dueDate = new Date().toISOString();

export default {
  metrics: [
    {
      assignmentId: 3678951,
      assignmentName: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 0.8947368421052632,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H30M57S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT17H48M13S',
      minTimeSpent: 'PT55M2S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645904,
      assignmentName: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 0.9473684210526315,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H3M11S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT2H36M55S',
      minTimeSpent: 'PT12M19S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645906,
      assignmentName: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 0.9473684210526315,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT59M28S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT3H28M4S',
      minTimeSpent: 'PT6M57S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645907,
      assignmentName: 'NUR 398A Pediatric Drugs - Novice',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-08T03:59:00Z',
      percentComplete: 0.8947368421052632,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT56M55S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT2H16M37S',
      minTimeSpent: 'PT9M1S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645912,
      assignmentName: 'NUR 398A Medical-Surgical Drugs - Novice',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-08T03:59:00Z',
      percentComplete: 0.8421052631578947,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H42M46S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 3,
      studyMode: true,
      maxTimeSpent: 'PT3H39M49S',
      minTimeSpent: 'PT18M38S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645914,
      assignmentName: 'NUR 398A Gastrointestinal - Intermediate',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-14T03:59:00Z',
      percentComplete: 0.7368421052631579,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2H17M42S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 5,
      studyMode: true,
      maxTimeSpent: 'PT11H12M38S',
      minTimeSpent: 'PT29M21S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3645916,
      assignmentName: 'NUR 398A Endocrine - Intermediate',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-18T03:59:00Z',
      percentComplete: 0.8947368421052632,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H16M1S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT3H19M6S',
      minTimeSpent: 'PT22M12S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3678945,
      assignmentName: 'NUR 398A Renal - Novice, recommend Intermediate',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-14T03:59:00Z',
      percentComplete: 0.8421052631578947,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT39M18S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 3,
      studyMode: true,
      maxTimeSpent: 'PT2H31M43S',
      minTimeSpent: 'PT8M10S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3678953,
      assignmentName: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
      assignmentType: 'MASTERY',
      dueDate: '2024-04-21T03:59:00Z',
      percentComplete: 0.8947368421052632,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H56M37S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 19,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT6H4M9S',
      minTimeSpent: 'PT25M57S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734857,
      assignmentName: 'Pharmacokinetics',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734858,
      assignmentName: 'Pharmacodynamics',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734859,
      assignmentName: 'Analgesics',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734860,
      assignmentName: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734861,
      assignmentName: 'Anticonvulsant Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 3734862,
      assignmentName: 'Antidepressant and Mood Stabilizer Drug Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 19,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 19,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    }
  ],
  card: {
    totalAssignments: 15,
    totalCompletedAssignments: 9,
    highPerformanceStudents: 15,
    totalStudents: 19,
    disengagedStudents: 3
  }
};
