import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import ELSIcon, { ELSIconProps } from '../els-icon/ELSIcon';

const baseButtonClass = 'c-els-button';

export const BUTTON_VARIANTS = {
  secondary: `${baseButtonClass}--secondary`,
  tertiary: `${baseButtonClass}--tertiary`,
  info: `${baseButtonClass}--info`,
  warn: `${baseButtonClass}--warn`,
  confirm: `${baseButtonClass}--confirm`,
  outlined: `${baseButtonClass}--outlined`
} as const;

export const BUTTON_SIZES = {
  xsmall: `${baseButtonClass}--x-small`,
  small: `${baseButtonClass}--small`,
  medium: `${baseButtonClass}--medium`,
  large: `${baseButtonClass}--large`
} as const;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof BUTTON_VARIANTS;
  size?: keyof typeof BUTTON_SIZES;
  iconLeft?: ELSIconProps;
  iconRight?: ELSIconProps;
}

interface GetButtonClassnamesProps {
  variant?: keyof typeof BUTTON_VARIANTS;
  size?: keyof typeof BUTTON_SIZES;
  iconLeft?: ELSIconProps;
  iconRight?: ELSIconProps;
  className?: string;
}

export const getButtonClassnames = ({ variant, size, className, iconLeft, iconRight }: GetButtonClassnamesProps) => {
  const displayVariant = variant && Object.keys(BUTTON_VARIANTS).includes(variant) ? BUTTON_VARIANTS[variant] : '';
  const displaySize = size && Object.keys(BUTTON_SIZES).includes(size) ? BUTTON_SIZES[size] : '';
  const iconLeftClass = iconLeft || iconRight ? `${baseButtonClass}--icon` : '';
  const iconRightClass = iconRight ? `${baseButtonClass}--icon-right` : '';
  return cx(`${baseButtonClass}`, displayVariant, displaySize, iconLeftClass, iconRightClass, className);
};

const ELSButton = forwardRef<HTMLButtonElement, ButtonProps>(({ variant, size, iconLeft, iconRight, className, children, ...props }, ref) => (
  <button ref={ref} type="button" {...props} className={getButtonClassnames({ variant, size, className, iconLeft, iconRight })}>
    {!isEmpty(iconLeft) && <ELSIcon {...iconLeft} />}
    {children}
    {!isEmpty(iconRight) && <ELSIcon {...iconRight} />}
  </button>
));

ELSButton.displayName = 'ELSButton';

export default ELSButton;
