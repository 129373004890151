import { configureHandler } from 'mocks/helpers';
import recentExamsProgram201710 from './_prod/201710';

export default configureHandler(async (req, res, ctx) => {
  const programId = req.params.programId;
  if (programId === '201710') {
    return res(ctx.status(200), ctx.json(recentExamsProgram201710));
  }

  return req.passthrough();
});
