export const PERFORMANCE_LEVEL_LABELS = {
  atRisk: 'At Risk',
  belowAcceptable: 'Below Acceptable',
  acceptable: 'Acceptable',
  recommended: 'Recommended'
};

export const NEW_PERFORMANCE_LEVEL_LABELS = {
  [PERFORMANCE_LEVEL_LABELS.atRisk]: 'Needs Further Preparation',
  [PERFORMANCE_LEVEL_LABELS.belowAcceptable]: PERFORMANCE_LEVEL_LABELS.belowAcceptable,
  [PERFORMANCE_LEVEL_LABELS.acceptable]: PERFORMANCE_LEVEL_LABELS.acceptable,
  [PERFORMANCE_LEVEL_LABELS.recommended]: PERFORMANCE_LEVEL_LABELS.recommended
};

export const PERFORMANCE_LEVEL_CONFIGS = {
  atRisk: {
    range: '<750',
    value: 'atRisk',
    label: PERFORMANCE_LEVEL_LABELS.atRisk,
    backgroundColorClassName: 'u-els-fill-custom-warn-1',
    fillColorClassName: 'u-els-fill-warn',
    stripeStrokeColorClassName: 'u-els-stroke-custom-warn-1'
  },
  belowAcceptable: {
    range: '750-849',
    value: 'belowAcceptable',
    label: PERFORMANCE_LEVEL_LABELS.belowAcceptable,
    backgroundColorClassName: 'u-els-fill-extended-orange-0',
    fillColorClassName: 'u-els-fill-extended-orange-7',
    stripeStrokeColorClassName: 'u-els-stroke-extended-orange-9'
  },
  acceptable: {
    range: '850-899',
    value: 'acceptable',
    label: PERFORMANCE_LEVEL_LABELS.acceptable,
    backgroundColorClassName: 'u-els-fill-custom-acceptable-1',
    fillColorClassName: 'u-els-fill-confirm',
    stripeStrokeColorClassName: 'u-els-stroke-custom-acceptable-1'
  },
  recommended: {
    range: '≥900',
    value: 'recommended',
    label: PERFORMANCE_LEVEL_LABELS.recommended,
    backgroundColorClassName: 'u-els-fill-custom-recommended-2',
    fillColorClassName: 'u-els-fill-custom-recommended-1',
    stripeStrokeColorClassName: 'u-els-stroke-custom-recommended-1'
  }
};

export const PERFORMANCE_NAME_TO_NUMBER_MAP = {
  [PERFORMANCE_LEVEL_LABELS.atRisk]: 0,
  [PERFORMANCE_LEVEL_LABELS.belowAcceptable]: 1,
  [PERFORMANCE_LEVEL_LABELS.acceptable]: 2,
  [PERFORMANCE_LEVEL_LABELS.recommended]: 3
};

export const PERFORMANCE_LEVEL_STACKED_BAR_SPAN = Object.freeze({
  performanceLevel: 62,
  gap: 4,
  height: 15
});
