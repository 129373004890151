import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts-v2';
import { buildDataForChart, renderActiveShape, ReportPieShapeProps } from './ReportPieChartHelper';

export enum ChartType {
  FULL = 'FULL',
  HALF = 'HALF'
}

const ReportPieChartConfig = {
  PIE_CX: '50%',
  PIE_CY: '50%',
  PIE_START_ANGLE: 180,
  PIE_END_ANGLE: 0,
  PIE_INNER_R: '65%',
  PIE_OUTER_R: '100%',
  DATA_POINT_INNER_R: 3.5,
  DATA_POINT_OUTER_R: 9,
  DATA_POINT_STROKE_WIDTH: 3,
  DEFAULT_ANIMATION_BEGIN: 400,
  ANIMATION_DURATION: 1200
};

export interface ReportPieChartProps {
  type: ChartType;
  value: number;
  total: number;
  fillColorClass: string;
  hideIndicator?: boolean;
  gapSize?: number;
  isUseChartInLineWithValue?: boolean;
  multiplePieces?: number[]; // divide pie chart to multiple pieces [50, 25, 25]
  name?: string;
  centerText?: string;
  customChartConfig?: typeof ReportPieChartConfig;
}

export interface ReportPieChartData {
  name: string;
  value: number;
}

const getChartConfig = (type: ChartType) => {
  if (type === ChartType.FULL) {
    return {
      ...ReportPieChartConfig,
      PIE_START_ANGLE: 90,
      PIE_END_ANGLE: -360
    };
  }
  return ReportPieChartConfig;
};

const ReportPieChart = ({ type, value, total, fillColorClass, hideIndicator, gapSize, multiplePieces, name, centerText, customChartConfig }: ReportPieChartProps) => {
  const chartConfig = customChartConfig || getChartConfig(type);
  const paddingAngle = gapSize || 0;

  const { data, activeIndex } = buildDataForChart(value, total, multiplePieces);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          activeIndex={activeIndex}
          activeShape={(shapeProps: ReportPieShapeProps) => renderActiveShape(value, fillColorClass, chartConfig, shapeProps, hideIndicator, name)}
          cx={chartConfig.PIE_CX}
          cy={chartConfig.PIE_CY}
          paddingAngle={paddingAngle}
          startAngle={chartConfig.PIE_START_ANGLE}
          endAngle={chartConfig.PIE_END_ANGLE}
          innerRadius={chartConfig.PIE_INNER_R}
          outerRadius={chartConfig.PIE_OUTER_R}
          animationBegin={chartConfig.DEFAULT_ANIMATION_BEGIN}
          animationDuration={chartConfig.ANIMATION_DURATION}
        >
          {data.map(entry => {
            if (entry.name.includes('rest')) {
              return <Cell key={entry.name} className="u-els-fill-n2 u-els-stroke-n2" />;
            }
            return <Cell key={entry.name} className={fillColorClass} />;
          })}
          {centerText && <Label className="u-els-font-size-h2" value={centerText} position="center" />}
        </Pie>
        {name && <use xlinkHref={`#custom-active-shape-${name}`} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ReportPieChart;
