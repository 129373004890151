import { useHistory } from 'react-router';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { appSelectors } from 'redux/ducks/app';
import { checkPathMatchV2 } from 'helpers/app.helper';
import { FeatureFlagNames, RoutePath } from 'constants/app.constant';
import { ReportConfig } from 'components/common/bento-box/BentoBox';

import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { hadSelectors } from 'reports/had/redux/ducks/had';
import { isFeatureEnabled } from 'services/feature-flag.service';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { PS_PILL_TITLES } from 'reports/ps/constants';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { shSelectors } from 'reports/sh/redux/ducks/sh';
import { HAD_PATHS } from 'reports/had/constants/had.constant';

const useCentralizedLandingPageCardsHook = () => {
  const history = useHistory();
  const allSHCourses = useSelector(shSelectors.getCourses);
  const selectedCourse = useSelector(appSelectors.getSelectedCourse);
  const featureFlags = useSelector(appSelectors.getFeatureFlags);
  const isNclexReportingEnabled = useSelector(appSelectors.getIsNclexReportingEnabled);
  const isTSPLinkFeatureEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.TSP_GATEWAY_LINK_FEATURE_ENABLED, selectedCourse?.toString());
  const isShEnabled = useSelector(appSelectors.getIsShEnabled);
  const isCWBentoBoxLinkShow = useSelector(appSelectors.getIsCWBentoBoxLinkShow);
  const isProgramFetching = useSelector(hadSelectors.getIsProgramsFetching);
  const isCourseFetching = useSelector(courseSelectors.getIsCourseFetching);

  const isHADDisable = !isTSPLinkFeatureEnabled || !isNclexReportingEnabled;

  const onHADNavigation = useCallback(() => {
    const path = RoutePath.had;
    if (!isHADDisable) {
      history.push(path);
    }
  }, [isHADDisable, history]);

  const isCWDisable = !isTSPLinkFeatureEnabled || !isCWBentoBoxLinkShow;

  const onCWNavigation = useCallback(() => {
    if (!isCWDisable) {
      const path = PerformancePath.courseAggregate;
      history.push(path);
    }
  }, [isCWDisable, history]);

  const isPRDDisable = !isTSPLinkFeatureEnabled || !isShEnabled;

  const onPRDNavigation = useCallback(() => {
    if (!isPRDDisable) {
      const { courseAggregate, dashboard } = SH_PATHS;
      const path = allSHCourses?.length > 1 ? courseAggregate : dashboard;
      history.push(path);
    }
  }, [allSHCourses?.length, history, isPRDDisable]);

  const reportConfigs: ReportConfig[] = useMemo(
    () => [
      {
        title: 'HESI Readiness for NCLEX Dashboard',
        description: 'Gain insights into student preparedness for the NCLEX exam, focusing on critical readiness indicators.',
        color: 'blue',
        icon: 'Statistics',
        iconColor: 'u-els-color-secondary',
        text: PS_PILL_TITLES.HESI,
        onNavigation: onHADNavigation,
        disabled: isHADDisable,
        loading: isProgramFetching,
        isSelected: checkPathMatchV2(Object.values(HAD_PATHS))
      },
      {
        title: 'Sherpath Performance & Grades Dashboard',
        description: 'Gain insights into student engagement and the mastery of foundational skills throughout the course.',
        color: 'green',
        icon: 'Neuroimagery',
        text: PS_PILL_TITLES.SHERPATH,
        onNavigation: onCWNavigation,
        disabled: isCWDisable,
        loading: isCourseFetching,
        isSelected: checkPathMatchV2(Object.values(PerformancePath).flatMap(value => (typeof value === 'string' ? value : Object.values(value))))
      },
      {
        title: 'Practice Readiness Dashboard',
        description: 'Gain insights into student development of clinical judgment skills in Shadow Health, preparing them for hands-on clinical experience.',
        color: 'orange',
        icon: 'Stethoscope',
        text: PS_PILL_TITLES.SHADOW_HEALTH,
        onNavigation: onPRDNavigation,
        disabled: isPRDDisable,
        loading: false,
        pillClassName: 'c-centralized-landing-page__content__pill-prd',
        isSelected: checkPathMatchV2(Object.values(SH_PATHS))
      }
    ],
    [isCWDisable, isCourseFetching, isHADDisable, isPRDDisable, isProgramFetching, onCWNavigation, onHADNavigation, onPRDNavigation]
  );

  return { reportConfigs };
};

export default useCentralizedLandingPageCardsHook;
