import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ELSTokenHelper } from 'components/common';
import { appLinkingHelper } from 'helpers';
import { AppConstant, CS_APP_NAME } from 'constants/app.constant';
import { appActions } from 'redux/ducks/app';
import { getBreadcrumbBackTitle } from 'helpers/app-linking.helper';
import { fetchSessionDataFromExternalApp } from 'services/app-linking.service';
import { getCourseSectionIsbns } from 'reports/cw/helpers/course-aggregate.helper';
import { fetchCourses, isCSAppByCourseSection } from 'reports/cw/services/courses.service';
import { EXTERNAL_APP_INFO } from 'constants/externalApp.constant';

const initialState = Immutable({
  courses: [],
  courseSectionInfo: null,
  isCourseFetching: false
});

const setCourses = createAction('COURSE/SET_COURSES');
const setCourseSectionInfo = createAction('COURSE/SET_COURSE_SECTION_INFO');
const restoreState = createAction('COURSE/RESTORE_STATE');
const setIsCourseFetching = createAction('COURSE/SET_IS_COURSE_FETCHING');

const getCourses = email => dispatch => fetchCourses(email).then(data => dispatch(setCourses(data)));

const switchCourse = (courseSectionId, userId) => async (dispatch, getState) => {
  const tokenUser = ELSTokenHelper.getUser();
  const appState = getState()[AppConstant.reduxResources.APP_STATE];
  const { xLinkId, isBackToPreviousAppIconShown, courses = [] } = appState;
  const selectedCourse = courses.find(c => c.id === courseSectionId);
  if (selectedCourse) {
    if (tokenUser?.appParams?.courseId === courseSectionId) {
      return;
    }
    dispatch(setCourseSectionInfo(selectedCourse));
    dispatch(appActions.setSelectedCourse(courseSectionId));

    const isCSApp = isCSAppByCourseSection(selectedCourse);
    if (isCSApp) {
      dispatch(appActions.setBreadcrumbBackTitle(CS_APP_NAME));
    } else if (!xLinkId) {
      dispatch(appActions.setBreadcrumbBackTitle(isBackToPreviousAppIconShown ? EXTERNAL_APP_INFO.APP_LINKING.SHERPATH.backTitle : ''));
    } else {
      const appLinkingSessionData = await fetchSessionDataFromExternalApp(xLinkId);
      const { outPostBody = {}, srcApp } = appLinkingSessionData?.data;
      const { performanceReportPage } = outPostBody;
      const breadcrumbBackTitle = getBreadcrumbBackTitle({ performanceReportPage, srcApp, courseSection: selectedCourse });
      dispatch(appActions.setBreadcrumbBackTitle(breadcrumbBackTitle));
    }

    const isbns = getCourseSectionIsbns(selectedCourse);
    const newToken = await appLinkingHelper.getTokenSwitchCourse({
      courseId: courseSectionId,
      userId,
      sessionId: tokenUser.sessionId,
      appId: tokenUser.appParams.appId,
      isbns
    });
    if (newToken) {
      ELSTokenHelper.storeToken(newToken);
    }
  }
};

const actions = {
  setCourses,
  getCourses,
  setCourseSectionInfo,
  restoreState,
  setIsCourseFetching,
  switchCourse
};

const reducer = handleActions(
  {
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setCourses]: (state, { payload }) => {
      const instructor = payload.data?.find(item => item.type === 'Ins');
      return Immutable.set(state, 'courses', instructor ? instructor.courseSections : []);
    },
    [actions.setCourseSectionInfo]: (state, { payload }) => Immutable.set(state, 'courseSectionInfo', payload),
    [actions.setIsCourseFetching]: (state, { payload }) => Immutable.set(state, 'isCourseFetching', payload)
  },
  initialState
);

const getCoursesState = state => state[AppConstant.reduxResources.COURSES_STATE];

const selectors = {
  getCourses: createSelector(getCoursesState, state => state.courses),
  getCourseSectionInfo: createSelector(getCoursesState, state => state.courseSectionInfo),
  getIsCourseFetching: createSelector(getCoursesState, state => state.isCourseFetching)
};

export { actions as courseActions, reducer as coursesReducer, selectors as courseSelectors };
export { initialState as coursesInitialState };
