import { lazy } from 'react';
import { ROUTE_ROLES } from 'constants/app.constant';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { HAD_PATHS } from 'reports/had/constants/had.constant';

const { INSTRUCTOR, STUDENT } = ROUTE_ROLES;

export const HadRoutes = {
  getRoutes: () => [
    {
      path: HAD_PATHS.studentHome,
      component: lazy(() => import(/* webpackChunkName: "HADStudentHome" */ 'reports/had/components/pages/student-role/student-home/StudentHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:had-student-dashboard`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: STUDENT
    },
    {
      path: HAD_PATHS.dashboard,
      component: lazy(() => import(/* webpackChunkName: "HADHome" */ 'reports/had/components/pages/had-home/HADHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:had-dashboard`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: HAD_PATHS.examDetail,
      component: lazy(() => import(/* webpackChunkName: "HADExamDetail" */ 'reports/had/components/pages/exam-detail-home/ExamDetailHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:had-exam-detail`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: HAD_PATHS.studentDetail,
      component: lazy(() => import(/* webpackChunkName: "HADStudentDetail" */ 'reports/had/components/pages/student-detail-home/StudentDetailHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:had-student-detail`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: HAD_PATHS.cohortDetail,
      component: lazy(() => import(/* webpackChunkName: "HADCohortDetail" */ 'reports/had/components/pages/cohort-detail-home/CohortDetailHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:had-cohort-detail`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: HAD_PATHS.selectCourseNGN,
      component: lazy(() => import(/* webpackChunkName: "HADSelectCourseNGN" */ 'reports/had/components/pages/select-course-ngn/SelectCourseNGN')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:select-course-ngn`,
          pageProductName: 'had'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
