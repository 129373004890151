export default [
  {
    examType: 'Specialty',
    exams: [
      {
        programId: 203811,
        examId: 15076,
        examName: 'RN Fundamentals V-1',
        dateTaken: '2023-03-02',
        cohortId: 14726,
        categories: [
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 945.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 3,
            categoryScore: 1231.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 9,
            categoryScore: 986.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 11,
            categoryScore: 474.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 1256.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 32,
            categoryScore: 863.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 14,
            categoryScore: 705.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 2,
            categoryScore: 1075.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 20,
            categoryScore: 711.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 4,
            categoryScore: 297.0
          }
        ]
      }
    ]
  },
  {
    examType: 'Exit',
    exams: [
      {
        programId: 203811,
        examId: 15061,
        examName: 'RN Exit V-2',
        dateTaken: '2023-03-01',
        cohortId: 14726,
        categories: [
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 10,
            categoryScore: 325.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 23,
            categoryScore: 760.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 28,
            categoryScore: 910.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 31,
            categoryScore: 739.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 9,
            categoryScore: 1051.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 19,
            categoryScore: 710.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 12,
            categoryScore: 797.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 19,
            categoryScore: 1090.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 10,
            categoryScore: 1002.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 80,
            categoryScore: 937.0
          }
        ]
      }
    ]
  }
];
