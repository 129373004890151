import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withHTMLHeadSEO, withLoading } from 'components/common';
import { filterActions, filterSelectors } from 'redux/ducks/filter';
import { AssignmentEngagementMetricDTO, AssignmentEngagementRecord } from 'reports/cw/models';
import { remapFieldsForAssignmentEngagementTable } from 'reports/cw/services/report.service';
import { ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { pluralizeUnit, renderNoDataFound, renderNoResultsFound } from 'helpers/ui.helper';
import TableFilter from 'components/common/table-filter/TableFilter';
import withResizeScreen from 'components/common/with-resize-screen/withResizeScreen';
import { WindowMode } from 'models';
import AssignmentEngagementTable from './assignment-engagement-table/AssignmentEngagementTable';
import AssignmentEngagementTableMobile from './assignment-engagement-table-mobile/AssignmentEngagementTableMobile';

export interface AssignmentEngagementProps {
  searchKeyword: string;
  assignmentTypeId: string;
  filteredAssignmentEngagement: AssignmentEngagementMetricDTO[];
  setSearchKeyword: Function;
  windowMode: WindowMode;
  isIncorrectDataHidden?: boolean;
}

export class AssignmentEngagement extends Component<AssignmentEngagementProps> {
  onSearch = (searchKeyword: string): void => {
    this.props.setSearchKeyword(searchKeyword);
  };

  filterData = (): AssignmentEngagementRecord[] => {
    const { filteredAssignmentEngagement, searchKeyword } = this.props;
    return filteredAssignmentEngagement
      .map(assignment => remapFieldsForAssignmentEngagementTable(assignment))
      .filter(assignment => assignment?.name.toLowerCase().includes(searchKeyword.toLowerCase().trim()));
  };

  render() {
    const { filteredAssignmentEngagement, assignmentTypeId, searchKeyword, windowMode, isIncorrectDataHidden } = this.props;
    if (filteredAssignmentEngagement.length === 0) {
      return renderNoDataFound();
    }

    const data = this.filterData();
    const numberOfAssignments = data.length;
    const assignmentEngagementTableProps = {
      tableData: data,
      assignmentTypeId,
      searchKeyword,
      isIncorrectDataHidden
    };

    return (
      <div className="u-els-margin-top-2x c-cw-assignment-engagement">
        <TableFilter
          filterHidden
          searchKeyword={searchKeyword}
          onSearch={this.onSearch}
          searchPlaceholder="Search by assignment"
          customClass="u-els-margin-top-2x u-els-margin-bottom-2x u-els-margin-bottom-1x@mobile"
        >
          <h4>
            {`Showing `}
            <strong>{`${numberOfAssignments} ${ASSIGNMENT_TYPES[assignmentTypeId].name}`}</strong>
            {` ${pluralizeUnit(numberOfAssignments, 'assignment')}`}
          </h4>
        </TableFilter>
        {windowMode.mobile ? <AssignmentEngagementTableMobile {...assignmentEngagementTableProps} /> : <AssignmentEngagementTable {...assignmentEngagementTableProps} />}
        {data.length === 0 && renderNoResultsFound()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchKeyword: filterSelectors.getCWAssignmentEngagementTableSearch(state)
});

const mapDispatchToProps = dispatch => ({
  setSearchKeyword: (searchKeyword: string) => dispatch(filterActions.setCWAssignmentEngagementTableSearch(searchKeyword))
});

const enhancers = [withHTMLHeadSEO({ title: 'Assignment Engagement' }), withLoading, withRouter, connect(mapStateToProps, mapDispatchToProps), withResizeScreen];

export { AssignmentEngagement as AssignmentEngagementBase };
export default compose(...enhancers)(AssignmentEngagement);
