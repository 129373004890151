import useCourseSelectionModalHook, { CourseSelectionModalHookProps } from './useCourseSelectionModal';
import { renderCourseList } from './CourseSelectionModalHelpers';

export type CourseSelectionModalProps = CourseSelectionModalHookProps;

const CourseSelectionModal = (props: CourseSelectionModalProps) => {
  const { wrapperRef, tempCourseId, radioChangeHandler, goButtonHandler, title, goButtonDescription, selectOptions = [] } = useCourseSelectionModalHook(props);

  return (
    <div className="c-els-modal c-course-select-modal">
      <div ref={wrapperRef} className="u-els-width-1o2 u-els-width-1o1@mobile u-els-padding-2x c-els-modal__window">
        <div className="c-els-field">
          <div className="c-course-select-modal__label">
            <h2 className="c-els-field__label-text">{title}</h2>
          </div>
          <span className="c-els-field__wrap c-els-field__wrap--icon-right">
            <span className="u-els-margin-top-2x c-els-field__label-text">Select Course:</span>
            <div className="o-els-container c-els-radio-modal">
              {renderCourseList({
                courseList: selectOptions,
                selectedCourseId: tempCourseId,
                onSelectCourse: radioChangeHandler
              })}
            </div>
            <button
              onClick={() => goButtonHandler()}
              className="u-els-width-1o1 u-els-margin-top-4x c-els-button c-els-button--default c-els-button--primary c-els-button--c-els-button c-els-button--primary"
              type="button"
            >
              {goButtonDescription}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseSelectionModal;
