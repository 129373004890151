import { ELSRestClientHelper, ELSCommonConfig, ELSLoggingService } from '@els/els-ui-common-react';

export const apiErrorHandler = (error: Error, defaultIfError: any) => {
  ELSLoggingService.error('', error.message, error);
  return defaultIfError;
};

export const withApiErrorHandler = (promiseFunction: () => Promise<any>, defaultIfError: any) =>
  promiseFunction()
    .then(res => res.data)
    .catch(err => apiErrorHandler(err, defaultIfError));

const baseFacadeApi = (baseUrl: any = () => `${ELSCommonConfig.buildUrl}`) => {
  return {
    get facadeUrl() {
      return baseUrl();
    },
    get: (url, options = {}) => ELSRestClientHelper.get(`${baseUrl()}${url}`, options),
    post: (url, options = {}) => ELSRestClientHelper.post(`${baseUrl()}${url}`, options),
    put: (url, options = {}) => ELSRestClientHelper.put(`${baseUrl()}${url}`, options),
    patch: (url, options = {}) => ELSRestClientHelper.patch(`${baseUrl()}${url}`, options),
    delete: (url, options = {}) => ELSRestClientHelper.delete(`${baseUrl()}${url}`, options)
  };
};

export const facadeApi = baseFacadeApi(() => `${ELSCommonConfig.buildUrl}/api`);
export const perFacadeApi = baseFacadeApi(() => `${ELSCommonConfig.buildUrl}/api/performancereport`);
export const tokenServiceApi = baseFacadeApi(() => `${ELSCommonConfig.buildUrl}${ELSCommonConfig.tokenServicePath}`);
export const hesiNGFacadeApi = baseFacadeApi(() => `${ELSCommonConfig.buildUrl}/api/hesi/app`);
