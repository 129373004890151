import { useCallback, useEffect } from 'react';

function useClickOutside(ref, callback: Function) {
  const handleClickOutside = useCallback(
    event => {
      if (ref.current && !ref.current.contains(event.target) && typeof callback === 'function') {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, handleClickOutside, ref]);

  return { handleClickOutside };
}

export default useClickOutside;
