import { FeatureFlagNames } from 'constants/app.constant';
import { worker } from 'mocks/browser';
import { FeatureFlag } from 'models';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/ducks/app';
import { isFeatureEnabled } from 'services/feature-flag.service';
import { useQuery } from '@tanstack/react-query';
import { isEnabledMockService } from 'helpers/app.helper';

// To keep the Mock Service Worker running even when in idle state
const pingMSW = async () => {
  const response = await fetch('/ping-msw');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const MockServiceHandler = () => {
  const featureFlags: FeatureFlag[] = useSelector(appSelectors.getFeatureFlags);
  const enableMockService = isFeatureEnabled(featureFlags, FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER);
  const storageMockServiceWorker = isEnabledMockService();

  // To keep the Mock Service Worker running even when in idle state
  useQuery({
    queryKey: [`ping-msw`],
    queryFn: () => pingMSW(),
    enabled: storageMockServiceWorker,
    refetchInterval: 20000
  });

  // To Restart the Mock Service Worker when the tab is visible
  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible' && isEnabledMockService()) {
      await worker.start({
        onUnhandledRequest: 'bypass' // To bypass other API calls.
      });
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!enableMockService && storageMockServiceWorker) {
      sessionStorage.setItem(FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER, 'false');
      worker.stop();
    }
  }, [enableMockService, storageMockServiceWorker]);

  return null;
};

export default MockServiceHandler;
