import { ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { PerformancePath } from '../constants/cw.constant';

const { INSTRUCTOR } = ROUTE_ROLES;

const studentDetailRoutes = [
  PerformancePath.assignmentEngagement.byStudent,
  PerformancePath.studentEngagement.studentDetail,
  PerformancePath.gradeBook.studentDetail,
  PerformancePath.disengagedStudent.studentDetail,
  PerformancePath.studentDetail
];

export const CwRoutes = {
  getRoutes: () => [
    {
      path: PerformancePath.oldHomepage,
      component: lazy(() => import(/* webpackChunkName: "CWOldHomePage" */ 'reports/cw/components/pages/old-instructor-home/InstructorHomeComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-old-home-page`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.courseAggregate,
      component: lazy(() => import(/* webpackChunkName: "CWCourseAggregatePage" */ 'reports/cw/components/pages/course-aggregate/CourseAggregate')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-course-aggregate-page`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.disengagedStudent.home,
      component: lazy(() => import(/* webpackChunkName: "CWDisengagedStudent" */ 'reports/cw/components/pages/disengaged-student/DisengagedStudent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-disengaged-student`
        }
      },
      isPrivate: true,
      exact: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.engagement.home,
      component: lazy(() => import(/* webpackChunkName: "CWEngagement" */ 'reports/cw/components/pages/engagement/Engagement')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-engagement`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.engagementSummary,
      component: lazy(() => import(/* webpackChunkName: "CWEngagementSummary" */ 'reports/cw/components/pages/engagement-summary/EngagementSummary')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-engagement-summary`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.assignmentDetail,
      component: lazy(() => import(/* webpackChunkName: "CWAssignmentDetail" */ 'reports/cw/components/pages/assignment-detail/AssignmentDetailComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-assignment-detail`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.assignmentEngagement.byAssignment,
      component: lazy(() => import(/* webpackChunkName: "CWAssignmentEngagement" */ 'reports/cw/components/pages/assignment-engagement/AssignmentEngagement')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-assignment-engagement`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.assignmentSummary,
      component: lazy(() => import(/* webpackChunkName: "CWAssignmentSummary" */ 'reports/cw/components/pages/assignment-summary/AssignmentSummaryComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-assignment-summary`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.studentEngagement.summary,
      component: lazy(() => import(/* webpackChunkName: "CWStudentEngagement" */ 'reports/cw/components/pages/student-engagement/StudentEngagement')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-student-engagement`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PerformancePath.gradeBook.home,
      component: lazy(() => import(/* webpackChunkName: "CWGradebook" */ 'reports/cw/components/pages/gradebook/Gradebook')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-gradebook`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    ...studentDetailRoutes.map(route => ({
      path: route,
      component: lazy(() => import(/* webpackChunkName: "CWStudentDetail" */ 'reports/cw/components/pages/student-detail/StudentDetailComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:cw-student-detail`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }))
  ]
};
