import React, { ReactElement, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';

import withRedirectToOutsideApp from 'components/common/with-redirect-to-outside-app/withRedirectToOutsideApp';
import { AssignmentEngagementRecord } from 'reports/cw/models/ui';
import { cwSelectors } from 'reports/cw/redux/ducks/cw';
import { ASSIGNMENT_TYPE_GROUPS, ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { CW_TABLE_SELECTOR } from 'reports/cw/constants/cw.constant';
import { appSelectors } from 'redux/ducks/app';
import { FeatureFlag } from 'models';
import TableCard, { TableRows } from 'components/common/table-card/TableCard';
import { querySelectorWrapper } from 'services/method-wrapper.service';
import { TableRowProps } from 'components/common/table-card/table-row/TableRow';
import { sortRecords } from 'components/common/responsive-table/ResponsiveTable';
import {
  renderPercentCompletion,
  renderDueDate,
  renderClassAvgGrade,
  renderClassAvgTimeSpent,
  renderScoringType,
  renderName
} from '../assignment-engagement-table-helper/AssignmentEngagementTableHelper';

export interface AssignmentEngagementDetailViewProps {
  featureFlags: FeatureFlag[];
  tableData: AssignmentEngagementRecord[];
  assignmentTypeId: string;
  searchKeyword: string;
  xLinkId: string;
  isBackToPreviousAppIconShown: boolean;
  courseId: string;
  onRedirectToOutsideApp: Function;
  isIncorrectDataHidden?: boolean;
}

const AssignmentEngagementTableMobile: React.FC<AssignmentEngagementDetailViewProps> = ({
  featureFlags,
  tableData,
  assignmentTypeId,
  searchKeyword,
  xLinkId,
  isBackToPreviousAppIconShown,
  courseId,
  onRedirectToOutsideApp,
  isIncorrectDataHidden
}) => {
  const isEAQAssignmentType = ASSIGNMENT_TYPES[assignmentTypeId].group === ASSIGNMENT_TYPE_GROUPS.EAQ;
  const assignmentEngagementCardAndTableSortInteraction = useSelector(state => cwSelectors.getAssignmentEngagementCardAndTableSortInteraction(state));
  const [sortedTableData, setSortedTableData] = useState<AssignmentEngagementRecord[]>(tableData);

  const percentCompleteRow: TableRowProps<AssignmentEngagementRecord> = { field: 'percentCompletion', header: '% Complete', customRenderContent: renderPercentCompletion };
  const classAvgScoreRow: TableRowProps<AssignmentEngagementRecord> = {
    field: 'classAvgScore',
    header: 'Class Avg Score',
    customRenderContent: renderClassAvgGrade,
    sortNARecordInBottom: true
  };

  const defaultrows: TableRows<AssignmentEngagementRecord> = {
    default: [
      ...(isIncorrectDataHidden ? [] : [percentCompleteRow]),
      ...(isIncorrectDataHidden ? [] : [classAvgScoreRow]),
      {
        field: 'classAvgTimeSpentSeconds',
        header: isEAQAssignmentType ? 'Class Avg Time Spent per Question' : 'Class Avg Time Spent',
        customRenderContent: renderClassAvgTimeSpent
      }
    ],
    expanded: [{ field: 'classAvgScore', header: '#Past Due' }]
  };

  const defaultFields: TableRowProps<AssignmentEngagementRecord>[] = [
    ...(isIncorrectDataHidden ? [] : [percentCompleteRow]),
    { field: 'scoringType', header: 'Scoring Type', customRenderContent: renderScoringType },
    ...(isIncorrectDataHidden ? [] : [classAvgScoreRow])
  ];

  const expandedFields: TableRowProps<AssignmentEngagementRecord>[] = [
    {
      field: 'classAvgTimeSpentSeconds',
      header: isEAQAssignmentType ? 'Class Avg Time Spent per Question' : 'Class Avg Time Spent',
      customRenderContent: renderClassAvgTimeSpent
    },
    { field: 'dueDate', header: 'Due Date', customRenderContent: renderDueDate }
  ];

  const customExpandRows: TableRows<AssignmentEngagementRecord> = {
    default: defaultFields,
    expanded: isEAQAssignmentType ? [{ field: 'numberOfAvgQuestionAttempt', header: 'Avg # Questions Attempted ' }, ...expandedFields] : expandedFields
  };
  const recordColumns = [...customExpandRows.default, ...customExpandRows.expanded];

  useEffect(() => {
    if (assignmentEngagementCardAndTableSortInteraction) {
      const { field, sortDirection } = assignmentEngagementCardAndTableSortInteraction;
      setSortedTableData(sortRecords(recordColumns, [...tableData], field, sortDirection));
      querySelectorWrapper(CW_TABLE_SELECTOR.assignmentEngagement)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [assignmentEngagementCardAndTableSortInteraction, tableData]);

  const renderTitle = (row: AssignmentEngagementRecord): ReactElement => {
    return renderName(row, featureFlags, courseId, xLinkId, isBackToPreviousAppIconShown, searchKeyword, assignmentTypeId, onRedirectToOutsideApp);
  };

  return (
    <div className="c-cw-assignment-engagement-table">
      {sortedTableData.map((row, index) => {
        const title = renderTitle(sortedTableData[index]);
        return <TableCard key={row.id} hideTitleIcon data={row} title={title} rows={defaultrows} customExpandRows={customExpandRows} />;
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  assignmentEngagementCardAndTableSortInteraction: cwSelectors.getAssignmentEngagementCardAndTableSortInteraction(state),
  courseId: appSelectors.getSelectedCourse(state),
  featureFlags: appSelectors.getFeatureFlags(state),
  xLinkId: appSelectors.getLinkId(state)
});

const enhancers = [connect(mapStateToProps), withRedirectToOutsideApp];

export { AssignmentEngagementTableMobile as AssignmentEngagementTableMobileBase };

export default compose(...enhancers)(AssignmentEngagementTableMobile);
