import { useState, ReactNode, cloneElement, ReactElement } from 'react';
import { toArray } from 'lodash';
import classNames from 'classnames';
import { CollapseProps } from './Collapse';

interface UseCollapseHookProps extends CollapseProps {
  children?: ReactNode;
}

interface UseCollapseHookResponse {
  expandedList: string[];
  handleClickItem: (key: string) => void;
  getItems: () => ReactElement[];
  collapseClassName: string;
}

const useCollapse = (props: UseCollapseHookProps): UseCollapseHookResponse => {
  const { activeKey = [], className, showArrow = true, collapseIconName = 'chevron-down', expandIconName = 'chevron-up' } = props;
  const [expandedList, setExpandedList] = useState<string[]>(activeKey);

  const handleClickItem = (key: string): void => {
    let updatedList = [];
    if (expandedList.includes(key)) {
      updatedList = expandedList.filter(item => item !== key);
    } else {
      updatedList = [...expandedList, key];
    }
    setExpandedList(updatedList);
  };

  const getItems = (): ReactElement[] => {
    const { children } = props;
    return toArray(children).map((child: React.ReactElement) => {
      const childProps = {
        ...child.props,
        onClickItem: handleClickItem,
        isCollapse: !expandedList.includes(child.props?.labelKey),
        showArrow,
        collapseIconName,
        expandIconName
      };
      return cloneElement(child, childProps);
    });
  };

  const collapseClassName = classNames('c-els-accordion', 'c-els-accordion--icon-right', 'c-collapse', { [className]: className });

  return {
    expandedList,
    handleClickItem,
    getItems,
    collapseClassName
  };
};
export default useCollapse;
