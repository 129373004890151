import { ELSCommonConfig } from '@els/els-ui-common-react';

const qaContentBaseURL = 'https://content-qa.els-ols.com';

const commonConfig = {
  TokenServiceEnabled: true, // toggle for enabling token service integration
  CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
  EnableLogging: true
};

const getEnvConfig = (name: string, overrides?: any) => {
  return {
    gatewayBaseURL: `https://eolsapi-${name}.apps.els-ols.com`,
    contentBaseURL: `https://content-${name}.els-ols.com`,
    cookiesDomainWithApps: '.apps.els-ols.com',
    cookiesDomain: '.els-ols.com',
    eolsEnv: '',
    config: commonConfig,
    shadowHealthAppUrl: 'https://app-staging.shadowhealth.com/',
    ...overrides
  };
};

export const ServerConstants = {
  DataSource: ELSCommonConfig.appProfile, // Replace with the preferred source from below.

  local: getEnvConfig('local', {
    gatewayBaseURL: 'http://localhost:8080',
    contentBaseURL: qaContentBaseURL
  }),
  dev: getEnvConfig('dev', {
    contentBaseURL: 'https://content-dev.els-ols.com/dev'
  }),
  qa: getEnvConfig('qa', {
    gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com',
    contentBaseURL: qaContentBaseURL
  }),
  demo: getEnvConfig('demo', {
    contentBaseURL: qaContentBaseURL
  }),
  test1: getEnvConfig('test1'),
  test2: getEnvConfig('test2', {
    ngnResultUrl: 'https://puma.dev.authess.health.elsevier.com/felt/library/eols/hesi/results'
  }),
  test3: getEnvConfig('test3'),
  test4: getEnvConfig('test4'),
  test5: getEnvConfig('test5'),
  test6: getEnvConfig('test6'),
  test7: getEnvConfig('test7'),
  'ck-cert': getEnvConfig('test7'),
  'cd-staging': getEnvConfig('stage'),
  staging: getEnvConfig('stage'),
  stage: getEnvConfig('stage', {
    ngnResultUrl: 'https://puma.stage.authess.health.elsevier.com/felt/library/eols/hesi/results'
  }),
  stage1: getEnvConfig('stage1'),
  stage2: getEnvConfig('stage2'),
  validate: getEnvConfig('validate', {
    eolsEnv: 'validate',
    contentBaseURL: qaContentBaseURL
  }),
  vendor: getEnvConfig('vendor'),
  perf: getEnvConfig('perf', {
    contentBaseURL: 'https://content-perf.els-ols.com/dev'
  }),
  betaprod: getEnvConfig('betaprod', {
    contentBaseURL: 'https://content-betaprod.els-ols.com/dev',
    config: {
      ...ELSCommonConfig,
      PushEventsEnabled: true,
      PushEventsUsesGateway: true
    }
  }),
  prod: getEnvConfig('prod', {
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    contentBaseURL: 'https://eolscontent.elsevier.com',
    cookiesDomain: '.elsevier.com',
    cookiesDomainWithApps: '.elsevier.com',
    ngnResultUrl: 'https://puma-authess.elsevier.com/felt/library/eols/hesi/results',
    shadowHealthAppUrl: 'https://app.shadowhealth.com/'
  })
};
