import { configureHandler } from 'mocks/helpers';
import courseSectionInstructor98812 from './_data/98812';
import courseSectionInstructor979826 from './_data/979826';

export default configureHandler(async (req, res, ctx) => {
  const instructorId = req.url.searchParams.get('instructorId');

  if (instructorId === '98812') {
    return res(ctx.status(200), ctx.json(courseSectionInstructor98812));
  }

  if (instructorId === '979826') {
    return res(ctx.status(200), ctx.json(courseSectionInstructor979826));
  }

  return req.passthrough();
});
