import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { ROUTE_ROLES } from 'constants/app.constant';
import { HESI_NG_PATHS } from 'reports/hesi-ng/constants/hesi-ng.constant';

const { INSTRUCTOR, STUDENT } = ROUTE_ROLES;
const { hesiNGExamResults, hesiNGExamResultsStudentDetail, hesiNGStudentExamResults } = HESI_NG_PATHS;

export const HesiNGRoutes = {
  getRoutes: () => [
    {
      path: hesiNGStudentExamResults,
      component: lazy(() => import(/* webpackChunkName: "HesiNGStudentExamResults" */ 'reports/hesi-ng/components/pages/student-exam-results/HesiNGStudentExamResults')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:hesi-ng-student-exam-results`,
          pageProductName: 'hesi-ng'
        }
      },
      isPrivate: true,
      roles: STUDENT
    },
    {
      path: hesiNGExamResultsStudentDetail,
      component: lazy(() => import(/* webpackChunkName: "StudentExamResultsDetail" */ 'reports/hesi-ng/components/pages/exam-results-student-detail/HesiExamResultsStudentDetail')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:hesi-ng-exam-results-student-detail`,
          pageProductName: 'hesi-ng'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: hesiNGExamResults,
      component: lazy(() => import(/* webpackChunkName: "HesiExamResults" */ 'reports/hesi-ng/components/pages/hesi-exam-results/HesiExamResults')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:hesi-ng-exam-results`,
          pageProductName: 'hesi-ng'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
