export default {
  data: [
    {
      id: 679190,
      role: 'instructor',
      firstName: 'Instructor',
      lastName: 'Testing',
      emailAddress: 'nhesaleseffectiveness@elsevier.com',
      evolveUsername: 'itesting'
    }
  ]
};
