import { useState } from 'react';
import { connect } from 'react-redux';
import { ELSCommonUIConstants, ELSPageLoader, ELSTokenHelper } from '@els/els-ui-common-react';
import { HESI_NG_TARGET_PAGES } from 'reports/hesi-ng/constants/hesi-ng.constant';
import { appSelectors } from 'redux/ducks/app';
import { ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { LinkConfigType, RedirectOptions, AppLinkOutPostBody } from 'models/app-linking';
import { redirectToOutsideApp } from 'services/app-linking.service';
import { fetchAllEolsUserByEolsUserId } from 'services/user-management.service';
import { SystemType } from 'models';
import { getTokenSwitchCourse } from 'helpers/app-linking.helper';

const mapStateToProps = state => ({
  selectedCourseId: appSelectors.getSelectedCourse(state),
  courseSectionInfo: courseSelectors.getCourseSectionInfo(state),
  isBackToPreviousAppIconShown: appSelectors.getIsBackToPreviousAppIconShown(state),
  xLinkId: appSelectors.getLinkId(state),
  appLinkOutPostBody: appSelectors.getAppLinkOutPostBody(state)
});

export const getNewTokenParams = async (token, selectedCourseId) => {
  const { userId: tokenUserId, sessionId: tokenSessionId } = token?.user;

  const allUserData = await fetchAllEolsUserByEolsUserId(tokenUserId, SystemType.EVOLVETYPE).catch(e => e);
  const { data } = allUserData;

  const selectedCourseData = data
    .flatMap(user => {
      return user.courseSections.flatMap(course => {
        const courseIsbns = course.entitlements.flatMap(entitlement => {
          return entitlement.isbn;
        });
        return { courseIsbns, courseId: course.id, userId: user.id };
      });
    })
    .filter(course => course.courseId === selectedCourseId)[0];
  return {
    appId: ELSCommonUIConstants.appIds.PERFORMANCE_REPORT,
    courseId: selectedCourseData.courseId,
    isbns: selectedCourseData.courseIsbns,
    sessionId: tokenSessionId,
    userId: selectedCourseData.userId
  };
};

export default WrappedComponent => {
  const LoadDataHOC = props => {
    const [isRedirectToOutsideAppLoading, setIsRedirectToOutsideAppLoading] = useState(false);

    const handleRedirectToOutsideApp = async (linkConfig: LinkConfigType, options: RedirectOptions, assignmentType?: string) => {
      const { selectedCourseId, appLinkOutPostBody, courseSectionInfo } = props;
      const { data = {}, ...otherOptions } = options || {};
      const finalCourseId = data?.courseId || selectedCourseId;

      // check to see if this is an Evolve token. If so get a Learn one.
      const token = ELSTokenHelper.parseToken(ELSTokenHelper.getToken());
      const { isbns: tokenIsbns } = token?.user?.appParams;
      let updatedLinkConfig = linkConfig;
      let updatedOptions = options;

      if ((!tokenIsbns || tokenIsbns.length === 0) && !Object.keys(HESI_NG_TARGET_PAGES).includes(data?.targetPage)) {
        const newTokenParams = await getNewTokenParams(token, finalCourseId);
        if (newTokenParams.isbns.length > 0) {
          const newToken = await getTokenSwitchCourse(newTokenParams);
          ELSTokenHelper.storeToken(newToken);
          updatedOptions = { ignoreToken: false, headers: { Authorization: `Bearer ${newToken}` } };
          updatedLinkConfig = { ...updatedLinkConfig, isIncludeLinkHash: true };
        }
      }

      const customBody: AppLinkOutPostBody = {
        keptAppLinkOutPostBody: appLinkOutPostBody,
        courseId: finalCourseId,
        courseSectionId: finalCourseId,
        ...otherOptions,
        ...data
      };
      if (assignmentType === ASSIGNMENT_TYPES.SHADOW_HEALTH.id) {
        customBody.courseSection = courseSectionInfo;
      }

      setIsRedirectToOutsideAppLoading(true);
      await redirectToOutsideApp(updatedLinkConfig, { ...updatedOptions, data: customBody, ...otherOptions });
      setIsRedirectToOutsideAppLoading(false);
    };

    return (
      <>
        {isRedirectToOutsideAppLoading && <ELSPageLoader />}
        <WrappedComponent {...props} onRedirectToOutsideApp={handleRedirectToOutsideApp} />
      </>
    );
  };
  return connect(mapStateToProps)(LoadDataHOC);
};
