import { Component } from 'react';
import { ELSPageLoader } from '@els/els-ui-common-react';

interface WrapperProps {
  isLoading: boolean;
}

const withLoading = BaseComponent =>
  class Wrapper extends Component<WrapperProps> {
    render() {
      return (
        <div>
          {this.props.isLoading ? <ELSPageLoader /> : null}
          <BaseComponent {...this.props} />
        </div>
      );
    }
  };

export default withLoading;
