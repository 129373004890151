import { uniqBy } from 'lodash';
import { perFacadeApi, facadeApi } from 'helpers/api.helper';
import { PSStudentAssessAndReview, PSAllSherpathCourseAggregations, PSStudentProfileData, PSStudent } from 'reports/ps/models/ui';
import { Program } from 'reports/had/models';
import { mapEAQWithIsbnAndStudentId } from 'mocks/demo-prod/map';
import { isEnabledMockService } from 'helpers/app.helper';
import { PSHesiRecentExamsResponse, TopicMastery, ProgramMeanHesiMetric, ExternalEntitiesDto, CatalogDto } from '../models';
import { RESOURCE_TYPES } from '../constants';

const fetchAssesAndReviewData = (evolveUserName: string): Promise<{ data: PSStudentAssessAndReview }> => {
  if (evolveUserName) {
    return perFacadeApi.get(`/ps/studentprofile/assessreview/${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as PSStudentAssessAndReview });
};

const fetchProgramAssessAndReview = (programId: number): Promise<{ data: ProgramMeanHesiMetric }> => {
  const params = new URLSearchParams();
  if (programId) {
    params.append('programId', programId.toString());
    return perFacadeApi.get(`/hesi/program/assessreview?programId=${programId}`);
  }
  return Promise.resolve({ data: {} as ProgramMeanHesiMetric });
};

const fetchAllCourseAggregations = (studentId: number): Promise<{ data: PSAllSherpathCourseAggregations }> => {
  if (studentId) {
    return perFacadeApi.get(`/ps/studentprofile/allcourseaggregations/${studentId}`);
  }
  return Promise.resolve({ data: {} as PSAllSherpathCourseAggregations });
};

const fetchStudentProfile = (evolveUserName: string): Promise<{ data: PSStudentProfileData }> => {
  if (evolveUserName) {
    return perFacadeApi.get(`/ps/studentprofile/${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as PSStudentProfileData });
};

const fetchInstructorRecentExams = (programId: number): Promise<{ data: PSHesiRecentExamsResponse }> => {
  if (programId) {
    return perFacadeApi.get(`/hesi/recentExams/program/${programId}`);
  }
  return Promise.resolve({ data: {} as PSHesiRecentExamsResponse });
};

const fetchStudentProfileCards = (evolveUsername: string): Promise<{ data: PSStudentProfileData }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/ps/instructorprofile/assignment/${evolveUsername}`);
  }
  return Promise.resolve({ data: {} as PSStudentProfileData });
};

const fetchSHCoursesCount = (evolveUsername: string): Promise<{ data: { courseCount: number } }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/ps/instructorprofile/shadowcourse/${evolveUsername}`);
  }
  return Promise.resolve({ data: { courseCount: 0 } });
};

const fetchStudentsByUserId = (evolveUserName: string): Promise<{ data: PSStudent[] }> => {
  const params = new URLSearchParams();
  const baseUrl = '/allCourses/students';
  if (evolveUserName) {
    params.append('evolveUserName', evolveUserName.toString());
  }
  return perFacadeApi.get(`${baseUrl}?${params}`);
};

const fetchPrograms = (evolveUsername?: string): Promise<{ data: Program[] }> => {
  const params = new URLSearchParams();
  const baseUrl = '/hesi/dwh/cohort/programs';
  if (evolveUsername) {
    params.append('evolveUsername', evolveUsername.toString());
  }
  return perFacadeApi.get(`${baseUrl}?${params}`);
};

const fetchStudentAssessmentMasteryByIsbnList = async (isbnIds: string[], studentId: number): Promise<TopicMastery[]> => {
  const isbnList = isEnabledMockService() && mapEAQWithIsbnAndStudentId[studentId] ? mapEAQWithIsbnAndStudentId[studentId] : isbnIds;

  try {
    if (isbnList.length && studentId) {
      const promises: Promise<{ data: TopicMastery[] }>[] = isbnList.map(isbn => perFacadeApi.get(`/assessment/mastery/${isbn}/student/${studentId}`));
      const responses = await Promise.all(promises);
      return uniqBy(
        responses.flatMap(({ data = [] }) => data),
        'assessmentTopic.chapterId'
      );
    }
    return Promise.resolve([]);
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchCatalogs = (isbnList: string[], resourceType?: string): Promise<{ data: { catalog: CatalogDto; externalEntities: ExternalEntitiesDto[] } }> => {
  const params = new URLSearchParams();
  const learningTypeQuery = 'filter[learningType]';
  const baseUrl = '/sherpath/course-management/recommendation/learning/catalog/v2';
  isbnList.forEach(isbn => {
    params.append('filter[isbns]', isbn);
  });
  params.append('included', 'TAXONOMY');
  if (resourceType) {
    params.append(learningTypeQuery, resourceType);
  } else {
    Array.from(Object.keys(RESOURCE_TYPES)).forEach(type => {
      params.append(learningTypeQuery, type);
    });
  }
  return facadeApi.get(`${baseUrl}?${params}`);
};

export {
  fetchAssesAndReviewData,
  fetchStudentProfile,
  fetchAllCourseAggregations,
  fetchSHCoursesCount,
  fetchStudentsByUserId,
  fetchInstructorRecentExams,
  fetchPrograms,
  fetchProgramAssessAndReview,
  fetchStudentAssessmentMasteryByIsbnList,
  fetchStudentProfileCards,
  fetchCatalogs
};
