import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';
import { AppConstant } from 'constants/app.constant';

const reducer = handleActions(
  {
    [LOCATION_CHANGE]: (state, action) => state.merge({ locationBeforeTransitions: action.payload })
  },
  Immutable({ locationBeforeTransitions: null })
);

const getRouteState = state => state[AppConstant.reduxResources.ROUTER];

const routerSelectors = {
  getLocation: createSelector(getRouteState, state => (state ? state.location : {})),
  getSearch: createSelector(getRouteState, state => (state ? state.location.search : ''))
};

export { reducer as routeReducer, routerSelectors };
