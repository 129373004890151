import React from 'react';
import Cookies from 'js-cookie';
import { ELSRestClientHelper, ELSCommonUIConstants } from '@els/els-ui-common-react';
import { facadeApi, tokenServiceApi } from 'helpers/api.helper';
import { EXTERNAL_APP_INFO } from 'constants/externalApp.constant';
import { EOLSUser, FeatureFlag } from 'models';
import { AppLinkDataDto, AppLinkHashDataDTO, AssignmentAppLinkConfig, LinkConfigType, RenderAppLinkingAssignmentNameProps, RedirectOptions } from 'models/app-linking';
import { CS_APP_NAME, EAB_APP_NAME, FeatureFlagNames, X_LINK_ID_COOKIE, X_TOKEN_COOKIE } from 'constants/app.constant';
import { ASSIGNMENT_TYPE_GROUPS, ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { isFeatureEnabled } from './feature-flag.service';
import { windowLocationAssignWrapper } from './method-wrapper.service';
import { BreadcrumbItem } from '../components/common/breadcrumb';
import { ServerConstants } from '../config/server.constant';

// "altSrcApp" is the appId where we want to back to after going to "targetApp" with app-linking.
// Ex: "targetApp" is EAQ --> we will go to the EAQ app. Then from the EAQ app we want to back to "altSrcApp".
export const getAppLinkOut = (linkConfig: LinkConfigType, options: RedirectOptions): Promise<{ data: { redirectUrl: string } }> => {
  const { appName: targetApp, action, isIncludeLinkHash, altSrcApp = ELSCommonUIConstants.appIds.PERFORMANCE_REPORT } = linkConfig;
  const removeCookiesAppLink = () => {
    Cookies.remove(X_TOKEN_COOKIE, { path: '/', domain: ServerConstants[ServerConstants.DataSource].cookiesDomain });
    Cookies.remove(X_TOKEN_COOKIE, { path: '/', domain: ServerConstants[ServerConstants.DataSource].cookiesDomainWithApps });
    Cookies.remove(X_LINK_ID_COOKIE, { path: '/', domain: ServerConstants[ServerConstants.DataSource].cookiesDomain });
    Cookies.remove(X_LINK_ID_COOKIE, { path: '/', domain: ServerConstants[ServerConstants.DataSource].cookiesDomainWithApps });
  };
  removeCookiesAppLink();
  if (isIncludeLinkHash) {
    return facadeApi.post(`/app-link/out/${targetApp}/${action}?includeLinkHash=true&altSrcApp=${altSrcApp}`, options);
  }
  return facadeApi.post(`/app-link/out/${targetApp}/${action}?altSrcApp=${altSrcApp}`, options);
};

export const getNewTokenForCourseSwitcher = (body: object) => {
  return facadeApi.post('/sherpath/course-management/courseSections/switch', {
    data: body
  });
};

export const fetchSessionDataFromExternalApp = (linkId: string): Promise<{ data: AppLinkDataDto }> => {
  return ELSRestClientHelper.get(`${EXTERNAL_APP_INFO.APP_LINK_DATA_API}/${linkId}`);
};

export const fetchDataBackToExternalApp = (linkId: string): Promise<{ data: { redirectUrl: string } }> => {
  if (linkId) {
    return ELSRestClientHelper.post(`${EXTERNAL_APP_INFO.APP_LINK_RETURN_API}/${linkId}`);
  }
  return Promise.reject(new Error('Invalid link id value: argument must be a non-null string'));
};

export const fetchAppLinkHashData = (linkHash: string): Promise<{ data: AppLinkHashDataDTO }> => {
  if (linkHash) {
    const option = {
      headers: {
        'X-App-ID': EXTERNAL_APP_INFO.APP_LINKING.QUAD.APP_NAME
      }
    };
    return ELSRestClientHelper.get(`${EXTERNAL_APP_INFO.APP_LINK_HASH_API}/${linkHash}`, option);
  }
  return Promise.reject(new Error('Invalid link hash value: argument must be a non-null string'));
};

export const redirectToOutsideApp = async (linkConfig: LinkConfigType, options: RedirectOptions, _getAppLinkOut = getAppLinkOut): Promise<void> => {
  const { appName } = linkConfig;
  const { isNewTab, ...restOptions } = options;
  const headers = options?.headers ?? {};
  if (appName === EXTERNAL_APP_INFO.APP_LINKING.SHADOW_HEALTH.APP_NAME) {
    headers['cache-control'] = 'no-store, no-cache, max-age=0, must-revalidate';
  }
  const customOptions: RedirectOptions = {
    ...restOptions,
    headers
  };
  const url = await _getAppLinkOut(linkConfig, customOptions);
  if (url?.data?.redirectUrl) {
    windowLocationAssignWrapper(url.data.redirectUrl, isNewTab);
  }
};

export const shouldRenderAssignmentDetailAppLinkUrl = (assignmentType: string, featureFlags: FeatureFlag[], groupId: string, isInstructor = true): boolean => {
  const isAssignmentDetailAppLinkEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_ASSIGNMENT_DETAIL_APP_LINK_ENABLED, groupId);
  if (!isAssignmentDetailAppLinkEnabled) {
    return false;
  }
  const isSHEREVOLLinked = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_ASSIGNMENT_DETAIL_LINK_TO_SHER_EVOL_ENABLED, groupId);
  const isEABLinked = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_ASSIGNMENT_DETAIL_LINK_TO_EAB_ENABLED, groupId);
  const isOsmosisLinked = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_ASSIGNMENT_DETAIL_LINK_TO_OSMOSIS_ENABLED, groupId);
  const SHEREVOLTypes = [ASSIGNMENT_TYPES.SKILL.id, ASSIGNMENT_TYPES.SIMULATION.id, ASSIGNMENT_TYPES.LESSONS.id];
  return (
    ASSIGNMENT_TYPES[assignmentType]?.isLinkedToOutsideApp ||
    (isSHEREVOLLinked && SHEREVOLTypes.includes(ASSIGNMENT_TYPES[assignmentType]?.id)) ||
    (isEABLinked && assignmentType === ASSIGNMENT_TYPES.PERSONAL_ASSESSMENT_BUILDER.id) ||
    (isOsmosisLinked && assignmentType === ASSIGNMENT_TYPES.OSMOSIS_VIDEO.id) ||
    (isInstructor && assignmentType === ASSIGNMENT_TYPES.INTERACTIVE_REVIEW.id)
  );
};

export const buildLinkConfig = (assignmentConfig: AssignmentAppLinkConfig): LinkConfigType => {
  if (ASSIGNMENT_TYPES[assignmentConfig.type].group === ASSIGNMENT_TYPE_GROUPS.EAQ) {
    if (assignmentConfig.type === ASSIGNMENT_TYPES.AUTHESS.id) {
      return {
        appName: EXTERNAL_APP_INFO.APP_LINKING.AUTHESS.APP_NAME,
        action: EXTERNAL_APP_INFO.APP_LINKING.AUTHESS.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
        isIncludeLinkHash: true
      };
    }

    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.EAQ.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.EAQ.ACTION.ASSIGNMENT_PERFORMANCE_VIEW
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.ADAPTIVE_LESSON.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.KNOWLEDGE_CARD.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.KNOWLEDGE_CARD.ACTION.ASSIGNMENT_PERFORMANCE_VIEW
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.CHART.id || assignmentConfig.type === ASSIGNMENT_TYPES.CASE_STUDY.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.SIMSNG.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.SIMSNG.ACTION.ASSIGNMENT_PERFORMANCE_VIEW
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.SHADOW_HEALTH.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.SHADOW_HEALTH.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.SHADOW_HEALTH.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
      isIncludeLinkHash: true
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.PERSONAL_ASSESSMENT_BUILDER.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.PERSONAL_ASSESSMENT_BUILDER.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.PERSONAL_ASSESSMENT_BUILDER.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
      isIncludeLinkHash: true
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.LESSONS.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
      isIncludeLinkHash: true
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.OSMOSIS_VIDEO.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.OSMOSIS_VIDEO.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.OSMOSIS_VIDEO.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
      isIncludeLinkHash: true
    };
  }

  if (assignmentConfig.type === ASSIGNMENT_TYPES.INTERACTIVE_REVIEW.id) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.ASSIGNMENT_MANAGEMENT.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.ASSIGNMENT_MANAGEMENT.ACTION.ASSIGNMENT_PERFORMANCE_VIEW,
      isIncludeLinkHash: true
    };
  }

  const SHEREVOLTypes = [ASSIGNMENT_TYPES.SKILL.id, ASSIGNMENT_TYPES.SIMULATION.id, ASSIGNMENT_TYPES.LESSONS.id];
  if (SHEREVOLTypes.includes(ASSIGNMENT_TYPES[assignmentConfig.type].id)) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.ACTION.ASSIGNMENT_PERFORMANCE_VIEW
    };
  }
  return { appName: '', action: '' };
};

export const renderAppLinkingAssignmentName = ({ assignmentConfig, assignmentType, body, handleRedirectToOutsideApp }: RenderAppLinkingAssignmentNameProps) => {
  const linkConfig = buildLinkConfig(assignmentConfig);
  const finalBody = { assignmentId: parseInt(assignmentConfig.id, 10), ...body };
  return (
    <span
      role="button"
      tabIndex={0}
      className="c-els-link"
      onClick={() => handleRedirectToOutsideApp(linkConfig, finalBody, assignmentType)}
      onKeyDown={evt => onKeyDownHandler(evt, () => handleRedirectToOutsideApp(linkConfig, finalBody, assignmentType))}
      title={assignmentConfig.name}
    >
      {assignmentConfig.name}
    </span>
  );
};

export const renderCourseNameBreadcrumb = (courseName: string, xLinkId: string, isBackToPreviousAppIconShown: boolean, handleBackPreviousAppIconClick?: Function) => {
  return (
    <BreadcrumbItem>
      {xLinkId && isBackToPreviousAppIconShown ? (
        <span className="c-breadcrumb-link" role="button" tabIndex={0} onKeyDown={() => handleBackPreviousAppIconClick()} onClick={() => handleBackPreviousAppIconClick()}>
          {courseName}
        </span>
      ) : (
        courseName
      )}
    </BreadcrumbItem>
  );
};

export const isEABAppByAppName = (appName: string): boolean => {
  return appName === EAB_APP_NAME;
};

export const isCSAppByAppName = (appName: string): boolean => {
  return appName === CS_APP_NAME;
};

export const refreshToken = (): Promise<{ data: { token: string } }> => {
  return tokenServiceApi.post('/refresh');
};

export const getDemoToken = async (currentUser: EOLSUser, token: string): Promise<{ data: { token: string } }> => {
  const { appParams, userId, id, role } = currentUser;
  const payload = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      user: {
        id: userId || id,
        role
      },
      appParams: {
        ...appParams,
        isMaskUserData: true
      }
    }
  };
  return tokenServiceApi.post('/masqueradeWithAppParams', payload);
};
