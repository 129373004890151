export default {
  courseSectionId: 523822,
  contentType: 'MASTERY',
  metrics: [
    {
      courseSectionId: 523822,
      assignmentId: 2532099,
      assignmentName: 'EAQ Novice Pain Management',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M49S',
      avgTimeSpentPerQuestion: 'PT45S',
      avgQuestionsAnsweredCorrect: 25.333333333333332,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 21,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: '2023-10-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532094,
      assignmentName: 'EAQ Intermediate Medication Administration',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT56M44S',
      avgTimeSpentPerQuestion: 'PT31S',
      avgQuestionsAnsweredCorrect: 110.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.09523809523809523,
      totalStudentsNotStarted: 13,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 2,
      totalStudentsPastDue: 19,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532068,
      assignmentName: 'EAQ Oxygenation & Tissue Perfusion Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M19S',
      avgTimeSpentPerQuestion: 'PT45S',
      avgQuestionsAnsweredCorrect: 18.526315789473685,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-23T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532075,
      assignmentName: 'EAQ Intermediate Hygiene & Personal Care',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M16S',
      avgTimeSpentPerQuestion: 'PT1M4S',
      avgQuestionsAnsweredCorrect: 20.94736842105263,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 2,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532085,
      assignmentName: 'EAQ Novice Medication Administration',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT20M56S',
      avgTimeSpentPerQuestion: 'PT47S',
      avgQuestionsAnsweredCorrect: 26.5,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-10-26T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532088,
      assignmentName: 'EAQ Perioperative Nursing Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT21M9S',
      avgTimeSpentPerQuestion: 'PT53S',
      avgQuestionsAnsweredCorrect: 23.75,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-07T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532112,
      assignmentName: 'EAQ Elimination Bowel Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT12M32S',
      avgTimeSpentPerQuestion: 'PT42S',
      avgQuestionsAnsweredCorrect: 17.85,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-20T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532106,
      assignmentName: 'EAQ Sleep Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M31S',
      avgTimeSpentPerQuestion: 'PT48S',
      avgQuestionsAnsweredCorrect: 22.9,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-07T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532102,
      assignmentName: 'EAQ Intermediate Safety',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H4M50S',
      avgTimeSpentPerQuestion: 'PT50S',
      avgQuestionsAnsweredCorrect: 78.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.7619047619047619,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 5,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532125,
      assignmentName: 'EAQ Novice Safety',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT10M59S',
      avgTimeSpentPerQuestion: 'PT41S',
      avgQuestionsAnsweredCorrect: 16.2,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-10-26T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532126,
      assignmentName: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M48S',
      avgTimeSpentPerQuestion: 'PT47S',
      avgQuestionsAnsweredCorrect: 24.1,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-30T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532078,
      assignmentName: 'EAQ Novice Hygiene & Personal Care',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT12M31S',
      avgTimeSpentPerQuestion: 'PT40S',
      avgQuestionsAnsweredCorrect: 18.523809523809526,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 21,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: '2023-10-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532123,
      assignmentName: 'EAQ Proficient Pain Management',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H33M22S',
      avgTimeSpentPerQuestion: 'PT27S',
      avgQuestionsAnsweredCorrect: 207.33333333333334,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.14285714285714285,
      totalStudentsNotStarted: 13,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 3,
      totalStudentsPastDue: 18,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2591202,
      assignmentName: 'EAQ intermediate Medication Administration',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT34M45S',
      avgTimeSpentPerQuestion: 'PT55S',
      avgQuestionsAnsweredCorrect: 37.65,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2591227,
      assignmentName: 'EAQ intermediate Pain Management',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M22S',
      avgTimeSpentPerQuestion: 'PT1M9S',
      avgQuestionsAnsweredCorrect: 15.947368421052632,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2591231,
      assignmentName: 'EAQ intermediate Skin Integrity & Wound Care',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M35S',
      avgTimeSpentPerQuestion: 'PT55S',
      avgQuestionsAnsweredCorrect: 31.6,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532122,
      assignmentName: 'EAQ Activity, Immobility, & Safe Movement-Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M48S',
      avgTimeSpentPerQuestion: 'PT40S',
      avgQuestionsAnsweredCorrect: 21.75,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-09T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532103,
      assignmentName: 'EAQ Nutrition Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT10M15S',
      avgTimeSpentPerQuestion: 'PT32S',
      avgQuestionsAnsweredCorrect: 18.95,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-13T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532119,
      assignmentName: 'EAQ Intermediate Nutrition',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT27M28S',
      avgTimeSpentPerQuestion: 'PT1M3S',
      avgQuestionsAnsweredCorrect: 26.05,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-27T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532059,
      assignmentName: 'EAQ Elimination Urinary Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M41S',
      avgTimeSpentPerQuestion: 'PT1M2S',
      avgQuestionsAnsweredCorrect: 22.105263157894736,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-16T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532087,
      assignmentName: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M43S',
      avgTimeSpentPerQuestion: 'PT44S',
      avgQuestionsAnsweredCorrect: 30.95,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-27T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532069,
      assignmentName: 'EAQ Intermediate Urinary',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT17M46S',
      avgTimeSpentPerQuestion: 'PT43S',
      avgQuestionsAnsweredCorrect: 25.05263157894737,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-27T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532128,
      assignmentName: 'EAQ Cognition & Sensation Alterations Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M57S',
      avgTimeSpentPerQuestion: 'PT48S',
      avgQuestionsAnsweredCorrect: 20.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8571428571428571,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 18,
      totalStudentsPastDue: 3,
      totalStudents: 21,
      dueDate: '2023-12-04T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532063,
      assignmentName: 'EAQ Intermediate Asepsis & Infection Control',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT17M30S',
      avgTimeSpentPerQuestion: 'PT58S',
      avgQuestionsAnsweredCorrect: 17.842105263157894,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532093,
      assignmentName: 'EAQ Asepsis & Infection Control',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M43S',
      avgTimeSpentPerQuestion: 'PT42S',
      avgQuestionsAnsweredCorrect: 20.85,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-02T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532061,
      assignmentName: 'EAQ Intermediate Skin Integrity & Wound Care',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M44S',
      avgTimeSpentPerQuestion: 'PT13S',
      avgQuestionsAnsweredCorrect: 136.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.09523809523809523,
      totalStudentsNotStarted: 12,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 2,
      totalStudentsPastDue: 19,
      totalStudents: 21,
      dueDate: '2023-11-06T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532116,
      assignmentName: 'EAQ Intermediate Bowel',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT20M57S',
      avgTimeSpentPerQuestion: 'PT48S',
      avgQuestionsAnsweredCorrect: 26.42105263157895,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-27T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532118,
      assignmentName: 'EAQ Skin Integrity & Wound Care',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M53S',
      avgTimeSpentPerQuestion: 'PT41S',
      avgQuestionsAnsweredCorrect: 22.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-02T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532065,
      assignmentName: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT34M8S',
      avgTimeSpentPerQuestion: 'PT50S',
      avgQuestionsAnsweredCorrect: 41.3,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-11T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532092,
      assignmentName: 'EAQ Intermediate Cognition & Sensory Alteration',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT28M57S',
      avgTimeSpentPerQuestion: 'PT53S',
      avgQuestionsAnsweredCorrect: 32.9,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-11T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532090,
      assignmentName: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT16M24S',
      avgTimeSpentPerQuestion: 'PT47S',
      avgQuestionsAnsweredCorrect: 21.473684210526315,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-12-11T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532110,
      assignmentName: 'EAQ Intermediate Perioperative Nursing',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT40M25S',
      avgTimeSpentPerQuestion: 'PT1M4S',
      avgQuestionsAnsweredCorrect: 37.94736842105263,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-12-11T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532098,
      assignmentName: 'EAQ Intermediate Sleep',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M46S',
      avgTimeSpentPerQuestion: 'PT39S',
      avgQuestionsAnsweredCorrect: 23.526315789473685,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-12-11T04:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 30,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 33,
    classAvgScore: 1.0,
    totalStudents: 21,
    pastDueStudents: 20,
    belowClassAvgStudents: 20,
    inProgressAssignment: 3
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'AUTHESS']
};
