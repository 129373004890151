import { map } from 'lodash';
import { AsyncResponseResolverReturnType, DefaultBodyType, MockedResponse, PathParams, ResponseComposition, rest, RestContext, RestHandler, RestRequest } from 'msw';

interface CTXFetch {
  ctx: RestContext;
  request: RestRequest;
  url: string;
}
export const ctxFetch = async ({ ctx, request, url }: CTXFetch) => {
  const token = request.headers.get('Authorization');
  if (!token) {
    return request.passthrough();
  }

  let remapUrl = url;
  if (!url.includes(request.url.origin)) {
    remapUrl = request.url.origin + url;
  }

  const response = await ctx.fetch(remapUrl, {
    headers: {
      Authorization: token,
      accept: 'application/json'
    }
  });

  return response.json();
};

export const replaceUrlParams = (request: RestRequest<never, PathParams<string>>, newParams: Record<string, string>) => {
  const baseUrl = request.url.origin;
  const url = request.url.href;
  const urlObj = new URL(url, baseUrl);
  Object.entries(newParams).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });
  return `${(baseUrl || urlObj.origin) + urlObj.pathname}?${urlObj.searchParams.toString()}`;
};

type RestHandlerParam = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => AsyncResponseResolverReturnType<MockedResponse<DefaultBodyType>>;

export const configureHandler = (handler: RestHandlerParam) => {
  return handler;
};

interface CustomHandler {
  url: string;
  handler: RestHandlerParam | void;
  method: 'get' | 'post' | 'put' | 'delete' | 'patch' | 'options' | 'head';
}

export const configureHandlers = (handlers: CustomHandler[]) => {
  return map(handlers, ({ url, handler, method }) => {
    return rest[method as any](url, handler);
  });
};
