import { useCallback, useState } from 'react';

interface UseHasScrollReturnType {
  hasScrollLeft: boolean;
  hasScrollRight: boolean;
  setHasScroll: Function;
}

const useHasScroll = (): UseHasScrollReturnType => {
  const [hasScrollLeft, setHasScrollLeft] = useState<boolean>(false);
  const [hasScrollRight, setHasScrollRight] = useState<boolean>(false);

  const setHasScroll = useCallback((element: HTMLDivElement): void => {
    if (element) {
      setHasScrollLeft(element.scrollLeft > 0);
      // This should be === 0, but the round algorithms do not work consistently with scrollWidth and offsetWidth
      setHasScrollRight(Math.abs(element.scrollWidth - element.scrollLeft - element.offsetWidth) > 1);
    }
  }, []);

  return { hasScrollLeft, hasScrollRight, setHasScroll };
};

export default useHasScroll;
