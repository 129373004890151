import React, { ReactElement, useRef } from 'react';

import { ELSIcon } from '@els/els-component-form-field-react';

import CourseSelectionModal from 'components/common/course-selection-modal/CourseSelectionModal';
import useClickOutside from 'components/common/use-click-out-side/useClickOutSide';
import { REPORT_TITLE } from 'constants/app.constant';
import { renderNavItem } from './HADPageNavigatorModalHelper';
import useHADPageNavigatorModal from './useHADPageNavigatorModal';

require(`assets/common/images/learning-competency.svg`);
require(`assets/common/images/learning-competency--disabled.svg`);
require(`assets/common/images/psychology.svg`);
require(`assets/common/images/psychology--disabled.svg`);
require(`assets/common/images/machine-learning--hover.svg`);
require(`assets/common/images/machine-learning--hover.svg`);
require(`assets/common/images/empowering-knowledge-c.svg`);
require(`assets/common/images/empowering-knowledge-c--disabled.svg`);

interface PageNavigatorModalProps {
  handleCancel: () => void;
  isHesiReadinessApp: boolean;
}

const HADPageNavigatorModal = ({ handleCancel, isHesiReadinessApp }: PageNavigatorModalProps): ReactElement => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleCancel);
  const { courseSelectionModalProps, navItems, showCourseSelectionModal } = useHADPageNavigatorModal({
    isHesiReadinessApp
  });

  return (
    <div className="c-els-modal c-had-navigator-modal">
      <div ref={wrapperRef} className="c-had-navigator-modal__content">
        <div className="c-had-navigator-modal__content-row">
          {REPORT_TITLE.HESI_NCLEX_READINESS.title}
          <button tabIndex={0} type="button" className="u-cursor-pointer c-had-navigator-modal__content-row-close-btn" onClick={handleCancel}>
            <ELSIcon name="close" size="1x1o2" />
          </button>
        </div>
        <div className="c-els-divider c-els-divider--1o2" />
        <div className="u-els-color-secondary c-had-navigator-modal__content-row">Dashboard Home</div>
        <div className="c-els-divider c-els-divider--1o2" />
        <div>
          <div className="c-had-navigator-modal__content-row">My Dashboards</div>
          <div className="c-had-navigator-modal__content-row c-had-navigator-modal__nav">{navItems.map(item => renderNavItem(item))}</div>
        </div>
      </div>
      {showCourseSelectionModal && <CourseSelectionModal {...courseSelectionModalProps} />}
    </div>
  );
};

export default HADPageNavigatorModal;
