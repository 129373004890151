export default [
  {
    courseSectionId: 554121,
    completedSelfStudyQuestionsEAQ: 3608,
    disengagedStudents: 13,
    totalStudent: 21,
    engagementByAssignmentTypes: [
      {
        assignmentType: 'MASTERY',
        totalCompletedAssignment: 30,
        totalAssignments: 33,
        totalAssignmentsPastDue: 30
      },
      {
        assignmentType: 'LESSONS',
        totalCompletedAssignment: 19,
        totalAssignments: 26,
        totalAssignmentsPastDue: 19
      },
      {
        assignmentType: 'AUTHESS',
        totalCompletedAssignment: 0,
        totalAssignments: 6,
        totalAssignmentsPastDue: 0
      }
    ],
    studentOverallScores: [
      {
        eolsId: 6307677,
        overallScore: 0.9058604250442785
      },
      {
        eolsId: 6308076,
        overallScore: 0.9246549555964667
      },
      {
        eolsId: 6308068,
        overallScore: 0.9241998685629577
      },
      {
        eolsId: 6308168,
        overallScore: 0.9879839786381843
      },
      {
        eolsId: 6307831,
        overallScore: 0.9759358084546819
      },
      {
        eolsId: 6308003,
        overallScore: 0.867884375168978
      },
      {
        eolsId: 6302509,
        overallScore: 0.9199577817384015
      },
      {
        eolsId: 6308027,
        overallScore: 0.9145954400611259
      },
      {
        eolsId: 6303057,
        overallScore: 0.8453572457854145
      },
      {
        eolsId: 6307122,
        overallScore: 0.9319752335705227
      },
      {
        eolsId: 5726072,
        overallScore: 0.5810378750614855
      },
      {
        eolsId: 6307927,
        overallScore: 0.9424298407381878
      },
      {
        eolsId: 4824095,
        overallScore: 0.9410713042099513
      },
      {
        eolsId: 6307820,
        overallScore: 0.8931870382638589
      },
      {
        eolsId: 6307754,
        overallScore: 0.947655760779881
      },
      {
        eolsId: 6306849,
        overallScore: 0.8529567195785622
      },
      {
        eolsId: 6308008,
        overallScore: 0.9816754347641168
      },
      {
        eolsId: 6308046,
        overallScore: 0.9307971500544053
      },
      {
        eolsId: 6309551,
        overallScore: 0.27164377179882215
      },
      {
        eolsId: 6308005,
        overallScore: 0.9620312672163024
      },
      {
        eolsId: 6307242,
        overallScore: 0.9196893428724892
      }
    ]
  }
];
