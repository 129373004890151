export default {
  courseSectionId: 516809,
  contentType: 'OSMOSIS_VIDEO',
  metrics: [
    {
      courseSectionId: 516809,
      assignmentId: 3734857,
      assignmentName: 'Pharmacokinetics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734858,
      assignmentName: 'Pharmacodynamics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734859,
      assignmentName: 'Analgesics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734860,
      assignmentName: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734861,
      assignmentName: 'Anticonvulsant Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734862,
      assignmentName: 'Antidepressant and Mood Stabilizer Drug Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    }
  ],
  cardData: {
    completedAssignments: 0,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 6,
    classAvgScore: 0.0,
    totalStudents: 19,
    pastDueStudents: 0,
    belowClassAvgStudents: 0,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['OSMOSIS_VIDEO', 'MASTERY']
};
