import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CW_TABLE_SELECTOR } from 'reports/cw/constants/cw.constant';
import { StudentEngagementRecord } from 'reports/cw/models';
import { appSelectors } from 'redux/ducks/app';
import { cwSelectors } from 'reports/cw/redux/ducks/cw';
import { querySelectorWrapper } from 'services/method-wrapper.service';
import TableCard from 'components/common/table-mobile/table-card/TableCard';
import { sortRecords } from 'components/common/responsive-table/ResponsiveTable';
import { TableRowProps } from 'components/common/table-card/table-row/TableRow';
import { TableRows } from 'components/common/table-card/TableCard';
import {
  renderStudentAvgScore,
  renderPastDue,
  renderAvgTimePerQuestionComparedToClass,
  renderAvgTimePerQuestion,
  renderName,
  renderComparedPerformanceMobile as renderComparedPerformance,
  renderStudentTotalGradePoints
} from '../student-engagement-table-helper/StudentEngagementTableHelper';
import { isFeatureEnabled } from '../../../../../../services/feature-flag.service';
import { FeatureFlagNames } from '../../../../../../constants/app.constant';

export interface StudentEngagementTableProps {
  tableData: Array<StudentEngagementRecord>;
  isEAQAssignmentType: boolean;
  isIncorrectDataHidden?: boolean;
  isGradePointsHidden?: boolean;
}

const StudentEngagementTableMobile: React.FC<StudentEngagementTableProps> = ({ tableData, isEAQAssignmentType, isIncorrectDataHidden, isGradePointsHidden }) => {
  const studentEngagementCardAndTableSortInteraction = useSelector(state => cwSelectors.getStudentEngagementCardAndTableSortInteraction(state));
  const featureFlags = useSelector(state => appSelectors.getFeatureFlags(state));
  const [sortedTableData, setSortedTableData] = useState<StudentEngagementRecord[]>(tableData);

  const maxPerformanceComparedToClassAvg = Math.max(...sortedTableData.map(item => Math.abs(item.performanceComparedToClassAvg)));
  const percentAvgScoreRow: TableRowProps<StudentEngagementRecord> = {
    field: 'percentAvgScore',
    header: 'Student Avg Score',
    customRenderContent: renderStudentAvgScore,
    sortNARecordInBottom: true
  };

  const isPerformanceComparedToClassAvgHidden = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_PERFORMANCE_COMPARED_TO_CLASS_AVERAGE_HIDDEN);
  const performanceComparedToClassAvg: TableRowProps<StudentEngagementRecord> = {
    field: 'performanceComparedToClassAvg',
    header: 'Performance Compared to Class Avg',
    customRender: row => renderComparedPerformance(row, maxPerformanceComparedToClassAvg)
  };

  const defaultrows: TableRows<StudentEngagementRecord> = {
    default: [
      ...(isPerformanceComparedToClassAvgHidden ? [] : [performanceComparedToClassAvg]),
      ...(isIncorrectDataHidden ? [] : [percentAvgScoreRow]),
      { field: 'pastDue', header: '# Past Due', customRenderContent: renderPastDue }
    ],
    expanded: [{ field: 'percentAvgScore', header: '#Past Due', customRenderContent: renderPastDue }]
  };

  const defaultFields: TableRowProps<StudentEngagementRecord>[] = [
    ...(isIncorrectDataHidden ? [] : [percentAvgScoreRow]),
    ...(isPerformanceComparedToClassAvgHidden ? [] : [performanceComparedToClassAvg])
  ];

  const expandedFields: TableRowProps<StudentEngagementRecord>[] = [
    { field: 'avgTimePerQuestionSeconds', header: isEAQAssignmentType ? 'Avg Time per Question' : 'Student Avg Time Spent', customRenderContent: renderAvgTimePerQuestion },
    {
      field: 'avgTimePerQuestionComparedToClassAvgSeconds',
      header: isEAQAssignmentType ? 'Avg Time per Question Compared to Class Avg' : 'Avg Time Spent Compared to Class Avg',
      customRenderContent: renderAvgTimePerQuestionComparedToClass
    },
    { field: 'pastDue', header: '# Past Due', customRenderContent: renderPastDue }
  ];

  const getCustomExpandRows = (): TableRows<StudentEngagementRecord> => {
    let customExpandRows = {
      default: defaultFields,
      expanded: expandedFields
    };

    if (isEAQAssignmentType) {
      customExpandRows = {
        default: [...defaultFields, { field: 'numberOfAssignedQuestionsAttempted', header: '# Assigned Questions Attemped ' }],
        expanded: [{ field: 'numberOfSelfStudyQuestionsAttempted', header: '# Self-Study Questions Attemped ' }, ...expandedFields]
      };
    }

    if (!isGradePointsHidden) {
      customExpandRows.expanded = [
        ...customExpandRows.expanded,
        { field: 'totalGradePoints', header: 'Student Total Grade Points', customRenderContent: row => renderStudentTotalGradePoints(row, featureFlags) }
      ];
    }

    return customExpandRows;
  };
  const customExpandRows = getCustomExpandRows();
  const recordColumns = [...customExpandRows.default, ...customExpandRows.expanded];

  useEffect(() => {
    if (studentEngagementCardAndTableSortInteraction) {
      const { field, sortDirection } = studentEngagementCardAndTableSortInteraction;
      setSortedTableData(sortRecords(recordColumns, [...tableData], field, sortDirection));
      querySelectorWrapper(CW_TABLE_SELECTOR.studentEngagement)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [studentEngagementCardAndTableSortInteraction, tableData]);

  return (
    <div className="c-cw-student-engagement-table">
      {sortedTableData.map(row => {
        return <TableCard key={row.studentId} hideTitleIcon data={row} title={renderName(row)} rows={defaultrows} customExpandRows={customExpandRows} />;
      })}
    </div>
  );
};

export { StudentEngagementTableMobile as StudentEngagementTableBase };
export default StudentEngagementTableMobile;
