import { ReactElement } from 'react';
import classNames from 'classnames';
import { handleNavigationClick } from 'helpers/app.helper';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { HADNavItems } from './useHADPageNavigatorModal';

const iconSize = 77;

const renderNavItem = (item: HADNavItems): ReactElement => {
  const { title, icon, description, isDisable, disabledDescription, onClick } = item;
  return (
    <div
      key={title}
      role="button"
      tabIndex={0}
      onClick={() => handleNavigationClick(isDisable, onClick)}
      onKeyDown={evt => onKeyDownHandler(evt, handleNavigationClick(isDisable, onClick))}
      className="c-had-navigator-modal__nav-item"
    >
      <div>
        <svg preserveAspectRatio="xMidYMid meet" width={iconSize} height={iconSize}>
          <use xlinkHref={`#${icon}${isDisable ? '--disabled' : ''}`} />
        </svg>
      </div>
      <div className="c-had-navigator-modal__nav-item-text">
        <div className={classNames({ 'u-els-color-n10': isDisable }, 'c-had-navigator-modal__nav-item-text--title')}>{title}</div>
        <div className={classNames({ 'u-els-color-n6': isDisable }, 'c-had-navigator-modal__nav-item-text--description')}>{isDisable ? disabledDescription : description}</div>
      </div>
    </div>
  );
};

export { renderNavItem };
