import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { kebabCase } from 'lodash';

import { ELSInlineLoader } from '@els/els-ui-common-react';
import { ELSIcon } from 'components/common';
import InfoIcon from 'components/common/info-icon/InfoIcon';
import ReportPieChart, { ReportPieChartProps, ChartType } from 'components/report-pie-chart/ReportPieChart';
import { KEY_CODES, CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD } from 'constants/app.constant';

export interface ReportInsightCardProps {
  id: string;
  icon?: string;
  customIcon?: ReactElement;
  title?: string;
  subtitle?: React.ReactNode;
  customSubTitle?: ReactElement;
  isLoading?: boolean;
  hideChart?: boolean;
  value?: any;
  valueUnit?: string;
  total?: any;
  customValueElement?: ReactElement;
  clickable?: boolean;
  chartConfig?: ReportPieChartProps;
  onSubTitleClick?: Function;
  customClass?: string;
  showTitleInfoIcon?: boolean;
  mainAriaLabel?: string; // a11y -  screen reader will read this label
  footerAriaLabel?: string; // a11y -  screen reader will read this label
  customFooter?: ReactElement;
  isUseBigFontSize?: boolean;
  className?: string;
  footerOnly?: boolean;
  hideTitle?: boolean;
  dashboard?: keyof typeof CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD;
}

const ReportInsightCard = ({
  id,
  icon,
  customIcon,
  title,
  showTitleInfoIcon,
  subtitle,
  customSubTitle,
  isLoading,
  hideChart,
  value,
  valueUnit,
  total,
  customValueElement,
  clickable,
  chartConfig,
  onSubTitleClick,
  customClass,
  mainAriaLabel,
  footerAriaLabel,
  customFooter,
  isUseBigFontSize,
  footerOnly,
  hideTitle,
  dashboard = 'cw'
}: ReportInsightCardProps): ReactElement => {
  const clickSubTitle = () => {
    if (clickable) {
      onSubTitleClick(id);
    }
  };

  const chart = chartConfig && (
    <div
      className={classnames(
        { 'u-els-margin-top-1x': chartConfig.type !== ChartType.FULL },
        { 'u-els-margin-bottom-1x': chartConfig.type === ChartType.FULL },
        'c-report-insight-card__chart'
      )}
      aria-hidden
    >
      <ReportPieChart key={`chart-${id}`} {...chartConfig} />
    </div>
  );

  const displayValue = customValueElement || (
    <>
      <span className="u-els-bold c-report-insight-card__value-data">{value || 0}</span>
      <span> of </span>
      <span className="c-report-insight-card__total-data">{total || 0}</span>
      {valueUnit && <span>{` ${valueUnit}`}</span>}
    </>
  );

  const displaySubTitle = customSubTitle || (
    <>
      <span className="u-els-margin-right-1o4 c-report-insight-card__subtitle-display">{subtitle}</span>
      <ELSIcon name="chevron-right" size="1x" customClass="o-els-icon-svg o-els-icon-svg--middle o-els-icon-svg--font-offset c-report-insight-card__subtitle-icon" />
    </>
  );

  const footer = customFooter || (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        clickSubTitle();
      }}
      onKeyDown={evt => {
        const { keyCode } = evt;
        if (keyCode === KEY_CODES.ENTER) {
          clickSubTitle();
        }
      }}
      className={classnames('c-report-insight-card__subtitle', { 'c-report-insight-card__subtitle--clickable': clickable })}
      aria-label={footerAriaLabel}
    >
      {displaySubTitle}
    </div>
  );

  const renderBody = () =>
    footerOnly ? null : (
      <div className={classnames({ 'c-report-insight-card__without_chart': hideChart }, 'c-report-insight-card__data-visualize')}>
        {!hideChart && !chartConfig?.isUseChartInLineWithValue && chart}
        <div className="c-report-insight-card__title-wrapper u-els-margin-top-1o2" tabIndex={-1}>
          {!hideTitle && (
            <div className="u-els-display-flex o-els-flex-layout--middle u-els-margin-bottom-1o2 c-report-insight-card__title">
              {customIcon || (
                <ELSIcon
                  name={icon}
                  size="1x"
                  customClass={classnames('o-els-icon-svg o-els-icon-svg--middle o-els-icon-svg--font-offset', { 'o-els-icon-svg--1x1o2': isUseBigFontSize })}
                />
              )}
              <span className={classnames('u-els-margin-left-1o2', { 'u-els-font-size-h4 u-els-margin-left-3o4': isUseBigFontSize }, 'c-report-insight-card__title')}>{title}</span>
              {showTitleInfoIcon && (
                <InfoIcon dashboard={dashboard} id={kebabCase(id)} customClassName="u-els-margin-left-1o4 u-els-margin-right-1o4 u-els-margin-top-none u-els-line-height-single" />
              )}
            </div>
          )}
          <div className="c-report-insight-card__value-wrapper">
            {!hideChart && chartConfig?.isUseChartInLineWithValue && chart}
            <div className="u-els-margin-bottom-1o4 u-els-font-size-h2 c-report-insight-card__value">{displayValue}</div>
          </div>
        </div>
      </div>
    );

  return (
    <section className={`u-els-background-color-white c-report-insight-card ${customClass || ''}`} tabIndex={-1} aria-label={mainAriaLabel}>
      {isLoading ? (
        <div className="u-els-display-flex c-report-insight-card__loader">
          <ELSInlineLoader />
        </div>
      ) : (
        <>
          {renderBody()}
          {footer}
        </>
      )}
    </section>
  );
};

export default ReportInsightCard;
