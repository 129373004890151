import React, { ReactElement, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { appSelectors } from 'redux/ducks/app';
import { AssignmentEngagementRecord, InteractionSortingBetweenCardAndTable } from 'reports/cw/models/ui';
import { FeatureFlag } from 'models';
import { SORT_DIRECTION } from 'constants/app.constant';
import { triggerSortTableManually } from 'services/table.service';
import ResponsiveTable, { TableColumn, TableProps } from 'components/common/responsive-table/ResponsiveTable';
import withRedirectToOutsideApp from 'components/common/with-redirect-to-outside-app/withRedirectToOutsideApp';

import { ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS, ASSIGNMENT_TYPE_GROUPS, ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { CatalogDto } from 'reports/ps/models';
import { CourseSection, CWAssignment } from 'reports/cw/models';
import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { cwSelectors } from 'reports/cw/redux/ducks/cw';
import { getIsOsmosisAssignment } from 'reports/cw/helpers/report.helper';

import {
  compareDate,
  renderPercentCompletion,
  renderScoringType,
  renderClassAvgGrade,
  renderClassAvgTimeSpent,
  renderDueDate,
  renderName
} from '../assignment-engagement-table-helper/AssignmentEngagementTableHelper';

export interface AssignmentEngagementDetailViewProps {
  featureFlags: FeatureFlag[];
  tableData: AssignmentEngagementRecord[];
  assignmentTypeId: string;
  searchKeyword: string;
  xLinkId: string;
  isBackToPreviousAppIconShown: boolean;
  assignmentEngagementCardAndTableSortInteraction: InteractionSortingBetweenCardAndTable;
  courseId: string;
  onRedirectToOutsideApp: Function;
  courseSectionInfo: CourseSection;
  catalog: CatalogDto;
  assignments: CWAssignment[];
  isIncorrectDataHidden?: boolean;
}

const AssignmentEngagementTable: React.FC<AssignmentEngagementDetailViewProps> = ({
  tableData,
  featureFlags,
  assignmentTypeId,
  searchKeyword,
  xLinkId,
  isBackToPreviousAppIconShown,
  assignmentEngagementCardAndTableSortInteraction,
  onRedirectToOutsideApp,
  courseId,
  courseSectionInfo,
  catalog,
  assignments,
  isIncorrectDataHidden
}) => {
  const interactionWithCard = useCallback(() => {
    if (assignmentEngagementCardAndTableSortInteraction) {
      triggerSortTableManually(assignmentEngagementCardAndTableSortInteraction.sortDirection, `.${assignmentEngagementCardAndTableSortInteraction.columnHeaderSelector}`);
    }
  }, [assignmentEngagementCardAndTableSortInteraction]);

  useEffect(() => {
    interactionWithCard();
  }, [assignmentEngagementCardAndTableSortInteraction, interactionWithCard]);

  const renderAssignmentName = (row: AssignmentEngagementRecord): ReactElement => {
    return renderName(
      row,
      featureFlags,
      courseId,
      xLinkId,
      isBackToPreviousAppIconShown,
      searchKeyword,
      assignmentTypeId,
      onRedirectToOutsideApp,
      courseSectionInfo,
      catalog,
      assignments
    );
  };

  const isEAQAssignmentType = ASSIGNMENT_TYPES[assignmentTypeId].group === ASSIGNMENT_TYPE_GROUPS.EAQ;
  const isOsmosisAssignmentType = getIsOsmosisAssignment(assignmentTypeId);
  let columns: TableColumn<AssignmentEngagementRecord>[] = [
    {
      field: 'name',
      header: 'Assignments',
      customRender: renderAssignmentName,
      minWidth: 300
    },
    {
      field: 'percentCompletion',
      header: 'Completion',
      headerCssModifier: ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS.completionColumn,
      customRender: renderPercentCompletion
    },
    {
      field: 'scoringType',
      header: 'Scoring Type',
      customRender: renderScoringType
    },
    {
      field: 'classAvgScore',
      header: 'Class Avg Score',
      customRender: renderClassAvgGrade,
      sortNARecordInBottom: true
    }
  ];
  if (isEAQAssignmentType) {
    columns.push({
      field: 'numberOfAvgQuestionAttempt',
      header: 'Avg # Questions Attempted'
    });
  }
  if (!isOsmosisAssignmentType) {
    columns.push({
      field: 'classAvgTimeSpentSeconds',
      header: isEAQAssignmentType ? 'Class Avg Time Spent per Question' : 'Class Avg Time Spent',
      customRender: renderClassAvgTimeSpent
    });
  }
  columns.push({
    field: 'dueDate',
    header: 'Due Date',
    customSort: compareDate,
    customRender: renderDueDate
  });

  if (isIncorrectDataHidden) {
    columns = columns.filter(c => c.field !== 'percentCompletion' && c.field !== 'classAvgScore');
  }
  const tableProps: TableProps<AssignmentEngagementRecord> = {
    cssModifier: 'c-cw-assignment-engagement-table',
    defaultSortField: 'classAvgScore',
    defaultSortDirection: SORT_DIRECTION.ASC
  };

  return <ResponsiveTable columns={columns} records={tableData} tableProps={tableProps} />;
};

const mapStateToProps = state => ({
  assignmentEngagementCardAndTableSortInteraction: cwSelectors.getAssignmentEngagementCardAndTableSortInteraction(state),
  courseId: appSelectors.getSelectedCourse(state),
  featureFlags: appSelectors.getFeatureFlags(state),
  xLinkId: appSelectors.getLinkId(state),
  courseSectionInfo: courseSelectors.getCourseSectionInfo(state),
  catalog: appSelectors.getCatalog(state),
  assignments: appSelectors.getAssignments(state)
});

const enhancers = [connect(mapStateToProps), withRedirectToOutsideApp];

export { AssignmentEngagementTable as AssignmentEngagementTableBase };

export default compose(...enhancers)(AssignmentEngagementTable);
