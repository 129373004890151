const dueDate = new Date().toISOString();

export default {
  metrics: [
    {
      assignmentId: 2532099,
      assignmentName: 'EAQ Novice Pain Management',
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M49S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT55M10S',
      minTimeSpent: 'PT3M54S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532094,
      assignmentName: 'EAQ Intermediate Medication Administration',
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 0.09523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 13,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT56M44S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 19,
      studyMode: false,
      maxTimeSpent: 'PT1H29M20S',
      minTimeSpent: 'PT24M7S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532068,
      assignmentName: 'EAQ Oxygenation & Tissue Perfusion Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-23T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M19S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT50M3S',
      minTimeSpent: 'PT4M29S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532062,
      assignmentName: 'Class activity- Pain NG- Not for a grade',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532072,
      assignmentName: 'Class activity: Next Gen Safety Case study (not for a grade)',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532073,
      assignmentName: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-10-30T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9345238095238095,
      percentAvgScore: 93.45238095238095,
      avgTimeSpent: 'PT7M49S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 6,
      studyMode: false,
      maxTimeSpent: 'PT36M37S',
      minTimeSpent: 'PT1M6S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532074,
      assignmentName: 'Urinary Elimination: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-16T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9883040935672515,
      percentAvgScore: 98.83040935672514,
      avgTimeSpent: 'PT3M38S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 4,
      studyMode: false,
      maxTimeSpent: 'PT11M1S',
      minTimeSpent: 'PT28S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532075,
      assignmentName: 'EAQ Intermediate Hygiene & Personal Care',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 2,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M16S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT1H6M56S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532085,
      assignmentName: 'EAQ Novice Medication Administration',
      assignmentType: 'MASTERY',
      dueDate: '2023-10-26T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT20M56S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT51M35S',
      minTimeSpent: 'PT4M42S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532083,
      assignmentName: 'Medication Administration: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-10-31T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9789473684210526,
      percentAvgScore: 97.89473684210526,
      avgTimeSpent: 'PT8M38S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 9,
      studyMode: false,
      maxTimeSpent: 'PT22M31S',
      minTimeSpent: 'PT1M20S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532086,
      assignmentName: 'Nutrition- NG - not for a grade',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532088,
      assignmentName: 'EAQ Perioperative Nursing Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-07T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT21M9S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT1H9M7S',
      minTimeSpent: 'PT3M43S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532095,
      assignmentName: 'Fluid Balance: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-30T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9785714285714286,
      percentAvgScore: 97.85714285714286,
      avgTimeSpent: 'PT6M38S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 6,
      studyMode: false,
      maxTimeSpent: 'PT12M29S',
      minTimeSpent: 'PT1M21S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532107,
      assignmentName: 'NG Bowel- not for a grade',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532130,
      assignmentName: 'Principles of Safe Medication Administration',
      assignmentType: 'LESSONS',
      dueDate: '2023-10-26T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9541666666666666,
      percentAvgScore: 95.41666666666666,
      avgTimeSpent: 'PT6M13S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 10,
      studyMode: false,
      maxTimeSpent: 'PT21M29S',
      minTimeSpent: 'PT1M19S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532112,
      assignmentName: 'EAQ Elimination Bowel Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-20T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT12M32S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT1H10M13S',
      minTimeSpent: 'PT2M12S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532100,
      assignmentName: 'Nutrition: Assess and Recognize Cues',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-13T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9543478260869568,
      percentAvgScore: 95.43478260869568,
      avgTimeSpent: 'PT12M36S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 6,
      studyMode: false,
      maxTimeSpent: 'PT44M17S',
      minTimeSpent: 'PT4M5S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532106,
      assignmentName: 'EAQ Sleep Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-07T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M31S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT2H25M2S',
      minTimeSpent: 'PT2M57S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532102,
      assignmentName: 'EAQ Intermediate Safety',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.7619047619047619,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H4M50S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 5,
      studyMode: false,
      maxTimeSpent: 'PT2H22M39S',
      minTimeSpent: 'PT6M18S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532105,
      assignmentName: 'Safety in Nursing Practice',
      assignmentType: 'LESSONS',
      dueDate: '2023-10-26T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.990909090909091,
      percentAvgScore: 99.0909090909091,
      avgTimeSpent: 'PT6M8S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT40M4S',
      minTimeSpent: 'PT57S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532111,
      assignmentName: 'Overview of Surgery',
      assignmentType: 'LESSONS',
      dueDate: '2023-12-07T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.8590909090909091,
      percentAvgScore: 85.9090909090909,
      avgTimeSpent: 'PT9M18S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 12,
      studyMode: false,
      maxTimeSpent: 'PT28M35S',
      minTimeSpent: 'PT1M13S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532108,
      assignmentName: 'NG urinary- Not for a grade',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532125,
      assignmentName: 'EAQ Novice Safety',
      assignmentType: 'MASTERY',
      dueDate: '2023-10-26T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT10M59S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT25M33S',
      minTimeSpent: 'PT2M2S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532120,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-05T03:59:00Z',
      percentComplete: 0.2857142857142857,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 7,
      gradePoints: 100,
      avgScore: 0.9500000000000001,
      percentAvgScore: 95.0,
      avgTimeSpent: 'PT4M40S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 16,
      studyMode: false,
      maxTimeSpent: 'PT10M16S',
      minTimeSpent: 'PT2M40S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532126,
      assignmentName: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-30T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M48S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT47M6S',
      minTimeSpent: 'PT4M22S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532132,
      assignmentName: 'Electrolyte Imbalance',
      assignmentType: 'LESSONS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532134,
      assignmentName: 'Overview of Fluid Balance',
      assignmentType: 'LESSONS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532113,
      assignmentName: 'Pain Management: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-10-30T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9557823129251701,
      percentAvgScore: 95.57823129251702,
      avgTimeSpent: 'PT6M23S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 7,
      studyMode: false,
      maxTimeSpent: 'PT12M27S',
      minTimeSpent: 'PT1M4S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532078,
      assignmentName: 'EAQ Novice Hygiene & Personal Care',
      assignmentType: 'MASTERY',
      dueDate: '2023-10-30T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT12M31S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT30M26S',
      minTimeSpent: 'PT3M3S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532123,
      assignmentName: 'EAQ Proficient Pain Management',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.14285714285714285,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 13,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H33M22S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 18,
      studyMode: false,
      maxTimeSpent: 'PT2H25M',
      minTimeSpent: 'PT1H3M19S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532058,
      assignmentName: 'Mobility In class activity- not for a grade',
      assignmentType: 'AUTHESS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532060,
      assignmentName: 'Activity and Movement: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-09T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9714285714285715,
      percentAvgScore: 97.14285714285715,
      avgTimeSpent: 'PT8M43S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 9,
      studyMode: false,
      maxTimeSpent: 'PT21M2S',
      minTimeSpent: 'PT2M6S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2591202,
      assignmentName: 'EAQ intermediate Medication Administration',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT34M45S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H23M34S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2591227,
      assignmentName: 'EAQ intermediate Pain Management',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT18M22S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT53M58S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2591231,
      assignmentName: 'EAQ intermediate Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M35S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H17M38S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532122,
      assignmentName: 'EAQ Activity, Immobility, & Safe Movement-Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-09T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M48S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT37M33S',
      minTimeSpent: 'PT2M18S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532103,
      assignmentName: 'EAQ Nutrition Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-13T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT10M15S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT30M2S',
      minTimeSpent: 'PT2M21S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532067,
      assignmentName: 'Nutrition: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-13T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9757085020242916,
      percentAvgScore: 97.57085020242916,
      avgTimeSpent: 'PT7M45S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 8,
      studyMode: false,
      maxTimeSpent: 'PT26M30S',
      minTimeSpent: 'PT1M46S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532119,
      assignmentName: 'EAQ Intermediate Nutrition',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-27T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT27M28S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H30M27S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532059,
      assignmentName: 'EAQ Elimination Urinary Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-16T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M41S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: true,
      maxTimeSpent: 'PT1H23M47S',
      minTimeSpent: 'PT5M27S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532071,
      assignmentName: 'Bowel Elimination: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-20T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9666666666666668,
      percentAvgScore: 96.66666666666669,
      avgTimeSpent: 'PT14M40S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 8,
      studyMode: false,
      maxTimeSpent: 'PT49M53S',
      minTimeSpent: 'PT4M48S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532087,
      assignmentName: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-27T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M43S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H26M27S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532069,
      assignmentName: 'EAQ Intermediate Urinary',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-27T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT17M46S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT1H2M30S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532133,
      assignmentName: 'Sleep: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-12-07T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 100,
      avgScore: 0.9875,
      percentAvgScore: 98.75,
      avgTimeSpent: 'PT3M52S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 3,
      studyMode: false,
      maxTimeSpent: 'PT12M49S',
      minTimeSpent: 'PT35S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532079,
      assignmentName: 'Cognition and Sensation: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-12-04T04:59:00Z',
      percentComplete: 0.8571428571428571,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9903381642512079,
      percentAvgScore: 99.03381642512079,
      avgTimeSpent: 'PT10M28S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 7,
      studyMode: false,
      maxTimeSpent: 'PT33M9S',
      minTimeSpent: 'PT2M16S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532089,
      assignmentName: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-23T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9597523219814241,
      percentAvgScore: 95.97523219814241,
      avgTimeSpent: 'PT11M39S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 12,
      studyMode: false,
      maxTimeSpent: 'PT23M33S',
      minTimeSpent: 'PT3M59S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532070,
      assignmentName: 'Overview of Electrolyte Balance',
      assignmentType: 'LESSONS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532128,
      assignmentName: 'EAQ Cognition & Sensation Alterations Novice',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-04T04:59:00Z',
      percentComplete: 0.8571428571428571,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M57S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 3,
      studyMode: true,
      maxTimeSpent: 'PT44M40S',
      minTimeSpent: 'PT2M59S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532063,
      assignmentName: 'EAQ Intermediate Asepsis & Infection Control',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT17M30S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT45M36S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532066,
      assignmentName: 'Spread of Infection',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-02T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9888888888888889,
      percentAvgScore: 98.88888888888889,
      avgTimeSpent: 'PT2M23S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 3,
      studyMode: false,
      maxTimeSpent: 'PT8M25S',
      minTimeSpent: 'PT28S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532080,
      assignmentName: 'Infection and Infection Control: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-02T03:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9649122807017545,
      percentAvgScore: 96.49122807017545,
      avgTimeSpent: 'PT9M24S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 7,
      studyMode: false,
      maxTimeSpent: 'PT29M23S',
      minTimeSpent: 'PT2M52S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532093,
      assignmentName: 'EAQ Asepsis & Infection Control',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-02T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M43S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT27M35S',
      minTimeSpent: 'PT5M48S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532061,
      assignmentName: 'EAQ Intermediate Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-06T04:59:00Z',
      percentComplete: 0.09523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 12,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M44S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 19,
      studyMode: false,
      maxTimeSpent: 'PT32M20S',
      minTimeSpent: 'PT27M8S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532116,
      assignmentName: 'EAQ Intermediate Bowel',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-27T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT20M57S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT1H37M26S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2573030,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-02T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9775,
      percentAvgScore: 97.75,
      avgTimeSpent: 'PT6M38S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 6,
      studyMode: false,
      maxTimeSpent: 'PT27M41S',
      minTimeSpent: 'PT2M9S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532118,
      assignmentName: 'EAQ Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      dueDate: '2023-11-02T03:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT14M53S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT37M50S',
      minTimeSpent: 'PT3M35S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532131,
      assignmentName: 'Fluid Imbalance',
      assignmentType: 'LESSONS',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 21,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532104,
      assignmentName: 'Electrolyte Balance: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      dueDate: '2023-11-30T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.9666666666666666,
      percentAvgScore: 96.66666666666666,
      avgTimeSpent: 'PT4M11S',
      assignmentGradeType: 'SCORED',
      totalStudents: 21,
      studentsBelowAvgScore: 9,
      studyMode: false,
      maxTimeSpent: 'PT10M52S',
      minTimeSpent: 'PT58S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532084,
      assignmentName: 'Overview of Loss and Grief (Recommended for HESI prep)',
      assignmentType: 'LESSONS',
      dueDate: '2023-12-14T04:59:00Z',
      percentComplete: 0.2857142857142857,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 13,
      gradePoints: 100,
      avgScore: 0.8916666666666666,
      percentAvgScore: 89.16666666666666,
      avgTimeSpent: 'PT21M33S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 17,
      studyMode: false,
      maxTimeSpent: 'PT42M38S',
      minTimeSpent: 'PT2M49S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532081,
      assignmentName: 'Overview of Stress and Coping (Recommended for HESI prep)',
      assignmentType: 'LESSONS',
      dueDate: '2023-12-14T04:59:00Z',
      percentComplete: 0.3333333333333333,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 12,
      gradePoints: 100,
      avgScore: 0.8666666666666666,
      percentAvgScore: 86.66666666666666,
      avgTimeSpent: 'PT14M17S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 21,
      studentsBelowAvgScore: 16,
      studyMode: false,
      maxTimeSpent: 'PT33M3S',
      minTimeSpent: 'PT1M21S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532065,
      assignmentName: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-11T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT34M8S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H36M16S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532092,
      assignmentName: 'EAQ Intermediate Cognition & Sensory Alteration',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-11T04:59:00Z',
      percentComplete: 0.9523809523809523,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT28M57S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 1,
      studyMode: false,
      maxTimeSpent: 'PT1H18M34S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532090,
      assignmentName: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-11T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT16M24S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT1H6M38S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532110,
      assignmentName: 'EAQ Intermediate Perioperative Nursing',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-11T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT40M25S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT2H27M42S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 2532098,
      assignmentName: 'EAQ Intermediate Sleep',
      assignmentType: 'MASTERY',
      dueDate: '2023-12-11T04:59:00Z',
      percentComplete: 0.9047619047619048,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M46S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 21,
      studentsBelowAvgScore: 2,
      studyMode: false,
      maxTimeSpent: 'PT1H24M44S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    }
  ],
  card: {
    totalAssignments: 65,
    totalCompletedAssignments: 49,
    highPerformanceStudents: 8,
    totalStudents: 21,
    disengagedStudents: 5
  }
};
