import { Icon } from '@els/els-react--icon';

type IconSize = 'xxs' | 'xs' | 's' | 'm' | 'ml' | 'l' | 'xl';
type IconColor = 'default' | 'primary' | 'secondary' | 'positive' | 'negative';
export type IconTextAlignment = 'sub' | 'bottom' | 'middle' | 'offset' | 'top';

export interface ELSIconProps {
  id: string;
  sprite: string;
  a11y?: {
    name: string;
    description: string;
  };
  className?: string;
  size?: IconSize;
  color?: IconColor;
  textAlignment?: IconTextAlignment;
  children?: string;
  isVisible?: boolean;
  isTextFirst?: boolean;
  isDecorative?: boolean;
  placeholder?: string;
}

const ELSIcon = ({ a11y, sprite, children, ...props }: ELSIconProps) => {
  return (
    <Icon
      sprite={sprite}
      a11y={
        a11y || {
          name: sprite,
          description: sprite
        }
      }
      {...props}
    >
      {children}
    </Icon>
  );
};

export default ELSIcon;
