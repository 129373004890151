import { MutableRefObject, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import useClickOutside from 'components/common/use-click-out-side/useClickOutSide';
import { querySelectorWrapper } from 'services/method-wrapper.service';
import { appActions } from 'redux/ducks/app';
import { courseActions } from 'reports/cw/redux/ducks/courses';
import { CourseSectionInfo } from 'reports/had/models';
import { selectedRadioClassName, CourseOptions } from './CourseSelectionModalHelpers';

export interface CourseSelectionModalHookProps {
  onCloseModal: Function;
  onSubmitSelectedCourse?: Function;
  initialCourseId: number;
  courseOptions?: CourseOptions[];
  isStudentDetail?: boolean;
  studentName?: string;
  submitButtonTitle?: string;
  courseSections?: CourseSectionInfo[];
  handleRedirectPage?: Function;
  isSwitchCourse?: boolean;
}
export interface CourseSelectionModalHookReturn {
  wrapperRef: MutableRefObject<HTMLDivElement>;
  tempCourseId: number;
  radioChangeHandler: (value: number) => void;
  goButtonHandler: () => void;
  selectOptions: CourseOptions[];
  title: string;
  goButtonDescription: string;
}

const getModalTitle = (isStudentDetail: boolean, studentName: string) => {
  if (isStudentDetail) {
    return `${studentName} was found in multiple courses you have access to.`;
  }
  return 'Your account is associated with multiple courses.';
};

const getButtonDescription = (submitButtonTitle: string, isStudentDetail: boolean) => {
  if (submitButtonTitle) {
    return submitButtonTitle;
  }
  return isStudentDetail ? 'View Sherpath Performance & Engagement' : 'Review Mastery in HESI Focus Chapters';
};
const useCourseSelectionModalHook = ({
  onCloseModal,
  initialCourseId,
  isStudentDetail,
  studentName,
  submitButtonTitle,
  isSwitchCourse = true,
  courseOptions = [],
  courseSections = [],
  onSubmitSelectedCourse = noop,
  handleRedirectPage = noop
}: CourseSelectionModalHookProps): CourseSelectionModalHookReturn => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  useClickOutside(wrapperRef, onCloseModal);
  const [tempCourseId, setTempCourseId] = useState<number>(initialCourseId);

  const title = getModalTitle(isStudentDetail, studentName);

  const goButtonDescription = useMemo(() => getButtonDescription(submitButtonTitle, isStudentDetail), [submitButtonTitle, isStudentDetail]);

  const selectOptions = useMemo(() => {
    if (!courseSections?.length) {
      return courseOptions;
    }
    // Remove duplicate objects by id
    const allCourseSectionIds = courseSections.map(course => course.id);
    // "index + 1" is the fromIndex parameter. This checks for duplicate ids from index + 1 to the end of the array
    const allUniqueCourseSections = courseSections.filter(({ id }, index) => !allCourseSectionIds.includes(id, index + 1));
    return allUniqueCourseSections.map(({ id, courseName }) => ({
      courseId: id,
      courseName
    }));
  }, [courseSections, courseOptions]);

  useEffect(() => {
    const selectedRadioSelector = `.${selectedRadioClassName}`;
    const selectedRadio = querySelectorWrapper(selectedRadioSelector);
    if (selectedRadio) {
      selectedRadio.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, []);

  const radioChangeHandler = (value: number): void => {
    setTempCourseId(value);
  };

  const goButtonHandler = (): void => {
    if (tempCourseId) {
      onSubmitSelectedCourse(tempCourseId);
      if (isSwitchCourse) {
        const changeCourse = courseSections.find(({ id }) => Number(id) === Number(tempCourseId));
        if (changeCourse) {
          dispatch(appActions.setSelectedCourse(changeCourse.id));
          dispatch(courseActions.setCourseSectionInfo(changeCourse));
        }
        handleRedirectPage();
      }
      onCloseModal();
    }
  };

  return { wrapperRef, tempCourseId, radioChangeHandler, goButtonHandler, selectOptions, title, goButtonDescription };
};

export default useCourseSelectionModalHook;
