import { ELSLoggingService } from '@els/els-ui-common-react';
import { SESSION_STORAGE_INDEXED_DB_KEY } from 'constants/app.constant';

const DB_NAME = 'programDashboard';

// Generate a unique identifier using crypto API
export const generateStorageId = () => {
  const array = new Uint32Array(4);
  window.crypto.getRandomValues(array);
  return array.join('-');
};

const dbStorageNameFromSession = sessionStorage.getItem(SESSION_STORAGE_INDEXED_DB_KEY);
const STORAGE_NAME = dbStorageNameFromSession || `PD-${generateStorageId()}`;

let dbInstance: IDBDatabase | null = null;

export enum Stores {
  ActionState = 'actionState'
}

export interface IndexedDBRecord {
  id: string;
  value: unknown;
  timestamp: number;
}

export const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(Stores.ActionState)) {
        db.createObjectStore(Stores.ActionState, { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      // set session storage to store the storage name
      sessionStorage.setItem(SESSION_STORAGE_INDEXED_DB_KEY, STORAGE_NAME);
      dbInstance = request.result;
      resolve(dbInstance);
      dbInstance.onversionchange = () => {
        dbInstance.close();
      };
    };
    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const addUpdateData = async (data: { value: unknown }): Promise<void> => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readwrite');
    const store = transaction.objectStore(Stores.ActionState);
    // Using "put" instead of "add" to update existing data if key exist and add new record if index doesn't exist.
    const dataWithTimestamp = { ...data, timestamp: new Date().getTime(), id: STORAGE_NAME };
    const request = store.put(dataWithTimestamp);

    request.onsuccess = () => {
      db.close();
      resolve();
    };
    request.onerror = () => {
      db.close();
      reject(request.error);
    };
  });
};

export const getData = async (): Promise<IndexedDBRecord> => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readonly');
    const store = transaction.objectStore(Stores.ActionState);
    const request = store.get(STORAGE_NAME);

    request.onsuccess = () => {
      db.close();
      resolve(request.result);
    };
    request.onerror = () => {
      db.close();
      reject(request.error);
    };
  });
};

const deleteRecord = async (dbStorageKey: string): Promise<void> => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readwrite');
    const store = transaction.objectStore(Stores.ActionState);
    const request = store.delete(dbStorageKey);

    request.onsuccess = () => {
      db.close();
      resolve();
    };
    request.onerror = () => {
      db.close();
      reject(request.error);
    };
  });
};

export const handleIndexedDBRecordDelete = async () => {
  const dbStorageKeyFromSession = sessionStorage.getItem(SESSION_STORAGE_INDEXED_DB_KEY);
  if (dbStorageKeyFromSession) {
    deleteRecord(dbStorageKeyFromSession).catch(error => {
      ELSLoggingService.warn('Error while deleting Record', error);
    });
  }
};

// Delete Old Records from IndexedDB
const dbDuration = 12 * 60 * 60 * 1000; // 12 hours

const getAllRecords = async (): Promise<IndexedDBRecord[]> => {
  const db = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readonly');
    const store = transaction.objectStore(Stores.ActionState);
    const request = store.getAll();

    request.onsuccess = () => {
      db.close();
      resolve(request.result);
    };
    request.onerror = () => {
      db.close();
      reject(request.error);
    };
  });
};

export const deleteAllOldIndexedDBRecords = async () => {
  const allRecords = await getAllRecords();
  if (allRecords.length > 0) {
    allRecords.forEach(async record => {
      const dbRecordTimestamp = record.timestamp;
      const timestampDifference = new Date().getTime() - dbDuration;
      if (dbRecordTimestamp && dbRecordTimestamp < timestampDifference) {
        deleteRecord(record.id).catch(error => {
          ELSLoggingService.warn('Error while deleting Record', error);
        });
      }
    });
  }
};
