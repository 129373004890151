export default [
  {
    courseSectionId: 471204,
    completedSelfStudyQuestionsEAQ: 71,
    disengagedStudents: 3,
    totalStudent: 19,
    engagementByAssignmentTypes: [
      {
        assignmentType: 'OSMOSIS_VIDEO',
        totalCompletedAssignment: 0,
        totalAssignments: 6,
        totalAssignmentsPastDue: 0
      },
      {
        assignmentType: 'MASTERY',
        totalCompletedAssignment: 9,
        totalAssignments: 9,
        totalAssignmentsPastDue: 9
      }
    ],
    studentOverallScores: [
      {
        eolsId: 6307677,
        overallScore: 1.0
      },
      {
        eolsId: 6308076,
        overallScore: 0.8888888888888888
      },
      {
        eolsId: 6308068,
        overallScore: 1.0
      },
      {
        eolsId: 6307831,
        overallScore: 1.0
      },
      {
        eolsId: 6308003,
        overallScore: 0.5555555555555556
      },
      {
        eolsId: 6302509,
        overallScore: 1.0
      },
      {
        eolsId: 6035430,
        overallScore: 0.8888888888888888
      },
      {
        eolsId: 6308027,
        overallScore: 1.0
      },
      {
        eolsId: 6303057,
        overallScore: 1.0
      },
      {
        eolsId: 6307122,
        overallScore: 1.0
      },
      {
        eolsId: 4824095,
        overallScore: 1.0
      },
      {
        eolsId: 6307820,
        overallScore: 1.0
      },
      {
        eolsId: 6307754,
        overallScore: 1.0
      },
      {
        eolsId: 6306849,
        overallScore: 0.7777777777777778
      },
      {
        eolsId: 6308046,
        overallScore: 1.0
      },
      {
        eolsId: 6309551,
        overallScore: 0.8888888888888888
      },
      {
        eolsId: 6308005,
        overallScore: 0.6666666666666666
      },
      {
        eolsId: 6307242,
        overallScore: 1.0
      }
    ]
  }
];
