import { Sector } from 'recharts-v2';
import { ReactElement } from 'react';
import { ReportPieChartData } from './ReportPieChart';

export type ReportPieShapeProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
};

export const renderActiveShape = (value: number, fillColorClass: string, chartConfig, shapeProps: ReportPieShapeProps, hideIndicator = false, name: string): ReactElement => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = shapeProps;
  const { DATA_POINT_OUTER_R, DATA_POINT_INNER_R, DATA_POINT_STROKE_WIDTH } = chartConfig;
  const dataPointAngle = startAngle + DATA_POINT_OUTER_R / 2;
  const sin = Math.sin(-RADIAN * dataPointAngle);
  const cos = Math.cos(-RADIAN * dataPointAngle);
  const dataPointR = innerRadius + (outerRadius - innerRadius) / 2;
  const dataPointX = cx + dataPointR * cos;
  const dataPointY = cy + dataPointR * sin;
  return (
    <g id={`custom-active-shape-${name}`}>
      <Sector className="u-els-fill-n2" cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} />
      {value > 0 && !hideIndicator && (
        <g>
          <circle cx={dataPointX} cy={dataPointY} r={DATA_POINT_OUTER_R} className={fillColorClass} />
          <circle cx={dataPointX} cy={dataPointY} r={DATA_POINT_OUTER_R - DATA_POINT_STROKE_WIDTH} className="u-els-fill-white" />
          <circle cx={dataPointX} cy={dataPointY} r={DATA_POINT_INNER_R} className={fillColorClass} />
        </g>
      )}
    </g>
  );
};

type ReportPieChartDataBuild = {
  data: Array<ReportPieChartData>;
  activeIndex: number;
};

export const buildDataForChart = (value: number, total: number, pieces: number[]): ReportPieChartDataBuild => {
  if (pieces && pieces.length) {
    const data: Array<ReportPieChartData> = [];
    let theRestOfValue = value;
    let temptValue = 0;
    let temptRest = 0;
    let activeIndex = 1;
    pieces.forEach((piece, idx) => {
      if (theRestOfValue > piece) {
        temptValue = piece;
        temptRest = 0;
        theRestOfValue -= piece;
      } else {
        temptValue = theRestOfValue;
        temptRest = piece - theRestOfValue;
        theRestOfValue = 0;
      }
      if (temptValue > 0) {
        activeIndex = idx + 1;
        data.push({
          name: `value ${idx}`,
          value: temptValue
        });
      }
      if (temptRest > 0) {
        data.push({
          name: `rest ${idx}`,
          value: temptRest
        });
      }
    });
    return { data, activeIndex };
  }
  return {
    data: [
      { name: 'value', value: value > 0 ? value : 0 },
      { name: 'rest', value: value > 0 ? total - value : 1 }
    ] as Array<ReportPieChartData>,
    activeIndex: 1
  };
};
