import { ELSIcon } from '@els/els-component-form-field-react';
import moment from 'moment';
import React, { ReactElement, ReactNode } from 'react';

import { EOLSUser } from 'models';

import { CatalogDto } from 'reports/ps/models';
import { CourseSection } from 'reports/cw/models';
import { EAQ_TOPIC_REPORT } from 'reports/cw/constants/cw.constant';
import { fetchCatalogs } from 'reports/ps/services/ps.service';

import { KEY_CODES, STUDENT_DETAIL_APP_LINK } from '../constants/app.constant';

const onKeyDownHandler = (evt, handler): void => {
  const { keyCode } = evt;
  if (keyCode === KEY_CODES.ENTER || keyCode === KEY_CODES.SPACE) {
    handler();
  }
};

const pluralizeUnit = (count: number, unit: string): string => `${unit}${count > 1 || count < -1 ? 's' : ''}`;

const renderNoDataFound = (customClass?: string, message = 'No data found'): ReactElement => {
  return <p className={`u-els-padding-top-2x u-els-padding-bottom-2x u-els-text-center u-els-italic c-no-data-found ${customClass}`}>{`${message}.`}</p>;
};

const renderNoCourseFound = (): ReactElement => {
  return <p className="u-els-padding-top-2x u-els-padding-bottom-2x u-els-text-center u-els-italic c-no-course-found">Course not found.</p>;
};

const renderNoResultsFound = (): ReactElement => {
  return (
    <p className="u-els-padding-top-2x u-els-padding-bottom-2x u-els-padding-left-1x@mobile u-els-italic u-els-text-center u-els-text-left@mobile c-not-results-found">
      No results found.
    </p>
  );
};

const renderStudentDetailLinkTitle = (title: ReactNode): ReactElement => {
  return (
    <span>
      {title}
      <ELSIcon name="new-window" size="1x" customClass="u-els-margin-left-1o2" />
    </span>
  );
};

const renderHadStudentDetailLinkTitle = () => renderStudentDetailLinkTitle(STUDENT_DETAIL_APP_LINK.HAD.title);

const renderCwStudentDetailLinkTitle = () => renderStudentDetailLinkTitle(STUDENT_DETAIL_APP_LINK.CW.title);

const renderCwStudentHomeEaqTopicReportTitle = () => renderStudentDetailLinkTitle(EAQ_TOPIC_REPORT);

const ordinalSuffixOf = (n: number) => {
  const tail = n % 10;
  const hundredTail = n % 100;
  if (tail === 1 && hundredTail !== 11) {
    return `${n}st`;
  }
  if (tail === 2 && hundredTail !== 12) {
    return `${n}nd`;
  }
  if (tail === 3 && hundredTail !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
};

const getIndexByKeyCode = (key, currentIndex, max) => {
  if (key === KEY_CODES.UP) {
    return currentIndex <= 0 ? max : currentIndex - 1;
  }
  if (key === KEY_CODES.DOWN || key === KEY_CODES.TAB) {
    return currentIndex >= max ? 0 : currentIndex + 1;
  }
  return currentIndex;
};

const getCatalogs = async (courses: CourseSection[], allExternalUserInfo: EOLSUser[], resourceType?: string) => {
  const instructorCourseIds = (courses || []).map(sc => sc.id || sc.courseId);
  if (allExternalUserInfo.length > 0) {
    const isbnsByUserId = allExternalUserInfo
      .map(student => {
        const isbns = student.courseSections
          .filter(course => instructorCourseIds.includes(course.id))
          .flatMap(course => Array.from(new Set(course.entitlements.map(etm => etm.isbn))));
        return {
          studentEolsId: student.id,
          isbns
        };
      })
      .filter(userIsbns => userIsbns.isbns.length > 0);
    const isbnList = Array.from(new Set(isbnsByUserId.flatMap(userIsbns => userIsbns.isbns)));
    const { data: { catalog = ([] as unknown) as CatalogDto, externalEntities = [] } = {} } = await fetchCatalogs(isbnList, resourceType);
    return { catalog, externalEntities, isbnsByUserId };
  }
  return { catalog: null, externalEntities: null, isbnsByUserId: null };
};

const convertRangeDates = (fromDate: number, toDate: number) => {
  const startDate = moment(fromDate)
    .startOf('day')
    .valueOf()
    .toString();
  const endDate = moment(toDate)
    .endOf('day')
    .valueOf()
    .toString();
  return { startDate, endDate };
};

export {
  onKeyDownHandler,
  ordinalSuffixOf,
  getIndexByKeyCode,
  pluralizeUnit,
  renderNoDataFound,
  renderNoResultsFound,
  renderNoCourseFound,
  renderHadStudentDetailLinkTitle,
  renderCwStudentDetailLinkTitle,
  renderStudentDetailLinkTitle,
  renderCwStudentHomeEaqTopicReportTitle,
  getCatalogs,
  convertRangeDates
};
