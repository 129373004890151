export default {
  courseSectionId: 622453,
  contentType: '',
  avgScoreOfClass: 0.9991918103448276,
  avgTimeOfClassOnQuestions: 'PT1H10M25S',
  metrics: [
    {
      courseSectionId: 622453,
      userId: 6952847,
      firstName: 'Jessica',
      lastName: 'Allbright',
      contentType: '',
      totalScore: 15,
      totalGradePoints: 93,
      percentAvgScore: 93.75,
      avgScore: 0.9375,
      totalAssignmentQuestionsAnswered: 963,
      totalSelfStudyQuestionsAnswered: 100,
      avgTimeSpentOnQuestions: 'PT1H23M22S',
      avgTimeSpent: 'PT1H23M22S',
      avgTimeSpentOnQuestionsDelta: 'PT1H23M22S',
      totalAssignmentsPastDue: 1,
      totalAssignmentsComplete: 15,
      totalAssignments: 17,
      lastLogin: '2024-08-01T13:37:19.643Z',
      totalTimeSpent: 'PT22H13M52S',
      totalAssignmentsBelowClassAvg: 1,
      isAtRiskStudent: false,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 622453,
      userId: 5846998,
      firstName: 'Alexander',
      lastName: 'Tushishvili',
      contentType: '',
      totalScore: 13,
      totalGradePoints: 87,
      percentAvgScore: 81.25,
      avgScore: 0.8125,
      totalAssignmentQuestionsAnswered: 918,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT1H8M41S',
      avgTimeSpent: 'PT1H8M41S',
      avgTimeSpentOnQuestionsDelta: 'PT1H8M41S',
      totalAssignmentsPastDue: 3,
      totalAssignmentsComplete: 13,
      totalAssignments: 17,
      lastLogin: '2024-08-01T12:31:05.168Z',
      totalTimeSpent: 'PT18H18M48S',
      totalAssignmentsBelowClassAvg: 3,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 622453,
      userId: 6037438,
      firstName: 'MacKenzie',
      lastName: 'Wagner',
      contentType: '',
      totalScore: 16,
      totalGradePoints: 193,
      percentAvgScore: 100,
      avgScore: 1,
      totalAssignmentQuestionsAnswered: 1194,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT1H21M',
      avgTimeSpent: 'PT1H21M',
      avgTimeSpentOnQuestionsDelta: 'PT1H21M',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 16,
      totalAssignments: 17,
      lastLogin: '2024-08-01T12:27:25.432Z',
      totalTimeSpent: 'PT21H35M57S',
      totalAssignmentsBelowClassAvg: 0,
      isAtRiskStudent: false,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 622453,
      userId: 3047711,
      firstName: 'Bennie',
      lastName: 'Factor',
      contentType: '',
      totalScore: 16,
      totalGradePoints: 193,
      percentAvgScore: 100,
      avgScore: 1,
      totalAssignmentQuestionsAnswered: 1050,
      totalSelfStudyQuestionsAnswered: 190,
      avgTimeSpentOnQuestions: 'PT2H23M53S',
      avgTimeSpent: 'PT2H23M53S',
      avgTimeSpentOnQuestionsDelta: 'PT2H23M53S',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 16,
      totalAssignments: 17,
      lastLogin: '2024-08-12T02:27:00.105Z',
      totalTimeSpent: 'PT38H22M10S',
      totalAssignmentsBelowClassAvg: 0,
      isAtRiskStudent: true,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 622453,
      userId: 5167001,
      firstName: 'Jacqueline',
      lastName: 'Payeur',
      contentType: '',
      totalScore: 16,
      totalGradePoints: 193,
      percentAvgScore: 100,
      avgScore: 1,
      totalAssignmentQuestionsAnswered: 969,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT14M33S',
      avgTimeSpent: 'PT14M33S',
      avgTimeSpentOnQuestionsDelta: 'PT14M33S',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 16,
      totalAssignments: 17,
      lastLogin: '2024-08-01T13:20:51.998Z',
      totalTimeSpent: 'PT3H52M47S',
      totalAssignmentsBelowClassAvg: 0,
      isAtRiskStudent: false,
      isHighPerformingStudent: true
    }
  ]
};
