import React from 'react';
import classNames from 'classnames';
import { WindowMode } from 'models/ui';
import withResizeScreen from 'components/common/with-resize-screen/withResizeScreen';

interface ComparisonBarChartProps {
  value: number;
  maxValue: number;
  maxBarChartPercentage?: number;
  backgroundColorClassName: string;
  displayTextValue: string;
  barChartThickness: number; // rem unit
  windowMode: WindowMode;
  spaceBetweenBarChartAndTextModified?: string;
  minimumPercentage?: number;
}

const comparisonBarChartConfig = {
  defaultMinimumPercentage: 5,
  defaultMaxBarChartPercentage: 30,
  defaultSpaceBetweenBarChartAndText: '0.625rem',
  defaultSpaceBetweenBarChartAndTextMobile: '1px'
};

const ComparisonBarChart = (props: ComparisonBarChartProps) => {
  const { defaultMinimumPercentage, defaultMaxBarChartPercentage } = comparisonBarChartConfig;
  const {
    value,
    maxValue,
    backgroundColorClassName,
    displayTextValue,
    barChartThickness,
    maxBarChartPercentage = defaultMaxBarChartPercentage,
    windowMode,
    minimumPercentage = defaultMinimumPercentage
  } = props;
  const maxBarChart = maxBarChartPercentage; // 30% barChart = 50% - 14% display text - 6% padding
  let barChartPercentage = (Math.abs(value) * maxBarChart) / maxValue || 0;
  barChartPercentage = barChartPercentage !== 0 && barChartPercentage < minimumPercentage ? minimumPercentage : barChartPercentage;
  const side = +value > 0 ? 'left' : 'right';
  const spaceBetweenBarChartAndText = windowMode.mobile ? '1px' : '0.625rem';

  return (
    <div className="c-comparison-bar-chart">
      <div
        style={{ width: `${barChartPercentage}%`, height: `${barChartThickness}rem`, position: 'absolute', [side]: '50%', top: `calc(50% - ${barChartThickness / 2}rem)` }}
        className={classNames(backgroundColorClassName, 'c-comparison-bar-chart__bar-chart')}
      />
      <div
        style={{ [side]: `calc(${50 + barChartPercentage}% + ${spaceBetweenBarChartAndText})`, position: 'absolute', top: `calc(50% - 0.625rem)` }}
        className="c-comparison-bar-chart__display-text"
      >
        {displayTextValue}
      </div>
      <div className="c-comparison-bar-chart__vertical-line" />
    </div>
  );
};

export default withResizeScreen(ComparisonBarChart);
