export default {
  courseSectionId: 523822,
  completedAssignments: 49,
  pastDueAssignments: 0,
  inProgressAssignments: 6,
  notStartedAssignments: 10,
  totalAssignments: 65,
  completedSelfStudyQuestionsEAQ: 3608.0,
  typeCardsList: [
    {
      assignmentType: 'MASTERY',
      assignmentCompletionRate: 0.8556998556998557,
      totalStudentsPastDue: 20,
      totalStudents: 21,
      totalAssignmentsByType: 33,
      avgTimeSpentPerQuestion: 'PT40S',
      avgTimeSpent: 'PT25M16S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 3577,
      avgSelfStudyQuestionsAttempted: 170.33333333333334,
      totalCompletedAssignment: 30,
      totalAssignmentsPastDue: 30,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'LESSONS',
      assignmentCompletionRate: 0.7234432234432234,
      totalStudentsPastDue: 18,
      totalStudents: 21,
      totalAssignmentsByType: 26,
      avgTimeSpentPerQuestion: 'PT28S',
      avgTimeSpent: 'PT7M35S',
      avgScore: 0.9647002784185903,
      percentAvgScore: 96.47002784185904,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 19,
      totalAssignmentsPastDue: 19,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'AUTHESS',
      assignmentCompletionRate: 0.0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      totalAssignmentsByType: 6,
      avgTimeSpentPerQuestion: 'PT0S',
      avgTimeSpent: 'PT0S',
      avgScore: 0.0,
      percentAvgScore: 0.0,
      totalSelfStudyQuestionsAttempted: 31,
      avgSelfStudyQuestionsAttempted: 15.5,
      totalCompletedAssignment: 0,
      totalAssignmentsPastDue: 0,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    }
  ]
};
