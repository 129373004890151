import { facadeApi, perFacadeApi } from 'helpers/api.helper';
import { CS_EVOLVE_PRODUCT_TYPE_KEY, EAB_EVOLVE_PRODUCT_TYPE_KEY, EAQ_ISBN } from 'constants/app.constant';
import { CourseSection } from '../models';

export const fetchCourses = email => {
  if (email === null) {
    return [];
  }
  return facadeApi.get(`v1/eolsUser/list/byEmail/${email}`, {});
};

export const fetchCourseSectionInfo = (courseId: number): Promise<{ data: CourseSection }> => {
  if (courseId) {
    return perFacadeApi.get(`/courseSections/${courseId}`);
  }
  return Promise.resolve({ data: {} as CourseSection });
};

export const getEAQIsbnFromCourseSection = (courseSectionInfo: CourseSection) => {
  return (courseSectionInfo?.entitlements || []).find(e => e.type === EAQ_ISBN)?.isbn;
};

const isProductByCourseSection = (courseSectionInfo: CourseSection, productTypeKey: string): boolean => {
  return (courseSectionInfo?.entitlements || []).every(e => e.evolveProductTypeKey === productTypeKey);
};

export const isEABAppByCourseSection = (courseSectionInfo: CourseSection): boolean => {
  return isProductByCourseSection(courseSectionInfo, EAB_EVOLVE_PRODUCT_TYPE_KEY);
};

export const isCSAppByCourseSection = (courseSectionInfo: CourseSection): boolean => {
  return isProductByCourseSection(courseSectionInfo, CS_EVOLVE_PRODUCT_TYPE_KEY);
};
