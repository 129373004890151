import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { addLocaleData } from 'react-intl';
import defaultLocale from 'react-intl/locale-data/en';
import debugLocale from 'react-intl/locale-data/vi';
import esLocale from 'react-intl/locale-data/es';
import { languageSelectors, languageActions } from 'redux/ducks/language';
import { ELSWithLanguageProvider } from 'components/common';
import { Language } from 'constants/app.constant';

addLocaleData(defaultLocale);
addLocaleData(debugLocale);
addLocaleData(esLocale);

interface LanguageProviderProps {
  locale: string;
  formattedMessages: object;
  children: React.ReactNode;
  setLocaleAndLanguageCallback: Function;
}

const LanguageProvider = (props: LanguageProviderProps) => React.Children.only(props.children);

const mapStateToProps = state => ({
  locale: languageSelectors.getLocale(state),
  formattedMessages: languageSelectors.getUsingFormattedMessages(state)
});

const mapDispatchToProps = dispatch => ({
  setLocaleAndLanguageCallback: ({ locale, formattedMessages }) => {
    dispatch(languageActions.setLocale(locale));
    dispatch(languageActions.setFormattedMessages({ [locale]: formattedMessages }));
  }
});

const loadLanguageByLocale = (locale = Language.DEFAULT_LOCALE) => import(`../../../localization/${locale}.json`);

const enhancers = [connect(mapStateToProps, mapDispatchToProps), ELSWithLanguageProvider(loadLanguageByLocale)];

export default compose(...enhancers)(LanguageProvider);
