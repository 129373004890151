import { RoutePath, ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';

const { ALL, INSTRUCTOR } = ROUTE_ROLES;

export const IntegrationRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.login,
      component: lazy(() => import('components/features/login/IntegrationLoginComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.login,
          pageName: `${analyticBaseConfig.edApp}:login`
        }
      },
      isPrivate: false,
      roles: ALL
    },
    {
      path: RoutePath.appLinkingLogin,
      component: lazy(() => import('components/features/login/IntegrationLoginComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.login,
          pageName: `${analyticBaseConfig.edApp}:app-linking-login`
        }
      },
      isPrivate: false,
      roles: ALL
    },
    {
      path: RoutePath.reportingPortal,
      component: lazy(() => import('components/features/centralized-landing-page/CentralizedLandingPage')),
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
