import React from 'react';

interface ComparisonBarChartProps {
  value: number;
  maxValue: number;
  displayTextValue: string;
}

const ComparisonBarChartMobile = (props: ComparisonBarChartProps) => {
  const chartConfig = {
    barHeight: 1.75, // in rem
    verticalLineWidth: 1
  };
  const { value, maxValue, displayTextValue } = props;
  const maxBarChartPercentage = 30;
  const barChartPercentage = (Math.abs(value) * maxBarChartPercentage) / maxValue;
  const verticalLineX = 50 - chartConfig.verticalLineWidth / 2;
  const comparedPercentX = value > 0 ? 50 : 50 - barChartPercentage;
  const backgroundColorClassName = value > 0 ? 'u-els-fill-extended-green-8' : 'u-els-fill-warn';

  return (
    <div className="c-comparison-bar-chart">
      <svg className="u-els-display-block" width="100%" height={`${chartConfig.barHeight}rem`}>
        <g>
          <rect width={`${100}%`} height={`${chartConfig.barHeight}rem`} className="u-els-fill-n2" />
          <rect x={`${comparedPercentX}%`} width={`${barChartPercentage}%`} height={`${chartConfig.barHeight}rem`} className={backgroundColorClassName} />
          <rect x={`${verticalLineX}%`} width={`${chartConfig.verticalLineWidth}%`} height={`${chartConfig.barHeight}rem`} className="u-els-fill-n8" />
        </g>
      </svg>
      <div className="c-comparison-bar-chart__description">
        <span className="c-table-card__table-row-header">Performance Compared to Class Avg</span>
        <span className="c-table-card__table-row-content">
          <span>{displayTextValue}</span>
        </span>
      </div>
    </div>
  );
};

export default ComparisonBarChartMobile;
