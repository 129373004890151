import classNames from 'classnames';

interface PillProps {
  children?: React.ReactNode;
  value?: string;
  className?: string;
}

const Pill = ({ children, className = '', value }: PillProps) => {
  const shown = children || value;
  return <>{shown && <span className={classNames('c-pill', className)}>{children || value}</span>}</>;
};

export default Pill;
