export default {
  courseSectionId: 622453,
  completedAssignments: 16,
  pastDueAssignments: 0,
  inProgressAssignments: 0,
  notStartedAssignments: 1,
  totalAssignments: 17,
  completedSelfStudyQuestionsEAQ: 465.0,
  typeCardsList: [
    {
      assignmentType: 'MASTERY',
      assignmentCompletionRate: 0.9732142857142857,
      totalStudentsPastDue: 3,
      totalStudents: 16,
      totalAssignmentsByType: 7,
      avgTimeSpentPerQuestion: 'PT1M3S',
      avgTimeSpent: 'PT2H30M47S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 7,
      totalAssignmentsPastDue: 7,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'LESSONS',
      assignmentCompletionRate: 0.75,
      totalStudentsPastDue: 4,
      totalStudents: 16,
      totalAssignmentsByType: 1,
      avgTimeSpentPerQuestion: 'PT15S',
      avgTimeSpent: 'PT4M21S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 1,
      totalAssignmentsPastDue: 1,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'SHERPATH_CASE_STUDY',
      assignmentCompletionRate: 0.0,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      totalAssignmentsByType: 1,
      avgTimeSpentPerQuestion: 'PT0S',
      avgTimeSpent: 'PT0S',
      avgScore: 0.0,
      percentAvgScore: 0.0,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 0,
      totalAssignmentsPastDue: 0,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'QUIZ_BY_QUESTION',
      assignmentCompletionRate: 1.0,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      totalAssignmentsByType: 2,
      avgTimeSpentPerQuestion: 'PT39S',
      avgTimeSpent: 'PT15M51S',
      avgScore: 0.9935344827586207,
      percentAvgScore: 99.35344827586206,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 2,
      totalAssignmentsPastDue: 2,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'STANDARD',
      assignmentCompletionRate: 0.975,
      totalStudentsPastDue: 2,
      totalStudents: 16,
      totalAssignmentsByType: 5,
      avgTimeSpentPerQuestion: 'PT30S',
      avgTimeSpent: 'PT2M31S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 465,
      avgSelfStudyQuestionsAttempted: 29.0625,
      totalCompletedAssignment: 5,
      totalAssignmentsPastDue: 5,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'AUTHESS',
      assignmentCompletionRate: 0.9375,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      totalAssignmentsByType: 1,
      avgTimeSpentPerQuestion: 'PT1M54S',
      avgTimeSpent: 'PT22M42S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 1,
      totalAssignmentsPastDue: 1,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    }
  ]
};
