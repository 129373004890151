export default [
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106005',
      chapterName: 'Chapter 1, Nursing Today',
      topicId: 'PO106010',
      topicName: 'Professional Nursing Practice'
    },
    masteryLevel: 2.01658249037638,
    questionsAnswered: 31,
    questionsCorrect: 29,
    questionsConfident: 26,
    percentCorrect: 93.54838709677419,
    timeSpent: 'PT53M14S',
    avgTimeSpentPerQuestion: 'PT1M43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106015',
      chapterName: 'Chapter 2, The Health Care Delivery System',
      topicId: 'PO106020',
      topicName: 'Health Care Delivery'
    },
    masteryLevel: 1.04087070725715,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 15,
    percentCorrect: 100.0,
    timeSpent: 'PT6M47S',
    avgTimeSpentPerQuestion: 'PT27S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106025',
      chapterName: 'Chapter 3, Community-Based Nursing Practice',
      topicId: 'PO106030',
      topicName: 'Community-Based Health Care'
    },
    masteryLevel: 1.01124167379269,
    questionsAnswered: 12,
    questionsCorrect: 12,
    questionsConfident: 12,
    percentCorrect: 100.0,
    timeSpent: 'PT13M43S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106035',
      chapterName: 'Chapter 4, Theoretical Foundations of Nursing Practice',
      topicId: 'PO106040',
      topicName: 'Interdisciplinary and Nursing Theories'
    },
    masteryLevel: 1.057672045432,
    questionsAnswered: 27,
    questionsCorrect: 17,
    questionsConfident: 13,
    percentCorrect: 62.96296296296296,
    timeSpent: 'PT11M36S',
    avgTimeSpentPerQuestion: 'PT25S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106045',
      chapterName: 'Chapter 5, Evidence-Based Practice',
      topicId: 'PO106050',
      topicName: 'Evidence-Based Practice'
    },
    masteryLevel: 1.03077190017239,
    questionsAnswered: 35,
    questionsCorrect: 24,
    questionsConfident: 22,
    percentCorrect: 68.57142857142857,
    timeSpent: 'PT17M3S',
    avgTimeSpentPerQuestion: 'PT29S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106055',
      chapterName: 'Chapter 6, Health and Wellness',
      topicId: 'PO106060',
      topicName: 'Health and Illness'
    },
    masteryLevel: 3.01116746177559,
    questionsAnswered: 94,
    questionsCorrect: 73,
    questionsConfident: 56,
    percentCorrect: 77.65957446808511,
    timeSpent: 'PT1H50M27S',
    avgTimeSpentPerQuestion: 'PT1M10S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106065',
      chapterName: 'Chapter 7, Caring in Nursing Practice',
      topicId: 'PO106070',
      topicName: 'Caring'
    },
    masteryLevel: 3.00608378109337,
    questionsAnswered: 95,
    questionsCorrect: 68,
    questionsConfident: 77,
    percentCorrect: 71.57894736842105,
    timeSpent: 'PT2H16M53S',
    avgTimeSpentPerQuestion: 'PT1M26S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106075',
      chapterName: 'Chapter 8, Caring for Patients With Chronic Illness',
      topicId: 'PO106080',
      topicName: 'Caring for Patients With Chronic Illness'
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'DR0007',
      chapterName: 'Chapter 1, Oral and Enteral Medicationsy',
      topicId: 'DR0007',
      topicName: 'Transcultural Nursing'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106095',
      chapterName: 'Chapter 10, Family Dynamics',
      topicId: 'PO106100',
      topicName: 'Family Nursing'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106105',
      chapterName: 'Chapter 11, Developmental Theories',
      topicId: 'PO106110',
      topicName: 'Developmental Theories'
    },
    masteryLevel: 3.07966202417771,
    questionsAnswered: 99,
    questionsCorrect: 74,
    questionsConfident: 80,
    percentCorrect: 74.74747474747475,
    timeSpent: 'PT1H14M10S',
    avgTimeSpentPerQuestion: 'PT44S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106115',
      chapterName: 'Chapter 12, Conception Through Adolescence',
      topicId: 'PO106120',
      topicName: 'Development - Newborn Through Toddler'
    },
    masteryLevel: 3.00634162595669,
    questionsAnswered: 81,
    questionsCorrect: 68,
    questionsConfident: 68,
    percentCorrect: 83.95061728395062,
    timeSpent: 'PT1H9M47S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106115',
      chapterName: 'Chapter 12, Conception Through Adolescence',
      topicId: 'PO106125',
      topicName: 'Development - Preschooler Through Adolescent'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106130',
      chapterName: 'Chapter 13, Young and Middle Adults',
      topicId: 'PO106135',
      topicName: 'Development - Young and Middle Adults'
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106140',
      chapterName: 'Chapter 14, Older Adults',
      topicId: 'PO106145',
      topicName: 'Development - Older Adults'
    },
    masteryLevel: 1.01697257031866,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 14,
    percentCorrect: 100.0,
    timeSpent: 'PT5M56S',
    avgTimeSpentPerQuestion: 'PT23S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106140',
      chapterName: 'Chapter 14, Older Adults',
      topicId: 'PO106150',
      topicName: 'Health Care for Older Adults'
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106155',
      chapterName: 'Chapter 15, Critical Thinking in Nursing Practice',
      topicId: 'PO106160',
      topicName: 'Critical Thinking in Nursing'
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106165',
      chapterName: 'Chapter 16, Nursing Assessment',
      topicId: 'PO106170',
      topicName: 'Nursing Assessment'
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106175',
      chapterName: 'Chapter 17, Nursing Diagnosis',
      topicId: 'PO106180',
      topicName: 'Nursing Diagnosis'
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106185',
      chapterName: 'Chapter 18, Planning Nursing Care',
      topicId: 'PO106190',
      topicName: 'Nursing Planning'
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106195',
      chapterName: 'Chapter 19, Implementing Nursing Care',
      topicId: 'PO106200',
      topicName: 'Nursing Implementation'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106205',
      chapterName: 'Chapter 20, Evaluation',
      topicId: 'PO106210',
      topicName: 'Nursing Evaluation'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106215',
      chapterName: 'Chapter 21, Managing Patient Care',
      topicId: 'PO106220',
      topicName: 'Managing Patient Care'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106225',
      chapterName: 'Chapter 22, Ethics and Values',
      topicId: 'PO106230',
      topicName: 'Ethics and Values'
    },
    masteryLevel: 3.00683484777475,
    questionsAnswered: 108,
    questionsCorrect: 84,
    questionsConfident: 91,
    percentCorrect: 77.77777777777777,
    timeSpent: 'PT2H58M28S',
    avgTimeSpentPerQuestion: 'PT1M39S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106235',
      chapterName: 'Chapter 23, Legal Implications in Nursing Practice',
      topicId: 'PO106240',
      topicName: 'Legal Implications in Nursing'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106245',
      chapterName: 'Chapter 24, Communication',
      topicId: 'PO106250',
      topicName: 'Communication'
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106245',
      chapterName: 'Chapter 24, Communication',
      topicId: 'PO106255',
      topicName: 'Communication and Nursing Practice'
    },
    masteryLevel: 1.02905860956625,
    questionsAnswered: 20,
    questionsCorrect: 18,
    questionsConfident: 17,
    percentCorrect: 90.0,
    timeSpent: 'PT16M',
    avgTimeSpentPerQuestion: 'PT48S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106260',
      chapterName: 'Chapter 25, Patient Education',
      topicId: 'PO106265',
      topicName: 'Teaching and Learning'
    },
    masteryLevel: 3.00683484777475,
    questionsAnswered: 108,
    questionsCorrect: 84,
    questionsConfident: 91,
    percentCorrect: 77.77777777777777,
    timeSpent: 'PT2H58M28S',
    avgTimeSpentPerQuestion: 'PT1M39S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106260',
      chapterName: 'Chapter 25, Patient Education',
      topicId: 'PO106270',
      topicName: 'Patient Education and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106275',
      chapterName: 'Chapter 26, Informatics and Documentation',
      topicId: 'PO106280',
      topicName: 'Documentation and Informatics'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106285',
      chapterName: 'Chapter 27, Patient Safety and Quality',
      topicId: 'PO106290',
      topicName: 'Safety'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106285',
      chapterName: 'Chapter 27, Patient Safety and Quality',
      topicId: 'PO106295',
      topicName: 'Safety and the Nursing Process'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106285',
      chapterName: 'Chapter 27, Patient Safety and Quality',
      topicId: 'PO106300',
      topicName: 'Nursing Skills Related to Safety'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106305',
      chapterName: 'Chapter 28, Infection Prevention and Control',
      topicId: 'PO106310',
      topicName: 'Infection Prevention and Control'
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106305',
      chapterName: 'Chapter 28, Infection Prevention and Control',
      topicId: 'PO106315',
      topicName: 'Infection Prevention and the Nursing Process'
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106305',
      chapterName: 'Chapter 28, Infection Prevention and Control',
      topicId: 'PO106320',
      topicName: 'Nursing Skills Related to Infection Prevention'
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106325',
      chapterName: 'Chapter 29, Vital Signs',
      topicId: 'PO106330',
      topicName: 'Vital Signs'
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106325',
      chapterName: 'Chapter 29, Vital Signs',
      topicId: 'PO106335',
      topicName: 'Body Temperature'
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106325',
      chapterName: 'Chapter 29, Vital Signs',
      topicId: 'PO106340',
      topicName: 'Pulse, Respiration, and Blood Pressure'
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106325',
      chapterName: 'Chapter 29, Vital Signs',
      topicId: 'PO106345',
      topicName: 'Nursing Skills Related to Vital Signs'
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106355',
      topicName: 'Physical Examination Overview'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106360',
      topicName: 'Examination of Skin, Hair, and Nails'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106365',
      topicName: 'Examination of Head and Neck'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106370',
      topicName: 'Examination of Thorax and Lungs'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106375',
      topicName: 'Examination of Vascular System, Abdomen, Rectum, and Anus'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106380',
      topicName: 'Examination of Breasts, Female Genitalia, and Male Genitalia'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106350',
      chapterName: 'Chapter 30, Health Assessment and Physical Examination',
      topicId: 'PO106385',
      topicName: 'Examination of Musculoskeletal and Neurologic Systems'
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106395',
      topicName: 'Medication Knowledge'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106400',
      topicName: 'Medication Administration and the Nursing Process'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106405',
      topicName: 'Oral and Inhalation Administration'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106410',
      topicName: 'Topical Administration'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106415',
      topicName: 'Parenteral and Injection Administration'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106390',
      chapterName: 'Chapter 31, Medication Administration',
      topicId: 'PO106420',
      topicName: 'Infusions and Intravenous Administration'
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106425',
      chapterName: 'Chapter 32, Complementary Therapies and Integrative Health',
      topicId: 'PO106430',
      topicName: 'Complementary Therapies and Integrative Health'
    },
    masteryLevel: 3.07966202417771,
    questionsAnswered: 99,
    questionsCorrect: 74,
    questionsConfident: 80,
    percentCorrect: 74.74747474747475,
    timeSpent: 'PT1H14M10S',
    avgTimeSpentPerQuestion: 'PT44S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106435',
      chapterName: 'Chapter 33, Self-Concept',
      topicId: 'PO106440',
      topicName: 'Self-Concept'
    },
    masteryLevel: 3.00634162595669,
    questionsAnswered: 81,
    questionsCorrect: 68,
    questionsConfident: 68,
    percentCorrect: 83.95061728395062,
    timeSpent: 'PT1H9M47S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106445',
      chapterName: 'Chapter 34, Sexuality',
      topicId: 'PO106450',
      topicName: 'Sexuality'
    },
    masteryLevel: 1.01697257031866,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 14,
    percentCorrect: 100.0,
    timeSpent: 'PT5M56S',
    avgTimeSpentPerQuestion: 'PT23S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106455',
      chapterName: 'Chapter 35, Spiritual Health',
      topicId: 'PO106460',
      topicName: 'Spiritual Health'
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106465',
      chapterName: 'Chapter 36, Loss and Grief',
      topicId: 'PO106470',
      topicName: 'Loss and Grief'
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106465',
      chapterName: 'Chapter 36, Loss and Grief',
      topicId: 'PO106475',
      topicName: 'Loss and Grief and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106480',
      chapterName: 'Chapter 37, Stress and Coping',
      topicId: 'PO106485',
      topicName: 'Stress and Coping'
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106490',
      chapterName: 'Chapter 38, Activity and Exercise',
      topicId: 'PO106495',
      topicName: 'Activity and Exercise'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106490',
      chapterName: 'Chapter 38, Activity and Exercise',
      topicId: 'PO106500',
      topicName: 'Nursing Skills Related to Activity and Exercise'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106505',
      chapterName: 'Chapter 39, Immobility',
      topicId: 'PO106510',
      topicName: 'Immobility'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106505',
      chapterName: 'Chapter 39, Immobility',
      topicId: 'PO106515',
      topicName: 'Immobility and the Nursing Process'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106505',
      chapterName: 'Chapter 39, Immobility',
      topicId: 'PO106520',
      topicName: 'Nursing Skills - Moving and Positioning'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106525',
      chapterName: 'Chapter 40, Hygiene',
      topicId: 'PO106530',
      topicName: 'Hygiene'
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106525',
      chapterName: 'Chapter 40, Hygiene',
      topicId: 'PO106535',
      topicName: 'Hygiene and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106525',
      chapterName: 'Chapter 40, Hygiene',
      topicId: 'PO106540',
      topicName: 'Nursing Skills - Bathing and Perineal Care'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106525',
      chapterName: 'Chapter 40, Hygiene',
      topicId: 'PO106545',
      topicName: 'Nursing Skills - Nail and Foot Care, Oral Hygiene, Mouth Care, Bed Making'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106550',
      chapterName: 'Chapter 41, Oxygenation',
      topicId: 'PO106555',
      topicName: 'Oxygenation'
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106550',
      chapterName: 'Chapter 41, Oxygenation',
      topicId: 'PO106560',
      topicName: 'Oxygenation and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106550',
      chapterName: 'Chapter 41, Oxygenation',
      topicId: 'PO106565',
      topicName: 'Nursing Skills - Suctioning'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106550',
      chapterName: 'Chapter 41, Oxygenation',
      topicId: 'PO106570',
      topicName: 'Nursing Skills - Artificial Airways'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106550',
      chapterName: 'Chapter 41, Oxygenation',
      topicId: 'PO106575',
      topicName: 'Nursing Skills - Chest Tubes, Nasal Cannula, Oxygen Masks, and Home Oxygen Equipment'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106580',
      chapterName: 'Chapter 42, Fluid, Electrolyte, and Acid-Base Balance',
      topicId: 'PO106585',
      topicName: 'Fluids and Electrolytes'
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106580',
      chapterName: 'Chapter 42, Fluid, Electrolyte, and Acid-Base Balance',
      topicId: 'PO106590',
      topicName: 'Fluids, Electrolytes, and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106580',
      chapterName: 'Chapter 42, Fluid, Electrolyte, and Acid-Base Balance',
      topicId: 'PO106595',
      topicName: 'Nursing Skills - Intravenous Therapy and Maintaining Flow Rate'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106580',
      chapterName: 'Chapter 42, Fluid, Electrolyte, and Acid-Base Balance',
      topicId: 'PO106600',
      topicName: 'Nursing Skills - IV System Maintenance and Peripheral IV Dressing'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106605',
      chapterName: 'Chapter 43, Sleep',
      topicId: 'PO106610',
      topicName: 'Sleep'
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106605',
      chapterName: 'Chapter 43, Sleep',
      topicId: 'PO106615',
      topicName: 'Sleep and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106620',
      chapterName: 'Chapter 44, Pain Management',
      topicId: 'PO106625',
      topicName: 'Pain Management'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106620',
      chapterName: 'Chapter 44, Pain Management',
      topicId: 'PO106630',
      topicName: 'Pain Management and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106620',
      chapterName: 'Chapter 44, Pain Management',
      topicId: 'PO106635',
      topicName: 'Nursing Skills Related to Pain Management'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106640',
      chapterName: 'Chapter 45, Nutrition',
      topicId: 'PO106645',
      topicName: 'Nutrition'
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106640',
      chapterName: 'Chapter 45, Nutrition',
      topicId: 'PO106650',
      topicName: 'Nutrition and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106640',
      chapterName: 'Chapter 45, Nutrition',
      topicId: 'PO106655',
      topicName: 'Nursing Skills Related to Nutrition'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106660',
      chapterName: 'Chapter 46, Urinary Elimination',
      topicId: 'PO106665',
      topicName: 'Urinary Elimination'
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106660',
      chapterName: 'Chapter 46, Urinary Elimination',
      topicId: 'PO106670',
      topicName: 'Urinary Elimination and the Nursing Process'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106660',
      chapterName: 'Chapter 46, Urinary Elimination',
      topicId: 'PO106675',
      topicName: 'Nursing Skills Related to Urinary Elimination'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106680',
      chapterName: 'Chapter 47, Bowel Elimination',
      topicId: 'PO106685',
      topicName: 'Bowel Elimination'
    },
    masteryLevel: 1.02905860956625,
    questionsAnswered: 20,
    questionsCorrect: 18,
    questionsConfident: 17,
    percentCorrect: 90.0,
    timeSpent: 'PT16M',
    avgTimeSpentPerQuestion: 'PT48S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106680',
      chapterName: 'Chapter 47, Bowel Elimination',
      topicId: 'PO106690',
      topicName: 'Bowel Elimination and the Nursing Process'
    },
    masteryLevel: 1.02905860956625,
    questionsAnswered: 20,
    questionsCorrect: 18,
    questionsConfident: 17,
    percentCorrect: 90.0,
    timeSpent: 'PT16M',
    avgTimeSpentPerQuestion: 'PT48S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106680',
      chapterName: 'Chapter 47, Bowel Elimination',
      topicId: 'PO106695',
      topicName: 'Nursing Skills Related to Bowel Elimination'
    },
    masteryLevel: 1.02905860956625,
    questionsAnswered: 20,
    questionsCorrect: 18,
    questionsConfident: 17,
    percentCorrect: 90.0,
    timeSpent: 'PT16M',
    avgTimeSpentPerQuestion: 'PT48S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106700',
      chapterName: 'Chapter 48, Skin Integrity and Wound Care',
      topicId: 'PO106705',
      topicName: 'Skin Integrity and Wound Care'
    },
    masteryLevel: 3.00634162595669,
    questionsAnswered: 81,
    questionsCorrect: 68,
    questionsConfident: 68,
    percentCorrect: 83.95061728395062,
    timeSpent: 'PT1H9M47S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106700',
      chapterName: 'Chapter 48, Skin Integrity and Wound Care',
      topicId: 'PO106710',
      topicName: 'Skin Integrity and Wound Care - Assessment, Diagnosis, and Planning'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106700',
      chapterName: 'Chapter 48, Skin Integrity and Wound Care',
      topicId: 'PO106715',
      topicName: 'Skin Integrity and Wound Care - Implementation and Evaluation'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106700',
      chapterName: 'Chapter 48, Skin Integrity and Wound Care',
      topicId: 'PO106720',
      topicName: 'Nursing Skills Related to Pressure Injuries and Dressings'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106700',
      chapterName: 'Chapter 48, Skin Integrity and Wound Care',
      topicId: 'PO106725',
      topicName: 'Nursing Skills Related to Wound Irrigation, Abdominal Binding, and Elastic Bandages'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106730',
      chapterName: 'Chapter 49, Sensory Alterations',
      topicId: 'PO106735',
      topicName: 'Sensory Alterations'
    },
    masteryLevel: 1.01697257031866,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 14,
    percentCorrect: 100.0,
    timeSpent: 'PT5M56S',
    avgTimeSpentPerQuestion: 'PT23S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106740',
      chapterName: 'Chapter 50, Perioperative Nursing Care',
      topicId: 'PO106745',
      topicName: 'Preoperative Surgical Nursing'
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106740',
      chapterName: 'Chapter 50, Perioperative Nursing Care',
      topicId: 'PO106750',
      topicName: 'Intraoperative and Postoperative Surgical Nursing'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'PO106000',
      topicOrganizationName: 'Potter 10e Chapter',
      chapterId: 'PO106740',
      chapterName: 'Chapter 50, Perioperative Nursing Care',
      topicId: 'PO106755',
      topicName: 'Nursing Skills - Care of the Surgical Patient'
    },
    masteryLevel: 0.0,
    questionsAnswered: 0,
    questionsCorrect: 0,
    questionsConfident: 0,
    percentCorrect: 0.0,
    timeSpent: 'PT0S',
    avgTimeSpentPerQuestion: 'PT0S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  }
];
