export default {
  courseSectionId: 523822,
  contentType: 'AUTHESS',
  metrics: [
    {
      courseSectionId: 523822,
      assignmentId: 2532062,
      assignmentName: 'Class activity- Pain NG- Not for a grade',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532072,
      assignmentName: 'Class activity: Next Gen Safety Case study (not for a grade)',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532086,
      assignmentName: 'Nutrition- NG - not for a grade',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532107,
      assignmentName: 'NG Bowel- not for a grade',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532108,
      assignmentName: 'NG urinary- Not for a grade',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532058,
      assignmentName: 'Mobility In class activity- not for a grade',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'AUTHESS',
      gradePoints: 7,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    }
  ],
  cardData: {
    completedAssignments: 0,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 6,
    classAvgScore: 0.0,
    totalStudents: 21,
    pastDueStudents: 0,
    belowClassAvgStudents: 0,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'AUTHESS']
};
