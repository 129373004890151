const querySelectorWrapper = (selector: string): Element => {
  return document.querySelector(selector);
};

const windowLocationAssignWrapper = (url: string, isNewTab?: boolean): void => {
  if (isNewTab) {
    window.open(url, '_blank');
  } else {
    window.location.assign(url);
  }
};

export { querySelectorWrapper, windowLocationAssignWrapper };
