import { onKeyDownHandler } from 'helpers/ui.helper';
import { ELSButton } from '..';

interface ExpandAllButtonProps {
  hideCondition?: boolean;
  isExpandAll: boolean;
  handleExpandAll: () => void;
}

const ExpandAllButton = ({ isExpandAll, handleExpandAll, hideCondition = false }: ExpandAllButtonProps) => {
  if (hideCondition) {
    return null;
  }
  return (
    <div className="c-expand-all-button__wrapper">
      <ELSButton size="xsmall" className="c-expand-all-button__button" onClick={handleExpandAll} onKeyDown={e => onKeyDownHandler(e, handleExpandAll)}>
        {isExpandAll ? 'Collapse All' : 'Expand All'}
      </ELSButton>
    </div>
  );
};

export default ExpandAllButton;
