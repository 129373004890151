import { perFacadeApi, facadeApi } from 'helpers/api.helper';
import { SystemType, CrosswalkUserDto, EOLSUser } from 'models/user-management';

export const fetchCrosswalkUser = (userId: string, type: SystemType): Promise<{ data: CrosswalkUserDto }> => {
  return perFacadeApi.get(`/eolsUser/${userId}/type/${type}/crosswalk`);
};

export const fetchUserByUserId = (id: string): Promise<{ data: EOLSUser }> => {
  return facadeApi.get(`v1/eolsUser/${id}`);
};

export const fetchAllEolsUserByEolsUserId = (eolsUserId: string | number, type: string): Promise<{ data: EOLSUser[] }> => {
  if (eolsUserId) {
    return perFacadeApi.get(`/eolsUser/${eolsUserId}/type/${type}/crosswalk/all`);
  }
  return Promise.resolve({ data: [] });
};

export const fetchExternalUserInfo = (evolveUserName: string, newToken?: string): Promise<{ data: EOLSUser }> => {
  const fetchUrl = `/eolsUser/externalUsername/${evolveUserName}`;
  if (newToken) {
    const header = new Headers({
      Authorization: `Bearer ${newToken}`
    });
    return facadeApi.get(fetchUrl, { header });
  }
  return facadeApi.get(fetchUrl);
};
