export const mapStudentName = {
  6952847: {
    firstName: 'Jessica',
    lastName: 'Allbright'
  },
  3047711: {
    firstName: 'Bennie',
    lastName: 'Factor'
  },
  5167001: {
    firstName: 'Jacqueline',
    lastName: 'Payeur'
  },
  5846998: {
    firstName: 'Alexander',
    lastName: 'Tushishvili'
  },
  6037438: {
    firstName: 'MacKenzie',
    lastName: 'Wagner'
  }
};

export const mapMockCourse = {
  471204: {
    courseSectionId: '471204',
    originalCourseName: "Sherpath Vantage for Harding Lewis's Medical-Surgical Nursing, 12th Edition",
    courseName: 'ABSN 398A Professional Development: Grad Dec 24 (Spring 2024)'
  },
  554121: {
    courseSectionId: '554121',
    originalCourseName: '(DEMO) Spring 2024 - Sherpath Vantage for Potter Fundamentals of Nursing',
    courseName: 'ABSN Nur 221A: Fundamentals: Healthcare Quality and Safety (Fall 2023)'
  },
  568749: {
    courseSectionId: '568749',
    originalCourseName: 'GSM Demo - Sherpath Vantage for Potter Fundamentals of Nursing',
    courseName: 'Clinical Intensive II-NUR412A'
  }
};

export const mapEAQWithIsbnAndStudentId = {
  6952847: [9780323760409, 9780323797337],
  3047711: [9780323797337],
  5167001: [9780323760409],
  5846998: [9780323797337, 9780323760409],
  6037438: [9780323760409]
};
