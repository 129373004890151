import classNames from 'classnames';
import { ELSRadio } from '@els/els-component-form-field-react';

export const selectedRadioClassName = 'c-els-radio-checked';

export interface CourseOptions {
  courseId: number;
  courseName: string;
}

export const renderCourseList = ({
  courseList,
  selectedCourseId,
  onSelectCourse
}: {
  courseList: CourseOptions[];
  selectedCourseId: number;
  onSelectCourse: (courseId: number) => void;
}) =>
  courseList.map(({ courseId, courseName }) => {
    const checked = courseId === Number(selectedCourseId);
    return (
      <div key={courseId} className={classNames('u-els-margin-top-1x1o2', { [selectedRadioClassName]: checked })}>
        <ELSRadio name={courseName} value={courseId} checked={checked} changeHandler={e => onSelectCourse(e.target.value)}>
          {courseName}
        </ELSRadio>
      </div>
    );
  });
