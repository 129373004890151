import useCollapse from './useCollapse';

export interface CollapseProps {
  activeKey?: Array<string>;
  showArrow?: boolean;
  className?: string;
  collapseIconName?: string;
  expandIconName?: string;
}

const Collapse: React.FC<CollapseProps> = props => {
  const { getItems, collapseClassName } = useCollapse(props);

  return (
    <ul className={collapseClassName} role="tablist">
      {getItems()}
    </ul>
  );
};

export default Collapse;
