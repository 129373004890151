import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RadioMenu } from 'components/common/select-menu';
import ReportInsightCard, { ReportInsightCardProps } from 'components/common/report-insight-card/ReportInsightCard';
import { ChartType } from 'components/report-pie-chart/ReportPieChart';
import { ANALYTIC_FEATURES, CW_ENGAGEMENT_CARD_ID } from 'constants/app.constant';
import { pluralizeUnit } from 'helpers/ui.helper';
import { AssignmentTypeOptions } from 'models';
import { INTERACTION_BETWEEN_CARD_AND_TABLE, REPORT_TABLE_ID } from 'reports/cw/constants/report.constant';
import { getAssignmentTypeIcon } from 'reports/cw/helpers/report.helper';
import { AssignmentEngagementMetricDTO, StudentEngagementDTO, AssignmentEngagementDTO } from 'reports/cw/models';
import { cwActions, cwSelectors } from 'reports/cw/redux/ducks/cw';
import { ENGAGEMENT_TABS } from '../Engagement';

interface EngagementHeaderProps {
  assignmentEngagement: AssignmentEngagementDTO;
  isAssignmentEngagementReportLoading: boolean;
  isStudentEngagementReportLoading: boolean;
  assignmentTypeValue: string;
  assignmentTypeOptions: AssignmentTypeOptions[];
  filteredAssignmentEngagement: AssignmentEngagementMetricDTO[];
  studentEngagement: StudentEngagementDTO;
  onSetAssignmentTypeValue: Function;
  setStudentEngagementCardAndTableSortInteraction?: Function;
  setAssignmentEngagementCardAndTableSortInteraction?: Function;
  trackAAFeature: Function;
  onChangeTab: Function;
  isIncorrectDataHidden?: boolean;
}

class EngagementHeader extends Component<EngagementHeaderProps> {
  buildPercentValueElement = (percent: number): ReactElement => {
    return <span className="u-els-bold c-cw-information-card__value-data">{`${percent}%`}</span>;
  };

  buildCardProps = () => {
    const { assignmentEngagement, isAssignmentEngagementReportLoading, isStudentEngagementReportLoading, isIncorrectDataHidden } = this.props;
    const {
      cardData: { completedAssignments, classAvgScore, totalAssignments, totalStudents, pastDueStudents, belowClassAvgStudents, inProgressAssignment }
    } = assignmentEngagement;

    let cards: ReportInsightCardProps[] = [
      {
        id: CW_ENGAGEMENT_CARD_ID.numberOfStudentsWithPastDue,
        icon: 'alarm-clock',
        title: '# Students with Past Due',
        subtitle: 'View students with past due assignments',
        value: pastDueStudents,
        total: totalStudents,
        clickable: true,
        isLoading: isStudentEngagementReportLoading,
        chartConfig: {
          type: ChartType.HALF,
          value: pastDueStudents,
          total: totalStudents,
          fillColorClass: 'u-els-fill-warn'
        },
        mainAriaLabel: 'Number students with past due card',
        footerAriaLabel: 'Click to scroll down to the Student Engagement table and the Past Due Column will be sorted in descending order',
        className: 'c-cw-engagement-header__student-with-past-due-card',
        showTitleInfoIcon: true
      }
    ];

    cards = [
      {
        id: CW_ENGAGEMENT_CARD_ID.classAverageScore,
        icon: 'performance',
        title: 'Class Average Score',
        subtitle: `${belowClassAvgStudents} ${pluralizeUnit(belowClassAvgStudents, 'student')} below class average`,
        customValueElement: !isIncorrectDataHidden ? (
          this.buildPercentValueElement(+(classAvgScore * 100).toFixed(0))
        ) : (
          <span className="u-els-bold c-report-insight-card__value-data">Coming Soon</span>
        ),
        clickable: true,
        isLoading: isStudentEngagementReportLoading,
        chartConfig: {
          type: ChartType.FULL,
          value: +(classAvgScore * 100).toFixed(0),
          total: 100,
          fillColorClass: 'u-els-fill-extended-teal-7'
        },
        mainAriaLabel: 'Class average score card',
        footerAriaLabel: 'Click to scroll down to the Student Engagement table and the Student Average Score column will be sorted in ascending order',
        className: 'c-cw-engagement-header__class-avg-score-card',
        customFooter: !isIncorrectDataHidden ? null : <span> </span>,
        showTitleInfoIcon: true
      },
      {
        id: CW_ENGAGEMENT_CARD_ID.assignmentCompleted,
        icon: 'document-check',
        title: 'Completed Assignments',
        subtitle: `${inProgressAssignment} ${pluralizeUnit(inProgressAssignment, 'assignment')} in progress`,
        value: completedAssignments,
        total: totalAssignments,
        clickable: true,
        isLoading: isAssignmentEngagementReportLoading,
        chartConfig: {
          type: ChartType.HALF,
          value: completedAssignments,
          total: totalAssignments,
          fillColorClass: 'u-els-fill-extended-blue-9'
        },
        mainAriaLabel: 'Completed assignment card',
        footerAriaLabel: 'Click to scroll down to the Assignment Engagement table and the Completion column will be sorted in descending order',
        className: 'c-cw-engagement-header__completed-assignment-card',
        customValueElement: !isIncorrectDataHidden ? null : <span className="u-els-bold c-report-insight-card__value-data">Coming Soon</span>,
        customFooter: !isIncorrectDataHidden ? null : <span> </span>,
        showTitleInfoIcon: true
      },
      ...cards
    ];

    return cards;
  };

  handleSubTitleClick = (id: string) => {
    const {
      student: { index: studentIdx },
      assignment: { index: assignmentIdx }
    } = ENGAGEMENT_TABS;

    this.props.trackAAFeature(ANALYTIC_FEATURES[id]);
    const getProps = (sortDirection: string, columnHeaderSelector: string, field: string) => {
      return {
        sortDirection,
        columnHeaderSelector,
        field
      };
    };

    const {
      tableColumn: { table, field, headerSelector },
      sortDirection
    } = INTERACTION_BETWEEN_CARD_AND_TABLE[id];
    if (table === REPORT_TABLE_ID.assignmentEngagement) {
      this.props.onChangeTab(assignmentIdx);
      this.props.setAssignmentEngagementCardAndTableSortInteraction(getProps(sortDirection, headerSelector, field));
    }
    if (table === REPORT_TABLE_ID.studentEngagement) {
      this.props.onChangeTab(studentIdx);
      this.props.setStudentEngagementCardAndTableSortInteraction(getProps(sortDirection, headerSelector, field));
    }
  };

  render() {
    const { assignmentTypeValue, assignmentTypeOptions, onSetAssignmentTypeValue } = this.props;
    const icon = getAssignmentTypeIcon(assignmentTypeValue);
    return (
      <div className="c-cw-engagement-header">
        <div className="u-els-margin-bottom-2x u-els-margin-top-1o2">
          {icon}
          <h2 className="u-els-text-align-inline-block  u-els-margin-right-1o2">How engaged is my class in</h2>
          <h2 className="u-els-text-align-inline-block  u-els-margin-right-1o2">
            <RadioMenu inline checkedKey={assignmentTypeValue} options={assignmentTypeOptions} onOptionChange={option => onSetAssignmentTypeValue(option)} hideRadio />
          </h2>
          <h2 className="u-els-text-align-inline-block ">assignments?</h2>
        </div>
        <div className="u-els-margin-top-2x u-els-margin-bottom-2x c-cw-engagement-header__cards">
          {this.buildCardProps().map(cardProp => (
            <div key={cardProp.id} className={`c-cw-engagement-header__card ${cardProp.className}`}>
              <ReportInsightCard
                onSubTitleClick={(id: string) => {
                  this.handleSubTitleClick(id);
                }}
                {...cardProp}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  assignmentEngagement: cwSelectors.getAssignmentEngagement(state),
  studentEngagement: cwSelectors.getStudentEngagement(state),
  isAssignmentEngagementReportLoading: cwSelectors.getAssignmentEngagementReportLoading(state),
  isStudentEngagementReportLoading: cwSelectors.getStudentEngagementReportLoading(state)
});

const mapDispatchToProps = dispatch => ({
  setStudentEngagementCardAndTableSortInteraction: data => dispatch(cwActions.setStudentEngagementCardAndTableSortInteraction(data)),
  setAssignmentEngagementCardAndTableSortInteraction: data => dispatch(cwActions.setAssignmentEngagementCardAndTableSortInteraction(data))
});

export { EngagementHeader as EngagementHeaderBase };
export default connect(mapStateToProps, mapDispatchToProps)(EngagementHeader);
