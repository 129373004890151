import { facadeApi, perFacadeApi } from 'helpers/api.helper';
import { AssessmentTaxonomy, EAQTopicMappedHesiExam, StudentTopicPerformance } from 'reports/ehr/models';
import { FULFILLED_STATUS } from 'constants/app.constant';

const fetchEAQTopicMappedHesiExam = async (isbnList: string[]): Promise<EAQTopicMappedHesiExam[]> => {
  const result: EAQTopicMappedHesiExam[] = [];
  const promises = [];
  const chunkSize = 10;
  for (let i = 0; i < isbnList.length; i += chunkSize) {
    const element = isbnList.slice(i, i + chunkSize);
    promises.push(facadeApi.get(`/performancereport/ehr/recommendation/learning/catalog?filter%5Bisbns%5D=${element}&filter%5BlearningType%5D=HESI_EXAM&included=TAXONOMY`));
  }
  const responses = await Promise.allSettled(promises);
  responses.forEach(response => {
    result.push(...(response.status === FULFILLED_STATUS ? response.value.data.included : []));
  });
  return result;
};

const fetchMasteryAssessment = async (isbnList: string[], courseId: number): Promise<AssessmentTaxonomy[]> => {
  const result: AssessmentTaxonomy[] = [];
  const promises = isbnList.map(isbn => perFacadeApi.get(`/v2/assessment/mastery/${isbn}/${courseId}`));
  const responses = await Promise.allSettled(promises);
  responses.forEach(response => {
    result.push(...(response.status === FULFILLED_STATUS ? response.value.data : []));
  });
  return result;
};

const fetchEHRStudents = async (isbnList: string[], courseId: number, studentId?: number): Promise<StudentTopicPerformance[]> => {
  const result: StudentTopicPerformance[] = [];
  let promises = [];
  if (studentId) {
    promises = isbnList.map(isbn => perFacadeApi.get(`/ehr/assessment/mastery/${isbn}/${courseId}?userId=${studentId}`));
  } else {
    promises = isbnList.map(isbn => perFacadeApi.get(`/ehr/assessment/mastery/${isbn}/${courseId}`));
  }
  const responses = await Promise.allSettled(promises);
  responses.forEach(response => {
    result.push(...(response.status === FULFILLED_STATUS ? response.value.data : []));
  });
  return result;
};

export { fetchEAQTopicMappedHesiExam, fetchMasteryAssessment, fetchEHRStudents };
