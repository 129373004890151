import React, { FC, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ELSIcon } from '@els/els-component-form-field-react';
import { NavItem } from 'models';

import { checkPathMatch } from 'helpers/app.helper';
import { onKeyDownHandler } from 'helpers/ui.helper';
import useClickOutside from '../use-click-out-side/useClickOutSide';

export interface NavItems {
  default: Array<NavItem>;
  expand?: Array<NavItem>;
}
interface PageNavigatorModalProps {
  navItems: NavItems;
  handleCancel: Function;
  activePageName: string;
  isHesiReadinessApp?: boolean;
  goToEAQReport?: Function;
}

const PageNavigatorModal: FC<PageNavigatorModalProps> = ({ navItems, handleCancel, activePageName, isHesiReadinessApp, goToEAQReport }) => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleCancel);
  const data = isHesiReadinessApp ? [...navItems.expand] : [...navItems.default, ...navItems.expand];

  const navItemElm = data.map(item => {
    const { name, paths, icon, isAlwaysActive, onClickCustomLink } = item;
    if (onClickCustomLink) {
      return (
        <div
          key={name}
          role="button"
          tabIndex={0}
          className="c-page-navigator-modal__item c-page-navigator-modal__item-eaq-topic-report"
          onClick={() => goToEAQReport()}
          onKeyDown={evt => onKeyDownHandler(evt, goToEAQReport)}
        >
          {name}
        </div>
      );
    }

    const match = checkPathMatch(paths);
    return (
      <Link key={name} className={classNames('c-page-navigator-modal__item', { 'c-page-navigator-modal__item--active': isAlwaysActive || match })} to={paths[0]}>
        {name}
        {icon && <ELSIcon name={icon} size="1x" />}
      </Link>
    );
  });

  return (
    <div className="c-els-modal c-page-navigator-modal">
      <div ref={wrapperRef} className="c-page-navigator-modal__content">
        <div className={classNames('c-page-navigator-modal__content-row', { 'c-page-navigator-modal__content-row--justify-center': !activePageName })}>
          {activePageName}
          <button tabIndex={0} type="button" className="u-cursor-pointer c-page-navigator-modal__content-row-close-btn" onClick={() => handleCancel()}>
            <ELSIcon name="close" size="1x1o2" />
          </button>
        </div>
        <div className="c-els-divider c-els-divider--1o2" />
        <div className="u-els-color-secondary c-page-navigator-modal__content-row">Back to My Course Plan</div>
        <div className="c-els-divider c-els-divider--1o2" />
        <div>{navItemElm}</div>
      </div>
    </div>
  );
};

export default PageNavigatorModal;
