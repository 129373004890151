import { ehrPath } from 'reports/ehr/constants/ehr.constant';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { HESI_NG_PATHS } from 'reports/hesi-ng/constants/hesi-ng.constant';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { PS_PATHS } from 'reports/ps/constants/ps.constant';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { ServerConstants } from '../config/server.constant';
import { RoutePath } from './app.constant';

const { gatewayBaseURL } = ServerConstants[ServerConstants.DataSource];

export const APP_LINK_PAGE_NAME_WITH_ROUTE = {
  PERFORMANCE_HESI_DASHBOARD: {
    name: 'PERFORMANCE_HESI_DASHBOARD',
    route: RoutePath.had
  },
  PERFORMANCE_HESI_READINESS: {
    name: 'PERFORMANCE_HESI_READINESS',
    route: RoutePath.ehr
  },
  CW_ASSIGNMENT_ENGAGEMENT: {
    name: 'CW_ASSIGNMENT_ENGAGEMENT',
    route: PerformancePath.engagement.assignment
  },
  CW_STUDENT_ENGAGEMENT: {
    name: 'CW_STUDENT_ENGAGEMENT',
    route: PerformancePath.engagement.student
  },
  CW_ASSIGNMENT_SUMMARY: {
    name: 'CW_ASSIGNMENT_SUMMARY',
    route: PerformancePath.assignmentSummary
  },
  CW_GRADEBOOK: {
    name: 'CW_GRADEBOOK',
    route: PerformancePath.gradeBook.home
  },
  CW_INSTRUCTOR_HOME: {
    name: 'CW_INSTRUCTOR_HOME',
    route: RoutePath.home
  },
  CW_STUDENT_HOME: {
    name: 'CW_STUDENT_HOME',
    route: RoutePath.student.home
  },
  CW_DISENGAGED_STUDENT: {
    name: 'CW_DISENGAGED_STUDENT',
    route: PerformancePath.disengagedStudent.home
  },
  CW_ENGAGEMENT_SUMMARY: {
    name: 'CW_ENGAGEMENT_SUMMARY',
    route: PerformancePath.engagementSummary
  },
  EHR_HOME_BY_TOPIC_AND_CHAPTER: {
    name: 'EHR_HOME_BY_TOPIC_AND_CHAPTER',
    route: ehrPath.ehrHomeByTopicAndChapter
  },
  EHR_HOME_BY_STUDENT: {
    name: 'EHREHR_HOME_BY_STUDENT_HOME',
    route: ehrPath.ehrHomeByStudent
  },
  EHR_STUDENT_DETAIL: {
    name: 'EHR_STUDENT_DETAIL',
    route: ehrPath.ehrStudentDetail
  },
  HAD_STUDENT_DASHBOARD: {
    name: 'HAD_STUDENT_DASHBOARD',
    route: HAD_PATHS.studentHome
  },
  REPORTING_PORTAL: {
    name: 'REPORTING_PORTAL',
    route: RoutePath.reportingPortal
  },
  SHADOW_HEALTH_DASHBOARD: {
    name: 'SHADOW_HEALTH_DASHBOARD',
    route: SH_PATHS.dashboard
  },
  PS_DASHBOARD: {
    name: 'PS_DASHBOARD',
    route: PS_PATHS.homePage
  },
  PERFORMANCE_HESI_NG_EXAM_RESULTS: {
    name: 'PERFORMANCE_HESI_NG_EXAM_RESULTS',
    route: HESI_NG_PATHS.hesiNGExamResults
  },
  PERFORMANCE_HESI_NG_EXAM_RESULTS_STUDENT: {
    name: 'PERFORMANCE_HESI_NG_EXAM_RESULTS_STUDENT',
    route: HESI_NG_PATHS.hesiNGStudentExamResults
  },
  EAQ_STUDENT_TOPIC_REPORT: {
    name: 'EAQ_STUDENT_TOPIC_REPORT',
    route: ''
  },
  CPRA_DASHBOARD: {
    name: 'CPRA_DASHBOARD',
    route: SH_PATHS.cpra
  }
};

export const EXTERNAL_APP_INFO = {
  APP_LINKING: {
    EAQ: {
      APP_NAME: 'EAQ',
      ACTION: {
        ASSIGNMENT_CREATE: 'ASSIGNMENT_CREATE',
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW',
        PERFORMANCE_VIEW: 'PERFORMANCE_VIEW',
        ASSESSMENT_START: 'ASSESSMENT_START'
      },
      backTitle: 'EAQ'
    },
    SHERPATH: {
      APP_NAME: 'SHERPATH',
      ACTION: {
        PERFORMANCE_VIEW: 'PERFORMANCE_VIEW',
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'Sherpath'
    },
    KNOWLEDGE_CARD: {
      APP_NAME: 'KNOWLEDGECARD',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW',
        ASSIGNMENT_PREVIEW: 'ASSIGNMENT_PREVIEW',
        ASSIGNMENT_EDIT: 'ASSIGNMENT_EDIT',
        ASSESSMENT_START: 'ASSESSMENT_START',
        ASSIGNMENT_CREATE: 'ASSIGNMENT_CREATE'
      },
      backTitle: 'Knowledge card'
    },
    SHER_EVOL: {
      APP_NAME: 'SHER_EVOL',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW',
        PERFORMANCE_VIEW: 'PERFORMANCE_VIEW',
        ASSIGNMENT_CREATE: 'ASSIGNMENT_CREATE'
      },
      backTitle: 'Sherpath'
    },
    SIMSNG: {
      APP_NAME: 'simsng',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'Sim NG'
    },
    PERSONAL_ASSESSMENT_BUILDER: {
      APP_NAME: 'EAB',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'EAB'
    },
    AUTHESS: {
      APP_NAME: 'AUTHESS',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'AUTHESS'
    },
    QUAD: {
      APP_NAME: 'QUAD',
      backTitle: 'QUAD'
    },
    SHADOW_HEALTH: {
      APP_NAME: 'SHADOW_HEALTH',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'Shadow Health'
    },
    OSMOSIS_VIDEO: {
      APP_NAME: 'SHER_EVOL',
      ACTION: {
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW'
      },
      backTitle: 'Osmosis'
    },
    PERFORMANCE: {
      APP_NAME: 'PERFORMANCE'
    },
    HESI_NG: {
      APP_NAME: 'HESI_NG',
      ACTION: {
        LAUNCH: 'LAUNCH'
      }
    },
    ASSIGNMENT_MANAGEMENT: {
      APP_NAME: 'ASSIGNMENT_MANAGEMENT',
      ACTION: {
        ASSIGNMENT_CREATE: 'ASSIGNMENT_CREATE',
        ASSIGNMENT_PREVIEW: 'ASSIGNMENT_PREVIEW',
        ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW',
        ASSIGNMENT_EDIT: 'ASSIGNMENT_EDIT'
      }
    },
    STUDENT_STUDY: {
      APP_NAME: 'STUDENT_STUDY',
      ACTION: {
        PERFORMANCE_VIEW: 'PERFORMANCE_VIEW',
        LAUNCH: 'LAUNCH'
      }
    }
  },
  APP_LINK_DATA_API: `${gatewayBaseURL}/api/app-link/data`,
  APP_LINK_RETURN_API: `${gatewayBaseURL}/api/app-link/return`,
  APP_LINK_HASH_API: `${gatewayBaseURL}/api/app-link/hash-link`
};

export enum EvolveProductTypeKey {
  SHERPATH_IA = 'sherpath_ia',
  SHERPATH_BOOK_ORGANIZED = 'sherpath_book_organized',
  // Non sellable product used mainly to map EAQs to Sherpath courses
  // This name is confusing since it is used for EAQs but it is meant to be read from the EAQ app perspective
  SHERPATH_COMPONENT_NSS = 'sherpath_component_nss',
  // Non sellable ebook product - not sure why we use this ISBN instead of the vbId here
  SHERPATH_EBOOK_COMPONENT_NSS = 'sherpath_ebook_component_nss',
  SIMULATION_SIM_CHART_NG = 'simulation_sim_chart_ng',
  EAQNG_IA = 'eaqng_ia',
  EAQ_IA = 'eaq_ia',
  ELSEVIER_ASSESSMENT_BUILDER = 'elsevier_assessment_builder_ia',
  SHADOW_HEALTH = 'shadow_health',
  HESI_CASE_STUDY = 'case_study',
  HESI_PATIENT_REVIEW = 'patient_reviews',
  HESI_PRACTICE_TEST = 'practice_test',
  NURSING_CONCEPTS_IA = 'nursing_concepts_ia',
  HESI_ASSESSMENT_NEXTGEN = 'hesi_assessment_nextgen'
}

export const ASSIGNMENT_CREATE_LINK_CONFIGS = {
  EAQ: {
    appName: EXTERNAL_APP_INFO.APP_LINKING.EAQ.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.EAQ.ACTION.ASSIGNMENT_CREATE
  },
  ASSIGNMENT_MANAGEMENT: {
    appName: EXTERNAL_APP_INFO.APP_LINKING.ASSIGNMENT_MANAGEMENT.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.ASSIGNMENT_MANAGEMENT.ACTION.ASSIGNMENT_CREATE
  },
  SHER_EVOL: {
    appName: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.ACTION.ASSIGNMENT_CREATE
  },
  KNOWLEDGE_CARD: {
    appName: EXTERNAL_APP_INFO.APP_LINKING.KNOWLEDGE_CARD.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.KNOWLEDGE_CARD.ACTION.ASSIGNMENT_CREATE
  },
  STUDENT_STUDY: {
    appName: EXTERNAL_APP_INFO.APP_LINKING.STUDENT_STUDY.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.STUDENT_STUDY.ACTION.LAUNCH
  }
};
