import { CourseSection } from 'reports/cw/models';

export enum SystemType {
  EVOLVETYPE = 'evolve',
  VSTTYPE = 'vst'
}

export interface CrosswalkUserDto {
  eolsId: number;
  externalUserId: number;
  userName: string;
  type: string;
  userData: string;
  eolsUserIdsByUserNameAndType: number[];
}

export interface EOLSUser {
  id: number;
  userId?: number;
  lmsExternalId: string;
  externalId: string;
  firstName: string;
  lastName: string;
  username: string;
  emailAddress: string;
  lastSeenDate: string;
  updatedAt: string;
  type: string;
  eolsUserRoles: Array<{ role: string }>;
  role?: string;
  courseSections?: Array<CourseSection>;
  appParams?: Record<string, any>;
}
