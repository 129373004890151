import React, { useState, useEffect } from 'react';
import { getWindowMode } from 'helpers/app.helper';
import { debounce, isEqual } from 'lodash';

const withResizeScreen = WrappedComponent => props => {
  const [windowMode, setWindowMode] = useState(getWindowMode());

  const updateDimension = debounce(() => {
    if (!isEqual(windowMode, getWindowMode())) {
      setWindowMode(getWindowMode());
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  return <WrappedComponent {...props} windowMode={windowMode} />;
};

export default withResizeScreen;
