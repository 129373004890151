import moment from 'moment';

export default {
  courseSectionId: 516809,
  contentType: null,
  metrics: [
    {
      courseSectionId: 516809,
      assignmentId: 3678951,
      assignmentName: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H30M57S',
      avgTimeSpentPerQuestion: 'PT45S',
      avgQuestionsAnsweredCorrect: 280.29411764705884,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8947368421052632,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 17,
      totalStudentsPastDue: 2,
      totalStudents: 19,
      dueDate: '2024-04-18T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645904,
      assignmentName: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H3M11S',
      avgTimeSpentPerQuestion: 'PT40S',
      avgQuestionsAnsweredCorrect: 95.33333333333333,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9473684210526315,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 18,
      totalStudentsPastDue: 1,
      totalStudents: 19,
      dueDate: '2024-04-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645906,
      assignmentName: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT59M28S',
      avgTimeSpentPerQuestion: 'PT38S',
      avgQuestionsAnsweredCorrect: 94.77777777777777,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9473684210526315,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 18,
      totalStudentsPastDue: 1,
      totalStudents: 19,
      dueDate: '2024-04-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645907,
      assignmentName: 'NUR 398A Pediatric Drugs - Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT56M55S',
      avgTimeSpentPerQuestion: 'PT35S',
      avgQuestionsAnsweredCorrect: 98.05882352941177,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8947368421052632,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 17,
      totalStudentsPastDue: 2,
      totalStudents: 19,
      dueDate: '2024-04-08T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645912,
      assignmentName: 'NUR 398A Medical-Surgical Drugs - Novice',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H42M46S',
      avgTimeSpentPerQuestion: 'PT34S',
      avgQuestionsAnsweredCorrect: 183.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8421052631578947,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 3,
      totalStudents: 19,
      dueDate: '2024-04-08T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645914,
      assignmentName: 'NUR 398A Gastrointestinal - Intermediate',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 0,
      percentAvgScore: 0,
      avgTimeSpent: 'PT2H17M42S',
      avgTimeSpentPerQuestion: 'PT47S',
      avgQuestionsAnsweredCorrect: 0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 8,
      totalStudents: 8,
      dueDate: '2024-04-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3645916,
      assignmentName: 'NUR 398A Endocrine - Intermediate',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1H16M1S',
      avgTimeSpentPerQuestion: 'PT37S',
      avgQuestionsAnsweredCorrect: 123.41176470588235,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8947368421052632,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 17,
      totalStudentsPastDue: 2,
      totalStudents: 19,
      dueDate: '2024-04-18T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3678945,
      assignmentName: 'NUR 398A Renal - Novice, recommend Intermediate',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT39M18S',
      avgTimeSpentPerQuestion: 'PT34S',
      avgQuestionsAnsweredCorrect: 70.0625,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.8421052631578947,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 3,
      totalStudents: 19,
      dueDate: '2024-04-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 516809,
      assignmentId: 3678953,
      assignmentName: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.75,
      percentAvgScore: 75,
      avgTimeSpent: 'PT1H56M37S',
      avgTimeSpentPerQuestion: 'PT43S',
      avgQuestionsAnsweredCorrect: 70,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.102,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 16,
      totalStudentsCompleted: 2,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: moment()
        .add(2, 'days')
        .toISOString(),
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734857,
      assignmentName: 'Pharmacokinetics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734858,
      assignmentName: 'Pharmacodynamics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734859,
      assignmentName: 'Analgesics',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734860,
      assignmentName: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734861,
      assignmentName: 'Anticonvulsant Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 516809,
      assignmentId: 3734862,
      assignmentName: 'Antidepressant and Mood Stabilizer Drug Therapy',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'OSMOSIS_VIDEO',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 19,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      dueDate: null,
      isHaveScore: false
    }
  ],
  cardData: {
    completedAssignments: 7,
    below85percentAssignments: 1,
    pastDueAssignments: 1,
    totalAssignments: 15,
    classAvgScore: null,
    totalStudents: null,
    pastDueStudents: null,
    belowClassAvgStudents: null,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['OSMOSIS_VIDEO', 'MASTERY']
};
