import { Component, ErrorInfo } from 'react';
import { Redirect } from 'react-router-dom';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { errorService } from 'services';
import { ELSIcon } from 'components/common';

interface ErrorBoundaryState {
  error: Error;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error
    });
    errorService.ErrorService.log({ err: error, previousState: errorInfo, previousStateParams: null });
  }

  handleLoadingChunkFailed = () => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const { error } = this.state;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  };

  render() {
    if (this.state.error) {
      if (this.state.error.name === 'InvalidTokenError') {
        return <Redirect to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />;
      }
      this.handleLoadingChunkFailed();

      return (
        <div className="u-els-padding-3x u-els-text-center">
          <div>
            <ELSIcon name="alert-unexpected-error" size="4x" />
          </div>
          <h2>Oops, something went wrong.</h2>
          <div>
            <a href="/">Go Home</a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
