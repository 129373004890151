export default {
  courseSectionId: 622453,
  contentType: 'QUIZ_BY_QUESTION',
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597488,
      assignmentName: 'Analgesic Therapy ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradePoints: 20,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M11S',
      avgTimeSpentPerQuestion: 'PT46S',
      avgQuestionsAnsweredCorrect: 20.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-06-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4785643,
      assignmentName: 'Week 4 Quiz- In Class ',
      assignmentGradeType: 'SCORED',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradePoints: 29,
      avgScore: 0.9870689655172413,
      percentAvgScore: 98.70689655172413,
      avgTimeSpent: 'PT16M30S',
      avgTimeSpentPerQuestion: 'PT34S',
      avgQuestionsAnsweredCorrect: 28.625,
      avgQuestionsAnsweredIncorrect: 0.37500000000000155,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-19T03:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 2,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 2,
    classAvgScore: 0.9935344827586207,
    totalStudents: 16,
    pastDueStudents: 0,
    belowClassAvgStudents: 4,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
