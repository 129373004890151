export const MASTERY_LEVELS_CONFIGS = {
  proficient: {
    key: 'proficient',
    field: 'proficient',
    color: 'extended-purple-9',
    label: 'Proficient',
    scoreRange: '(3.0-3.9)'
  },
  intermediate: {
    key: 'intermediate',
    field: 'intermediate',
    color: 'extended-purple-5',
    label: 'Intermediate',
    scoreRange: '(2.0-2.9)'
  },
  novice: {
    key: 'novice',
    field: 'novice',
    color: 'extended-purple-1',
    label: 'Novice',
    scoreRange: '(1.0-1.9)'
  },
  preNovice: {
    key: 'preNovice',
    field: 'preNovice',
    color: 'n1',
    label: 'Incomplete',
    scoreRange: '(0-0.9)'
  }
};

export const PS_PILL_TITLES = {
  PS: 'Program Success Dashboard',
  SHERPATH: 'Build Knowledge | Sherpath',
  SHADOW_HEALTH: 'Apply Clinical Judgment | Shadow Health',
  HESI: 'Assess & Review | HESI'
};

export const DEFAULT_SH_DATA = {
  data: [],
  fallbackMessage: ''
};

export const PS_SHERPATH_ASSIGNMENT_SCORE_COLOR = {
  RED: 'u-els-fill-warn',
  GREEN: 'u-els-fill-extended-green-8'
};

export const PS_SHERPATH_ASSIGNMENT_SCORE_THRESHOLD = 65;
