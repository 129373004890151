export default [
  {
    examType: 'Specialty',
    exams: [
      {
        programId: 197395,
        examId: 10529,
        examName: 'SP Gerontology_D',
        dateTaken: '2019-03-15',
        cohortId: 12429,
        categories: [
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 8,
            categoryScore: 814.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 8,
            categoryScore: 468.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 31,
            categoryScore: 928.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 5,
            categoryScore: 1460.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 4,
            categoryScore: 321.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 9,
            categoryScore: 1056.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 6,
            categoryScore: 1120.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 5,
            categoryScore: 629.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 9,
            categoryScore: 919.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 11,
            categoryScore: 962.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1219,
        examName: 'SP MS_D',
        dateTaken: '2019-10-30',
        cohortId: 12429,
        categories: [
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 4,
            categoryScore: 1545.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 1,
            categoryScore: 955.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 10,
            categoryScore: 1261.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 10,
            categoryScore: 1261.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 39,
            categoryScore: 987.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 11,
            categoryScore: 874.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 23,
            categoryScore: 964.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 6,
            categoryScore: 1184.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1284,
        examName: 'SP Management_D',
        dateTaken: '2019-07-16',
        cohortId: 12429,
        categories: [
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 54,
            categoryScore: 1059.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 3,
            categoryScore: 1374.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 51,
            categoryScore: 1044.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1220,
        examName: 'SP Mat_D',
        dateTaken: '2019-03-11',
        cohortId: 12429,
        categories: [
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 2,
            categoryScore: 1131.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 1,
            categoryScore: 1370.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 36,
            categoryScore: 1135.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 7,
            categoryScore: 1002.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 18,
            categoryScore: 1066.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 12,
            categoryScore: 1187.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 6,
            categoryScore: 940.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 13,
            categoryScore: 966.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 4,
            categoryScore: 1290.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1221,
        examName: 'SP Pedi_D',
        dateTaken: '2019-10-30',
        cohortId: 12429,
        categories: [
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 2,
            categoryScore: 1434.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 33,
            categoryScore: 833.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 6,
            categoryScore: 1311.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 8,
            categoryScore: 1353.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 11,
            categoryScore: 989.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 13,
            categoryScore: 736.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 10,
            categoryScore: 818.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 6,
            categoryScore: 736.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 4,
            categoryScore: 666.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 3,
            categoryScore: 889.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1225,
        examName: 'SP Fund_D',
        dateTaken: '2019-06-19',
        cohortId: 12429,
        categories: [
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 14,
            categoryScore: 1188.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 20,
            categoryScore: 1047.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 1505.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 11,
            categoryScore: 968.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 2,
            categoryScore: 1062.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 2,
            categoryScore: 1462.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 7,
            categoryScore: 395.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 10,
            categoryScore: 1118.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 4,
            categoryScore: 717.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 31,
            categoryScore: 1142.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1195,
        examName: 'SP Psy_D',
        dateTaken: '2019-11-15',
        cohortId: 12429,
        categories: [
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 6,
            categoryScore: 1345.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 3,
            categoryScore: 499.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 3,
            categoryScore: 573.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 7,
            categoryScore: 1345.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 34,
            categoryScore: 1020.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 1349.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 14,
            categoryScore: 1110.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 10509,
        examName: 'SP Health Assessment_D',
        dateTaken: '2019-02-28',
        cohortId: 12429,
        categories: [
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 43,
            categoryScore: 1095.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 6,
            categoryScore: 599.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 1,
            categoryScore: 1103.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 50,
            categoryScore: 1039.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 1,
            categoryScore: 1049.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 2,
            categoryScore: 1199.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 2,
            categoryScore: 0.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 1,
            categoryScore: 1103.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 1230,
        examName: 'SP Pharm_D',
        dateTaken: '2019-07-03',
        cohortId: 12429,
        categories: [
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 51,
            categoryScore: 1034.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 4,
            categoryScore: 780.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 55,
            categoryScore: 1020.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 798,
        examName: 'Critical Thinking_D',
        dateTaken: '2018-12-05',
        cohortId: 12429,
        categories: []
      }
    ]
  },
  {
    examType: 'Exit',
    exams: [
      {
        programId: 197395,
        examId: 10969,
        examName: 'RN Exit V-1_D',
        dateTaken: '2019-11-06',
        cohortId: 12429,
        categories: [
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 12,
            categoryScore: 842.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 10,
            categoryScore: 1268.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 11,
            categoryScore: 1053.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 50,
            categoryScore: 1133.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 90,
            categoryScore: 1034.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 29,
            categoryScore: 1000.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 18,
            categoryScore: 1086.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 20,
            categoryScore: 1008.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 29,
            categoryScore: 1178.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 32,
            categoryScore: 1153.0
          }
        ]
      },
      {
        programId: 197395,
        examId: 10970,
        examName: 'RN Exit V-2_D',
        dateTaken: '2019-11-27',
        cohortId: 12429,
        categories: [
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 51,
            categoryScore: 930.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 88,
            categoryScore: 1115.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 12,
            categoryScore: 1259.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 27,
            categoryScore: 1138.0
          },
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 18,
            categoryScore: 972.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 25,
            categoryScore: 1031.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 24,
            categoryScore: 1101.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 33,
            categoryScore: 913.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 11,
            categoryScore: 1365.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 10,
            categoryScore: 960.0
          }
        ]
      }
    ]
  }
];
