import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { AppConstant } from 'constants/app.constant';
import { CATEGORY_DROPDOWN_KEYS, STUDENT_PERFORMANCE_AVERAGE_TYPE } from 'reports/sh/constants/sh.constant';

const getSHState = state => state[AppConstant.reduxResources.SH_STATE];

const initialState = Immutable({
  assignmentDistributedScoreCardAndTableSortInteraction: null,
  assignmentInteractionTimeCardAndTableSortInteraction: null,
  instructorId: null,
  patientInteractionTimeCardAndTableSortInteraction: null,
  scoreDistributedCardAndTableSortInteraction: null,
  studentAssignmentsDistributedScoreCardAndTableSortInteraction: null,
  studentAverageComparedIndexPercentage: 0,
  studentAveragePerformanceType: STUDENT_PERFORMANCE_AVERAGE_TYPE.DCE,
  studentInteractionTimeCardAndTableSortInteraction: null,
  studentPerformanceIndexPercentage: 0,
  weakestCategoriesCardAndChartInteraction: { selectedCategories: [CATEGORY_DROPDOWN_KEYS.SDC] },
  sectionId: null,
  courseId: null,
  isCoursesLoading: false,
  studentClassAveragePITMinutes: null,
  assignmentViewCJMMItemAndTabInteraction: null,
  courses: [],
  classConstructSummaryCardAndTableSortInteraction: null,
  assignmentId: null
});

const prefixActionName = 'SH';
const setStudentPerformanceIndexPercentage = createAction(`${prefixActionName}/SET_STUDENT_PERFORMANCE_INDEX_PERCENTAGE`);
const setPatientInteractionTimeCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_PATIENT_INTERACTION_TIME_CARD_AND_TABLE_SORT_INTERACTION`);
const setStudentInteractionTimeCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_STUDENT_INTERACTION_TIME_CARD_AND_TABLE_SORT_INTERACTION`);
const setAssignmentInteractionTimeCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_ASSIGNMENT_INTERACTION_TIME_CARD_AND_TABLE_SORT_INTERACTION`);
const setScoreDistributedCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_SCORE_DISTRIBUTED_CARD_AND_TABLE_SORT_INTERACTION`);
const setStudentAssignmentsDistributedScoreCardAndTableSortInteraction = createAction(
  `${prefixActionName}/SET_STUDENT_ASSIGNMENTS_DISTRIBUTED_SCORE_CARD_AND_TABLE_SORT_INTERACTION`
);
const setAssignmentDistributedScoreCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_ASSIGNMENT_DISTRIBUTED_SCORE_CARD_AND_TABLE_SORT_INTERACTION`);
const setWeakestCategoriesCardAndChartInteraction = createAction(`${prefixActionName}/SET_WEAKEST_CATEGORIES_CARD_AND_CHART_INTERACTION`);
const setInstructorId = createAction(`${prefixActionName}/SET_INSTRUCTOR_ID`);
const restoreState = createAction(`${prefixActionName}/RESTORE`);
const setStudentAveragePerformanceType = createAction(`${prefixActionName}/SET_STUDENT_AVERAGE_PERFORMANCE_TYPE`);
const setStudentAverageComparedIndexPercentage = createAction(`${prefixActionName}/SET_STUDENT_AVERAGE_COMPARED_INDEX_PERCENTAGE`);
const setCourseId = createAction(`${prefixActionName}/SET_COURSE_ID`);
const setSectionId = createAction(`${prefixActionName}/SET_SECTION_ID`);
const setIsCoursesLoading = createAction(`${prefixActionName}/SET_IS_COURSES_LOADING`);
const setStudentClassAveragePITMinutes = createAction(`${prefixActionName}/SET_STUDENT_CLASS_AVERAGE_PIT_MINUTES`);
const setAssignmentViewCJMMItemAndTabInteraction = createAction(`${prefixActionName}/SET_ASSIGNMENT_VIEW_CJMM_ITEM_AND_TAB_INTERACTION`);
const setCourses = createAction(`${prefixActionName}/SET_COURSES`);
const setClassConstructSummaryCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_CLASS_CONSTRUCT_SUMMARY_CARD_AND_TABLE_SORT_INTERACTION`);
const setAssignmentId = createAction(`${prefixActionName}/SET_ASSIGNMENT_ID`);

const actions = {
  restoreState,
  setAssignmentDistributedScoreCardAndTableSortInteraction,
  setAssignmentInteractionTimeCardAndTableSortInteraction,
  setInstructorId,
  setPatientInteractionTimeCardAndTableSortInteraction,
  setScoreDistributedCardAndTableSortInteraction,
  setStudentAssignmentsDistributedScoreCardAndTableSortInteraction,
  setStudentAverageComparedIndexPercentage,
  setStudentAveragePerformanceType,
  setStudentInteractionTimeCardAndTableSortInteraction,
  setStudentPerformanceIndexPercentage,
  setWeakestCategoriesCardAndChartInteraction,
  setSectionId,
  setIsCoursesLoading,
  setCourseId,
  setStudentClassAveragePITMinutes,
  setAssignmentViewCJMMItemAndTabInteraction,
  setCourses,
  setClassConstructSummaryCardAndTableSortInteraction,
  setAssignmentId
};

const reducer = handleActions(
  {
    [actions.setStudentPerformanceIndexPercentage]: (state, { payload }) => Immutable.set(state, 'studentPerformanceIndexPercentage', payload),
    [actions.setPatientInteractionTimeCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'patientInteractionTimeCardAndTableSortInteraction', payload.data),
    [actions.setStudentInteractionTimeCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'studentInteractionTimeCardAndTableSortInteraction', payload.data),
    [actions.setAssignmentInteractionTimeCardAndTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'assignmentInteractionTimeCardAndTableSortInteraction', payload.data),
    [actions.setScoreDistributedCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'scoreDistributedCardAndTableSortInteraction', payload.data),
    [actions.setStudentAssignmentsDistributedScoreCardAndTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'studentAssignmentsDistributedScoreCardAndTableSortInteraction', payload.data),
    [actions.setAssignmentDistributedScoreCardAndTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'assignmentDistributedScoreCardAndTableSortInteraction', payload.data),
    [actions.setWeakestCategoriesCardAndChartInteraction]: (state, { payload }) => Immutable.set(state, 'weakestCategoriesCardAndChartInteraction', payload.data),
    [actions.setInstructorId]: (state, { payload }) => Immutable.set(state, 'instructorId', payload),
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setStudentAveragePerformanceType]: (state, { payload }) => Immutable.set(state, 'studentAveragePerformanceType', payload),
    [actions.setStudentAverageComparedIndexPercentage]: (state, { payload }) => Immutable.set(state, 'studentAverageComparedIndexPercentage', payload),
    [actions.setCourseId]: (state, { payload }) => Immutable.set(state, 'courseId', payload),
    [actions.setSectionId]: (state, { payload }) => Immutable.set(state, 'sectionId', payload),
    [actions.setIsCoursesLoading]: (state, { payload }) => Immutable.set(state, 'isCoursesLoading', payload),
    [actions.setStudentClassAveragePITMinutes]: (state, { payload }) => Immutable.set(state, 'studentClassAveragePITMinutes', payload),
    [actions.setAssignmentViewCJMMItemAndTabInteraction]: (state, { payload }) => Immutable.set(state, 'assignmentViewCJMMItemAndTabInteraction', payload.data),
    [actions.setCourses]: (state, { payload }) => Immutable.set(state, 'courses', payload),
    [actions.setClassConstructSummaryCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'classConstructSummaryCardAndTableSortInteraction', payload),
    [actions.setAssignmentId]: (state, { payload }) => Immutable.set(state, 'assignmentId', payload)
  },
  initialState
);

const selectors = {
  getInstructorId: createSelector(getSHState, state => state.instructorId),
  getPatientInteractionTimeCardAndTableSortInteraction: createSelector(getSHState, state => state.patientInteractionTimeCardAndTableSortInteraction),
  getStudentInteractionTimeCardAndTableSortInteraction: createSelector(getSHState, state => state.studentInteractionTimeCardAndTableSortInteraction),
  getAssignmentInteractionTimeCardAndTableSortInteraction: createSelector(getSHState, state => state.assignmentInteractionTimeCardAndTableSortInteraction),
  getScoreDistributedCardAndTableSortInteraction: createSelector(getSHState, state => state.scoreDistributedCardAndTableSortInteraction),
  getStudentAssignmentsDistributedScoreCardAndTableSortInteraction: createSelector(getSHState, state => state.studentAssignmentsDistributedScoreCardAndTableSortInteraction),
  getAssignmentDistributedScoreCardAndTableSortInteraction: createSelector(getSHState, state => state.assignmentDistributedScoreCardAndTableSortInteraction),
  getStudentAverageComparedIndexPercentage: createSelector(getSHState, state => state.studentAverageComparedIndexPercentage),
  getStudentAveragePerformanceType: createSelector(getSHState, state => state.studentAveragePerformanceType),
  getStudentPerformanceIndexPercentage: createSelector(getSHState, state => state.studentPerformanceIndexPercentage),
  getWeakestCategoriesCardAndChartInteraction: createSelector(getSHState, state => state.weakestCategoriesCardAndChartInteraction),
  getSectionId: createSelector(getSHState, state => state.sectionId),
  getCourseId: createSelector(getSHState, state => state.courseId),
  getIsCoursesLoading: createSelector(getSHState, state => state.isCoursesLoading),
  getStudentClassAveragePITMinutes: createSelector(getSHState, state => state.studentClassAveragePITMinutes),
  getAssignmentViewCJMMItemAndTabInteraction: createSelector(getSHState, state => state.assignmentViewCJMMItemAndTabInteraction),
  getCourses: createSelector(getSHState, state => state.courses),
  getClassConstructSummaryCardAndTableSortInteraction: createSelector(getSHState, state => state.classConstructSummaryCardAndTableSortInteraction),
  getAssignmentId: createSelector(getSHState, state => state.assignmentId)
};

export { actions as shActions, reducer as shReducer, selectors as shSelectors };
export { initialState as shInitialState };
