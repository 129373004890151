import { StudentEngagementRecord } from 'reports/cw/models';
import { ReactElement } from 'react';
import { Link, generatePath } from 'react-router-dom';
import ComparisonBarChart from 'components/common/comparison-bar-chart/ComparisonBarChart';
import ComparisonBarChartMobile from 'components/common/comparison-bar-chart/ComparisonBarChartMobile';
import { asTimestringFormated, displayNumberWithSign } from 'reports/cw/services/report.service';
import { VALUE_NA, VALUE_NA_TEXT } from 'reports/cw/constants/report.constant';
import { PerformancePath, ASSIGNMENT_GRADE_TYPES } from 'reports/cw/constants/cw.constant';
import { renderWeightedGradePoints } from 'reports/cw/helpers/report.helper';
import { FeatureFlag } from 'models';

export const renderName = (row: StudentEngagementRecord): ReactElement => {
  const path = generatePath(PerformancePath.studentEngagement.studentDetail, {
    studentId: row.studentId.toString()
  });
  return (
    <div className="c-cw-student-engagement-table__student-name-cell" title={row.studentName}>
      <Link to={path}>
        <span>{row.studentName}</span>
      </Link>
    </div>
  );
};

export const renderAvgTimePerQuestion = (row: StudentEngagementRecord): ReactElement => {
  const { avgTimePerQuestionSeconds } = row;
  if (avgTimePerQuestionSeconds === 0) {
    return <span>{VALUE_NA}</span>;
  }
  return <span>{asTimestringFormated(avgTimePerQuestionSeconds)}</span>;
};

export const renderComparedPerformance = (row: StudentEngagementRecord, maxPerformanceComparedToClassAvg: number): ReactElement => {
  const { avgScore } = row;
  const value = +(+avgScore.toFixed(2) - +maxPerformanceComparedToClassAvg.toFixed(2)).toFixed(2);
  const backgroundColorClassName = value > 0 ? 'u-els-background-color-extended-green-8' : 'u-els-background-color-warn';
  const maxValue = maxPerformanceComparedToClassAvg > 1 ? maxPerformanceComparedToClassAvg : 1;

  return (
    <ComparisonBarChart
      value={value}
      maxValue={maxValue}
      backgroundColorClassName={backgroundColorClassName}
      displayTextValue={displayNumberWithSign(value)}
      barChartThickness={1.5}
    />
  );
};

export const renderComparedPerformanceMobile = (row: StudentEngagementRecord, maxPerformanceComparedToClassAvg: number): ReactElement => {
  const { avgScore } = row;
  const value = +(avgScore - maxPerformanceComparedToClassAvg).toFixed(2);
  const maxValue = maxPerformanceComparedToClassAvg > 1 ? maxPerformanceComparedToClassAvg : 1;
  return <ComparisonBarChartMobile value={value} maxValue={maxValue} displayTextValue={displayNumberWithSign(value)} />;
};

export const renderPastDue = (row: StudentEngagementRecord): ReactElement => {
  const colorClassName = +row.pastDue > 0 ? 'u-els-color-warn' : 'u-els-color-n6';
  return <span className={colorClassName}>{row.pastDue}</span>;
};

export const renderStudentAvgScore = (row: StudentEngagementRecord): ReactElement => {
  if (!row.avgScore) {
    return <span>{VALUE_NA_TEXT}</span>;
  }
  return <span>{`${+(row.avgScore * 100).toFixed(0)}%`}</span>;
};

export const renderAvgTimePerQuestionComparedToClass = (row: StudentEngagementRecord): ReactElement => {
  const { avgTimePerQuestionComparedToClassAvgSeconds } = row;
  if (avgTimePerQuestionComparedToClassAvgSeconds === 0) {
    return <span>Same</span>;
  }

  const displayTime = asTimestringFormated(Math.abs(avgTimePerQuestionComparedToClassAvgSeconds));
  return <span>{avgTimePerQuestionComparedToClassAvgSeconds > 0 ? `+${displayTime}` : `-${displayTime}`}</span>;
};

export const renderStudentTotalGradePoints = (row: StudentEngagementRecord, featureFlags: FeatureFlag[]): ReactElement => {
  const { avgScore, totalGradePoints } = row;
  return (
    <span>
      {renderWeightedGradePoints({
        assignmentGradeType: ASSIGNMENT_GRADE_TYPES.SCORED.key,
        points: avgScore,
        totalPoints: totalGradePoints,
        featureFlags,
        courseId: '',
        withParenthesis: false
      })}
    </span>
  );
};
