import { configureHandler } from 'mocks/helpers';
import isbn9780323760409 from './_data/9780323760409';
import isbn9780323797337 from './_data/9780323797337';

const data = {
  '9780323760409-6952847': isbn9780323760409,
  '9780323797337-6952847': isbn9780323797337,
  '9780323797337-3047711': isbn9780323797337,
  '9780323760409-5167001': isbn9780323760409,
  '9780323797337-5846998': isbn9780323797337,
  '9780323760409-5846998': isbn9780323760409,
  '9780323760409-6037438': isbn9780323760409
};

export default configureHandler(async (req, res, ctx) => {
  const { isbnId, studentId } = req.params;
  const key = `${isbnId}-${studentId}`;

  const responseData = data[key];
  if (responseData) {
    return res(ctx.status(200), ctx.json(responseData));
  }

  return req.passthrough();
});
