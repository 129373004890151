import { chain, groupBy, map, mapValues, sumBy, unionBy, uniqBy, keyBy } from 'lodash';
import moment, { Moment } from 'moment';
import { ELSCommonUIConstants } from 'components/common';
import { PERFORMANCE_LEVEL_CONFIGS } from 'constants/performanceLevel.constant';
import {
  FILTER_OPTIONS_EXAM_DATE_RANGE,
  DEFAULT_PERFORMANCE_LEVEL_SCORE_RANGE,
  DISPLAY_CUSTOM_FILTER_DATE_FORMAT,
  DEFAULT_DATE_RANGE_UNIX,
  HESI_PRODUCT_EXAM_TYPE,
  DISPLAY_DATE_FORMAT_IN_STUDENT_DETAIL,
  HP_EXAMS_KEY,
  A2_EXAMS_KEY
} from 'reports/hesi-ng/constants';
import { getStudentUserFullName } from 'helpers/app.helper';
import { DynamicScoreScaleProps } from 'models';
import { NGNItemDTO } from 'reports/had/models';
import {
  NGNItemHesiNGDto,
  TestTakerData,
  ExamGroupInfo,
  ExamData,
  RemapStudentPerformance,
  StudentDropdownOptions,
  HesiNGStudentSummaryResult,
  OrderItemSummaryTestTakerData
} from 'reports/hesi-ng/models';

export const getDateRangeFromFilterOption = (option: string) => {
  const { lastMonth, lastSixMonths, lastThreeMonths, lastYear } = FILTER_OPTIONS_EXAM_DATE_RANGE;
  let rangeFrom: Moment;
  let rangeTo: Moment;
  const currentDay = moment();
  switch (option) {
    case lastMonth:
      rangeFrom = moment().subtract(1, 'M');
      rangeTo = currentDay;
      break;
    case lastThreeMonths:
      rangeFrom = moment().subtract(3, 'M');
      rangeTo = currentDay;
      break;
    case lastSixMonths:
      rangeFrom = moment().subtract(6, 'M');
      rangeTo = currentDay;
      break;
    case lastYear:
      rangeFrom = moment().subtract(12, 'M');
      rangeTo = currentDay;
      break;
    default:
      return DEFAULT_DATE_RANGE_UNIX;
  }
  return { fromDateUnix: rangeFrom.valueOf(), toDateUnix: rangeTo.valueOf() }; // unix in milliseconds
};

const getNGNItemByScoreType = (ngnItems: NGNItemHesiNGDto[], isNewScore: boolean) => {
  if (isNewScore) {
    return (ngnItems || []).filter(({ isPilot }) => !isPilot);
  }
  return (ngnItems || []).filter(({ isPilot }) => isPilot);
};

export const getPercentCorrect = (ngnItems: NGNItemHesiNGDto[], isNewScore?: boolean) => {
  const filteredNGNItems = getNGNItemByScoreType(ngnItems, isNewScore);
  const uniqueNGNItems = unionBy(filteredNGNItems, ({ questionId, examId, examGroupId }) => `${questionId}-${examId}-${examGroupId}`);
  const score = sumBy(uniqueNGNItems, 'awardedTotal');
  const maxScore = sumBy(uniqueNGNItems, 'maxPossibleScore');
  return Math.round((score / maxScore) * 100);
};

export const getFilteredNGNItem = (ngnItems: NGNItemHesiNGDto[], examGroupIdsNewScore: number[], isNewScore?: boolean) => {
  if (examGroupIdsNewScore && examGroupIdsNewScore.length > 0) {
    return (ngnItems || []).filter(({ examGroupId, isPilot }) => examGroupIdsNewScore.includes(examGroupId) && isPilot === !isNewScore);
  }
  return (ngnItems || []).filter(({ isPilot }) => isPilot === !isNewScore);
};

export const buildNGNCardSummaryExamData = (ngnItems: NGNItemHesiNGDto[], examGroupIdsNewScore: number[]) => {
  const isNewScore = examGroupIdsNewScore && examGroupIdsNewScore.length > 0;
  const filteredNGNItems = getFilteredNGNItem(ngnItems, examGroupIdsNewScore, isNewScore);
  const distinctNGNItems = uniqBy<NGNItemHesiNGDto>(filteredNGNItems, 'questionId');
  const percentCorrect = getPercentCorrect(ngnItems, isNewScore);
  const numOfNGNStandalones = distinctNGNItems.filter(({ displayType }) => displayType !== 'Case').length;
  const numOfNGNUnfoldingCases = uniqBy(
    distinctNGNItems.filter(({ displayType }) => displayType === 'Case'),
    'caseId'
  ).length;

  return {
    numOfNGNPilotItems: distinctNGNItems.length,
    numOfNGNStandalones,
    numOfNGNUnfoldingCases,
    ngnPercentCorrect: percentCorrect,
    newScoreEnable: examGroupIdsNewScore && examGroupIdsNewScore.length
  };
};

export const getExamIdsByExamGroupIds = (examGroupIds: string[], testTakerData: TestTakerData[]) => {
  const examGroupNumberIds = (examGroupIds || []).map(Number);
  const examGroupsIdTestTakerMap = chain(testTakerData)
    .groupBy('examGroupId')
    .mapValues(items => map(items, 'examId'))
    .value();
  return examGroupNumberIds.map(id => examGroupsIdTestTakerMap[id]).flat();
};

export const isNewScoreByExamGroupData = (examGroupData: ExamGroupInfo[]) => {
  return (examGroupData || []).some(({ allowPartialCreditFlag }) => allowPartialCreditFlag);
};
export const getExamGroupIdsNewScore = (selectedExamGroups: string[], { examGroupInfo }: ExamData) => {
  const examGroupIds = (selectedExamGroups || []).map(Number);
  const examGroupIdMap = mapValues(keyBy(examGroupInfo, 'examGroupId'), v => v.allowPartialCreditFlag);
  return examGroupIds.map(id => (examGroupIdMap[id] ? id : null)).filter(Boolean);
};

export const getStudentNGNScoreMap = (isNewScoreAvailable: boolean, isNewGroup: boolean, ngnItems: NGNItemHesiNGDto[]) => {
  return Object.entries(groupBy(ngnItems, 'examId')).map(([key, value]) => {
    if (isNewScoreAvailable) {
      if (!isNewGroup) {
        return {
          percentCorrect: -1,
          examId: key
        };
      }
      return {
        percentCorrect: getPercentCorrect(value, isNewGroup),
        examId: key
      };
    }
    return {
      percentCorrect: getPercentCorrect(value),
      examId: key
    };
  });
};

export const getPerformanceLevelScoreRange = (acceptableScore = 0, recommendedScore = 0) => {
  if (acceptableScore && recommendedScore) {
    const scoreDistance = recommendedScore - acceptableScore;
    return {
      atRisk: {
        max: acceptableScore - 2 * scoreDistance - 1
      },
      belowAcceptable: {
        min: acceptableScore - 2 * scoreDistance,
        max: acceptableScore - 1
      },
      acceptable: {
        min: acceptableScore,
        max: recommendedScore - 1
      },
      recommended: {
        min: recommendedScore
      }
    };
  }
  return DEFAULT_PERFORMANCE_LEVEL_SCORE_RANGE;
};

export const calculatePerformanceLevel = (studentScore: number, performanceScoreRange: Record<string, { min?: number; max?: number }>): string | null => {
  const foundLevel = Object.keys(performanceScoreRange).find(level => {
    const { min = 0, max = 0 } = performanceScoreRange[level];
    return (min === 0 || studentScore >= min) && (max === 0 || studentScore <= max);
  });
  return foundLevel || null;
};

export const getStudentPerformance = ({
  examData,
  selectedExamGroups,
  ngnItems,
  acceptableScore,
  recommendedScore
}: {
  examData: ExamData;
  selectedExamGroups: string[];
  ngnItems: NGNItemHesiNGDto[];
  acceptableScore: number;
  recommendedScore: number;
}): RemapStudentPerformance[] => {
  const { testTakerData = [], accountInfo = [], examGroupInfo = [], examInfo = [] } = examData;
  const isNewScoreAvailable = getExamGroupIdsNewScore(selectedExamGroups, examData).length > 0;
  const filteredNgnItems = isNewScoreAvailable ? ngnItems.filter(i => !i.isPilot) : ngnItems.filter(i => i.isPilot);
  const performanceScoreRange = getPerformanceLevelScoreRange(acceptableScore, recommendedScore);
  return testTakerData.reduce((studentList: RemapStudentPerformance[], stu: TestTakerData) => {
    const examGroupId = stu.examGroupId.toString();
    if (!selectedExamGroups.includes(examGroupId)) {
      return studentList;
    }

    const { hesiScore = 0, conversionScore = 0, examEndDate = null, eolsId, examId } = stu;
    const { firstName, lastName } = accountInfo.find(ai => ai.eolsId === eolsId) || { firstName: '', lastName: '' };
    const selectedExamGroup = examGroupInfo.find(egi => egi.examGroupId === stu.examGroupId);
    const { nationalHesiAverage, examName } = examInfo.find(ei => ei.editionId === selectedExamGroup?.examEditionId) || {
      nationalHesiAverage: 0,
      examName: ''
    };
    const mapExamIdWithNGNScore = getStudentNGNScoreMap(isNewScoreAvailable, selectedExamGroup.allowPartialCreditFlag, filteredNgnItems);
    const { percentCorrect } = mapExamIdWithNGNScore.find(i => stu.examId === Number(i.examId)) || { percentCorrect: -1 };

    studentList.push({
      examId,
      eolsId,
      examName,
      fullName: getStudentUserFullName({ firstName, lastName }),
      meanHesiScore: hesiScore,
      conversionScore,
      nationalHesiAverage,
      ngnPercentCorrect: percentCorrect,
      dateTaken: examEndDate ? moment(examEndDate).format(DISPLAY_CUSTOM_FILTER_DATE_FORMAT) : '',
      dateTakenUnix: examEndDate,
      performanceLevel: calculatePerformanceLevel(hesiScore, performanceScoreRange),
      guid: stu?.scoringSystemId
    });

    return studentList;
  }, []);
};

const defaultScoreRange = { min: 0, max: 0 };

export const getPerformanceLevelConfig = (acceptableScore = 0, recommendedScore = 0) => {
  const { atRisk = defaultScoreRange, belowAcceptable = defaultScoreRange, acceptable = defaultScoreRange, recommended = defaultScoreRange } = getPerformanceLevelScoreRange(
    acceptableScore,
    recommendedScore
  );
  return {
    atRisk: {
      ...PERFORMANCE_LEVEL_CONFIGS.atRisk,
      ...atRisk,
      range: `<${atRisk.max + 1}`
    },
    belowAcceptable: {
      ...PERFORMANCE_LEVEL_CONFIGS.belowAcceptable,
      ...belowAcceptable,
      range: `${belowAcceptable.min} - ${belowAcceptable.max}`
    },
    acceptable: {
      ...PERFORMANCE_LEVEL_CONFIGS.acceptable,
      ...acceptable,
      range: `${acceptable.min} - ${acceptable.max}`
    },
    recommended: {
      ...PERFORMANCE_LEVEL_CONFIGS.recommended,
      ...recommended,
      range: `≥${recommended.min}`
    }
  };
};

const defaultPerformanceLevelsCount = {
  atRisk: 0,
  belowAcceptable: 0,
  acceptable: 0,
  recommended: 0
};

export const buildPerformanceLevelsProps = ({
  acceptableScore,
  recommendedScore,
  studentList = [],
  highestScore,
  lowestScore
}: {
  acceptableScore: number;
  recommendedScore: number;
  studentList: RemapStudentPerformance[];
  highestScore: number;
  lowestScore: number;
}) => {
  const performanceLevelsCount = studentList.reduce((result, { performanceLevel }) => {
    const newResult = { ...result };
    newResult[performanceLevel] = (result[performanceLevel] || 0) + 1;
    return newResult;
  }, defaultPerformanceLevelsCount);

  const rangeLevelBarChartData = Object.values(getPerformanceLevelConfig(acceptableScore, recommendedScore)).map(level => {
    return {
      name: level.range,
      subLabel: level.label,
      value: performanceLevelsCount[level.value],
      fillColorClassName: level.fillColorClassName,
      stripeStrokeColorClassName: level.stripeStrokeColorClassName
    };
  });

  const rangeLevelBarChartConfig = {
    barSize: 65,
    xAxisKey: 'name',
    yAxisKey: 'value',
    isStripeColorUsed: false,
    margin: { top: 30, right: 0, bottom: 5, left: 0 }
  };

  return {
    data: rangeLevelBarChartData,
    config: rangeLevelBarChartConfig,
    scoreRange: {
      highestScore,
      lowestScore
    }
  };
};

export const getPerformanceValueFromLabel = (label: string) => (Object.values(PERFORMANCE_LEVEL_CONFIGS).find(config => config.label === label) || { value: '' }).value;

export const getExamGroupIdByAllowPartialCreditFlag = (examGroupInfo: ExamGroupInfo[]) => {
  const examGroupIdMap = groupBy(examGroupInfo, 'examGroupId');
  return mapValues(examGroupIdMap, isNewScoreByExamGroupData);
};

interface ExamMap {
  [examId: number]: string;
}

interface ExamGroupIdAllowPartialMap {
  [examGroupId: string]: boolean;
}

export const mapNGNItemHesiNGToNGNItem = (ngnHesiNGItem: NGNItemHesiNGDto, examMap: ExamMap, examGroupIdMap: ExamGroupIdAllowPartialMap): NGNItemDTO => {
  const { examId, isPilot, itemType, displayType, awardedTotal, maxPossibleScore, questionId, caseId, caseTitle, timeUsedInSeconds, categoryName, examGroupId } = ngnHesiNGItem;
  return {
    examId,
    examName: examMap[examId],
    itemType,
    itemDisplayType: displayType,
    score: awardedTotal,
    maxScore: maxPossibleScore,
    questionId,
    caseId,
    caseTitle,
    timeSpent: timeUsedInSeconds,
    skill: categoryName,
    itemTypeCode: itemType,
    isPilot,
    allowPartialCreditFlag: examGroupIdMap[examGroupId]
  };
};

export const getStudentExamIdsByTestTaker = (testTakerData: TestTakerData[], studentId: string | number, selectedExamGroups: string[]) => {
  const selectedExamGroupIds = (selectedExamGroups || []).map(Number);
  const currentStudentData = (testTakerData || []).filter(({ eolsId, examGroupId }) => eolsId === Number(studentId) && selectedExamGroupIds.includes(examGroupId));
  if (currentStudentData && currentStudentData.length) {
    return map(currentStudentData, 'examId');
  }
  return [];
};

export const getStudentListDropdownOptions = (studentList: RemapStudentPerformance[] = []): StudentDropdownOptions[] => {
  return studentList.map(student => {
    const { fullName, examId } = student;
    return {
      name: fullName,
      key: examId.toString(),
      ...student
    };
  });
};

export const checkIfExamTypeCAT = (examGroupAssessmentType: string) => examGroupAssessmentType === HESI_PRODUCT_EXAM_TYPE.cat;

export const getProductName = (versionId: number, examType = '') => {
  const versionStr = versionId ? `v${versionId}` : '';
  const examTypeStr = examType ? `${examType} ` : '';
  return `${examTypeStr}${versionStr}`.trim();
};

export const getPerformanceLevelRelatedData = (acceptableScore: number, recommendedScore: number) => {
  const performanceLevel = getPerformanceLevelConfig(acceptableScore, recommendedScore);

  const performanceScoreRange = getPerformanceLevelScoreRange(acceptableScore, recommendedScore);

  const scoreScale: DynamicScoreScaleProps = {
    atRisk: {
      max: performanceLevel.atRisk.max,
      label: performanceLevel.atRisk.range
    },
    belowAcceptable: {
      max: performanceLevel.belowAcceptable.max,
      label: performanceLevel.belowAcceptable.range
    },
    acceptable: {
      max: performanceLevel.acceptable.max,
      label: `${acceptableScore}+`
    }
  };

  return { scoreScale, performanceLevel, performanceScoreRange };
};

export const getStudentExamResultDetail = ({
  score,
  nationalHesiAverage,
  classAverage,
  isExamTypeCat,
  examName,
  scoreScale,
  performanceLevel,
  productName,
  examDateTaken
}: {
  score: number;
  nationalHesiAverage: number;
  classAverage: number;
  scoreScale: DynamicScoreScaleProps;
  performanceLevel: string;
  isExamTypeCat?: boolean;
  productName?: string;
  examDateTaken?: number;
  examName?: string;
}) => {
  const examInfo = [];

  if (productName) {
    examInfo.push({
      label: 'Product Name',
      value: productName
    });
  }

  if (examDateTaken) {
    examInfo.push({
      label: 'Date Taken',
      value: moment(examDateTaken).format(DISPLAY_DATE_FORMAT_IN_STUDENT_DETAIL)
    });
  }

  const scoreCardProps = {
    score,
    showCompareToNational: nationalHesiAverage > 0,
    comparedToNational: Math.round(score - nationalHesiAverage),
    comparedToClassAvg: Math.round(score - classAverage),
    nationalScore: nationalHesiAverage,
    scoreScale,
    isExamTypeCat
  };

  const performanceLevelProps = {
    title: `Performance Level on ${examName}`,
    performanceLevel
  };

  return {
    examInfo,
    scoreCardProps,
    performanceLevelProps,
    isExamTypeCat
  };
};

export const buildExamGroupInfo = ({ students }: HesiNGStudentSummaryResult) => {
  return (students || []).map(({ examGroupId, allowPartialCreditFlag }) => ({
    examGroupId,
    allowPartialCreditFlag
  }));
};

export const formatHesiScore = (score: number, isExamTypeCat: boolean) => {
  let displayScore = Math.round(score)?.toFixed(0);
  if (isExamTypeCat) {
    displayScore = !score ? 'No viable score is available' : score.toFixed(2);
  }
  return {
    meanScore: !score ? 0 : Number(displayScore),
    displayScore
  };
};

export const getClassAvgScore = ({ studentTestTaker, testTakerData }: { studentTestTaker: OrderItemSummaryTestTakerData[]; testTakerData: TestTakerData[] }) => {
  const guidList = studentTestTaker.map(ot => ot.guid);
  const currentTestTaker = testTakerData?.filter(({ scoringSystemId }) => guidList.includes(scoringSystemId)) || [];
  return Math.round(sumBy(currentTestTaker, 'hesiScore') / currentTestTaker.length) || 0;
};

export const getStudentListDateTaken = (studentList: RemapStudentPerformance[]): string => {
  const sortedStudentList = ([...studentList] || []).sort((a, b) => {
    return a?.dateTakenUnix - b?.dateTakenUnix;
  });

  let firstStudent = null;
  let lastStudent = null;

  if (sortedStudentList.length > 0) {
    [firstStudent, lastStudent] =
      sortedStudentList.length === 1 ? [sortedStudentList[0], sortedStudentList[0]] : [sortedStudentList[0], sortedStudentList[sortedStudentList.length - 1]];
  }

  return `
  ${moment(firstStudent?.dateTakenUnix).format(ELSCommonUIConstants.momentDateFormat.datePrimary)} - ${moment(lastStudent?.dateTakenUnix).format(
    ELSCommonUIConstants.momentDateFormat.datePrimary
  )}`;
};

export const checkHPExams = (productName: string) => productName.startsWith(`${HP_EXAMS_KEY} `);

export const checkA2Exams = (productName: string) => productName && productName.startsWith(`${A2_EXAMS_KEY} `);
