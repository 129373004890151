export default {
  courseSectionId: 516809,
  completedAssignments: 9,
  pastDueAssignments: 0,
  inProgressAssignments: 0,
  notStartedAssignments: 6,
  totalAssignments: 15,
  completedSelfStudyQuestionsEAQ: 71.0,
  typeCardsList: [
    {
      assignmentType: 'OSMOSIS_VIDEO',
      assignmentCompletionRate: 0.0,
      totalStudentsPastDue: 0,
      totalStudents: 19,
      totalAssignmentsByType: 6,
      avgTimeSpentPerQuestion: 'PT0S',
      avgTimeSpent: 'PT0S',
      avgScore: 0.0,
      percentAvgScore: 0.0,
      totalSelfStudyQuestionsAttempted: 0,
      avgSelfStudyQuestionsAttempted: 0.0,
      totalCompletedAssignment: 0,
      totalAssignmentsPastDue: 0,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    },
    {
      assignmentType: 'MASTERY',
      assignmentCompletionRate: 0.8771929824561402,
      totalStudentsPastDue: 7,
      totalStudents: 19,
      totalAssignmentsByType: 9,
      avgTimeSpentPerQuestion: 'PT40S',
      avgTimeSpent: 'PT1H35M53S',
      avgScore: 1.0,
      percentAvgScore: 100.0,
      totalSelfStudyQuestionsAttempted: 71,
      avgSelfStudyQuestionsAttempted: 3.736842105263158,
      totalCompletedAssignment: 9,
      totalAssignmentsPastDue: 9,
      totalInProgressAssignment: null,
      totalStudentBelowClassAvg: null
    }
  ]
};
