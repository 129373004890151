import React from 'react';
import { ELSIcon } from '../index';

const Breadcrumb = props => {
  const separator = <ELSIcon name="chevron-right" size="1x" customClass="u-els-color-n4" align="middle" />;
  const children = React.Children.toArray(props.children);
  const lastIdx = children.length - 1;
  return (
    <div className="c-breadcrumb u-els-display-flex o-els-flex-layout--wrap">
      {children.map((child, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`breadcrumb_item_${idx}`}>
          <span className="c-breadcrumb-item">{child}</span>
          {idx !== lastIdx && <span className="u-els-margin-1o2 c-breadcrumb-separator">{separator}</span>}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
