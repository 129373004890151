import { ELSCommonConfig, ELSCommonUIConstants } from '@els/els-ui-common-react';
import { ELSFormFieldConfig } from '@els/els-component-form-field-react';
import { RoutePath, DATE_FORMAT } from 'constants/app.constant';
import { ServerConstants } from './server.constant';

export const setDefaultAppConfig = () => {
  ELSCommonConfig.setBuildUrl(ServerConstants[ServerConstants.DataSource].gatewayBaseURL);
  ELSCommonConfig.setLoginPath(RoutePath.loginPath);
  ELSCommonConfig.setUserServicePath(RoutePath.userServicePath);
  ELSCommonConfig.setTokenServicePath(RoutePath.tokenServicePath);
  ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.SecondaryDriver);
  ELSCommonConfig.setAppId(ELSCommonUIConstants.appIds.PERFORMANCE_REPORT);
  ELSCommonConfig.setUseAppParams(true);
  ELSFormFieldConfig.setI18nConfig({
    dateFormat: {
      primary: DATE_FORMAT
    }
  });
};

export const analyticBaseConfig = {
  edApp: 'els-performance-reports-app',
  baseUrl: ServerConstants[ServerConstants.DataSource].gatewayBaseURL
};
