import { CW_ENGAGEMENT_CARD_ID, CW_INSTRUCTOR_HOME_CARD_ID, SORT_DIRECTION } from 'constants/app.constant';
import { DependentOption, DropdownOption, MenuOption } from 'models';
import { AssignmentType } from '../models';

export const sortUpIcon = 'arrow-up';
export const sortDownIcon = 'arrow-down';
export const WARNING_COLOR = 'u-els-color-warn';
export const EMPOWER_ICON = 'empower';
export const PLAY_VIDEO = 'play-video';
export const INSERT_QUESTION = 'insert-question';

export const ASSIGNMENT_GRADES_TYPES = {
  GRADED: 'PASS_FAIL',
  GRADED_SCORED: 'SCORED'
};

export const ASSIGNMENT_TYPE_GROUPS = {
  EAQ: 'EAQ',
  NON_EAQ: 'NON_EAQ',
  AUTHESS: 'AUTHESS'
};

export const ASSIGNMENT_TYPES = {
  MASTERY: {
    id: 'MASTERY',
    name: 'EAQ Mastery',
    group: ASSIGNMENT_TYPE_GROUPS.EAQ,
    icon: 'eaq',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  STANDARD: {
    id: 'STANDARD',
    name: 'EAQ Custom Quiz',
    group: ASSIGNMENT_TYPE_GROUPS.EAQ,
    icon: 'eaq',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  QUIZ_BY_QUESTION: {
    id: 'QUIZ_BY_QUESTION',
    name: 'EAQ Custom Quiz by Question',
    group: ASSIGNMENT_TYPE_GROUPS.EAQ,
    icon: 'eaq',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  AUTHESS: {
    id: 'AUTHESS',
    name: 'EAQ NGN',
    group: ASSIGNMENT_TYPE_GROUPS.EAQ,
    icon: EMPOWER_ICON,
    isLinkedToOutsideApp: true
  } as AssignmentType,
  CASE_STUDY: {
    id: 'CASE_STUDY',
    name: 'SimChart Case Study',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'case-study',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  LESSONS: {
    id: 'LESSONS',
    name: 'Lesson',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'lesson',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  ADAPTIVE_LESSON: {
    id: 'ADAPTIVE_LESSON',
    name: 'Lesson',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'lesson',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  CHART: {
    id: 'CHART',
    name: 'SimChart Empty EHR',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'simulation-nursing',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  SIMULATION: {
    id: 'SIMULATION',
    name: 'Simulation',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'simulation-nursing'
  } as AssignmentType,
  SKILL: {
    id: 'SKILL',
    name: 'Skill',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'hand'
  } as AssignmentType,
  SLS: {
    id: 'SLS',
    name: 'SLS(VR)',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'play-hollow'
  } as AssignmentType,
  PERSONAL_ASSESSMENT_BUILDER: {
    id: 'PERSONAL_ASSESSMENT_BUILDER',
    name: 'Assessment Builder Assignment',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: EMPOWER_ICON
  } as AssignmentType,
  SHADOW_HEALTH: {
    id: 'SHADOW_HEALTH',
    name: 'ShadowHealth',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: 'lesson',
    isLinkedToOutsideApp: true
  } as AssignmentType,
  OSMOSIS_VIDEO: {
    id: 'OSMOSIS_VIDEO',
    name: 'Osmosis',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: PLAY_VIDEO
  } as AssignmentType,
  INTERACTIVE_REVIEW: {
    id: 'INTERACTIVE_REVIEW',
    name: 'Interactive Review Questions',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: INSERT_QUESTION
  } as AssignmentType,
  CUSTOM_LINK: {
    id: 'CUSTOM_LINK',
    name: 'Skill',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: INSERT_QUESTION
  } as AssignmentType,
  EBOOK_READING: {
    id: 'EBOOK_READING',
    name: 'Skill',
    group: ASSIGNMENT_TYPE_GROUPS.NON_EAQ,
    icon: INSERT_QUESTION
  } as AssignmentType
};

export const DATE_FORMATS = {
  DEFAULT: 'M/D/YYYY',
  LOCALE: 'll',
  TIMESTAMP: 'M/D/YYYY h:mm a',
  CUSTOM: 'YYYY/MM/DD'
};

export const eaqAssignmentConstants = {
  labels: {
    assignmentGrades: {
      notGraded: 'NOT_GRADED',
      graded: 'PASS_FAIL',
      gradedScored: 'SCORED'
    }
  }
};

export const eaqConstants = {
  assignmentType: {
    mastery: 'MASTERY',
    standard: 'STANDARD',
    quizByQuestion: 'QUIZ_BY_QUESTION'
  }
};

export const NAClassname = 'u-els-font-family-italic u-els-color-n7';

export const VALUE_NA = '-';
export const VALUE_NA_TEXT = 'N/A';

export const GRADEBOOK_PASS_FAIL = {
  pass: '1',
  fail: '0'
};

export const NO_DATA_FOUND = 'No Data Found';
export const NO_DUE_DATE = 'No Due Date';

export const CW_STUDENT_HOME_CARD_ID = {
  assignmentsAboveAvg: 'CW_ASSIGNMENTS_ABOVE_AVG',
  assignmentCompletedStatus: 'CW_ASSIGNMENT_COMPLETED_STATUS',
  topicInNoviceOrBellow: 'CW_TOPIC_IN_NOVICE_OR_BELLOW',
  eaqTopicReport: 'CW_EAQ_TOPIC_REPORT',
  createEAQQuiz: 'CW_EAQ_QUIZ_QUIZ'
};

export const CW_STUDENT_DETAIL_CARD_ID = {
  assignmentsAboveAvg: 'CW_STUDENT_DETAIL_ASSIGNMENTS_ABOVE_AVG',
  assignmentCompletedStatus: 'CW_STUDENT_DETAIL_ASSIGNMENT_COMPLETED_STATUS',
  topicInNoviceOrBellow: 'CW_STUDENT_DETAIL_TOPIC_IN_NOVICE_OR_BELLOW'
};

export const REPORT_TABLE_ID = {
  assignmentEngagement: 'ASSIGNMENT_ENGAGEMENT_TABLE',
  studentEngagement: 'STUDENT_ENGAGEMENT_TABLE'
};

export const ASSIGNMENT_SUMMARY_CARD_ID = {
  assignmentsCompleted: 'ASSIGNMENTS_COMPLETED',
  assignmentsWithPastDue: 'ASSIGNMENTS_WITH_PAST_DUE',
  assignmentsWithStudentsBelowClassAvg: 'ASSIGNMENTS_WITH_STUDENT_BELOW_CLASS_AVG'
};

export const ENGAGEMENT_SUMMARY_CARD_ID = {
  completedAssignment: 'COMPLETED_ASSIGNMENTS'
};

export const STUDENT_HOME_ASSIGNMENT_SUMMARY_TABLE_SELECTORS = {
  scoreColumn: 'c-cw-student-home-assignment-summary-table__score-header-column',
  statusColumn: 'c-cw-student-home-assignment-summary-table__status-header-column'
};

export const STUDENT_HOME_ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS = {
  scoreColumn: 'c-cw-student-home-assignment-engagement-table__score-header-column',
  performanceComparedToClassAvg: 'c-cw-student-home-assignment-engagement-table__performance-compare-header-column'
};

export const STUDENT_DETAIL_ASSIGNMENT_SUMMARY_TABLE_SELECTORS = {
  scoreColumn: 'c-cw-student-detail-assignment-summary-table__score-header-column'
};

export const STUDENT_ENGAGEMENT_TABLE_SELECTOR = {
  studentAverageScoreColumn: 'c-cw-student-engagement-table__student-average-score-header-column',
  numberOfStudentsWithPastDueColumn: 'c-cw-student-engagement-table__number-of-student-with-past-due-header-column',
  timeSpentComparedToClassAvgColumn: 'c-cw-student-engagement-table__time-spent-compared-to-class-avg-header-column',
  selfStudyQuestionsAttemptedColumn: 'c-cw-student-engagement-table__self-study-questions-attempted-header-column'
};

export const ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS = {
  completionColumn: 'c-cw-assignment-engagement-table__completion-header-column'
};

export const ASSIGNMENT_SUMMARY_TABLE_SELECTOR = {
  percentCompletedColumn: 'c-assignment-summary-table__percent-competed-header-column',
  numberOfPassDueColumn: 'c-assignment-summary-table__pass-due-header-column',
  classAvgScoreColumn: 'c-assignment-summary-table__class-avg-score-header-column'
};

export const INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [ASSIGNMENT_SUMMARY_CARD_ID.assignmentsCompleted]: {
    tableColumn: {
      headerSelector: ASSIGNMENT_SUMMARY_TABLE_SELECTOR.percentCompletedColumn,
      field: 'percentComplete'
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [ASSIGNMENT_SUMMARY_CARD_ID.assignmentsWithPastDue]: {
    tableColumn: {
      headerSelector: ASSIGNMENT_SUMMARY_TABLE_SELECTOR.numberOfPassDueColumn,
      field: 'totalStudentsPastDue'
    },
    sortDirection: SORT_DIRECTION.DESC
  },
  [ASSIGNMENT_SUMMARY_CARD_ID.assignmentsWithStudentsBelowClassAvg]: {
    tableColumn: {
      headerSelector: ASSIGNMENT_SUMMARY_TABLE_SELECTOR.classAvgScoreColumn,
      field: 'avgScore'
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CW_ENGAGEMENT_CARD_ID.classAverageScore]: {
    tableColumn: {
      table: REPORT_TABLE_ID.studentEngagement,
      headerSelector: STUDENT_ENGAGEMENT_TABLE_SELECTOR.studentAverageScoreColumn,
      field: 'percentAvgScore'
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CW_ENGAGEMENT_CARD_ID.assignmentCompleted]: {
    tableColumn: {
      table: REPORT_TABLE_ID.assignmentEngagement,
      headerSelector: ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS.completionColumn,
      field: 'percentCompletion'
    },
    sortDirection: SORT_DIRECTION.DESC
  },
  [CW_ENGAGEMENT_CARD_ID.numberOfStudentsWithPastDue]: {
    tableColumn: {
      table: REPORT_TABLE_ID.studentEngagement,
      headerSelector: STUDENT_ENGAGEMENT_TABLE_SELECTOR.numberOfStudentsWithPastDueColumn,
      field: 'pastDue'
    },
    sortDirection: SORT_DIRECTION.DESC
  },
  [CW_INSTRUCTOR_HOME_CARD_ID.assignmentCompleted]: {
    tableColumn: {
      headerSelector: ASSIGNMENT_SUMMARY_TABLE_SELECTOR.percentCompletedColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CW_INSTRUCTOR_HOME_CARD_ID.aboveClassAvg]: {
    tableColumn: {
      table: REPORT_TABLE_ID.studentEngagement,
      headerSelector: STUDENT_ENGAGEMENT_TABLE_SELECTOR.studentAverageScoreColumn
    },
    sortDirection: SORT_DIRECTION.DESC
  },
  [CW_STUDENT_DETAIL_CARD_ID.assignmentsAboveAvg]: {
    tableColumn: {
      headerSelector: STUDENT_DETAIL_ASSIGNMENT_SUMMARY_TABLE_SELECTORS.scoreColumn,
      field: 'score'
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CW_STUDENT_HOME_CARD_ID.assignmentsAboveAvg]: {
    tableColumn: {
      headerSelector: STUDENT_HOME_ASSIGNMENT_ENGAGEMENT_TABLE_SELECTORS.performanceComparedToClassAvg
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [ENGAGEMENT_SUMMARY_CARD_ID.completedAssignment]: {
    tableColumn: {
      field: 'assignmentCompletionRate'
    },
    sortDirection: SORT_DIRECTION.DESC
  }
};

export const sortLabelByType = {
  time: {
    asc: 'Most recent first',
    desc: 'Least recent first'
  },
  number: {
    asc: 'Low to high',
    desc: 'High to low'
  }
};

const iconPrefix = 'gizmo';
export const NEW_CW_DASHBOARD_ASSIGNMENT_SORT_OPTIONS: Array<DropdownOption> = [
  {
    name: 'Due Date',
    value: 'dueDate',
    dependedOptions: [
      { name: sortLabelByType.time.asc, value: 'asc', icon: sortUpIcon, iconPrefix },
      { name: sortLabelByType.time.desc, value: 'desc', icon: sortDownIcon, iconPrefix }
    ]
  },
  {
    name: 'Completion Rate',
    value: 'percentComplete',
    dependedOptions: [
      { name: sortLabelByType.number.asc, value: 'asc', icon: sortUpIcon, iconPrefix },
      { name: sortLabelByType.number.desc, value: 'desc', icon: sortDownIcon, iconPrefix }
    ]
  },
  {
    name: 'Class Average Score',
    value: 'avgScore',
    dependedOptions: [
      { name: sortLabelByType.number.asc, value: 'asc', icon: sortUpIcon, iconPrefix },
      { name: sortLabelByType.number.desc, value: 'desc', icon: sortDownIcon, iconPrefix }
    ]
  },
  {
    name: 'Class Average Time Spent',
    value: 'avgTimeSpent',
    dependedOptions: [
      { name: sortLabelByType.number.asc, value: 'asc', icon: sortUpIcon, iconPrefix },
      { name: sortLabelByType.number.desc, value: 'desc', icon: sortDownIcon, iconPrefix }
    ]
  }
];

export const REPORT_DEPENDENT_OPTIONS: Array<DependentOption> = [{ parentOption: 'LESSONS', dependentOptionlist: ['ADAPTIVE_LESSON'] }];

export const PERCENT_ASSIGNMENTS_BELOW_CLASS_AVG_RED_CONDITION = 15;
export const PERCENT_ASSIGNMENTS_PASSED_DUE_RED_CONDITION = 50;
export const GRADEBOOK_ATTEMPT_OPTIONS: Array<MenuOption> = [
  {
    key: 'FIRST_ATTEMPT',
    name: 'First Attempt'
  },
  {
    key: 'FIRST_ATTEMPT_BEFORE_DUE_DATE',
    name: 'First Attempt (before due date)'
  },
  {
    key: 'LAST_ATTEMPT',
    name: 'Last Attempt'
  },
  {
    key: 'LAST_ATTEMPT_BEFORE_DUE_DATE',
    name: 'Last Attempt (before due date)'
  },
  {
    key: 'BEST_ATTEMPT',
    name: 'Best Attempt'
  },
  {
    key: 'BEST_ATTEMPT_BEFORE_DUE_DATE',
    name: 'Best Attempt (before due date)'
  },
  {
    key: 'AVERAGE_SCORE',
    name: 'Average Score'
  },
  {
    key: 'AVERAGE_SCORE_BEFORE_DUE_DATE',
    name: 'Average Score (before due date)'
  }
];

export const STUDENT_ROLE_SYNONYMS_ARRAY = ['Student', 'Stu'];

export const ONE_DECIMAL_PLACE = 1;

export const STUDENT_TABLE_INFO_CONTEXT_TEXT = 'The submission of this assignment after the due date resulted in a failing grade.';
