export default [
  {
    examType: 'Specialty',
    exams: [
      {
        programId: 203294,
        examId: 14105,
        examName: 'Demo NG Demo 1',
        dateTaken: '2022-05-18',
        cohortId: 12633,
        categories: [
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 3,
            categoryScore: 336.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 0.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 4,
            categoryScore: 563.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 1,
            categoryScore: 0.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 25,
            categoryScore: 163.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 9,
            categoryScore: 124.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 6,
            categoryScore: 0.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 12,
            categoryScore: 148.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 4,
            categoryScore: 261.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 2,
            categoryScore: 1093.0
          }
        ]
      }
    ]
  }
];
