import { ELSInlineLoader } from '@els/els-ui-common-react';
import cx from 'classnames';

import { ELSIconV2 } from 'components/common';
import { ReportConfig } from 'components/common/bento-box/BentoBox';
import Pill from 'components/common/pill/Pill';

const CentralizedLandingPageCard = ({ title, description, color, icon, text, loading, disabled, onNavigation, isSelected, iconColor = '', pillClassName = '' }: ReportConfig) => (
  <button
    type="button"
    disabled={disabled}
    className={cx('c-centralized-landing-page__content-card', {
      'c-centralized-landing-page__content-card--disabled': disabled,
      'c-centralized-landing-page__content-card--selected': !disabled && isSelected
    })}
    onClick={onNavigation}
  >
    <div className="c-centralized-landing-page__content-card-title">{title}</div>
    {loading ? (
      <ELSInlineLoader />
    ) : (
      <div className="c-centralized-landing-page__content__pill-row-container">
        <Pill className={`c-centralized-landing-page__content__pill ${pillClassName} c-centralized-landing-page__content__pill--${color}`}>
          <ELSIconV2 id={icon} sprite={icon} size="xs" className={`u-custom-color-${color}-1 ${iconColor} c-centralized-landing-page__content__pill-icon`} />
          <span className="c-centralized-landing-page__content__pill-text">{text}</span>
        </Pill>
        <p className="u-els-color-n9 c-centralized-landing-page__content__pill-description">{description}</p>
      </div>
    )}
  </button>
);

export default CentralizedLandingPageCard;
