export default [
  {
    courseSectionId: 523822,
    studentId: 3047711,
    firstName: 'Bennie',
    lastName: 'Factor',
    metrics: [
      {
        assignmentId: 2532133,
        title: 'Sleep: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M12S',
        totalQuestionsAnswered: 8,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T02:06:57.854Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532130,
        title: 'Principles of Safe Medication Administration',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT1M33S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T14:07:37.976Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532128,
        title: 'EAQ Cognition & Sensation Alterations Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M2S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2023-12-04T13:54:53.003Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532102,
        title: 'EAQ Intermediate Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT48M7S',
        totalQuestionsAnswered: 77,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T03:00:28.409Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532103,
        title: 'EAQ Nutrition Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M17S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T03:46:45.625Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532100,
        title: 'Nutrition: Assess and Recognize Cues',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT9M5S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T04:35:23.449Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532098,
        title: 'EAQ Intermediate Sleep',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M9S',
        totalQuestionsAnswered: 48,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T21:14:23.137Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532099,
        title: 'EAQ Novice Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT55M10S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T19:50:34.391Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532110,
        title: 'EAQ Intermediate Perioperative Nursing',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H27M23S',
        totalQuestionsAnswered: 48,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T20:14:34.018Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532111,
        title: 'Overview of Surgery',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.8181818181818182',
        timeSpent: 'PT3M46S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T02:04:12.274Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532106,
        title: 'EAQ Sleep Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M14S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T01:53:33.424Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532104,
        title: 'Electrolyte Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT7M11S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T18:42:05.249Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532105,
        title: 'Safety in Nursing Practice',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT40M4S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T15:34:43.173Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532118,
        title: 'EAQ Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M24S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T01:00:15.982Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532119,
        title: 'EAQ Intermediate Nutrition',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT47M43S',
        totalQuestionsAnswered: 30,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T02:11:27.638Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532116,
        title: 'EAQ Intermediate Bowel',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT10M16S',
        totalQuestionsAnswered: 38,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T01:06:21.771Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532112,
        title: 'EAQ Elimination Bowel Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M30S',
        totalQuestionsAnswered: 15,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T14:21:54.698Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532113,
        title: 'Pain Management: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M4S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T23:17:48.577Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532126,
        title: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT26M54S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T18:31:39.102Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532125,
        title: 'EAQ Novice Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M23S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T00:48:03.978Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532122,
        title: 'EAQ Activity, Immobility, & Safe Movement-Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M14S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T19:01:56.285Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532123,
        title: 'EAQ Proficient Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2H25M',
        totalQuestionsAnswered: 149,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T22:08:58.369Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532120,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT9M4S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2023-11-05T17:04:10.893Z',
        dueDate: '2023-11-05T03:59:00Z'
      },
      {
        assignmentId: 2573030,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT6M49S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T03:01:57.908Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532071,
        title: 'Bowel Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT17M33S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T14:08:26.679Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532068,
        title: 'EAQ Oxygenation & Tissue Perfusion Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT24M24S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-23T03:43:06.154Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532069,
        title: 'EAQ Intermediate Urinary',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT16M15S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T00:40:01.235Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532066,
        title: 'Spread of Infection',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT37S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T03:12:41.625Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591202,
        title: 'EAQ intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M57S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T22:42:11.792Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532067,
        title: 'Nutrition: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9230769230769231',
        timeSpent: 'PT7M4S',
        totalQuestionsAnswered: 13,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T04:17:03.518Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532065,
        title: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H2M16S',
        totalQuestionsAnswered: 73,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-11T04:43:11.564Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532078,
        title: 'EAQ Novice Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M4S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T15:44:55.037Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532079,
        title: 'Cognition and Sensation: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M47S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2023-12-04T13:40:50.398Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532074,
        title: 'Urinary Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT8M9S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-16T00:29:39.157Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532075,
        title: 'EAQ Intermediate Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M55S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T02:07:20.759Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532073,
        title: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M6S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T23:45:12.971Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532087,
        title: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M30S',
        totalQuestionsAnswered: 46,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T00:54:47.804Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532085,
        title: 'EAQ Novice Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT51M35S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T15:26:01.709Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532083,
        title: 'Medication Administration: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9473684210526315',
        timeSpent: 'PT22M31S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-31T01:38:33.681Z',
        dueDate: '2023-10-31T03:59:00Z'
      },
      {
        assignmentId: 2532080,
        title: 'Infection and Infection Control: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT29M23S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:54:01.454Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591231,
        title: 'EAQ intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M46S',
        totalQuestionsAnswered: 44,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T01:47:07.146Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532094,
        title: 'EAQ Intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: null,
        timeSpent: 'PT14M21S',
        totalQuestionsAnswered: 22,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532095,
        title: 'Fluid Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9285714285714286',
        timeSpent: 'PT5M18S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T20:03:34.083Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532092,
        title: 'EAQ Intermediate Cognition & Sensory Alteration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT47M57S',
        totalQuestionsAnswered: 47,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T21:03:42.522Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532093,
        title: 'EAQ Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT27M35S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T00:48:34.022Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532090,
        title: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M10S',
        totalQuestionsAnswered: 30,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T23:53:33.695Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2591227,
        title: 'EAQ intermediate Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T22:45:52.517Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532088,
        title: 'EAQ Perioperative Nursing Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4M57S',
        totalQuestionsAnswered: 22,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T01:59:46.137Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532089,
        title: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9411764705882353',
        timeSpent: 'PT22M26S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-23T03:55:57.934Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532063,
        title: 'EAQ Intermediate Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT45M36S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T23:32:57.465Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532060,
        title: 'Activity and Movement: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9523809523809523',
        timeSpent: 'PT18M23S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-09T03:28:03.468Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532059,
        title: 'EAQ Elimination Urinary Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT24M53S',
        totalQuestionsAnswered: 22,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-15T23:39:47.468Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532076,
        title: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532091,
        title: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532096,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532107,
        title: 'NG Bowel- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532109,
        title: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532114,
        title: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532124,
        title: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532129,
        title:
          'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532135,
        title: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532061,
        title: 'EAQ Intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532058,
        title: 'Mobility In class activity- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532064,
        title: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532070,
        title: 'Overview of Electrolyte Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532072,
        title: 'Class activity: Next Gen Safety Case study (not for a grade)',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532077,
        title: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532082,
        title: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532084,
        title: 'Overview of Loss and Grief (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2532101,
        title: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532117,
        title: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532131,
        title: 'Fluid Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532132,
        title: 'Electrolyte Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532134,
        title: 'Overview of Fluid Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532062,
        title: 'Class activity- Pain NG- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532086,
        title: 'Nutrition- NG - not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532097,
        title: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532108,
        title: 'NG urinary- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532115,
        title: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532121,
        title: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532127,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2592448,
        title: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532081,
        title: 'Overview of Stress and Coping (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      }
    ]
  },
  {
    courseSectionId: 523822,
    studentId: 5846998,
    firstName: 'Alexander',
    lastName: 'Tushishvili',
    metrics: [
      {
        assignmentId: 2532133,
        title: 'Sleep: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT35S',
        totalQuestionsAnswered: 8,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T18:28:14.191Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532130,
        title: 'Principles of Safe Medication Administration',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M33S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T23:49:34.383Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532128,
        title: 'EAQ Cognition & Sensation Alterations Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M13S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-03T16:52:36.798Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532102,
        title: 'EAQ Intermediate Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT51M12S',
        totalQuestionsAnswered: 78,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T01:19:41.015Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532103,
        title: 'EAQ Nutrition Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M37S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T01:00:52.188Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532100,
        title: 'Nutrition: Assess and Recognize Cues',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M6S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T00:45:41.381Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532098,
        title: 'EAQ Intermediate Sleep',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT22M59S',
        totalQuestionsAnswered: 49,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T20:37:34.423Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532099,
        title: 'EAQ Novice Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT11M45S',
        totalQuestionsAnswered: 32,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T22:55:11.847Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532110,
        title: 'EAQ Intermediate Perioperative Nursing',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT30M45S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T20:13:39.307Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532111,
        title: 'Overview of Surgery',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.6363636363636364',
        timeSpent: 'PT15M47S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T18:25:20.631Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532106,
        title: 'EAQ Sleep Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M12S',
        totalQuestionsAnswered: 31,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T21:14:55.38Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532104,
        title: 'Electrolyte Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT58S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T18:12:55.558Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532105,
        title: 'Safety in Nursing Practice',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M4S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T01:09:53.014Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532118,
        title: 'EAQ Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT37M50S',
        totalQuestionsAnswered: 34,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:17:19.764Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532119,
        title: 'EAQ Intermediate Nutrition',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H9M5S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T02:02:54.778Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532116,
        title: 'EAQ Intermediate Bowel',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT32M27S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T03:19:27.322Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532112,
        title: 'EAQ Elimination Bowel Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M35S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-20T01:29:59.098Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532113,
        title: 'Pain Management: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M8S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T20:38:15.971Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532126,
        title: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT21M13S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T18:45:03.134Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532125,
        title: 'EAQ Novice Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT15M43S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T01:35:23.357Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532122,
        title: 'EAQ Activity, Immobility, & Safe Movement-Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M1S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T16:52:30.844Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532120,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M43S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T00:20:26.826Z',
        dueDate: '2023-11-05T03:59:00Z'
      },
      {
        assignmentId: 2573030,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M30S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-01T21:13:04.801Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532071,
        title: 'Bowel Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M6S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-20T01:13:41.52Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532068,
        title: 'EAQ Oxygenation & Tissue Perfusion Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M41S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T16:15:55.783Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532069,
        title: 'EAQ Intermediate Urinary',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M26S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-23T00:14:51.346Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532066,
        title: 'Spread of Infection',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.8888888888888888',
        timeSpent: 'PT1M11S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T01:34:40.402Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591202,
        title: 'EAQ intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT44M55S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T23:15:41.182Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532067,
        title: 'Nutrition: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M28S',
        totalQuestionsAnswered: 13,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T00:35:54.387Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532065,
        title: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT15M54S',
        totalQuestionsAnswered: 22,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T20:54:11.368Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532078,
        title: 'EAQ Novice Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M54S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T23:19:18.77Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532079,
        title: 'Cognition and Sensation: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M52S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-03T16:36:43.015Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532074,
        title: 'Urinary Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT36S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-15T18:43:14.853Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532075,
        title: 'EAQ Intermediate Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT41M26S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T23:57:50.461Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532073,
        title: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.875',
        timeSpent: 'PT10M45S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T21:00:58.719Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532087,
        title: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M43S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T02:45:00.246Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532085,
        title: 'EAQ Novice Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT15M44S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T19:58:44.261Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532083,
        title: 'Medication Administration: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT6M49S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-31T00:19:23.262Z',
        dueDate: '2023-10-31T03:59:00Z'
      },
      {
        assignmentId: 2532080,
        title: 'Infection and Infection Control: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT3M45S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-01T21:04:10.293Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591231,
        title: 'EAQ intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H6M8S',
        totalQuestionsAnswered: 45,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T21:28:07.529Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532095,
        title: 'Fluid Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M30S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T18:18:45.993Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532092,
        title: 'EAQ Intermediate Cognition & Sensory Alteration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M39S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T21:26:42.766Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532093,
        title: 'EAQ Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M23S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:30:16.594Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532090,
        title: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT10M15S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T21:38:11.584Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2591227,
        title: 'EAQ intermediate Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT35M58S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T21:48:20.614Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532088,
        title: 'EAQ Perioperative Nursing Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M21S',
        totalQuestionsAnswered: 28,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T21:29:59.227Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532089,
        title: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9411764705882353',
        timeSpent: 'PT4M8S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T16:08:50.759Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532063,
        title: 'EAQ Intermediate Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT27M',
        totalQuestionsAnswered: 32,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T00:26:06.763Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532060,
        title: 'Activity and Movement: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M6S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T16:18:23.405Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532059,
        title: 'EAQ Elimination Urinary Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT19M6S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-15T21:54:33.519Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532076,
        title: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532091,
        title: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532096,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532107,
        title: 'NG Bowel- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532109,
        title: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532114,
        title: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532124,
        title: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532123,
        title: 'EAQ Proficient Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532129,
        title:
          'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532135,
        title: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532061,
        title: 'EAQ Intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532058,
        title: 'Mobility In class activity- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532064,
        title: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532070,
        title: 'Overview of Electrolyte Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532072,
        title: 'Class activity: Next Gen Safety Case study (not for a grade)',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532077,
        title: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532082,
        title: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532084,
        title: 'Overview of Loss and Grief (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2532101,
        title: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532117,
        title: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532131,
        title: 'Fluid Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532132,
        title: 'Electrolyte Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532134,
        title: 'Overview of Fluid Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532062,
        title: 'Class activity- Pain NG- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532086,
        title: 'Nutrition- NG - not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532097,
        title: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532108,
        title: 'NG urinary- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532115,
        title: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532121,
        title: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532127,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532094,
        title: 'EAQ Intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2592448,
        title: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532081,
        title: 'Overview of Stress and Coping (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      }
    ]
  },
  {
    courseSectionId: 523822,
    studentId: 5167001,
    firstName: 'Jacqueline',
    lastName: 'Payeur',
    metrics: [
      {
        assignmentId: 2532133,
        title: 'Sleep: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M32S',
        totalQuestionsAnswered: 8,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T19:07:06.788Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532130,
        title: 'Principles of Safe Medication Administration',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M19S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-23T21:02:00.366Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532128,
        title: 'EAQ Cognition & Sensation Alterations Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2M59S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-01T01:22:27.707Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532102,
        title: 'EAQ Intermediate Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT30M8S',
        totalQuestionsAnswered: 128,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T05:25:55.711Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532103,
        title: 'EAQ Nutrition Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4M6S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-10T18:40:02.046Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532100,
        title: 'Nutrition: Assess and Recognize Cues',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9130434782608695',
        timeSpent: 'PT7M14S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-10T21:08:08.353Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532098,
        title: 'EAQ Intermediate Sleep',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M13S',
        totalQuestionsAnswered: 44,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T15:21:26.222Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532099,
        title: 'EAQ Novice Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M54S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T16:51:51.712Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532110,
        title: 'EAQ Intermediate Perioperative Nursing',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT20M50S',
        totalQuestionsAnswered: 57,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T15:08:14.359Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532111,
        title: 'Overview of Surgery',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.8181818181818182',
        timeSpent: 'PT2M31S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-06T18:59:29.131Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532106,
        title: 'EAQ Sleep Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2M57S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-03T23:26:05.088Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532104,
        title: 'Electrolyte Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT1M2S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-28T23:59:07.206Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532105,
        title: 'Safety in Nursing Practice',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT57S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-23T20:39:35.475Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532118,
        title: 'EAQ Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M10S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T12:43:51.999Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532119,
        title: 'EAQ Intermediate Nutrition',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M20S',
        totalQuestionsAnswered: 43,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T23:13:53.083Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532116,
        title: 'EAQ Intermediate Bowel',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M30S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T23:05:32.659Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532112,
        title: 'EAQ Elimination Bowel Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2M12S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T13:41:09.639Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532113,
        title: 'Pain Management: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9285714285714286',
        timeSpent: 'PT1M36S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T20:11:56.72Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532126,
        title: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4M22S',
        totalQuestionsAnswered: 31,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T07:16:30.32Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532125,
        title: 'EAQ Novice Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2M2S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-23T21:05:20.644Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532122,
        title: 'EAQ Activity, Immobility, & Safe Movement-Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2M18S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T07:10:18.354Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532123,
        title: 'EAQ Proficient Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H11M46S',
        totalQuestionsAnswered: 246,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T09:30:36.15Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532120,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.8',
        timeSpent: 'PT2M40S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-01T06:15:31.817Z',
        dueDate: '2023-11-05T03:59:00Z'
      },
      {
        assignmentId: 2573030,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9',
        timeSpent: 'PT2M24S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T17:23:08.992Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532071,
        title: 'Bowel Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT12M10S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T14:11:01.617Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532068,
        title: 'EAQ Oxygenation & Tissue Perfusion Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M8S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T14:21:42.448Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532069,
        title: 'EAQ Intermediate Urinary',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M26S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T22:51:50.86Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532066,
        title: 'Spread of Infection',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT28S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T17:34:05.558Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591202,
        title: 'EAQ intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M48S',
        totalQuestionsAnswered: 65,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T17:21:12.886Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532067,
        title: 'Nutrition: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT7M3S',
        totalQuestionsAnswered: 13,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-10T20:50:31.913Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532065,
        title: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT15M51S',
        totalQuestionsAnswered: 60,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T15:56:49.938Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532078,
        title: 'EAQ Novice Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M3S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T16:46:10.649Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532079,
        title: 'Cognition and Sensation: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M16S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-01T01:38:07.438Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532074,
        title: 'Urinary Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT3M26S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T13:44:18.381Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532075,
        title: 'EAQ Intermediate Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M52S',
        totalQuestionsAnswered: 30,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T17:32:20.255Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532073,
        title: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9375',
        timeSpent: 'PT1M32S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T20:31:47.676Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532087,
        title: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M40S',
        totalQuestionsAnswered: 36,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T22:59:21.743Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532084,
        title: 'Overview of Loss and Grief (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: '0.8',
        timeSpent: 'PT2M49S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-12T21:12:37.643Z',
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2532085,
        title: 'EAQ Novice Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4M42S',
        totalQuestionsAnswered: 34,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-23T21:12:46.259Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532083,
        title: 'Medication Administration: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9473684210526315',
        timeSpent: 'PT5M36S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T20:57:53.493Z',
        dueDate: '2023-10-31T03:59:00Z'
      },
      {
        assignmentId: 2532080,
        title: 'Infection and Infection Control: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT2M52S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T17:00:20.131Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532081,
        title: 'Overview of Stress and Coping (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M21S',
        totalQuestionsAnswered: 15,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-12T20:56:45.671Z',
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2591231,
        title: 'EAQ intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M20S',
        totalQuestionsAnswered: 43,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T17:42:28.36Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532094,
        title: 'EAQ Intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT24M7S',
        totalQuestionsAnswered: 100,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T21:08:29.677Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532095,
        title: 'Fluid Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M21S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-29T00:20:16.354Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532092,
        title: 'EAQ Intermediate Cognition & Sensory Alteration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT17M20S',
        totalQuestionsAnswered: 52,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T15:39:48.067Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532093,
        title: 'EAQ Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M48S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T12:28:49.587Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532090,
        title: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M54S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T16:04:26.832Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2591227,
        title: 'EAQ intermediate Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T18:01:16.25Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532088,
        title: 'EAQ Perioperative Nursing Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M43S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T01:19:58.882Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532089,
        title: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9411764705882353',
        timeSpent: 'PT5M16S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T14:37:12.838Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532063,
        title: 'EAQ Intermediate Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M40S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T17:25:36.25Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532060,
        title: 'Activity and Movement: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M41S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T07:24:58.15Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532061,
        title: 'EAQ Intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT32M20S',
        totalQuestionsAnswered: 168,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T10:12:57.904Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532059,
        title: 'EAQ Elimination Urinary Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M55S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T12:44:08.493Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532076,
        title: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532091,
        title: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532096,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532107,
        title: 'NG Bowel- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532109,
        title: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532114,
        title: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532124,
        title: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532129,
        title:
          'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532135,
        title: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532058,
        title: 'Mobility In class activity- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532064,
        title: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532070,
        title: 'Overview of Electrolyte Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532072,
        title: 'Class activity: Next Gen Safety Case study (not for a grade)',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532077,
        title: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532082,
        title: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532101,
        title: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532117,
        title: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532131,
        title: 'Fluid Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532132,
        title: 'Electrolyte Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532134,
        title: 'Overview of Fluid Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532062,
        title: 'Class activity- Pain NG- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532086,
        title: 'Nutrition- NG - not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532097,
        title: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532108,
        title: 'NG urinary- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532115,
        title: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532121,
        title: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532127,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2592448,
        title: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 523822,
    studentId: 6037438,
    firstName: 'MacKenzie',
    lastName: 'Wagner',
    metrics: [
      {
        assignmentId: 2532133,
        title: 'Sleep: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M37S',
        totalQuestionsAnswered: 8,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T22:41:23.269Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532130,
        title: 'Principles of Safe Medication Administration',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT4M11S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T19:25:51.51Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532128,
        title: 'EAQ Cognition & Sensation Alterations Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M38S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T00:24:42.506Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532102,
        title: 'EAQ Intermediate Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT25M25S',
        totalQuestionsAnswered: 71,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T18:09:38.529Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532103,
        title: 'EAQ Nutrition Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M24S',
        totalQuestionsAnswered: 22,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T00:26:45.681Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532100,
        title: 'Nutrition: Assess and Recognize Cues',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9130434782608695',
        timeSpent: 'PT11M41S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T00:01:07.254Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532098,
        title: 'EAQ Intermediate Sleep',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M',
        totalQuestionsAnswered: 31,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-09T20:30:09.707Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532099,
        title: 'EAQ Novice Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT11M46S',
        totalQuestionsAnswered: 30,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T18:02:40.491Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532110,
        title: 'EAQ Intermediate Perioperative Nursing',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H17M28S',
        totalQuestionsAnswered: 40,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T23:19:44.104Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532111,
        title: 'Overview of Surgery',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M39S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T23:18:11.582Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532106,
        title: 'EAQ Sleep Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT8M55S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T22:52:42.814Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532104,
        title: 'Electrolyte Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9166666666666666',
        timeSpent: 'PT10M52S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-28T00:34:35.33Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532105,
        title: 'Safety in Nursing Practice',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M41S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T15:08:45.535Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532118,
        title: 'EAQ Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT19M6S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-01T00:09:00.42Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532119,
        title: 'EAQ Intermediate Nutrition',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT17M2S',
        totalQuestionsAnswered: 28,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T15:58:43.919Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532116,
        title: 'EAQ Intermediate Bowel',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M57S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T15:40:57.105Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532112,
        title: 'EAQ Elimination Bowel Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5M53S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T02:14:11.725Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532113,
        title: 'Pain Management: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT12M27S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T17:49:12.466Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532126,
        title: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT20M34S',
        totalQuestionsAnswered: 28,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-28T00:59:59.538Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532125,
        title: 'EAQ Novice Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M33S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T19:08:57.002Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532122,
        title: 'EAQ Activity, Immobility, & Safe Movement-Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3M43S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-07T22:41:53.787Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532120,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: null,
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-05T03:59:00Z'
      },
      {
        assignmentId: 2573030,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT5M28S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-31T20:52:47.165Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532071,
        title: 'Bowel Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT12M3S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T02:06:53.751Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532068,
        title: 'EAQ Oxygenation & Tissue Perfusion Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT50M3S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-21T02:04:55.994Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532069,
        title: 'EAQ Intermediate Urinary',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT29M38S',
        totalQuestionsAnswered: 47,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T01:08:03.267Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532066,
        title: 'Spread of Infection',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M9S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-31T20:38:27.535Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591202,
        title: 'EAQ intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-04T00:25:51.723Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532067,
        title: 'Nutrition: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9230769230769231',
        timeSpent: 'PT8M29S',
        totalQuestionsAnswered: 13,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T00:17:57.711Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532065,
        title: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT32M9S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-08T00:49:18.241Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532078,
        title: 'EAQ Novice Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT8M58S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T18:43:26.955Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532079,
        title: 'Cognition and Sensation: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT15M9S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-04T00:15:02.911Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532074,
        title: 'Urinary Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M58S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T13:48:03.741Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532075,
        title: 'EAQ Intermediate Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M57S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T18:17:34.602Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532073,
        title: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M25S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T18:33:14.412Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532087,
        title: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT30M58S',
        totalQuestionsAnswered: 41,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T01:40:25.123Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532085,
        title: 'EAQ Novice Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M40S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-24T19:37:52.063Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532083,
        title: 'Medication Administration: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9473684210526315',
        timeSpent: 'PT6M46S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T20:09:12.007Z',
        dueDate: '2023-10-31T03:59:00Z'
      },
      {
        assignmentId: 2532080,
        title: 'Infection and Infection Control: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9583333333333334',
        timeSpent: 'PT9M42S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-31T21:21:25.361Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532081,
        title: 'Overview of Stress and Coping (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: '0.5333333333333333',
        timeSpent: 'PT13M8S',
        totalQuestionsAnswered: 15,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-13T02:35:35.35Z',
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2591231,
        title: 'EAQ intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-04T00:24:25.826Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532094,
        title: 'EAQ Intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: null,
        timeSpent: 'PT1H12M52S',
        totalQuestionsAnswered: 86,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532095,
        title: 'Fluid Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9285714285714286',
        timeSpent: 'PT12M28S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T23:38:49.963Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532092,
        title: 'EAQ Intermediate Cognition & Sensory Alteration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT47M12S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-08T00:14:51.161Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532093,
        title: 'EAQ Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT8M38S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-01T00:19:45.594Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532090,
        title: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H6M38S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-09T20:11:20.647Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2591227,
        title: 'EAQ intermediate Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M30S',
        totalQuestionsAnswered: 35,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T16:33:59.594Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532088,
        title: 'EAQ Perioperative Nursing Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT35M36S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-05T00:15:24.432Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532089,
        title: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9411764705882353',
        timeSpent: 'PT9M54S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-21T00:38:55.649Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532063,
        title: 'EAQ Intermediate Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M45S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-03T17:17:01.655Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532060,
        title: 'Activity and Movement: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9523809523809523',
        timeSpent: 'PT13M57S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-07T22:36:53.169Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532061,
        title: 'EAQ Intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: null,
        timeSpent: 'PT1H36M33S',
        totalQuestionsAnswered: 79,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532059,
        title: 'EAQ Elimination Urinary Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT31M40S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-13T14:21:38.543Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532076,
        title: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532091,
        title: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532096,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532107,
        title: 'NG Bowel- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532109,
        title: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532114,
        title: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532124,
        title: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532123,
        title: 'EAQ Proficient Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532129,
        title:
          'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532135,
        title: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532058,
        title: 'Mobility In class activity- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532064,
        title: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532070,
        title: 'Overview of Electrolyte Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532072,
        title: 'Class activity: Next Gen Safety Case study (not for a grade)',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532077,
        title: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532082,
        title: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532084,
        title: 'Overview of Loss and Grief (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2532101,
        title: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532117,
        title: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532131,
        title: 'Fluid Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532132,
        title: 'Electrolyte Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532134,
        title: 'Overview of Fluid Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532062,
        title: 'Class activity- Pain NG- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532086,
        title: 'Nutrition- NG - not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532097,
        title: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532108,
        title: 'NG urinary- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532115,
        title: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532121,
        title: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532127,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2592448,
        title: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 523822,
    studentId: 6952847,
    firstName: 'Jessica',
    lastName: 'Allbright',
    metrics: [
      {
        assignmentId: 2573030,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT7M21S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:36:18.975Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532071,
        title: 'Bowel Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT22M50S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T15:12:44.785Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532068,
        title: 'EAQ Oxygenation & Tissue Perfusion Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M54S',
        totalQuestionsAnswered: 15,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T20:25:04.249Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532069,
        title: 'EAQ Intermediate Urinary',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT22M',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T00:12:20.528Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532133,
        title: 'Sleep: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT6M28S',
        totalQuestionsAnswered: 8,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T00:43:59.121Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532066,
        title: 'Spread of Infection',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT53S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:44:49.928Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532130,
        title: 'Principles of Safe Medication Administration',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT9M11S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T13:07:01.347Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2591202,
        title: 'EAQ intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H33S',
        totalQuestionsAnswered: 58,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T18:18:27.897Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532067,
        title: 'Nutrition: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9230769230769231',
        timeSpent: 'PT26M30S',
        totalQuestionsAnswered: 13,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-12T04:34:29.918Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532128,
        title: 'EAQ Cognition & Sensation Alterations Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M59S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-03T03:55:43.333Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532065,
        title: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H36M16S',
        totalQuestionsAnswered: 45,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T04:05:54.445Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532078,
        title: 'EAQ Novice Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT13M43S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T16:05:53.252Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532079,
        title: 'Cognition and Sensation: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9565217391304348',
        timeSpent: 'PT13M3S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-03T01:18:53.336Z',
        dueDate: '2023-12-04T04:59:00Z'
      },
      {
        assignmentId: 2532074,
        title: 'Urinary Elimination: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M11S',
        totalQuestionsAnswered: 9,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-15T22:13:51.839Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532075,
        title: 'EAQ Intermediate Hygiene & Personal Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT27M20S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T21:54:16.181Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532073,
        title: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M21S',
        totalQuestionsAnswered: 16,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-27T16:45:40.489Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532087,
        title: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT41M19S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-26T17:21:50.685Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532085,
        title: 'EAQ Novice Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT40M8S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-26T00:22:32.909Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532083,
        title: 'Medication Administration: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT8M15S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-30T22:30:30.214Z',
        dueDate: '2023-10-31T03:59:00Z'
      },
      {
        assignmentId: 2532080,
        title: 'Infection and Infection Control: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.875',
        timeSpent: 'PT10M28S',
        totalQuestionsAnswered: 24,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T02:27:00.867Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2591231,
        title: 'EAQ intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H2M57S',
        totalQuestionsAnswered: 45,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T23:03:20.196Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532095,
        title: 'Fluid Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT12M29S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-30T04:22:01.477Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532092,
        title: 'EAQ Intermediate Cognition & Sensory Alteration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT34M44S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T08:09:51.953Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532093,
        title: 'EAQ Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT11M19S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T01:39:33.828Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532090,
        title: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT51M46S',
        totalQuestionsAnswered: 27,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T16:04:52.874Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2591227,
        title: 'EAQ intermediate Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M28S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T16:48:46.51Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532088,
        title: 'EAQ Perioperative Nursing Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT19M45S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T01:28:13Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532089,
        title: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.8823529411764706',
        timeSpent: 'PT20M2S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-22T20:45:58.586Z',
        dueDate: '2023-11-23T04:59:00Z'
      },
      {
        assignmentId: 2532102,
        title: 'EAQ Intermediate Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H21M33S',
        totalQuestionsAnswered: 93,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-06T03:18:06.788Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532103,
        title: 'EAQ Nutrition Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT27M',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-12T04:05:04.351Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532100,
        title: 'Nutrition: Assess and Recognize Cues',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9565217391304348',
        timeSpent: 'PT44M17S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-12T05:20:53.166Z',
        dueDate: '2023-11-13T04:59:00Z'
      },
      {
        assignmentId: 2532098,
        title: 'EAQ Intermediate Sleep',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T15:11:05.546Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532099,
        title: 'EAQ Novice Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT36M4S',
        totalQuestionsAnswered: 19,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T01:26:51.797Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532110,
        title: 'EAQ Intermediate Perioperative Nursing',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT48M39S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-10T07:32:48.163Z',
        dueDate: '2023-12-11T04:59:00Z'
      },
      {
        assignmentId: 2532111,
        title: 'Overview of Surgery',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T01:03:31.289Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532106,
        title: 'EAQ Sleep Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT15M37S',
        totalQuestionsAnswered: 17,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-12-07T01:46:07.961Z',
        dueDate: '2023-12-07T04:59:00Z'
      },
      {
        assignmentId: 2532104,
        title: 'Electrolyte Balance: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT4M40S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-30T04:08:40.118Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532105,
        title: 'Safety in Nursing Practice',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M26S',
        totalQuestionsAnswered: 11,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T12:45:28.139Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532118,
        title: 'EAQ Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M8S',
        totalQuestionsAnswered: 26,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-02T01:55:00.089Z',
        dueDate: '2023-11-02T03:59:00Z'
      },
      {
        assignmentId: 2532119,
        title: 'EAQ Intermediate Nutrition',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT41M38S',
        totalQuestionsAnswered: 32,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-26T20:29:02.635Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532116,
        title: 'EAQ Intermediate Bowel',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT32M42S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-27T01:30:16.901Z',
        dueDate: '2023-11-27T04:59:00Z'
      },
      {
        assignmentId: 2532112,
        title: 'EAQ Elimination Bowel Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT14M21S',
        totalQuestionsAnswered: 15,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-19T15:30:54.501Z',
        dueDate: '2023-11-20T04:59:00Z'
      },
      {
        assignmentId: 2532113,
        title: 'Pain Management: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT3M29S',
        totalQuestionsAnswered: 14,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-29T01:39:18.727Z',
        dueDate: '2023-10-30T03:59:00Z'
      },
      {
        assignmentId: 2532126,
        title: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT24M40S',
        totalQuestionsAnswered: 22,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-30T04:48:05.502Z',
        dueDate: '2023-11-30T04:59:00Z'
      },
      {
        assignmentId: 2532063,
        title: 'EAQ Intermediate Asepsis & Infection Control',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT25M35S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-05T17:15:44.819Z',
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532060,
        title: 'Activity and Movement: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: '0.9047619047619048',
        timeSpent: 'PT21M2S',
        totalQuestionsAnswered: 21,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T23:07:49.204Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532125,
        title: 'EAQ Novice Safety',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT23M29S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-10-25T13:33:59.301Z',
        dueDate: '2023-10-26T03:59:00Z'
      },
      {
        assignmentId: 2532122,
        title: 'EAQ Activity, Immobility, & Safe Movement-Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M9S',
        totalQuestionsAnswered: 23,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-08T23:43:56.388Z',
        dueDate: '2023-11-09T04:59:00Z'
      },
      {
        assignmentId: 2532059,
        title: 'EAQ Elimination Urinary Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT31M39S',
        totalQuestionsAnswered: 25,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2023-11-15T22:47:24.504Z',
        dueDate: '2023-11-16T04:59:00Z'
      },
      {
        assignmentId: 2532076,
        title: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532091,
        title: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532096,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532107,
        title: 'NG Bowel- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532109,
        title: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532114,
        title: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532120,
        title: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
        contentType: 'LESSONS',
        gradeType: 'SCORED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-05T03:59:00Z'
      },
      {
        assignmentId: 2532124,
        title: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532123,
        title: 'EAQ Proficient Pain Management',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532129,
        title:
          'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532135,
        title: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532061,
        title: 'EAQ Intermediate Skin Integrity & Wound Care',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2532058,
        title: 'Mobility In class activity- not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532064,
        title: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532070,
        title: 'Overview of Electrolyte Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532072,
        title: 'Class activity: Next Gen Safety Case study (not for a grade)',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532077,
        title: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532082,
        title: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532084,
        title: 'Overview of Loss and Grief (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      },
      {
        assignmentId: 2532101,
        title: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532117,
        title: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532131,
        title: 'Fluid Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532132,
        title: 'Electrolyte Imbalance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532134,
        title: 'Overview of Fluid Balance',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532062,
        title: 'Class activity- Pain NG- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532086,
        title: 'Nutrition- NG - not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532097,
        title: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532108,
        title: 'NG urinary- Not for a grade',
        contentType: 'AUTHESS',
        gradeType: 'NOT_GRADED',
        gradePoints: 7,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532115,
        title: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532121,
        title: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532127,
        title: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 3,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532094,
        title: 'EAQ Intermediate Medication Administration',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-11-06T04:59:00Z'
      },
      {
        assignmentId: 2592448,
        title: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
        contentType: 'EBOOK_READING',
        gradeType: 'NOT_GRADED',
        gradePoints: 0,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 2532081,
        title: 'Overview of Stress and Coping (Recommended for HESI prep)',
        contentType: 'LESSONS',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: true,
        submissionDate: null,
        dueDate: '2023-12-14T04:59:00Z'
      }
    ]
  }
];
