import { noop } from 'lodash';
import CentralizedLandingPageCard from '../centralized-landing-page-card/CentralizedLandingPageCard';
import useCentralizedLandingPageCardsHook from './useCentralizedLandingPageCardsHook';

interface CentralizedLandingPageCardsProps {
  onClickOutSide?: Function;
}

const CentralizedLandingPageCards = ({ onClickOutSide = noop }: CentralizedLandingPageCardsProps) => {
  const { reportConfigs } = useCentralizedLandingPageCardsHook();

  const modifiedReportConfigs = reportConfigs.map(config => ({
    ...config,
    onNavigation: () => {
      if (!config.isSelected) {
        config.onNavigation();
      }
      onClickOutSide();
    }
  }));

  return (
    <div className="c-centralized-landing-page__content">
      {modifiedReportConfigs.map(config => (
        <CentralizedLandingPageCard key={config.text} {...config} />
      ))}
    </div>
  );
};

export default CentralizedLandingPageCards;
