import { ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { ehrPath } from 'reports/ehr/constants/ehr.constant';

const { INSTRUCTOR } = ROUTE_ROLES;

export const EhrRoutes = {
  getRoutes: () => [
    {
      path: ehrPath.ehrStudentDetail,
      component: lazy(() => import(/* webpackChunkName: "EHRStudentDetail" */ 'reports/ehr/components/pages/ehr-student-detail/EHRStudentDetail')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:ehr-student-detail`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: ehrPath.ehrHome,
      component: lazy(() => import(/* webpackChunkName: "EHRHome" */ 'reports/ehr/components/pages/ehr-home/EHRHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:ehr-dashboard`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
