import React, { Component } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { ELSIcon } from 'components/common';
import { LIST_BOX_INDEX } from 'components/common/list-box/ListBox';
import { DropdownOption } from 'models';

export interface ListBoxModalProps {
  header: string;
  headerIcon: string;
  headerTitleClass?: string;
  options: Array<DropdownOption>;
  value: string;
  dependedValue?: string;
  hasDependent?: boolean;
  changeHandler: Function;
  closeHandler: Function;
}

interface ListBoxModalState {
  selectedOption: DropdownOption;
  selectedDependedOption: DropdownOption;
}

class ListBoxModal extends Component<ListBoxModalProps, ListBoxModalState> {
  constructor(props) {
    super(props);
    const { options, value, dependedValue } = this.props;
    const selectedOption = options.find(option => option.value === value);
    const selectedDependedOption = (selectedOption.dependedOptions || []).find(option => option.value === dependedValue);
    this.state = {
      selectedOption,
      selectedDependedOption
    };
    document.documentElement.classList.add('c-list-box-modal--opened');
  }

  componentWillUnmount(): void {
    document.documentElement.classList.remove('c-list-box-modal--opened');
  }

  onChange = (option: DropdownOption, listIndex: number) => {
    if (listIndex === LIST_BOX_INDEX.PARENT) {
      this.setState({
        selectedOption: option,
        selectedDependedOption: option.dependedOptions && option.dependedOptions.length ? option.dependedOptions[0] : null
      });
    }
    if (listIndex === LIST_BOX_INDEX.DEPENDENT) {
      this.setState({
        selectedDependedOption: option
      });
    }
  };

  closeModal = () => {
    const { value, dependedValue } = this.props;
    const { selectedOption, selectedDependedOption } = this.state;
    if (value !== selectedOption.value) {
      this.props.changeHandler(selectedOption, 0);
    }
    if (this.props.hasDependent && dependedValue !== selectedDependedOption.value) {
      this.props.changeHandler(selectedDependedOption, 1);
    }
    this.props.closeHandler();
  };

  getDependedOptions = () => {
    const { selectedOption } = this.state;
    return selectedOption ? selectedOption.dependedOptions || [] : [];
  };

  renderItem = (option: DropdownOption, selected: boolean, listIndex: number) => {
    const handler = () => {
      this.onChange(option, listIndex);
    };
    return (
      <li
        role="option"
        aria-selected={selected}
        className={classNames('c-had-list-box-modal__item u-cursor-pointer', { 'c-had-list-box-modal__item--selected': selected })}
        key={uuidv4()}
        onClick={handler}
        onKeyPress={handler}
      >
        {option.icon && <ELSIcon customClass="u-els-margin-right-1o2" name={option.icon} size="3o4" />}
        {option.name}
      </li>
    );
  };

  render() {
    const { options, header, headerIcon, hasDependent, headerTitleClass } = this.props;
    const { selectedOption, selectedDependedOption } = this.state;
    return (
      <div className={classNames('c-had-list-box-modal')}>
        <header className="c-had-list-box-modal__header">
          <span className={classNames('c-had-list-box-modal__header__title', headerTitleClass)}>
            <ELSIcon customClass="u-els-margin-right-1o2" name={headerIcon} size="1x" />
            <span>{header}</span>
          </span>
          <ELSIcon customClass="u-cursor-pointer c-had-list-box-modal__header__close" name="close" size="1x" onClick={this.closeModal} />
        </header>

        <div className="c-had-list-box-modal__body">
          <ul>{options.map(option => this.renderItem(option, option.value === selectedOption.value, LIST_BOX_INDEX.PARENT))}</ul>
        </div>

        {hasDependent && (
          <footer className="c-had-list-box-modal__footer">
            {this.getDependedOptions().map(option => this.renderItem(option, option.value === selectedDependedOption.value, LIST_BOX_INDEX.DEPENDENT))}
          </footer>
        )}
      </div>
    );
  }
}

export default ListBoxModal;
