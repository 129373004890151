import { uniqBy, orderBy } from 'lodash';
import { MenuOption } from 'models';
import {
  CATEGORY_GROUP_HIDDEN,
  DEFAULT_CATEGORY_GROUP_NAME,
  DEFAULT_STUDENT_HOME_CATEGORY_GROUP_NAME,
  EXAM_EXIT_TYPE,
  EXAM_SPECIALTY_TYPE,
  STUDENT_CATEGORY_GROUP
} from 'reports/had/constants/had.constant';
import { CategoryGroup, CategorySummary, CohortCategoryGroup, StudentExamCategoryDTO } from 'reports/had/models';
import { CategorySummaryJoinExam, UniqueExam } from 'reports/had/helpers/category.helper';

type UniqueObjectKey = {
  [key: string]: string;
};

export type BuildColorsOutputType = {
  color: string;
  selectedColor: string;
};

export interface BrushIndex {
  startIndex: number;
  endIndex: number;
}

export interface TrendLineChartData {
  chartData: any;
  maxCategoryScore: number;
  minCategoryScore: number;
  maxExamNameLength: number;
}

export interface SubCategoryChildName {
  color: {
    default: string;
    selected: string;
  };
  name: string;
  id: number;
}

export interface AxisPadding {
  left: number;
  right: number;
}

export interface TrendLineChartConfig {
  domainMin: number;
  domainMax: number;
  labelTickWidth: number;
  brushIndex: BrushIndex;
  isBrushShown: boolean;
  xAxisPadding: AxisPadding;
  xAxisHeight: number;
  tickHeight: number;
}

export interface Header {
  title: string;
  field: string;
  align: string;
  customRender?: Function;
}

export const screenModes = {
  desktop: 'desktop',
  ipad: 'ipad',
  mobile: 'mobile'
};

export interface OffsetSelectedLineDot {
  offsetStart: number;
  offsetEnd: number;
}

export interface ArrowBoundingClientRect {
  scrollSlideElm: SVGElement;
  chartElm: HTMLDivElement;
  scrollSlideBoundingClientRect: DOMRect;
  chartBoundingClientRect: DOMRect;
}

// ================= Category Common =========================
export const getCategoryGroupIdByName = (categoryGroupList: CohortCategoryGroup[], categoryGroupName: string): number => {
  let categoryGroupId = categoryGroupList
    ?.find((categoryGroup: CohortCategoryGroup) => categoryGroup.examType === EXAM_EXIT_TYPE)
    ?.categoryGroup.find(category => category.categoryGroupName === categoryGroupName).categoryGroupId;

  if (!categoryGroupId) {
    categoryGroupId = categoryGroupList
      ?.find((categoryGroup: CohortCategoryGroup) => categoryGroup.examType === EXAM_SPECIALTY_TYPE)
      ?.categoryGroup.find(category => category.categoryGroupName === categoryGroupName).categoryGroupId;
  }
  return categoryGroupId;
};

export const getUniqueStudentExamCategories = (studentExamCategory: StudentExamCategoryDTO[]): StudentExamCategoryDTO[] => {
  return studentExamCategory.map(sec => {
    const exams = orderBy(sec.exams, ['dateTaken'], ['desc']);
    const uniqExams = uniqBy(exams, 'examId');
    return {
      ...sec,
      exams: uniqExams
    };
  });
};

// ================= CategoryWeakest =========================
export const NO_DATA_FOUND_RECOMMENDED_LEVEL_MESSAGE = 'All categories with more than 3 items are at the Recommended level';
export const REQUIRED_NUMBER_OF_ITEMS = 3;
export const NUMBER_OF_CATEGORY_DISPLAY = 3;
export const CATEGORY_SCORE_900 = 900;

export const getCategorySummaryByNumeralCondition = (
  data: Array<CategorySummary> = [],
  numberOfItems: number,
  numberOfCategoryDisplay: number,
  isUseScoreLessThan900?: boolean
): Array<CategorySummary> => {
  const filteredData = isUseScoreLessThan900
    ? data.filter(category => category.totalNumberOfItems > numberOfItems && category.meanCategoryScore < CATEGORY_SCORE_900)
    : data.filter(category => category.totalNumberOfItems > numberOfItems);
  return filteredData
    .sort((category1, category2) => category1.meanCategoryScore - category2.meanCategoryScore || category1.subCategoryChildName.localeCompare(category2.subCategoryChildName))
    .slice(0, numberOfCategoryDisplay);
};

export const buildWeakestCategoryHeader = (numberOfItems: number, renderMeanCategoryScore: Function): Array<Header> => {
  return [
    {
      title: `Categories with ${numberOfItems}+ items`,
      field: 'subCategoryChildName',
      align: 'left'
    },
    {
      title: 'Mean Category Score',
      field: 'meanCategoryScore',
      align: 'right',
      customRender: renderMeanCategoryScore
    }
  ];
};

// ================= CategoryTable =========================
export const buildCategoryGroupOptions = (categories: CohortCategoryGroup[], isStudentRole?: boolean): MenuOption[] => {
  if (categories.length) {
    let totalCategories: CategoryGroup[] = [];
    categories.forEach(item => {
      totalCategories = [...totalCategories, ...item.categoryGroup];
    });

    const totalCategoriesObject: UniqueObjectKey = {};
    totalCategories.forEach(item => {
      if (!totalCategoriesObject[item.categoryGroupId]) {
        totalCategoriesObject[item.categoryGroupId] = item.categoryGroupName;
      }
    });

    const distinctCategories: MenuOption[] = Object.keys(totalCategoriesObject).map(item => ({ key: item, name: totalCategoriesObject[item] }));

    const compareMenu = (menu1: MenuOption, menu2: MenuOption): number => menu1.name.localeCompare(menu2.name);

    let finalCategoriesGroup = distinctCategories.filter(item => !CATEGORY_GROUP_HIDDEN.includes(item.name));

    if (isStudentRole) {
      finalCategoriesGroup = distinctCategories.filter(item => STUDENT_CATEGORY_GROUP.includes(item.name));
    }

    return finalCategoriesGroup.sort(compareMenu);
  }
  return [];
};

export const getDefaultCategoryGroupId = (categoryGroupOptions: MenuOption[], isStudentRole?: boolean): string => {
  if (categoryGroupOptions.length) {
    if (isStudentRole) {
      const { key = '' } = categoryGroupOptions.find(group => group.name === DEFAULT_STUDENT_HOME_CATEGORY_GROUP_NAME);
      return key;
    }
    return categoryGroupOptions.find(group => group.name === DEFAULT_CATEGORY_GROUP_NAME).key;
  }
  return '';
};

export const buildColors = (): BuildColorsOutputType[] => {
  let colors: { color: string; selectedColor: string }[] = [];
  for (let i = 0; i < 3; i += 1) {
    colors = [
      ...colors,
      { color: `extended-blue-${i + 2}`, selectedColor: `extended-blue-${i + 8}` },
      { color: `extended-orange-${i + 2}`, selectedColor: `extended-orange-${i + 8}` },
      { color: `extended-green-${i + 2}`, selectedColor: `extended-green-${i + 8}` },
      { color: `extended-yellow-${i + 2}`, selectedColor: `extended-yellow-${i + 8}` },
      { color: `extended-purple-${i + 2}`, selectedColor: `extended-purple-${i + 8}` },
      { color: `extended-cobalt-${i + 2}`, selectedColor: `extended-cobalt-${i + 8}` },
      { color: `extended-teal-${i + 2}`, selectedColor: `extended-teal-${i + 8}` },
      { color: `extended-pink-${i + 2}`, selectedColor: `extended-pink-${i + 8}` },
      { color: `n${i + 2}`, selectedColor: `n${i + 8}` }
    ];
  }
  return colors;
};

export const remapCategoryTableRows = (categoryData: CategorySummaryJoinExam[], allExamsById: UniqueExam[]) => {
  const testedOnValues = [];
  return categoryData
    .map(row => {
      const { categoryId, subCategoryChildName, subCategoryParentName, meanCategoryScore, totalNumberOfItems, percentStudentsBelow850, totalExamBelow850, exams, color } = row;
      return {
        categoryId,
        subCategoryChildName,
        subCategoryParentName,
        meanCategoryScore,
        totalNumberOfItems,
        percentStudentsBelow850,
        totalExamBelow850,
        testedOn: 0,
        exams,
        color
      };
    })
    .map((row, rowIndex) => {
      testedOnValues[rowIndex] = 0;
      const examData = allExamsById
        .map((examById, index) => {
          const examIndex = index + 1;
          const examWithData = row.exams?.find(exam => exam.examId === examById.examId);
          if (examWithData) {
            if (examWithData.numOfItems >= 1) {
              testedOnValues[rowIndex] += 1;
            }
            return {
              testedOn: testedOnValues[rowIndex],
              [`exam${examIndex}Score`]: examWithData.categoryScore,
              [`exam${examIndex}Total`]: examWithData.numOfItems
            };
          }
          return {
            [`exam${examIndex}Score`]: 0,
            [`exam${examIndex}Total`]: 0
          };
        })
        .forEach(column => Object.assign(row, column));
      return Object.assign(row, examData);
    });
};

// ================= TrendLineChart =========================
export const trendLineConfig = {
  yAxisLabel: 40,
  xAxisPadding: {
    desktop: 110,
    mobile: 60
  },
  labelLetterWidth: 7,
  xAxisAndBrushPadding: 16,
  lastDateTakenHeight: 19,
  defaultNumberOfBrushItem: {
    desktop: 5,
    ipad: 3,
    mobile: 2
  },
  heightOfOneLineExamNameInTick: 19,
  referenceLines: {
    min: 700,
    middle: 850,
    max: 1000
  },
  chartWidth: {
    mobile: 550,
    ipad: 720
  },
  dateTakenSign: '$DateTaken$',
  maxCategoryLine: 20
};

export const getSubCategoryChildNames = (categorySummaryJoinExams: CategorySummaryJoinExam[]): SubCategoryChildName[] => {
  return categorySummaryJoinExams.map(csje => ({
    name: csje.subCategoryChildName,
    id: csje.categoryId,
    color: csje.color
  }));
};

export const getChartDomain = (min: number, max: number): { domainMin: number; domainMax: number } => {
  const domainMax = max > trendLineConfig.referenceLines.max ? max : trendLineConfig.referenceLines.max;
  const domainMin = min < trendLineConfig.referenceLines.min ? min : trendLineConfig.referenceLines.min;
  const maxBufferForDisplayScore = (domainMax - domainMin) / 4;
  return {
    domainMin: Math.round(domainMin),
    domainMax: Math.round(domainMax + maxBufferForDisplayScore)
  };
};

export const initializeBrushIndex = (chartLength: number, defaultNumberOfBrushItem = 1): BrushIndex => {
  const numberOfBrushItem = chartLength > defaultNumberOfBrushItem ? defaultNumberOfBrushItem : chartLength;
  return { startIndex: chartLength - numberOfBrushItem, endIndex: chartLength - 1 };
};

export const getResizeScreenData = (
  chartDataLength: number,
  offsetWidth: number
): {
  brushIndex: BrushIndex;
  labelTickWidth: number;
  screenMode: string;
  isBrushShown: boolean;
} => {
  let tempBrushIndex: BrushIndex;
  let screenMode: string;
  let isBrushShown: boolean;

  if (offsetWidth < trendLineConfig.chartWidth.mobile) {
    tempBrushIndex = initializeBrushIndex(chartDataLength, trendLineConfig.defaultNumberOfBrushItem.mobile);
    screenMode = screenModes.mobile;
    isBrushShown = chartDataLength > trendLineConfig.defaultNumberOfBrushItem.mobile;
  } else if (offsetWidth < trendLineConfig.chartWidth.ipad) {
    tempBrushIndex = initializeBrushIndex(chartDataLength, trendLineConfig.defaultNumberOfBrushItem.ipad);
    screenMode = screenModes.ipad;
    isBrushShown = chartDataLength > trendLineConfig.defaultNumberOfBrushItem.ipad;
  } else {
    tempBrushIndex = initializeBrushIndex(chartDataLength, trendLineConfig.defaultNumberOfBrushItem.desktop);
    screenMode = screenModes.desktop;
    isBrushShown = chartDataLength > trendLineConfig.defaultNumberOfBrushItem.desktop;
  }
  const numberOfBrushItem = tempBrushIndex.endIndex - tempBrushIndex.startIndex + 1;
  const xAxisPadding = screenMode === screenModes.desktop ? trendLineConfig.xAxisPadding.desktop : trendLineConfig.xAxisPadding.mobile;
  const calculatedLabelTickWidth = (offsetWidth - xAxisPadding) / numberOfBrushItem;
  return {
    brushIndex: tempBrushIndex,
    labelTickWidth: calculatedLabelTickWidth,
    screenMode,
    isBrushShown
  };
};

export const getXAxisPadding = (screenMode: string): AxisPadding => {
  const xAxisPadding = screenMode === screenModes.mobile ? trendLineConfig.xAxisPadding.mobile : trendLineConfig.xAxisPadding.desktop;
  return {
    left: xAxisPadding + trendLineConfig.yAxisLabel,
    right: xAxisPadding
  };
};

export const calculateLabelWidth = (letter: number, labelLetterWidth: number): number => letter * labelLetterWidth;

export const getTrendLineChartConfig = (maxExamNameLength: number, labelTickWidth: number): { xAxisHeight: number; tickHeight: number } => {
  const maxExamNameWidth = calculateLabelWidth(maxExamNameLength, trendLineConfig.labelLetterWidth);
  const maxExamNameNumberOfLine = Math.ceil(maxExamNameWidth / labelTickWidth);
  const xAxisHeight = trendLineConfig.xAxisAndBrushPadding * 2 + trendLineConfig.heightOfOneLineExamNameInTick * maxExamNameNumberOfLine + trendLineConfig.lastDateTakenHeight;
  const tickHeight = maxExamNameNumberOfLine * trendLineConfig.heightOfOneLineExamNameInTick + trendLineConfig.lastDateTakenHeight;
  return {
    xAxisHeight,
    tickHeight
  };
};
