import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { triggerSortTableManually } from 'services/table.service';
import { InteractionSortingBetweenCardAndTable, StudentEngagementRecord } from 'reports/cw/models';
import { STUDENT_ENGAGEMENT_TABLE_SELECTOR } from 'reports/cw/constants/report.constant';
import { cwSelectors } from 'reports/cw/redux/ducks/cw';
import ResponsiveTable, { TableColumn, TableProps } from 'components/common/responsive-table/ResponsiveTable';
import { FeatureFlagNames, SORT_DIRECTION } from 'constants/app.constant';
import { appSelectors } from 'redux/ducks/app';
import { FeatureFlag } from 'models';
import {
  renderAvgTimePerQuestion,
  renderStudentAvgScore,
  renderAvgTimePerQuestionComparedToClass,
  renderPastDue,
  renderName,
  renderComparedPerformance,
  renderStudentTotalGradePoints
} from '../student-engagement-table-helper/StudentEngagementTableHelper';
import { isFeatureEnabled } from '../../../../../../services/feature-flag.service';

export interface StudentEngagementTableProps {
  avgScoreOfClass: number;
  tableData: Array<StudentEngagementRecord>;
  featureFlags: FeatureFlag[];
  isEAQAssignmentType: boolean;
  studentEngagementCardAndTableSortInteraction: InteractionSortingBetweenCardAndTable;
  isIncorrectDataHidden?: boolean;
  isGradePointsHidden?: boolean;
  isOsmosisAssignmentType?: boolean;
}

const StudentEngagementTable: React.FC<StudentEngagementTableProps> = ({
  avgScoreOfClass,
  tableData,
  featureFlags,
  isEAQAssignmentType,
  isIncorrectDataHidden,
  isGradePointsHidden,
  isOsmosisAssignmentType,
  studentEngagementCardAndTableSortInteraction
}) => {
  const interactionWithCard = useCallback(() => {
    if (studentEngagementCardAndTableSortInteraction) {
      triggerSortTableManually(studentEngagementCardAndTableSortInteraction.sortDirection, `.${studentEngagementCardAndTableSortInteraction.columnHeaderSelector}`);
    }
  }, [studentEngagementCardAndTableSortInteraction]);

  useEffect(() => {
    interactionWithCard();
  }, [studentEngagementCardAndTableSortInteraction, interactionWithCard]);
  const { studentAverageScoreColumn, timeSpentComparedToClassAvgColumn, numberOfStudentsWithPastDueColumn, selfStudyQuestionsAttemptedColumn } = STUDENT_ENGAGEMENT_TABLE_SELECTOR;
  const eaqAssignmentColumns: TableColumn<StudentEngagementRecord>[] = isEAQAssignmentType
    ? [
        {
          field: 'numberOfAssignedQuestionsAttempted',
          header: '# Assigned Questions Attempted'
        },
        {
          field: 'numberOfSelfStudyQuestionsAttempted',
          header: '# Self-Study Questions Attempted',
          headerCssModifier: selfStudyQuestionsAttemptedColumn
        }
      ]
    : [];
  let columns: TableColumn<StudentEngagementRecord>[] = [
    {
      field: 'studentName',
      header: 'Student',
      customRender: renderName,
      minWidth: !isIncorrectDataHidden ? 250 : 150
    },
    {
      field: 'percentAvgScore',
      header: 'Student Avg Score',
      headerCssModifier: studentAverageScoreColumn,
      customRender: renderStudentAvgScore,
      sortNARecordInBottom: true
    },
    {
      field: 'performanceComparedToClassAvg',
      header: 'Performance Compared to Class Avg',
      customRender: row => renderComparedPerformance(row, avgScoreOfClass),
      cssModifier: 'u-position-relative u-els-padding-none',
      minWidth: 200
    },
    {
      field: 'totalGradePoints',
      header: 'Student Total Grade Points',
      customRender: row => renderStudentTotalGradePoints(row, featureFlags),
      minWidth: 150,
      align: 'right'
    },
    ...eaqAssignmentColumns,
    {
      field: 'avgTimePerQuestionSeconds',
      header: isEAQAssignmentType ? 'Avg Time per Question' : 'Student Avg Time Spent',
      customRender: renderAvgTimePerQuestion
    },
    {
      field: 'avgTimePerQuestionComparedToClassAvgSeconds',
      header: isEAQAssignmentType ? 'Avg Time per Question Compared to Class Avg' : 'Avg Time Spent Compared to Class Avg',
      headerCssModifier: timeSpentComparedToClassAvgColumn,
      customRender: renderAvgTimePerQuestionComparedToClass
    },
    {
      field: 'pastDue',
      header: '# Past Due',
      headerCssModifier: numberOfStudentsWithPastDueColumn,
      customRender: renderPastDue,
      minWidth: 100
    }
  ];
  if (isIncorrectDataHidden) {
    columns = columns.filter(c => c.field !== 'percentAvgScore');
  }
  if (isGradePointsHidden) {
    columns = columns.filter(c => c.field !== 'totalGradePoints');
  }
  if (isOsmosisAssignmentType) {
    columns = columns.filter(c => {
      const { field } = c;
      return field !== 'avgTimePerQuestionSeconds' && field !== 'avgTimePerQuestionComparedToClassAvgSeconds';
    });
  }

  const isPerformanceComparedToClassAvgHidden = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_PERFORMANCE_COMPARED_TO_CLASS_AVERAGE_HIDDEN);
  if (isPerformanceComparedToClassAvgHidden) {
    columns = columns.filter(c => c.field !== 'performanceComparedToClassAvg');
  }

  const tableProps: TableProps<StudentEngagementRecord> = {
    cssModifier: 'u-els-margin-top-2x c-cw-student-engagement-table',
    defaultSortField: 'percentAvgScore',
    defaultSortDirection: SORT_DIRECTION.ASC
  };

  return <ResponsiveTable columns={columns} records={tableData} tableProps={tableProps} />;
};

const mapStateToProps = state => ({
  studentEngagementCardAndTableSortInteraction: cwSelectors.getStudentEngagementCardAndTableSortInteraction(state),
  featureFlags: appSelectors.getFeatureFlags(state)
});

export { StudentEngagementTable as StudentEngagementTableBase };
export default connect(mapStateToProps)(StudentEngagementTable);
