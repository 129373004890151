import { ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { PS_PATHS } from 'reports/ps/constants/ps.constant';

const { INSTRUCTOR } = ROUTE_ROLES;

export const PsRoutes = {
  getRoutes: () => [
    {
      path: PS_PATHS.studentProfile,
      component: lazy(() => import('reports/ps/components/pages/student-profile/PSStudentProfile')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:ps-student-profile`,
          pageProductName: 'ps'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PS_PATHS.courseSummaryPage,
      component: lazy(() => import('reports/ps/components/pages/course-summary/PSCourseSummary')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:ps-course-summary`,
          pageProductName: 'ps'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: PS_PATHS.homePage,
      component: lazy(() => import('reports/ps/components/pages/dashboard/PSDashboard')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:ps-dashboard`,
          pageProductName: 'ps'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
