import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/ducks/app';
import { hadSelectors } from 'reports/had/redux/ducks/had';
import useBentoBoxModal from 'components/common/bento-box-modal/useBentoBoxModal';
import { CourseSelectionModalProps } from 'components/common/course-selection-modal/CourseSelectionModal';
import { REPORT_TITLE } from 'constants/app.constant';

export interface HADNavItems {
  title: string;
  icon: string;
  description: string;
  isDisable: boolean;
  disabledDescription: string;
  onClick?: Function;
}
interface UseHADPageNavigatorModalProps {
  isHesiReadinessApp: boolean;
}

interface UseHADPageNavigatorModalResponse {
  courseSelectionModalProps: CourseSelectionModalProps;
  navItems: HADNavItems[];
  handleCourseSelectionModal: () => void;
  showCourseSelectionModal: boolean;
}

const useHADPageNavigatorModal = ({ isHesiReadinessApp }: UseHADPageNavigatorModalProps): UseHADPageNavigatorModalResponse => {
  const [showCourseSelectionModal, setShowCourseSelectionModal] = useState(false);
  const hadPrograms = useSelector(hadSelectors.getPrograms);
  const selectedCourse = useSelector(appSelectors.getSelectedCourse);
  const courses = useSelector(appSelectors.getCourses);
  const handleCourseSelectionModal = (): void => {
    setShowCourseSelectionModal(!showCourseSelectionModal);
  };
  const { isCWDisable, isEHRDisable, onEHRNavigation, onHADNavigation, onCWNavigation, handleRedirectPage } = useBentoBoxModal({
    courses,
    isHesiReadinessApp,
    hadPrograms,
    handleCourseSelectionModal
  });

  const navItems: HADNavItems[] = useMemo(
    () => [
      {
        ...REPORT_TITLE.SHERPATH_PERFORMANCE_GRADES,
        icon: 'learning-competency',
        isDisable: isCWDisable,
        onClick: onCWNavigation
      },
      {
        ...REPORT_TITLE.HESI_SPECIALTY_EXAM_READINESS,
        icon: 'psychology',
        isDisable: isEHRDisable,
        onClick: onEHRNavigation
      },
      {
        ...REPORT_TITLE.READINESS_DASHBOARD,
        icon: 'empowering-knowledge-c',
        isDisable: true
      },
      {
        ...REPORT_TITLE.HESI_NCLEX_READINESS,
        icon: 'machine-learning--hover',
        isDisable: false,
        onClick: onHADNavigation
      }
    ],
    [isCWDisable, isEHRDisable, onCWNavigation, onEHRNavigation, onHADNavigation]
  );

  const courseSelectionModalProps: CourseSelectionModalProps = {
    onCloseModal: handleCourseSelectionModal,
    courseSections: courses,
    initialCourseId: selectedCourse,
    handleRedirectPage
  };

  return {
    courseSelectionModalProps,
    navItems,
    handleCourseSelectionModal,
    showCourseSelectionModal
  };
};

export default useHADPageNavigatorModal;
