import { ELSCommonUIConstants } from 'components/common';
import { facadeApi } from 'helpers/api.helper';
import { FeatureFlag } from 'models';

export const fetchFeatureFlagWithToken = (key, token, app = ELSCommonUIConstants.appIds.PERFORMANCE_REPORT.toUpperCase()): Promise<{ data: FeatureFlag }> => {
  return facadeApi.get(`/features/app/${app}/key/${key}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const fetchFeatureFlags = (): Promise<{ data: Array<FeatureFlag> }> => facadeApi.get(`/features/app/${ELSCommonUIConstants.appIds.PERFORMANCE_REPORT.toUpperCase()}`);

export const isFeatureEnabled = (featureFlags: Array<FeatureFlag>, featureName?: string, groupId?: string): boolean => {
  if (featureName) {
    const featureFlagByName = (featureFlags || []).filter(flag => flag.featureName === featureName);
    const featureFlagByGroupId = featureFlagByName.find(flag => flag.group === groupId) || featureFlagByName.find(flag => flag.group === 'ALL' || flag.group === null);
    if (featureFlagByGroupId) {
      return featureFlagByGroupId.featureValue === 'true';
    }
  }
  return false;
};
