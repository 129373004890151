import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { RoutePath } from 'constants/app.constant';
import { appActions, appSelectors } from 'redux/ducks/app';
import { ServerConstants } from 'config/server.constant';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { fetchDataBackToExternalApp } from 'services/app-linking.service';

const mapStateToProps = state => ({
  xLinkId: appSelectors.getLinkId(state),
  isBackToPreviousAppIconShown: appSelectors.getIsBackToPreviousAppIconShown(state),
  redirectUrl: appSelectors.getRedirectUrl(state)
});

const mapDispatchToProps = dispatch => ({
  setAppLoading: loading => dispatch(appActions.setAppLoading(loading)),
  setRedirectUrl: (redirectUrl: string) => dispatch(appActions.setRedirectUrl(redirectUrl))
});

export default WrappedComponent => {
  const LoadDataHOC = props => {
    const reactHistory = useHistory();
    const handleBackPreviousAppIconClick = async (): Promise<void> => {
      const { setAppLoading, setRedirectUrl, xLinkId, redirectUrl } = props;
      setAppLoading(true);
      try {
        if (redirectUrl !== RoutePath.reportingPortal && redirectUrl !== HAD_PATHS.dashboard) {
          if (redirectUrl === SH_PATHS.dashboard) {
            const { shadowHealthAppUrl } = ServerConstants[ServerConstants.DataSource];
            window.location.assign(shadowHealthAppUrl);
            return;
          }
          const response = await fetchDataBackToExternalApp(xLinkId);
          const {
            data: { redirectUrl: redirectUrlBackToExternalApp }
          } = response;
          setRedirectUrl(redirectUrlBackToExternalApp);
          await setAppLoading(false);
          window.location.assign(redirectUrlBackToExternalApp);
        } else {
          await setAppLoading(false);
          reactHistory.push(redirectUrl);
        }
      } catch (error) {
        ELSLoggingService.error('PageHeader', `Failed to fetchDataBackToExternalApp: ${error.message}`);
      } finally {
        setAppLoading(false);
      }
    };
    return <WrappedComponent {...props} handleBackPreviousAppIconClick={handleBackPreviousAppIconClick} />;
  };
  return connect(mapStateToProps, mapDispatchToProps)(LoadDataHOC);
};
