import React, { Component } from 'react';
import classNames from 'classnames';
import { ELSIcon } from 'components/common';
import { DropdownOption } from 'models';
import { KEY_CODES } from 'constants/app.constant';

interface ListBoxProps {
  inline?: boolean;
  disabled?: boolean;
  options: Array<DropdownOption>;
  value: string;
  dependedValue?: string;
  hasDependent?: boolean;
  changeHandler: Function;
}

interface ListBoxState {
  opened: boolean;
  optionIdxSelectedByKey: number;
}

export const LIST_BOX_INDEX = {
  PARENT: 0,
  DEPENDENT: 1
};

const INITIAL_OPTION_IDX = -1;

class ListBox extends Component<ListBoxProps, ListBoxState> {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      optionIdxSelectedByKey: INITIAL_OPTION_IDX
    };
  }

  toggleOptions = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState(prevState => ({
      opened: !prevState.opened,
      optionIdxSelectedByKey: INITIAL_OPTION_IDX
    }));
  };

  hideOptions = () => {
    this.setState({
      opened: false,
      optionIdxSelectedByKey: INITIAL_OPTION_IDX
    });
  };

  onListBoxKeyDown = evt => {
    if (this.props.disabled) {
      return;
    }

    const key = evt.keyCode;
    if (key !== KEY_CODES.TAB) {
      evt.preventDefault();
    }

    const max = this.props.options.length - 1;
    switch (key) {
      case KEY_CODES.ENTER: {
        const selectedIdx = this.state.optionIdxSelectedByKey;
        if (selectedIdx !== INITIAL_OPTION_IDX) {
          this.props.changeHandler(this.props.options[this.state.optionIdxSelectedByKey], 0);
        }
        this.toggleOptions();
        break;
      }
      case KEY_CODES.UP: {
        this.setState(prevState => ({
          opened: true,
          optionIdxSelectedByKey: prevState.optionIdxSelectedByKey <= 0 ? max : prevState.optionIdxSelectedByKey - 1
        }));
        break;
      }
      case KEY_CODES.DOWN: {
        this.setState(prevState => ({
          opened: true,
          optionIdxSelectedByKey: prevState.optionIdxSelectedByKey >= max ? 0 : prevState.optionIdxSelectedByKey + 1
        }));
        break;
      }
      default: {
        break;
      }
    }
  };

  onOptionMouseMove = (idx: number) => {
    this.setState({
      optionIdxSelectedByKey: idx
    });
  };

  renderList = (options: Array<DropdownOption>, value: string, listIndex: number) => (
    <ul className={classNames('c-list-box__options-list', { 'c-list-box__options-depended-list': listIndex === LIST_BOX_INDEX.DEPENDENT })}>
      {options.map((option, idx) => {
        const selected = option.value === value;
        const clickHandler = evt => {
          evt.stopPropagation();
          this.props.changeHandler(option, listIndex);
          this.hideOptions();
        };

        return (
          <li
            role="option"
            key={option.value}
            aria-selected={selected}
            className={classNames('c-list-box__options-list-item', {
              'c-list-box__options-list-item--selected': selected,
              'c-list-box__options-list-item--selected-by-key': listIndex === LIST_BOX_INDEX.PARENT && this.state.optionIdxSelectedByKey === idx
            })}
            onClick={clickHandler}
            onKeyDown={clickHandler}
            onMouseMove={() => {
              this.onOptionMouseMove(listIndex === LIST_BOX_INDEX.PARENT ? idx : INITIAL_OPTION_IDX);
            }}
          >
            {option.icon && <ELSIcon customClass="u-els-margin-right-1o2" name={option.icon} size="3o4" prefix={option.iconPrefix} />}
            <span className="c-list-box__options-list-item-text">{option.name}</span>
          </li>
        );
      })}
    </ul>
  );

  renderDependentList = () => {
    const { options, value, dependedValue, hasDependent } = this.props;
    const selectedOption = (options || []).find(option => option.value === value);
    const dependedOptions = selectedOption ? selectedOption.dependedOptions || [] : [];
    const shouldRender = hasDependent && !!dependedOptions.length;
    if (shouldRender) {
      return this.renderList(dependedOptions, dependedValue || dependedOptions[0].value, LIST_BOX_INDEX.DEPENDENT);
    }
    return <></>;
  };

  render() {
    const { options, value, inline, disabled } = this.props;
    const { opened } = this.state;
    const selectedOption = (options || []).find(option => option.value === value);
    return (
      <div
        role="button"
        tabIndex={0}
        className={classNames('c-list-box', { 'c-list-box--opened': opened, 'c-list-box--inline': inline, 'c-list-box--disabled': disabled })}
        onClick={this.toggleOptions}
        onBlur={this.hideOptions}
        onKeyDown={this.onListBoxKeyDown}
      >
        <div className="c-list-box__trigger">
          <span className="o-els-flex-layout__item--grow-1 u-els-padding-right-3o4 c-list-box__display-option">{selectedOption ? selectedOption.name : ''}</span>
          <ELSIcon customClass="c-list-box__trigger-arrow" name="chevron-down" />
        </div>

        {opened && (
          <div role="listbox" className="c-list-box__options">
            {this.renderList(options, value, LIST_BOX_INDEX.PARENT)}
            {this.renderDependentList()}
          </div>
        )}
      </div>
    );
  }
}

export default ListBox;
