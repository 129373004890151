import React from 'react';
import classNames from 'classnames';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { ELSIcon } from '..';

export interface CollapsePanelProps {
  labelKey: string | number;
  header: React.ReactNode;
  className?: string;
  showArrow?: boolean;
  isCollapse?: boolean;
  onClickItem?: Function;
  collapseIconName?: string;
  expandIconName?: string;
}

const CollapsePanel: React.FC<CollapsePanelProps> = props => {
  const { labelKey, header, children, isCollapse = true, onClickItem, showArrow, collapseIconName, expandIconName } = props;
  const contentClassName = classNames('c-els-accordion__body', {
    'c-collapse__panel--collapsed': isCollapse,
    'c-collapse__panel--expanded': !isCollapse
  });

  const renderIcon = isCollapse ? (
    <ELSIcon name={collapseIconName} size="1x" customClass="c-collapse__panel-header-icon" />
  ) : (
    <ELSIcon name={expandIconName} size="1x" customClass="c-collapse__panel-header-icon" />
  );

  const onClickHandler = (): void => onClickItem(labelKey);

  return (
    <li key={labelKey} className="c-els-accordion__group c-els-accordion__group--expanded c-collapse__panel">
      <div className="c-collapse__panel-header" onClick={onClickHandler} onKeyDown={evt => onKeyDownHandler(evt, onClickHandler)} role="presentation">
        <span role="button" tabIndex={0} className="c-collapse__panel-header-button">
          {showArrow && renderIcon}
        </span>
        <span className="c-els-accordion__button-text c-collapse__panel-header-text">{header}</span>
      </div>

      <div className={contentClassName} id="accordion-panel-15" aria-expanded="true" aria-labelledby="accordion-button-15">
        <div className="c-els-accordion__body-content">{children}</div>
      </div>
    </li>
  );
};

export default CollapsePanel;
