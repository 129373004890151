export default [
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6010',
      chapterName: 'Chapter 1, Perspectives of Pediatric Nursing',
      topicId: null,
      topicName: null
    },
    masteryLevel: 2.01658249037638,
    questionsAnswered: 31,
    questionsCorrect: 29,
    questionsConfident: 26,
    percentCorrect: 93.54838709677419,
    timeSpent: 'PT53M14S',
    avgTimeSpentPerQuestion: 'PT1M43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6040',
      chapterName: 'Chapter 2, Social, Cultural, Religious, and Family Influences on Child Health Promotion',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.04087070725715,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 15,
    percentCorrect: 100.0,
    timeSpent: 'PT6M47S',
    avgTimeSpentPerQuestion: 'PT27S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6090',
      chapterName: 'Chapter 3, Developmental and Genetic Influences on Child Health Promotion',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.01124167379269,
    questionsAnswered: 12,
    questionsCorrect: 12,
    questionsConfident: 12,
    percentCorrect: 100.0,
    timeSpent: 'PT13M43S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6101',
      chapterName: 'Chapter 4, Communication and Physical Assessment of the Child and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.057672045432,
    questionsAnswered: 27,
    questionsCorrect: 17,
    questionsConfident: 13,
    percentCorrect: 62.96296296296296,
    timeSpent: 'PT11M36S',
    avgTimeSpentPerQuestion: 'PT25S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6140',
      chapterName: 'Chapter 5, Pain Assessment and Management in Children',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.03077190017239,
    questionsAnswered: 35,
    questionsCorrect: 24,
    questionsConfident: 22,
    percentCorrect: 68.57142857142857,
    timeSpent: 'PT17M3S',
    avgTimeSpentPerQuestion: 'PT29S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6170',
      chapterName: 'Chapter 6, Childhood Communicable and Infectious Disease',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.01116746177559,
    questionsAnswered: 94,
    questionsCorrect: 73,
    questionsConfident: 56,
    percentCorrect: 77.65957446808511,
    timeSpent: 'PT1H50M27S',
    avgTimeSpentPerQuestion: 'PT1M10S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6220',
      chapterName: 'Chapter 7, Health Promotion of the Newborn and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00608378109337,
    questionsAnswered: 95,
    questionsCorrect: 68,
    questionsConfident: 77,
    percentCorrect: 71.57894736842105,
    timeSpent: 'PT2H16M53S',
    avgTimeSpentPerQuestion: 'PT1M26S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6250',
      chapterName: 'Chapter 8, Health Problems of Newborns',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6300',
      chapterName: 'Chapter 9, Health Promotion of the Infant and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.1004370454795,
    questionsAnswered: 108,
    questionsCorrect: 81,
    questionsConfident: 85,
    percentCorrect: 75.0,
    timeSpent: 'PT1H17M39S',
    avgTimeSpentPerQuestion: 'PT43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6330',
      chapterName: 'Chapter 10, Health Problems of Infants',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00580912994155,
    questionsAnswered: 94,
    questionsCorrect: 74,
    questionsConfident: 73,
    percentCorrect: 78.72340425531915,
    timeSpent: 'PT1H47M12S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6360',
      chapterName: 'Chapter 11, Health Promotion of the Toddler and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.07966202417771,
    questionsAnswered: 99,
    questionsCorrect: 74,
    questionsConfident: 80,
    percentCorrect: 74.74747474747475,
    timeSpent: 'PT1H14M10S',
    avgTimeSpentPerQuestion: 'PT44S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6390',
      chapterName: 'Chapter 12, Health Promotion of the Preschooler and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00634162595669,
    questionsAnswered: 81,
    questionsCorrect: 68,
    questionsConfident: 68,
    percentCorrect: 83.95061728395062,
    timeSpent: 'PT1H9M47S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6420',
      chapterName: 'Chapter 13, Health Problems of Toddlers and Preschoolers',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00921656501666,
    questionsAnswered: 82,
    questionsCorrect: 63,
    questionsConfident: 65,
    percentCorrect: 76.82926829268293,
    timeSpent: 'PT39M26S',
    avgTimeSpentPerQuestion: 'PT28S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6460',
      chapterName: 'Chapter 14, Health Promotion of the School-Age Child and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.01697257031866,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 14,
    percentCorrect: 100.0,
    timeSpent: 'PT5M56S',
    avgTimeSpentPerQuestion: 'PT23S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6490',
      chapterName: 'Chapter 15, Health Promotion of the Adolescent and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6520',
      chapterName: 'Chapter 16, Health Problems of School-Age Children and Adolescents',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6550',
      chapterName: 'Chapter 17, Impact of Chronic Illness, Disability, or End-of-Life Care on the Child and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6580',
      chapterName: 'Chapter 18, Impact of Cognitive or Sensory Impairment on the Child and Family',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6610',
      chapterName: 'Chapter 19, Family-Centered Care of the Child During Illness and Hospitalization',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00683484777475,
    questionsAnswered: 108,
    questionsCorrect: 84,
    questionsConfident: 91,
    percentCorrect: 77.77777777777777,
    timeSpent: 'PT2H58M28S',
    avgTimeSpentPerQuestion: 'PT1M39S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'DR0001',
      chapterName: 'Chapter 20, Numbering and Measurement Systems',
      topicId: 'DR0001',
      topicName: null
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6660',
      chapterName: 'Chapter 21, The Child with Respiratory Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6690',
      chapterName: 'Chapter 22, The Child with Gastrointestinal Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.02905860956625,
    questionsAnswered: 20,
    questionsCorrect: 18,
    questionsConfident: 17,
    percentCorrect: 90.0,
    timeSpent: 'PT16M',
    avgTimeSpentPerQuestion: 'PT48S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6730',
      chapterName: 'Chapter 23, The Child with Cardiovascular Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 2.01658249037638,
    questionsAnswered: 31,
    questionsCorrect: 29,
    questionsConfident: 26,
    percentCorrect: 93.54838709677419,
    timeSpent: 'PT53M14S',
    avgTimeSpentPerQuestion: 'PT1M43S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6760',
      chapterName: 'Chapter 24, The Child with Hematologic or Immunologic Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 1.04087070725715,
    questionsAnswered: 15,
    questionsCorrect: 15,
    questionsConfident: 15,
    percentCorrect: 100.0,
    timeSpent: 'PT6M47S',
    avgTimeSpentPerQuestion: 'PT27S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6790',
      chapterName: 'Chapter 25, The Child with Cancer',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.08828122859468,
    questionsAnswered: 104,
    questionsCorrect: 78,
    questionsConfident: 83,
    percentCorrect: 75.0,
    timeSpent: 'PT1H51M12S',
    avgTimeSpentPerQuestion: 'PT1M4S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6830',
      chapterName: 'Chapter 26, The Child with Genitourinary Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.09424608210224,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 70,
    percentCorrect: 75.0,
    timeSpent: 'PT1H22M55S',
    avgTimeSpentPerQuestion: 'PT51S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6860',
      chapterName: 'Chapter 27, The Child with Cerebral Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00683484777475,
    questionsAnswered: 108,
    questionsCorrect: 84,
    questionsConfident: 91,
    percentCorrect: 77.77777777777777,
    timeSpent: 'PT2H58M28S',
    avgTimeSpentPerQuestion: 'PT1M39S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6900',
      chapterName: 'Chapter 28, The Child with Endocrine Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.0081154862402,
    questionsAnswered: 73,
    questionsCorrect: 61,
    questionsConfident: 61,
    percentCorrect: 83.56164383561644,
    timeSpent: 'PT1H37M4S',
    avgTimeSpentPerQuestion: 'PT1M19S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6940',
      chapterName: 'Chapter 29, The Child with Musculoskeletal or Articular Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.01215879036864,
    questionsAnswered: 104,
    questionsCorrect: 69,
    questionsConfident: 89,
    percentCorrect: 66.34615384615384,
    timeSpent: 'PT1H59M16S',
    avgTimeSpentPerQuestion: 'PT1M8S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E6980',
      chapterName: 'Chapter 30, The Child with Neuromuscular or Muscular Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.01147207094266,
    questionsAnswered: 96,
    questionsCorrect: 72,
    questionsConfident: 74,
    percentCorrect: 75.0,
    timeSpent: 'PT1H58M6S',
    avgTimeSpentPerQuestion: 'PT1M13S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  },
  {
    assessmentTopic: {
      topicOrganizationId: 'HOCKESS11E6000',
      topicOrganizationName: 'Hockenberry Essentials 11e Chapter',
      chapterId: 'HOCKESS11E7010',
      chapterName: 'Chapter 31, The Child with Integumentary Dysfunction',
      topicId: null,
      topicName: null
    },
    masteryLevel: 3.00234932872162,
    questionsAnswered: 116,
    questionsCorrect: 85,
    questionsConfident: 82,
    percentCorrect: 73.27586206896552,
    timeSpent: 'PT1H47M15S',
    avgTimeSpentPerQuestion: 'PT55S',
    userId: 6037438,
    userFirstName: 'MacKenzie',
    userLastName: 'Wagner',
    userLastUpdatedDate: '2023-05-04T13:42:17.953+00:00'
  }
];
