import moment from 'moment';

export default {
  courseSectionId: 622453,
  contentType: null,
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597520,
      assignmentName: 'Antibiotic Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 0,
      percentAvgScore: 0,
      avgTimeSpent: 'PT2M45S',
      avgTimeSpentPerQuestion: 'PT33S',
      avgQuestionsAnsweredCorrect: 5,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 12,
      totalStudentsPastDue: 4,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597491,
      assignmentName: 'Safety & Infection Control EAQ (Intermediate)',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT29M',
      avgTimeSpentPerQuestion: 'PT44S',
      avgQuestionsAnsweredCorrect: 40.06666666666667,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597495,
      assignmentName: 'Antiviral Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT2M27S',
      avgTimeSpentPerQuestion: 'PT29S',
      avgQuestionsAnsweredCorrect: 5.066666666666666,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597496,
      assignmentName: 'Antiviral and Antiretroviral Therapy',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.79,
      percentAvgScore: 79,
      avgTimeSpent: 'PT4M21S',
      avgTimeSpentPerQuestion: 'PT15S',
      avgQuestionsAnsweredCorrect: 14.22,
      avgQuestionsAnsweredIncorrect: 3.78,
      percentComplete: 0.75,
      totalStudentsNotStarted: 2,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 12,
      totalStudentsPastDue: 4,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597498,
      assignmentName: 'Psychosocial Integrity EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT2H56M10S',
      avgTimeSpentPerQuestion: 'PT56S',
      avgQuestionsAnsweredCorrect: 188.13333333333333,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597499,
      assignmentName: 'Coagulation Modifier Drugs Pharm EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT2M',
      avgTimeSpentPerQuestion: 'PT24S',
      avgQuestionsAnsweredCorrect: 5,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597500,
      assignmentName: 'Pharmacological & Parenteral Therapies EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT3H14M11S',
      avgTimeSpentPerQuestion: 'PT1M15S',
      avgQuestionsAnsweredCorrect: 155.6875,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597502,
      assignmentName: 'Physiological Adaptation EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT8M43S',
      avgTimeSpentPerQuestion: 'PT1M45S',
      avgQuestionsAnsweredCorrect: 5.3125,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597505,
      assignmentName: 'Respiratory Drugs Pharm EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT3M31S',
      avgTimeSpentPerQuestion: 'PT42S',
      avgQuestionsAnsweredCorrect: 5.1875,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597508,
      assignmentName: 'Reduction of Risk Potential EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT3H8M12S',
      avgTimeSpentPerQuestion: 'PT1M14S',
      avgQuestionsAnsweredCorrect: 152.5625,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-28T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597509,
      assignmentName: 'Antidysrhythmic Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT1M50S',
      avgTimeSpentPerQuestion: 'PT22S',
      avgQuestionsAnsweredCorrect: 5,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-28T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597515,
      assignmentName: 'Care of Patients with Dysrhythmias',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'SHERPATH_CASE_STUDY',
      gradePoints: 100,
      avgScore: 0,
      percentAvgScore: 0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0,
      totalStudentsNotStarted: 16,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597518,
      assignmentName: 'Health Promotion & Maintenance EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT3H6M59S',
      avgTimeSpentPerQuestion: 'PT1M6S',
      avgQuestionsAnsweredCorrect: 170.33333333333334,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597521,
      assignmentName: 'Critical Care Practice HESI',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'AUTHESS',
      gradePoints: 12,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT22M42S',
      avgTimeSpentPerQuestion: 'PT1M54S',
      avgQuestionsAnsweredCorrect: 12,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597481,
      assignmentName: 'Management of Care EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT4H32M15S',
      avgTimeSpentPerQuestion: 'PT56S',
      avgQuestionsAnsweredCorrect: 292.75,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-06-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597488,
      assignmentName: 'Analgesic Therapy ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradePoints: 20,
      avgScore: 1,
      percentAvgScore: 100,
      avgTimeSpent: 'PT15M11S',
      avgTimeSpentPerQuestion: 'PT46S',
      avgQuestionsAnsweredCorrect: 20,
      avgQuestionsAnsweredIncorrect: 0,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-06-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4785643,
      assignmentName: 'Week 4 Quiz- In Class ',
      assignmentGradeType: 'SCORED',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradePoints: 29,
      avgScore: 0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT16M30S',
      avgTimeSpentPerQuestion: 'PT34S',
      avgQuestionsAnsweredCorrect: 0,
      avgQuestionsAnsweredIncorrect: 0.37500000000000155,
      percentComplete: 1,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 16,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: moment()
        .add(2, 'days')
        .toISOString(),
      isHaveScore: false
    }
  ],
  cardData: {
    completedAssignments: 15,
    below85percentAssignments: 1,
    pastDueAssignments: 2,
    totalAssignments: 17,
    classAvgScore: null,
    totalStudents: null,
    pastDueStudents: null,
    belowClassAvgStudents: null,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
