import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

export interface TableRowProps {
  header?: string;
  content?: ReactNode;
  data?: any;
  field?: string;
  customClass?: string;
  customRender?: Function;
  customRenderContent?: Function;
  isActive?: boolean;
}
const TableRow = (props: TableRowProps): ReactElement => {
  const { header, content, data, field, customRenderContent, customRender, isActive } = props;
  const className = classNames('c-table-card__table-row', { [props.customClass]: props.customClass });
  let rowContent = content || data[field];
  const row = data;
  const column = { field: props.field };
  if (customRenderContent) {
    rowContent = props.customRenderContent(row, column, isActive);
  }
  if (customRender) {
    rowContent = customRender(row, column);
    return <div className={className}>{rowContent}</div>;
  }
  return (
    <div className={className}>
      <span className="c-table-card__table-row-header">{header ? `${header}` : ''}</span>
      <span className="c-table-card__table-row-content">{rowContent}</span>
    </div>
  );
};

export default TableRow;
