import { configureHandler } from 'mocks/helpers';
import activeCourseSection from 'mock-data/performancereport/courseSections/active/activeCourseSection';
import courseDetails568749 from './_data/568749';
import courseDetails554121 from './_data/554121';
import courseDetails471204 from './_data/471204';

const data = {
  568749: courseDetails568749,
  554121: courseDetails554121,
  471204: courseDetails471204
};

export default configureHandler(async (req, res, ctx) => {
  const isActive = req.url.searchParams.get('isActive') === 'true';
  const courseId = Number(req.params.courseId);

  if (isActive) {
    return res(ctx.status(200), ctx.json(activeCourseSection));
  }

  if (data[courseId]) {
    return res(ctx.status(200), ctx.json(data[courseId]));
  }

  return req.passthrough();
});
