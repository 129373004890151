import React, { ReactElement, useState, ReactNode } from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { ELSIcon } from '@els/els-component-form-field-react';
import { onKeyDownHandler } from 'helpers/ui.helper';
import TableRow, { TableRowProps } from './table-row/TableRow';

export interface TableRows<T> {
  default: TableRowProps<T>[];
  expanded?: TableRowProps<T>[];
}

interface TableCardProps<T> {
  rows: TableRows<T>;
  title: ReactNode;
  data?: any;
  customExpandRows?: TableRows<T>;
  hideTitleIcon?: boolean;
}

const TableCard = <T extends unknown>(props: TableCardProps<T>): ReactElement => {
  const [isActive, setIsActive] = useState(false);
  const [rows, setRows] = useState(props.rows);
  const onClickHandler = () => {
    setIsActive(prevIsActive => !prevIsActive);
    if (props.customExpandRows) {
      if (isActive) {
        setRows(props.rows);
      } else {
        setRows(props.customExpandRows);
      }
    }
  };

  const renderExpandButton = () => {
    const icon = isActive ? 'chevron-up' : 'chevron-down';
    const expandText = isActive ? 'Show less' : 'Show more';
    return (
      <div role="button" tabIndex={0} onClick={onClickHandler} onKeyDown={evt => onKeyDownHandler(evt, onclick)}>
        <TableRow customClass="c-table-card__expand-button" header={expandText} content={<ELSIcon name={icon} size="1x1o2" />} />
      </div>
    );
  };

  const renderExpandContent = () => {
    const expandedContent = (
      <div className="c-els-accordion c-els-accordion--no-indent">
        <div>
          {isActive && (
            <div className="c-els-accordion__body">
              {rows?.expanded?.map(row => {
                return <TableRow key={uuidv4()} {...row} data={props.data} />;
              })}
            </div>
          )}
          {renderExpandButton()}
        </div>
      </div>
    );
    return rows.expanded ? expandedContent : '';
  };

  return (
    <div className={classnames('c-els-accordion c-table-card', props.data.cssModifier)}>
      <div className="c-table-card__title">
        {props.title}
        {props.hideTitleIcon ? '' : <ELSIcon customClass="u-els-margin-left-3o4 c-table-card__title-arrow-icon" name="chevron-right" size="1x1o2" />}
      </div>
      <div className="c-table-card__content">
        {rows.default.map(row => {
          return <TableRow key={uuidv4()} {...row} data={props.data} isActive={isActive} />;
        })}
        {renderExpandContent()}
      </div>
    </div>
  );
};

export default TableCard;
