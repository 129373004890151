export default {
  id: 568749,
  courseId: '105H1FGK1ST',
  externalId: '169372_hmoots2_6005',
  lmsClientId: null,
  consumerKey: null,
  lmsExternalId: null,
  courseName: 'Clinical Intensive II-NUR412A',
  sectionName: null,
  duration: null,
  institution: {
    id: 50226,
    name: 'Elsevier Education Services',
    externalId: 'Elsevier_Education_Services'
  },
  courseActive: true,
  schedules: null,
  entitlements: [
    {
      id: 86487765,
      isbn: '9780323879705',
      type: 'EPRODUCT',
      data:
        '{"components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780323879705","productTypeName":"Sherpath - Component - NSS","productTypeKey":"sherpath_component_nss","title":"PART - BOC: Sherpath for Fundamentals (Potter Fundamentals Version)","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'sherpath_component_nss',
      parentIsbn: '9780443126093'
    }
  ],
  users: null,
  role: null,
  locked: false,
  copied: true,
  copiedFromId: 554121,
  migrated: false,
  gradeSyncVersion: 2,
  createdAt: '2024-02-16T19:56:52.663+00:00',
  updatedAt: '2024-04-18T14:11:51.965+00:00'
};
