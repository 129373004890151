import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { appSelectors } from 'redux/ducks/app';
import { CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD } from 'constants/app.constant';
import { isFeatureEnabled } from 'services/feature-flag.service';
import { PS_PATHS } from 'reports/ps/constants';
import { checkPathMatchV2 } from 'helpers/app.helper';
import { ELSIconV2 } from 'components/common';

interface InfoIcon {
  id: string;
  customClassName?: string;
  dashboard?: keyof typeof CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD;
}

const InfoIcon = ({ dashboard = 'ps', id = '', customClassName = '' }: InfoIcon) => {
  const isPsDashboard = checkPathMatchV2(Object.values(PS_PATHS));
  const finalDashboard = isPsDashboard ? 'ps' : dashboard;
  const featureFlags = useSelector(appSelectors.getFeatureFlags);
  const isVisible = isFeatureEnabled(featureFlags, CLARITY_DATA_ICON_FEATURE_BY_DASHBOARD[finalDashboard]);

  if (!isVisible) {
    return null;
  }
  return <ELSIconV2 sprite="InformationOutlineCircle" size="xs" id={id} className={cx(`u-els-color-secondary js-pendo-${id}`, { [customClassName]: customClassName.length })} />;
};

export default InfoIcon;
