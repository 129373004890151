export const studentsByEvolveUserName = [
  {
    id: 6952847,
    lmsExternalId: null,
    externalId: '11243515',
    firstName: 'Jessica',
    lastName: 'Allbright',
    username: 'jallbright7',
    emailAddress: 'JAllbright@fakemail.com',
    lastSeenDate: null,
    updatedAt: '2024-05-07T17:09:04.268+00:00',
    type: 'Stu',
    courseSections: null,
    eolsUserHistory: null,
    eolsUserRoles: [],
    migrated: false
  },
  {
    id: 3047711,
    lmsExternalId: null,
    externalId: '8572198',
    firstName: 'Bennie',
    lastName: 'Factor',
    username: 'bfactor',
    emailAddress: 'benniefactor@fakemail.com',
    lastSeenDate: null,
    updatedAt: '2023-03-21T14:11:31.614+00:00',
    type: 'Stu',
    courseSections: null,
    eolsUserHistory: null,
    eolsUserRoles: [],
    migrated: false
  },
  {
    id: 5167001,
    lmsExternalId: null,
    externalId: '10064986',
    firstName: 'Jacquelyn',
    lastName: 'Payeur',
    username: 'jpayeur4',
    emailAddress: 'JacquelynPayeur@fakemail.com',
    lastSeenDate: null,
    updatedAt: '2022-05-18T18:31:14.899+00:00',
    type: 'Stu',
    courseSections: null,
    eolsUserHistory: null,
    eolsUserRoles: [],
    migrated: false
  },
  {
    id: 5846998,
    lmsExternalId: 'null',
    externalId: '10522988',
    firstName: 'Alexander',
    lastName: 'Tushishvili',
    username: 'Tushishvili',
    emailAddress: 'atushishvili@fakemail.com',
    lastSeenDate: null,
    updatedAt: '2023-01-30T15:42:45.533+00:00',
    type: 'Stu',
    courseSections: null,
    eolsUserHistory: null,
    eolsUserRoles: [],
    migrated: false
  },
  {
    id: 6037438,
    lmsExternalId: null,
    externalId: '10653399',
    firstName: 'MacKenzie',
    lastName: 'Wagner',
    username: 'mwagner512',
    emailAddress: 'MWagner@fakemail.com',
    lastSeenDate: null,
    updatedAt: '2023-05-04T13:42:17.953+00:00',
    type: 'Stu',
    courseSections: null,
    eolsUserHistory: null,
    eolsUserRoles: [],
    migrated: false
  }
];
