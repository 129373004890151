export default [
  {
    courseSectionId: 622453,
    studentId: 6952847,
    firstName: 'Jessica',
    lastName: 'Allbright',
    metrics: [
      {
        assignmentId: 4785643,
        title: 'Week 4 Quiz- In Class ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'SCORED',
        gradePoints: 29,
        score: '1',
        timeSpent: 'PT10M14S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T15:15:44.781Z',
        dueDate: '2024-07-19T03:59:00Z'
      },
      {
        assignmentId: 4597508,
        title: 'Reduction of Risk Potential EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H36M52S',
        totalQuestionsAnswered: 149,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-27T01:17:27.98Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597509,
        title: 'Antidysrhythmic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M45S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-27T01:21:34.016Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597505,
        title: 'Respiratory Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT2M30S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T13:33:55.392Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597518,
        title: 'Health Promotion & Maintenance EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H29M44S',
        totalQuestionsAnswered: 129,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-28T00:58:00.908Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597481,
        title: 'Management of Care EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6H8M50S',
        totalQuestionsAnswered: 307,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T23:44:57.156Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597495,
        title: 'Antiviral Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT5M18S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-01T14:23:53.752Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597491,
        title: 'Safety & Infection Control EAQ (Intermediate)',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT23M58S',
        totalQuestionsAnswered: 33,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-01T14:17:55.418Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597520,
        title: 'Antibiotic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT3M59S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-28T01:03:32.163Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597488,
        title: 'Analgesic Therapy ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'PASS_FAIL',
        gradePoints: 20,
        score: '1',
        timeSpent: 'PT34M46S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-29T23:33:48.584Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597521,
        title: 'Critical Care Practice HESI',
        contentType: 'AUTHESS',
        gradeType: 'PASS_FAIL',
        gradePoints: 12,
        score: '1',
        timeSpent: 'PT17M40S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-28T01:22:02.3Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597502,
        title: 'Physiological Adaptation EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T13:30:40.386Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597500,
        title: 'Pharmacological & Parenteral Therapies EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H48M46S',
        totalQuestionsAnswered: 93,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T13:27:30.624Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597498,
        title: 'Psychosocial Integrity EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H27M1S',
        totalQuestionsAnswered: 166,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-07T13:30:41.012Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597499,
        title: 'Coagulation Modifier Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT2M29S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-07T13:35:12.67Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597496,
        title: 'Antiviral and Antiretroviral Therapy',
        contentType: 'LESSONS',
        gradeType: 'PASS_FAIL',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT3M55S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-07-07T13:43:07.572Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597515,
        title: 'Care of Patients with Dysrhythmias',
        contentType: 'SHERPATH_CASE_STUDY',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 622453,
    studentId: 5846998,
    firstName: 'Alexander',
    lastName: 'Tushishvili',
    metrics: [
      {
        assignmentId: 4785643,
        title: 'Week 4 Quiz- In Class ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'SCORED',
        gradePoints: 29,
        score: '1',
        timeSpent: 'PT11M55S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T15:15:39.083Z',
        dueDate: '2024-07-19T03:59:00Z'
      },
      {
        assignmentId: 4597508,
        title: 'Reduction of Risk Potential EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H6M4S',
        totalQuestionsAnswered: 166,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:58:59.71Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597509,
        title: 'Antidysrhythmic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT2M33S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:18:14.675Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597505,
        title: 'Respiratory Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT29S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T12:04:38.438Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597518,
        title: 'Health Promotion & Maintenance EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2H3M50S',
        totalQuestionsAnswered: 95,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-01T12:41:56.912Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597481,
        title: 'Management of Care EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5H43M9S',
        totalQuestionsAnswered: 273,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T12:51:29.328Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597495,
        title: 'Antiviral Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT20S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-07-08T01:48:55.279Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597491,
        title: 'Safety & Infection Control EAQ (Intermediate)',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-07-08T01:49:12.225Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597520,
        title: 'Antibiotic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT38S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:20:31.856Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597488,
        title: 'Analgesic Therapy ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'PASS_FAIL',
        gradePoints: 20,
        score: '1',
        timeSpent: 'PT1M55S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T12:56:36.784Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597521,
        title: 'Critical Care Practice HESI',
        contentType: 'AUTHESS',
        gradeType: 'PASS_FAIL',
        gradePoints: 12,
        score: '1',
        timeSpent: 'PT10M8S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-01T12:29:41.726Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597502,
        title: 'Physiological Adaptation EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2H19M28S',
        totalQuestionsAnswered: 85,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T12:28:52.076Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597500,
        title: 'Pharmacological & Parenteral Therapies EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H14M39S',
        totalQuestionsAnswered: 68,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T12:14:39.201Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597498,
        title: 'Psychosocial Integrity EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H22M57S',
        totalQuestionsAnswered: 150,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-13T03:18:52.946Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597499,
        title: 'Coagulation Modifier Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M3S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-13T03:20:31.273Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597496,
        title: 'Antiviral and Antiretroviral Therapy',
        contentType: 'LESSONS',
        gradeType: 'PASS_FAIL',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M50S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-07-08T01:36:59.317Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597515,
        title: 'Care of Patients with Dysrhythmias',
        contentType: 'SHERPATH_CASE_STUDY',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 622453,
    studentId: 6037438,
    firstName: 'MacKenzie',
    lastName: 'Wagner',
    metrics: [
      {
        assignmentId: 4785643,
        title: 'Week 4 Quiz- In Class ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'SCORED',
        gradePoints: 29,
        score: '1',
        timeSpent: 'PT12M28S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T15:15:43.375Z',
        dueDate: '2024-07-19T03:59:00Z'
      },
      {
        assignmentId: 4597508,
        title: 'Reduction of Risk Potential EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H27M38S',
        totalQuestionsAnswered: 184,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-24T23:39:37.287Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597509,
        title: 'Antidysrhythmic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M55S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-24T23:42:15.471Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597505,
        title: 'Respiratory Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT8M21S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T00:56:57.244Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597518,
        title: 'Health Promotion & Maintenance EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4H21M23S',
        totalQuestionsAnswered: 195,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-30T14:48:33.596Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597481,
        title: 'Management of Care EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT5H5M43S',
        totalQuestionsAnswered: 354,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-24T21:17:42.643Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597495,
        title: 'Antiviral Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M19S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T14:15:37.653Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597491,
        title: 'Safety & Infection Control EAQ (Intermediate)',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT46M39S',
        totalQuestionsAnswered: 69,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T14:11:07.356Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597520,
        title: 'Antibiotic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M24S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-30T14:50:43.283Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597488,
        title: 'Analgesic Therapy ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'PASS_FAIL',
        gradePoints: 20,
        score: '1',
        timeSpent: 'PT17M15S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-26T18:12:41.159Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597521,
        title: 'Critical Care Practice HESI',
        contentType: 'AUTHESS',
        gradeType: 'PASS_FAIL',
        gradePoints: 12,
        score: '1',
        timeSpent: 'PT12M12S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-30T15:03:20.383Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597502,
        title: 'Physiological Adaptation EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T00:47:54.918Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597500,
        title: 'Pharmacological & Parenteral Therapies EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H38M41S',
        totalQuestionsAnswered: 127,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T00:47:14.7Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597498,
        title: 'Psychosocial Integrity EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H15M46S',
        totalQuestionsAnswered: 161,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-07T22:07:19.075Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597499,
        title: 'Coagulation Modifier Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT3M13S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-07T22:11:41.269Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597496,
        title: 'Antiviral and Antiretroviral Therapy',
        contentType: 'LESSONS',
        gradeType: 'PASS_FAIL',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT2M',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T14:13:48.597Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597515,
        title: 'Care of Patients with Dysrhythmias',
        contentType: 'SHERPATH_CASE_STUDY',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 622453,
    studentId: 3047711,
    firstName: 'Bennie',
    lastName: 'Factor',
    metrics: [
      {
        assignmentId: 4785643,
        title: 'Week 4 Quiz- In Class ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'SCORED',
        gradePoints: 29,
        score: '1',
        timeSpent: 'PT13M18S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T15:16:38.795Z',
        dueDate: '2024-07-19T03:59:00Z'
      },
      {
        assignmentId: 4597508,
        title: 'Reduction of Risk Potential EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9H57M18S',
        totalQuestionsAnswered: 182,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-27T20:20:15.634Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597509,
        title: 'Antidysrhythmic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M44S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-25T13:15:50.572Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597505,
        title: 'Respiratory Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT10M6S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T12:31:16.879Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597518,
        title: 'Health Promotion & Maintenance EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H1S',
        totalQuestionsAnswered: 161,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-04T02:32:31.582Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597481,
        title: 'Management of Care EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7H24M59S',
        totalQuestionsAnswered: 328,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T13:44:13.264Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597495,
        title: 'Antiviral Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M57S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-05T23:11:43.131Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597491,
        title: 'Safety & Infection Control EAQ (Intermediate)',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT17M13S',
        totalQuestionsAnswered: 6,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-05T23:35:08.881Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597520,
        title: 'Antibiotic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT2M10S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-04T02:04:30.09Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597488,
        title: 'Analgesic Therapy ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'PASS_FAIL',
        gradePoints: 20,
        score: '1',
        timeSpent: 'PT36M34S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T14:35:45.618Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597521,
        title: 'Critical Care Practice HESI',
        contentType: 'AUTHESS',
        gradeType: 'PASS_FAIL',
        gradePoints: 12,
        score: '1',
        timeSpent: 'PT27M21S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-04T02:00:10.433Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597502,
        title: 'Physiological Adaptation EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T12:31:58.572Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597500,
        title: 'Pharmacological & Parenteral Therapies EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT11H8M24S',
        totalQuestionsAnswered: 123,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:11:12.255Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597498,
        title: 'Psychosocial Integrity EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4H45M39S',
        totalQuestionsAnswered: 146,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-12T14:23:58.773Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597499,
        title: 'Coagulation Modifier Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT1M50S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-12T14:26:30.418Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597496,
        title: 'Antiviral and Antiretroviral Therapy',
        contentType: 'LESSONS',
        gradeType: 'PASS_FAIL',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT13M36S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-05T23:27:04.382Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597515,
        title: 'Care of Patients with Dysrhythmias',
        contentType: 'SHERPATH_CASE_STUDY',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 622453,
    studentId: 5167001,
    firstName: 'Jacqueline',
    lastName: 'Payeur',
    metrics: [
      {
        assignmentId: 4785643,
        title: 'Week 4 Quiz- In Class ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'SCORED',
        gradePoints: 29,
        score: '1',
        timeSpent: 'PT12M56S',
        totalQuestionsAnswered: 29,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T15:15:44.535Z',
        dueDate: '2024-07-19T03:59:00Z'
      },
      {
        assignmentId: 4597508,
        title: 'Reduction of Risk Potential EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT16M52S',
        totalQuestionsAnswered: 73,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:14:11.53Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597509,
        title: 'Antidysrhythmic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT38S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-18T14:15:20.379Z',
        dueDate: '2024-07-28T03:59:00Z'
      },
      {
        assignmentId: 4597505,
        title: 'Respiratory Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT24S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T19:18:30.915Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597518,
        title: 'Health Promotion & Maintenance EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H41S',
        totalQuestionsAnswered: 252,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-31T20:43:19.396Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597481,
        title: 'Management of Care EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT38M30S',
        totalQuestionsAnswered: 112,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-27T12:36:21.185Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597495,
        title: 'Antiviral Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT25S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-02T21:37:20.328Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597491,
        title: 'Safety & Infection Control EAQ (Intermediate)',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M10S',
        totalQuestionsAnswered: 47,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-02T21:30:50.744Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597520,
        title: 'Antibiotic Drugs EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT36S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-01T12:19:24.161Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597488,
        title: 'Analgesic Therapy ',
        contentType: 'QUIZ_BY_QUESTION',
        gradeType: 'PASS_FAIL',
        gradePoints: 20,
        score: '1',
        timeSpent: 'PT6M53S',
        totalQuestionsAnswered: 20,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-06-28T01:33:45.961Z',
        dueDate: '2024-06-30T03:59:00Z'
      },
      {
        assignmentId: 4597521,
        title: 'Critical Care Practice HESI',
        contentType: 'AUTHESS',
        gradeType: 'PASS_FAIL',
        gradePoints: 12,
        score: '1',
        timeSpent: 'PT17M30S',
        totalQuestionsAnswered: 12,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-08-01T12:37:24.497Z',
        dueDate: '2024-08-04T03:59:00Z'
      },
      {
        assignmentId: 4597502,
        title: 'Physiological Adaptation EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T19:17:40.301Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597500,
        title: 'Pharmacological & Parenteral Therapies EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT35M23S',
        totalQuestionsAnswered: 194,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-16T19:17:10.754Z',
        dueDate: '2024-07-21T03:59:00Z'
      },
      {
        assignmentId: 4597498,
        title: 'Psychosocial Integrity EAQ ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M54S',
        totalQuestionsAnswered: 187,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-08T20:16:36.318Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597499,
        title: 'Coagulation Modifier Drugs Pharm EAQ ',
        contentType: 'STANDARD',
        gradeType: 'PASS_FAIL',
        gradePoints: 5,
        score: '1',
        timeSpent: 'PT49S',
        totalQuestionsAnswered: 5,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-08T20:18:10.059Z',
        dueDate: '2024-07-14T03:59:00Z'
      },
      {
        assignmentId: 4597496,
        title: 'Antiviral and Antiretroviral Therapy',
        contentType: 'LESSONS',
        gradeType: 'PASS_FAIL',
        gradePoints: 100,
        score: '1',
        timeSpent: 'PT1M6S',
        totalQuestionsAnswered: 18,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-07-02T21:36:30.794Z',
        dueDate: '2024-07-07T03:59:00Z'
      },
      {
        assignmentId: 4597515,
        title: 'Care of Patients with Dysrhythmias',
        contentType: 'SHERPATH_CASE_STUDY',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  }
];
