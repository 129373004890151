export default [
  {
    courseSectionId: 516809,
    studentId: 3047711,
    firstName: 'Bennie',
    lastName: 'Factor',
    metrics: [
      {
        assignmentId: 3678953,
        title: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2H13M8S',
        totalQuestionsAnswered: 117,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-20T15:54:27.725Z',
        dueDate: '2024-04-21T03:59:00Z'
      },
      {
        assignmentId: 3645907,
        title: 'NUR 398A Pediatric Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT50M29S',
        totalQuestionsAnswered: 78,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-08T01:35:48.37Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3645906,
        title: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT35M49S',
        totalQuestionsAnswered: 60,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-04T00:41:52.539Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3645904,
        title: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H24M56S',
        totalQuestionsAnswered: 65,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-04T00:19:36.195Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3678945,
        title: 'NUR 398A Renal - Novice, recommend Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT33M42S',
        totalQuestionsAnswered: 71,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-15T00:33:34.014Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645916,
        title: 'NUR 398A Endocrine - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H9M36S',
        totalQuestionsAnswered: 94,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-19T02:21:35.933Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3645914,
        title: 'NUR 398A Gastrointestinal - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: null,
        timeSpent: 'PT39M22S',
        totalQuestionsAnswered: 48,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645912,
        title: 'NUR 398A Medical-Surgical Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H49M43S',
        totalQuestionsAnswered: 172,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-08T00:00:54.375Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3678951,
        title: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT4H3M39S',
        totalQuestionsAnswered: 268,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-18T14:48:17.865Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3734860,
        title: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734857,
        title: 'Pharmacokinetics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734858,
        title: 'Pharmacodynamics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734859,
        title: 'Analgesics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734861,
        title: 'Anticonvulsant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734862,
        title: 'Antidepressant and Mood Stabilizer Drug Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 516809,
    studentId: 5846998,
    firstName: 'Alexander',
    lastName: 'Tushishvili',
    metrics: [
      {
        assignmentId: 3678953,
        title: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: null,
        timeSpent: 'PT2M',
        totalQuestionsAnswered: 2,
        progressStatus: 'IN_PROGRESS',
        pastDue: true,
        submissionDate: null,
        dueDate: '2024-04-21T03:59:00Z'
      },
      {
        assignmentId: 3645907,
        title: 'NUR 398A Pediatric Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT16M',
        totalQuestionsAnswered: 69,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-07T17:53:35.576Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3645906,
        title: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT52M7S',
        totalQuestionsAnswered: 86,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-03T04:02:29.326Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3645904,
        title: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H15M14S',
        totalQuestionsAnswered: 86,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-03T03:05:19.35Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3678945,
        title: 'NUR 398A Renal - Novice, recommend Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT7M31S',
        totalQuestionsAnswered: 86,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-14T04:35:57.09Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645916,
        title: 'NUR 398A Endocrine - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT44M41S',
        totalQuestionsAnswered: 139,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-17T21:17:40.457Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3645914,
        title: 'NUR 398A Gastrointestinal - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H51S',
        totalQuestionsAnswered: 331,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-14T05:39:30.213Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645912,
        title: 'NUR 398A Medical-Surgical Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H15M10S',
        totalQuestionsAnswered: 205,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-05T01:39:56.118Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3678951,
        title: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H20M45S',
        totalQuestionsAnswered: 225,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-17T20:39:50.388Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3734860,
        title: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734857,
        title: 'Pharmacokinetics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734858,
        title: 'Pharmacodynamics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734859,
        title: 'Analgesics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734861,
        title: 'Anticonvulsant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734862,
        title: 'Antidepressant and Mood Stabilizer Drug Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 516809,
    studentId: 5167001,
    firstName: 'Jacquelyn',
    lastName: 'Payeur',
    metrics: [
      {
        assignmentId: 3678953,
        title: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT25M57S',
        totalQuestionsAnswered: 190,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-16T14:35:46.752Z',
        dueDate: '2024-04-21T03:59:00Z'
      },
      {
        assignmentId: 3645907,
        title: 'NUR 398A Pediatric Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT9M1S',
        totalQuestionsAnswered: 87,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-07T08:53:31.222Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3645906,
        title: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT6M57S',
        totalQuestionsAnswered: 56,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-01T17:14:27.521Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3645904,
        title: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT12M19S',
        totalQuestionsAnswered: 64,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-01T17:04:43.882Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3678945,
        title: 'NUR 398A Renal - Novice, recommend Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT8M10S',
        totalQuestionsAnswered: 37,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-08T16:21:28.555Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645916,
        title: 'NUR 398A Endocrine - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT34M59S',
        totalQuestionsAnswered: 182,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-14T23:37:29.453Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3645914,
        title: 'NUR 398A Gastrointestinal - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT40M15S',
        totalQuestionsAnswered: 193,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-08T17:14:30.13Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645912,
        title: 'NUR 398A Medical-Surgical Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT18M38S',
        totalQuestionsAnswered: 77,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-07T08:41:08.309Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3678951,
        title: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H43M58S',
        totalQuestionsAnswered: 310,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-11T18:00:40.909Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3734860,
        title: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734857,
        title: 'Pharmacokinetics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734858,
        title: 'Pharmacodynamics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734859,
        title: 'Analgesics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734861,
        title: 'Anticonvulsant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734862,
        title: 'Antidepressant and Mood Stabilizer Drug Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 516809,
    studentId: 6037438,
    firstName: 'MacKenzie',
    lastName: 'Wagner',
    metrics: [
      {
        assignmentId: 3678953,
        title: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT58M19S',
        totalQuestionsAnswered: 123,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-19T00:12:13.346Z',
        dueDate: '2024-04-21T03:59:00Z'
      },
      {
        assignmentId: 3645907,
        title: 'NUR 398A Pediatric Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H20M10S',
        totalQuestionsAnswered: 129,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-05T18:59:00.217Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3645906,
        title: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT48M19S',
        totalQuestionsAnswered: 159,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-03T00:46:11.747Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3645904,
        title: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H12M29S',
        totalQuestionsAnswered: 106,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-02T22:45:54.685Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3678945,
        title: 'NUR 398A Renal - Novice, recommend Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT48M3S',
        totalQuestionsAnswered: 85,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-11T19:39:35.505Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645916,
        title: 'NUR 398A Endocrine - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT51M30S',
        totalQuestionsAnswered: 118,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-16T16:42:08.748Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3645914,
        title: 'NUR 398A Gastrointestinal - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H32M43S',
        totalQuestionsAnswered: 211,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-12T18:20:47.226Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645912,
        title: 'NUR 398A Medical-Surgical Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT58M46S',
        totalQuestionsAnswered: 170,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-05T20:14:36.534Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3678951,
        title: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H17M59S',
        totalQuestionsAnswered: 285,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-16T18:28:26.143Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3734860,
        title: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734857,
        title: 'Pharmacokinetics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734858,
        title: 'Pharmacodynamics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734859,
        title: 'Analgesics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734861,
        title: 'Anticonvulsant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734862,
        title: 'Antidepressant and Mood Stabilizer Drug Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  },
  {
    courseSectionId: 516809,
    studentId: 6952847,
    firstName: 'Jessica',
    lastName: 'Allbright',
    metrics: [
      {
        assignmentId: 3678953,
        title: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H13M51S',
        totalQuestionsAnswered: 121,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-18T23:29:17.889Z',
        dueDate: '2024-04-21T03:59:00Z'
      },
      {
        assignmentId: 3645907,
        title: 'NUR 398A Pediatric Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT2H16M37S',
        totalQuestionsAnswered: 87,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-07T17:12:28.071Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3645906,
        title: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H7S',
        totalQuestionsAnswered: 106,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-02T21:41:14.676Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3645904,
        title: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H20M27S',
        totalQuestionsAnswered: 92,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-02T20:31:14.551Z',
        dueDate: '2024-04-04T03:59:00Z'
      },
      {
        assignmentId: 3678945,
        title: 'NUR 398A Renal - Novice, recommend Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT36M15S',
        totalQuestionsAnswered: 48,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-13T16:27:04.094Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645916,
        title: 'NUR 398A Endocrine - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H16M20S',
        totalQuestionsAnswered: 93,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-16T16:48:02.405Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3645914,
        title: 'NUR 398A Gastrointestinal - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT1H47M44S',
        totalQuestionsAnswered: 135,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-13T03:12:43.08Z',
        dueDate: '2024-04-14T03:59:00Z'
      },
      {
        assignmentId: 3645912,
        title: 'NUR 398A Medical-Surgical Drugs - Novice',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H41M',
        totalQuestionsAnswered: 197,
        progressStatus: 'COMPLETED',
        pastDue: true,
        submissionDate: '2024-04-08T17:23:25.781Z',
        dueDate: '2024-04-08T03:59:00Z'
      },
      {
        assignmentId: 3678951,
        title: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
        contentType: 'MASTERY',
        gradeType: 'PASS_FAIL',
        gradePoints: 1,
        score: '1',
        timeSpent: 'PT3H25M7S',
        totalQuestionsAnswered: 267,
        progressStatus: 'COMPLETED',
        pastDue: false,
        submissionDate: '2024-04-17T02:00:56.353Z',
        dueDate: '2024-04-18T03:59:00Z'
      },
      {
        assignmentId: 3734860,
        title: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734857,
        title: 'Pharmacokinetics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734858,
        title: 'Pharmacodynamics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734859,
        title: 'Analgesics',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734861,
        title: 'Anticonvulsant Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      },
      {
        assignmentId: 3734862,
        title: 'Antidepressant and Mood Stabilizer Drug Therapy',
        contentType: 'OSMOSIS_VIDEO',
        gradeType: 'NOT_GRADED',
        gradePoints: 100,
        score: 'null',
        timeSpent: 'PT0S',
        totalQuestionsAnswered: 0,
        progressStatus: 'NOT_STARTED',
        pastDue: false,
        submissionDate: null,
        dueDate: null
      }
    ]
  }
];
