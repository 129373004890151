import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { ELSTokenHelper } from 'components/common';
import { SystemType } from 'models';
import { appActions, appSelectors } from 'redux/ducks/app';
import { checkPathMatchV2 } from 'helpers/app.helper';
import { fetchCrosswalkUser } from 'services/user-management.service';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { hadActions } from 'reports/had/redux/ducks/had';
import { PS_PATHS } from 'reports/ps/constants';
import { psActions } from 'reports/ps/redux/ducks/ps';

// 3395138 is demo user id (in HRND)
const demoEvolveUserId = 3395138;
const CROSSWALK_STALE_TIME = 1000 * 60;
const DemoInit = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(appSelectors.getLoggedInUser);
  const evolveUser = useSelector(appSelectors.getEvolveUser);
  const { isMaskUserData } = loggedInUser?.appParams || {};

  let userData = {
    userId: null,
    id: null
  };
  const token = ELSTokenHelper.getToken();
  if (token) {
    userData = ELSTokenHelper.getUserInfoFromToken(token);
  }

  const isHadPath = checkPathMatchV2([...Object.values(HAD_PATHS)]);
  const isPsPath = checkPathMatchV2([...Object.values(PS_PATHS)]);
  const eolsId = isHadPath && isMaskUserData ? demoEvolveUserId : userData.userId || userData.id;

  const enabledCrosswalk = useMemo(() => {
    if (!token || !eolsId || !evolveUser) {
      return false;
    }
    const { eolsUserIdsByUserNameAndType = [] } = evolveUser || {};
    const isEolsIdMatched = eolsUserIdsByUserNameAndType.includes(eolsId);
    if (isHadPath && !isEolsIdMatched) {
      return true;
    }
    if (!isHadPath && !isEolsIdMatched) {
      return true;
    }
    return false;
  }, [eolsId, evolveUser, isHadPath, token]);

  const condition = enabledCrosswalk && !!isMaskUserData;

  const { data } = useQuery({
    queryFn: () => fetchCrosswalkUser(eolsId, SystemType.EVOLVETYPE),
    queryKey: ['crosswalkUser', 'HRND', eolsId, SystemType.EVOLVETYPE],
    enabled: !!condition,
    keepPreviousData: true,
    staleTime: CROSSWALK_STALE_TIME
  });

  const onCrosswalkUserSuccess = useCallback(
    async res => {
      await dispatch(appActions.setEvolveUsername(res?.data?.userName));
      dispatch(hadActions.setPrograms([]));
      dispatch(appActions.setEvolveUser(res?.data));
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    if (data) {
      onCrosswalkUserSuccess(data);
    }
  }, [data, onCrosswalkUserSuccess]);

  useQuery({
    queryKey: ['crosswalkUser', 'PS', eolsId, SystemType.EVOLVETYPE],
    queryFn: () => fetchCrosswalkUser(String(demoEvolveUserId), SystemType.EVOLVETYPE),
    enabled: isPsPath && !!isMaskUserData,
    onSuccess: res => {
      dispatch(psActions.setHadDemoEvolveUsername(res?.data?.userName));
    }
  });

  return null;
};

export default DemoInit;
