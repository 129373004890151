export default [
  {
    courseSectionId: 568749,
    completedSelfStudyQuestionsEAQ: 465,
    disengagedStudents: 2,
    totalStudent: 16,
    engagementByAssignmentTypes: [
      {
        assignmentType: 'MASTERY',
        totalCompletedAssignment: 7,
        totalAssignments: 7,
        totalAssignmentsPastDue: 7
      },
      {
        assignmentType: 'LESSONS',
        totalCompletedAssignment: 1,
        totalAssignments: 1,
        totalAssignmentsPastDue: 1
      },
      {
        assignmentType: 'SHERPATH_CASE_STUDY',
        totalCompletedAssignment: 0,
        totalAssignments: 1,
        totalAssignmentsPastDue: 0
      },
      {
        assignmentType: 'QUIZ_BY_QUESTION',
        totalCompletedAssignment: 2,
        totalAssignments: 2,
        totalAssignmentsPastDue: 2
      },
      {
        assignmentType: 'STANDARD',
        totalCompletedAssignment: 5,
        totalAssignments: 5,
        totalAssignmentsPastDue: 5
      },
      {
        assignmentType: 'AUTHESS',
        totalCompletedAssignment: 1,
        totalAssignments: 1,
        totalAssignmentsPastDue: 1
      }
    ],
    studentOverallScores: [
      {
        eolsId: 6943186,
        overallScore: 0.47668393782383417
      },
      {
        eolsId: 6957833,
        overallScore: 0.48186528497409326
      },
      {
        eolsId: 6958947,
        overallScore: 1.0
      },
      {
        eolsId: 6959175,
        overallScore: 1.0
      },
      {
        eolsId: 6959245,
        overallScore: 0.9948186528497409
      },
      {
        eolsId: 6959103,
        overallScore: 0.9689119170984456
      },
      {
        eolsId: 6950285,
        overallScore: 1.0
      },
      {
        eolsId: 6959640,
        overallScore: 0.48186528497409326
      },
      {
        eolsId: 6957200,
        overallScore: 1.0
      },
      {
        eolsId: 6953650,
        overallScore: 0.9844559585492227
      },
      {
        eolsId: 6958943,
        overallScore: 1.0
      },
      {
        eolsId: 6958053,
        overallScore: 1.0
      },
      {
        eolsId: 6959073,
        overallScore: 1.0
      },
      {
        eolsId: 6959069,
        overallScore: 0.927461139896373
      },
      {
        eolsId: 6943054,
        overallScore: 0.45077720207253885
      },
      {
        eolsId: 6957376,
        overallScore: 1.0
      }
    ]
  }
];
