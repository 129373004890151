import React from 'react';
import classNames from 'classnames';
import { ELSTextBox } from '@els/els-component-form-field-react';

export interface SearchBarProps<T> {
  id?: string;
  value: T;
  placeholder?: string;
  handleSearch?: Function;
  focusHandler?: Function;
  customClass?: string;
  iconLeft?: string;
  iconLeftPrefix?: string;
}

const SearchBar = <T,>({ id, value, handleSearch, focusHandler, customClass, iconLeft = 'search', iconLeftPrefix = 'gizmo', placeholder = '' }: SearchBarProps<T>) => {
  return (
    <ELSTextBox
      id={id}
      iconLeft={iconLeft}
      iconLeftPrefix={iconLeftPrefix}
      placeholder={placeholder}
      value={value}
      changeHandler={handleSearch}
      cssClasses={classNames('c-search-bar', customClass)}
      focusHandler={focusHandler}
    />
  );
};

export default SearchBar;
