const dueDate = new Date().toISOString();

export default {
  metrics: [
    {
      assignmentId: 4597520,
      assignmentName: 'Antibiotic Drugs EAQ ',
      assignmentType: 'STANDARD',
      dueDate: dueDate,
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M45S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT13M13S',
      minTimeSpent: 'PT36S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597491,
      assignmentName: 'Safety & Infection Control EAQ (Intermediate)',
      assignmentType: 'MASTERY',
      dueDate: dueDate,
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT2H16M39S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597495,
      assignmentName: 'Antiviral Drugs EAQ ',
      assignmentType: 'STANDARD',
      dueDate: '2024-07-07T03:59:00Z',
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M27S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT10M20S',
      minTimeSpent: 'PT25S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597496,
      assignmentName: 'Antiviral and Antiretroviral Therapy',
      assignmentType: 'LESSONS',
      dueDate: '2024-07-07T03:59:00Z',
      percentComplete: 0.75,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 2,
      gradePoints: 100,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT4M21S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 4,
      studyMode: false,
      maxTimeSpent: 'PT13M36S',
      minTimeSpent: 'PT1M6S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597498,
      assignmentName: 'Psychosocial Integrity EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-07-14T03:59:00Z',
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2H56M10S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT5H41M22S',
      minTimeSpent: 'PT33M54S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597499,
      assignmentName: 'Coagulation Modifier Drugs Pharm EAQ ',
      assignmentType: 'STANDARD',
      dueDate: '2024-07-14T03:59:00Z',
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT3M19S',
      minTimeSpent: 'PT46S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597500,
      assignmentName: 'Pharmacological & Parenteral Therapies EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-07-21T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H14M11S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT11H8M24S',
      minTimeSpent: 'PT35M23S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597502,
      assignmentName: 'Physiological Adaptation EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-07-21T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT8M43S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT2H19M28S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597505,
      assignmentName: 'Respiratory Drugs Pharm EAQ ',
      assignmentType: 'STANDARD',
      dueDate: '2024-07-21T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3M31S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT14M26S',
      minTimeSpent: 'PT24S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597508,
      assignmentName: 'Reduction of Risk Potential EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-07-28T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H8M12S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT9H57M18S',
      minTimeSpent: 'PT16M52S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597509,
      assignmentName: 'Antidysrhythmic Drugs EAQ ',
      assignmentType: 'STANDARD',
      dueDate: '2024-07-28T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1M50S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT3M56S',
      minTimeSpent: 'PT38S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597515,
      assignmentName: 'Care of Patients with Dysrhythmias',
      assignmentType: 'SHERPATH_CASE_STUDY',
      dueDate: null,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 16,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597518,
      assignmentName: 'Health Promotion & Maintenance EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-08-04T03:59:00Z',
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H6M59S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT8H5M4S',
      minTimeSpent: 'PT1H41S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597521,
      assignmentName: 'Critical Care Practice HESI',
      assignmentType: 'AUTHESS',
      dueDate: '2024-08-04T03:59:00Z',
      percentComplete: 0.9375,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 12,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M42S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 1,
      studyMode: true,
      maxTimeSpent: 'PT54M35S',
      minTimeSpent: 'PT6M31S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597481,
      assignmentName: 'Management of Care EAQ ',
      assignmentType: 'MASTERY',
      dueDate: '2024-06-30T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT4H32M15S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT10H8M34S',
      minTimeSpent: 'PT0S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4597488,
      assignmentName: 'Analgesic Therapy ',
      assignmentType: 'QUIZ_BY_QUESTION',
      dueDate: '2024-06-30T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 20,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT15M11S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 16,
      studentsBelowAvgScore: 0,
      studyMode: true,
      maxTimeSpent: 'PT36M34S',
      minTimeSpent: 'PT1M55S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 4785643,
      assignmentName: 'Week 4 Quiz- In Class ',
      assignmentType: 'QUIZ_BY_QUESTION',
      dueDate: '2024-07-19T03:59:00Z',
      percentComplete: 1.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 0,
      gradePoints: 29,
      avgScore: 0.9870689655172413,
      percentAvgScore: 98.70689655172413,
      avgTimeSpent: 'PT16M30S',
      assignmentGradeType: 'SCORED',
      totalStudents: 16,
      studentsBelowAvgScore: 4,
      studyMode: true,
      maxTimeSpent: 'PT1H5M22S',
      minTimeSpent: 'PT10M14S',
      isPastDueAssignment: false
    }
  ],
  card: {
    totalAssignments: 17,
    totalCompletedAssignments: 16,
    highPerformanceStudents: 14,
    totalStudents: 16,
    disengagedStudents: 2
  }
};
