import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import Immutable from 'seamless-immutable';
import { AppConstant, SORT_DIRECTION, FULFILLED_STATUS } from 'constants/app.constant';
import { SystemType, EOLSUser } from 'models';
import { mergeAndSumByCourseId } from 'reports/ps/helpers';
import { fetchAllCourseAggregations } from 'reports/ps/services/ps.service';
import { fetchExternalUserInfo, fetchCrosswalkUser } from 'services/user-management.service';
import { getSortedCourseByAverageScore } from 'reports/ps/components/pages/student-profile/sherpath-assignments-tab/SherpathAssignmentTabHelper';

const getPSState = state => state[AppConstant.reduxResources.PS_STATE];

export const initialState = Immutable({
  isSherpathCourseListLoading: true,
  hadDemoEvolveUsername: null,
  selectedProgramId: null,
  selectedExamId: null,
  selectedStudentDetail: {} as EOLSUser,
  selectedStudentId: null,
  selectedSHStudentId: 3,
  sherpathCourseList: [],
  ngnItems: [],
  sherpathAssignmentsCardAndTabSortInteraction: null,
  sHAssignmentsCardAndTabSortInteraction: null
});

const prefixActionName = 'PS';
const setSelectedStudentId = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_ID`);
const setHadDemoEvolveUsername = createAction(`${prefixActionName}/SET_HAD_DEMO_EVOLVE_USERNAME`);
const setSelectedSHStudentId = createAction(`${prefixActionName}/SET_SELECTED_SH_STUDENT_ID`);
const setSherpathCourseList = createAction(`${prefixActionName}/SET_SHERPATH_COURSE_LIST`);
const setIsSherpathCourseListLoading = createAction(`${prefixActionName}/SET_IS_SHERPATH_COURSE_LIST_LOADING`);
const restoreState = createAction(`${prefixActionName}/RESTORE`);
const setSelectedStudentDetail = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_DETAIL`);
const setSelectedExamMixedId = createAction(`${prefixActionName}/SET_SELECTED_EXAM_MIXED_ID`);
const setSelectedProgramId = createAction(`${prefixActionName}/SET_SELECTED_PROGRAM_ID`);
const setSherpathAssignmentsCardAndTabSortInteraction = createAction(`${prefixActionName}/SET_SHERPATH_ASSIGNMENTS_CARD_AND_TAB_SORT_INTERACTION`);
const setSHAssignmentsCardAndTabSortInteraction = createAction(`${prefixActionName}/SET_SH_ASSIGNMENTS_CARD_AND_TAB_SORT_INTERACTION`);

const setUserIdFromEvolveUsername = (evolveUsername: string) => async dispatch => {
  await fetchExternalUserInfo(evolveUsername)
    .then(res => {
      if (!isEmpty(res.data)) {
        dispatch(setSelectedStudentId(res.data.id));
      } else {
        dispatch(setSelectedStudentId(null));
      }
    })
    .catch(() => {
      dispatch(setSelectedStudentId(null));
    });
};

export const getSherpathCourseListData = (selectedStudentId: number) => async dispatch => {
  dispatch(setIsSherpathCourseListLoading(true));
  try {
    if (selectedStudentId) {
      const {
        data: { eolsUserIdsByUserNameAndType }
      } = await fetchCrosswalkUser(selectedStudentId.toString(), SystemType.EVOLVETYPE);
      let courseList = [];
      const fetchPromise = eolsUserIdsByUserNameAndType.map(eolsUserId => {
        return fetchAllCourseAggregations(eolsUserId);
      });
      const fetchResult = await Promise.allSettled(fetchPromise);
      fetchResult.forEach(res => {
        if (res.status === FULFILLED_STATUS) {
          courseList = [...courseList, ...res.value.data.content];
        }
      });
      courseList = mergeAndSumByCourseId(courseList);
      const defaultSortedCourseList = courseList.slice(0).sort((a, b) => getSortedCourseByAverageScore(a, b, SORT_DIRECTION.ASC));
      dispatch(setSherpathCourseList(defaultSortedCourseList));
    } else {
      dispatch(setSherpathCourseList([]));
    }
  } catch (error) {
    dispatch(setSherpathCourseList([]));
  } finally {
    dispatch(setIsSherpathCourseListLoading(false));
  }
};

const actions = {
  setSelectedStudentId,
  setSelectedSHStudentId,
  setSherpathCourseList,
  setIsSherpathCourseListLoading,
  restoreState,
  setUserIdFromEvolveUsername,
  setSelectedStudentDetail,
  setSelectedExamMixedId,
  setSelectedProgramId,
  setSherpathAssignmentsCardAndTabSortInteraction,
  setSHAssignmentsCardAndTabSortInteraction,
  setHadDemoEvolveUsername
};

const reducer = handleActions(
  {
    [actions.setSherpathCourseList]: (state, { payload }) => Immutable.set(state, 'sherpathCourseList', payload),
    [actions.setSelectedStudentId]: (state, { payload }) => Immutable.set(state, 'selectedStudentId', payload),
    [actions.setSelectedSHStudentId]: (state, { payload }) => Immutable.set(state, 'selectedSHStudentId', payload),
    [actions.setIsSherpathCourseListLoading]: (state, { payload }) => Immutable.set(state, 'isSherpathCourseListLoading', payload),
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setSelectedStudentDetail]: (state, { payload }) => Immutable.set(state, 'selectedStudentDetail', payload),
    [actions.setSelectedExamMixedId]: (state, { payload }) => Immutable.set(state, 'selectedExamId', payload),
    [actions.setSelectedProgramId]: (state, { payload }) => Immutable.set(state, 'selectedProgramId', payload),
    [actions.setSherpathAssignmentsCardAndTabSortInteraction]: (state, { payload }) => Immutable.set(state, 'sherpathAssignmentsCardAndTabSortInteraction', payload),
    [actions.setSHAssignmentsCardAndTabSortInteraction]: (state, { payload }) => Immutable.set(state, 'sHAssignmentsCardAndTabSortInteraction', payload),
    [actions.setHadDemoEvolveUsername]: (state, { payload }) => Immutable.set(state, 'hadDemoEvolveUsername', payload)
  },
  initialState
);

const selectors = {
  getSelectedStudentId: createSelector(getPSState, state => state.selectedStudentId),
  getDemoHadEvolveUsername: createSelector(getPSState, state => state.hadDemoEvolveUsername),
  getSelectedSHStudentId: createSelector(getPSState, state => state.selectedSHStudentId),
  getSherpathCourseList: createSelector(getPSState, state => state.sherpathCourseList),
  getIsSherpathCourseListLoading: createSelector(getPSState, state => state.isSherpathCourseListLoading),
  getSelectedStudentDetail: createSelector(getPSState, state => state.selectedStudentDetail),
  getSelectedExamId: createSelector(getPSState, state => state.selectedExamId),
  getSelectedProgramId: createSelector(getPSState, state => state.selectedProgramId),
  getNGNItems: createSelector(getPSState, state => state.ngnItems),
  getSherpathAssignmentsCardAndTabSortInteraction: createSelector(getPSState, state => state.sherpathAssignmentsCardAndTabSortInteraction),
  getSHAssignmentsCardAndTabSortInteraction: createSelector(getPSState, state => state.sHAssignmentsCardAndTabSortInteraction)
};

export { actions as psActions, reducer as psReducer, selectors as psSelectors };
export { initialState as psInitialState };
