import React, { ReactElement } from 'react';
import classNames from 'classnames';

interface ReportContentProps {
  children: ReactElement | ReactElement[];
  customClass?: string;
}

const ReportContent = (props: ReportContentProps): ReactElement => {
  return (
    <div className={classNames('o-els-flex-layout__item--grow-1 u-max-width-1400 u-els-padding-1x1o2 u-els-width-1o1 c-cw-report-content', props.customClass)}>
      {props.children}
    </div>
  );
};

export default ReportContent;
