import { whiteListedDemoUsername } from 'mocks/demo-prod/whitelist';
import { configureHandler } from 'mocks/helpers';
import shadowEvolveUser from './_data/itesting';

export default configureHandler(async (req, res, ctx) => {
  const evolveUserName = req.params.evolveUserName;
  if (evolveUserName !== whiteListedDemoUsername) {
    return req.passthrough();
  }

  return res(ctx.status(200), ctx.json(shadowEvolveUser));
});
