export default {
  courseSectionId: 622453,
  studentId: 6037438,
  firstName: 'MacKenzie',
  lastName: 'Wagner',
  topicCount: 116,
  noviceOrBelowTopicCount: 100,
  metrics: [
    {
      assignmentId: 4785643,
      assignmentName: 'Week 4 Quiz- In Class ',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2024-07-19T03:59:00Z',
      pastDue: false,
      gradePoints: 29,
      score: '1.0',
      classAvgScore: 0.9870689655172376,
      timeSpent: 'PT12M28S',
      classAvgTimeSpent: 'PT16M29.5S',
      totalQuestionsAnswered: 29
    },
    {
      assignmentId: 4597508,
      assignmentName: 'Reduction of Risk Potential EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-28T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.0275,
      timeSpent: 'PT3H27M38S',
      classAvgTimeSpent: 'PT3H8M11.8125S',
      totalQuestionsAnswered: 184
    },
    {
      assignmentId: 4597509,
      assignmentName: 'Antidysrhythmic Drugs EAQ ',
      assignmentType: 'STANDARD',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-28T03:59:00Z',
      pastDue: false,
      gradePoints: 5,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT1M55S',
      classAvgTimeSpent: 'PT1M50.125S',
      totalQuestionsAnswered: 5
    },
    {
      assignmentId: 4597505,
      assignmentName: 'Respiratory Drugs Pharm EAQ ',
      assignmentType: 'STANDARD',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-21T03:59:00Z',
      pastDue: false,
      gradePoints: 5,
      score: '1.0',
      classAvgScore: 0.011875,
      timeSpent: 'PT8M21S',
      classAvgTimeSpent: 'PT3M30.8125S',
      totalQuestionsAnswered: 5
    },
    {
      assignmentId: 4597518,
      assignmentName: 'Health Promotion & Maintenance EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-08-04T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.025,
      timeSpent: 'PT4H21M23S',
      classAvgTimeSpent: 'PT3H7M32S',
      totalQuestionsAnswered: 195
    },
    {
      assignmentId: 4597481,
      assignmentName: 'Management of Care EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-06-30T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.02125,
      timeSpent: 'PT5H5M43S',
      classAvgTimeSpent: 'PT4H32M15.4375S',
      totalQuestionsAnswered: 354
    },
    {
      assignmentId: 4597495,
      assignmentName: 'Antiviral Drugs EAQ ',
      assignmentType: 'STANDARD',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-07T03:59:00Z',
      pastDue: false,
      gradePoints: 5,
      score: '1.0',
      classAvgScore: 0.010625,
      timeSpent: 'PT1M19S',
      classAvgTimeSpent: 'PT2M19.4375S',
      totalQuestionsAnswered: 5
    },
    {
      assignmentId: 4597491,
      assignmentName: 'Safety & Infection Control EAQ (Intermediate)',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-07T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.015,
      timeSpent: 'PT46M39S',
      classAvgTimeSpent: 'PT27M10.875S',
      totalQuestionsAnswered: 69
    },
    {
      assignmentId: 4597520,
      assignmentName: 'Antibiotic Drugs EAQ ',
      assignmentType: 'STANDARD',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-08-04T03:59:00Z',
      pastDue: false,
      gradePoints: 5,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT1M24S',
      classAvgTimeSpent: 'PT2M45.1875S',
      totalQuestionsAnswered: 5
    },
    {
      assignmentId: 4597488,
      assignmentName: 'Analgesic Therapy ',
      assignmentType: 'QUIZ_BY_QUESTION',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-06-30T03:59:00Z',
      pastDue: false,
      gradePoints: 20,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT17M15S',
      classAvgTimeSpent: 'PT15M11.25S',
      totalQuestionsAnswered: 20
    },
    {
      assignmentId: 4597521,
      assignmentName: 'Critical Care Practice HESI',
      assignmentType: 'AUTHESS',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-08-04T03:59:00Z',
      pastDue: false,
      gradePoints: 12,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT12M12S',
      classAvgTimeSpent: 'PT22M9.5S',
      totalQuestionsAnswered: 12
    },
    {
      assignmentId: 4597502,
      assignmentName: 'Physiological Adaptation EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-21T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT8M43S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 4597500,
      assignmentName: 'Pharmacological & Parenteral Therapies EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-21T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.019375,
      timeSpent: 'PT3H38M41S',
      classAvgTimeSpent: 'PT3H14M11.4375S',
      totalQuestionsAnswered: 127
    },
    {
      assignmentId: 4597498,
      assignmentName: 'Psychosocial Integrity EAQ ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-14T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.02375,
      timeSpent: 'PT3H15M46S',
      classAvgTimeSpent: 'PT3H22M55.125S',
      totalQuestionsAnswered: 161
    },
    {
      assignmentId: 4597499,
      assignmentName: 'Coagulation Modifier Drugs Pharm EAQ ',
      assignmentType: 'STANDARD',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-14T03:59:00Z',
      pastDue: false,
      gradePoints: 5,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT3M13S',
      classAvgTimeSpent: 'PT2M1.3125S',
      totalQuestionsAnswered: 5
    },
    {
      assignmentId: 4597496,
      assignmentName: 'Antiviral and Antiretroviral Therapy',
      assignmentType: 'LESSONS',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-07-07T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9375,
      timeSpent: 'PT2M',
      classAvgTimeSpent: 'PT3M50.9375S',
      totalQuestionsAnswered: 18
    },
    {
      assignmentId: 4597515,
      assignmentName: 'Care of Patients with Dysrhythmias',
      assignmentType: 'SHERPATH_CASE_STUDY',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    }
  ]
};
