export default {
  courseSectionId: 516809,
  contentType: '',
  avgScoreOfClass: 1,
  avgTimeOfClassOnQuestions: 'PT1H35M53S',
  metrics: [
    {
      courseSectionId: 516809,
      userId: 3047711,
      firstName: 'Bennie',
      lastName: 'Factor',
      contentType: '',
      totalScore: 5,
      totalGradePoints: 5,
      percentAvgScore: 55.55555555555556,
      avgScore: 0.5555555555555556,
      totalAssignmentQuestionsAnswered: 492,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT46M1S',
      avgTimeSpent: 'PT46M1S',
      avgTimeSpentOnQuestionsDelta: 'PT46M1S',
      totalAssignmentsPastDue: 4,
      totalAssignmentsComplete: 5,
      totalAssignments: 15,
      lastLogin: '2024-04-25T02:51:34.395Z',
      totalTimeSpent: 'PT6H54M5S',
      totalAssignmentsBelowClassAvg: 4,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 516809,
      userId: 5846998,
      firstName: 'Alexander',
      lastName: 'Tushishvili',
      contentType: '',
      totalScore: 6,
      totalGradePoints: 6,
      percentAvgScore: 66.66666666666666,
      avgScore: 0.6666666666666666,
      totalAssignmentQuestionsAnswered: 810,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT1H4M53S',
      avgTimeSpent: 'PT1H4M53S',
      avgTimeSpentOnQuestionsDelta: 'PT1H4M53S',
      totalAssignmentsPastDue: 3,
      totalAssignmentsComplete: 6,
      totalAssignments: 15,
      lastLogin: '2024-04-18T00:10:09.096Z',
      totalTimeSpent: 'PT9H43M57S',
      totalAssignmentsBelowClassAvg: 3,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 516809,
      userId: 5167001,
      firstName: 'Jacqueline',
      lastName: 'Payeur',
      contentType: '',
      totalScore: 9,
      totalGradePoints: 9,
      percentAvgScore: 100,
      avgScore: 1,
      totalAssignmentQuestionsAnswered: 1196,
      totalSelfStudyQuestionsAnswered: 5,
      avgTimeSpentOnQuestions: 'PT28M55S',
      avgTimeSpent: 'PT28M55S',
      avgTimeSpentOnQuestionsDelta: 'PT28M55S',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 9,
      totalAssignments: 15,
      lastLogin: '2024-04-22T13:44:19.925Z',
      totalTimeSpent: 'PT4H20M14S',
      totalAssignmentsBelowClassAvg: 0,
      isAtRiskStudent: false,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 516809,
      userId: 6037438,
      firstName: 'MacKenzie',
      lastName: 'Wagner',
      contentType: '',
      totalScore: 9,
      totalGradePoints: 9,
      percentAvgScore: 100,
      avgScore: 1,
      totalAssignmentQuestionsAnswered: 1386,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT1H18M42S',
      avgTimeSpent: 'PT1H18M42S',
      avgTimeSpentOnQuestionsDelta: 'PT1H18M42S',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 9,
      totalAssignments: 15,
      lastLogin: '2024-04-23T00:31:41.097Z',
      totalTimeSpent: 'PT11H48M18S',
      totalAssignmentsBelowClassAvg: 0,
      isAtRiskStudent: true,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 516809,
      userId: 6952847,
      firstName: 'Jessica',
      lastName: 'Allbright',
      contentType: '',
      totalScore: 8,
      totalGradePoints: 8,
      percentAvgScore: 88.88888888888889,
      avgScore: 0.8888888888888888,
      totalAssignmentQuestionsAnswered: 949,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT1H26M16S',
      avgTimeSpent: 'PT1H26M16S',
      avgTimeSpentOnQuestionsDelta: 'PT1H26M16S',
      totalAssignmentsPastDue: 1,
      totalAssignmentsComplete: 8,
      totalAssignments: 15,
      lastLogin: '2024-07-10T15:00:23.263Z',
      totalTimeSpent: 'PT12H56M28S',
      totalAssignmentsBelowClassAvg: 1,
      isAtRiskStudent: false,
      isHighPerformingStudent: true
    }
  ]
};
