export default {
  courseSectionId: 622453,
  contentType: 'LESSONS',
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597496,
      assignmentName: 'Antiviral and Antiretroviral Therapy',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT4M21S',
      avgTimeSpentPerQuestion: 'PT15S',
      avgQuestionsAnsweredCorrect: 18.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.75,
      totalStudentsNotStarted: 2,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 12,
      totalStudentsPastDue: 4,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 1,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 1,
    classAvgScore: 1.0,
    totalStudents: 16,
    pastDueStudents: 4,
    belowClassAvgStudents: 4,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
