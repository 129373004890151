import { configureHandler } from 'mocks/helpers';

import courseSectionId622453 from './_data/622453';
import courseSectionId523822 from './_data/523822';
import courseSectionId516809 from './_data/516809';

const data = {
  568749: courseSectionId622453,
  554121: courseSectionId523822,
  471204: courseSectionId516809
};

export default configureHandler(async (req, res, ctx) => {
  const courseSectionId = Number(req.params.courseSectionId);
  if (!data[courseSectionId]) {
    return req.passthrough();
  }

  return res(ctx.status(200), ctx.json(data[courseSectionId]));
});
