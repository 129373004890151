import React, { Component, ReactNode } from 'react';
import cx from 'classnames';
import { KEY_CODES } from 'constants/app.constant';
import { ELSIcon, ELSRadio } from 'components/common';
import { MenuOption } from 'models';
import { onKeyDownHandler, getIndexByKeyCode } from 'helpers/ui.helper';
import SearchBar from 'components/common/search-bar/SearchBar';

interface RadioMenuProps {
  checkedKey: string;
  options: MenuOption[];
  inline?: boolean;
  disabled?: boolean;
  hideRadio?: boolean;
  searchable?: boolean;
  expandWidth?: boolean;
  searchPlaceholder?: string;
  activeItemClass?: string;
  itemClass?: string;
  listClass?: string;
  className?: string;
  onOptionChange?: Function;
  renderItem?: (option: MenuOption) => ReactNode;
  renderDisplayItem?: (option: MenuOption) => ReactNode;
}

interface RadioMenuState {
  opened: boolean;
  optionIdxSelectedByKey: number;
  shouldChangeOptionListPosition: boolean;
  searchText: string;
  tempCheckedKey: string;
}

const initialOptionIdx = -1;

class RadioMenu extends Component<RadioMenuProps, RadioMenuState> {
  private menuRef = React.createRef<HTMLDivElement>();
  private selectedOptionRef = React.createRef<HTMLLIElement>();
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      optionIdxSelectedByKey: initialOptionIdx,
      shouldChangeOptionListPosition: false,
      searchText: '',
      tempCheckedKey: this.props.checkedKey
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.changeOptionListPosition);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.opened && prevState.opened !== this.state.opened) {
      this.changeOptionListPosition();
      this.selectedOptionRef.current?.scrollIntoView({ block: 'nearest' });
    }
    if (prevState.tempCheckedKey !== this.props.checkedKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tempCheckedKey: this.props.checkedKey
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.changeOptionListPosition);
  }

  handleClickOutside = evt => {
    if (this.menuRef && !this.menuRef.current.contains(evt.target)) {
      this.hideOptions();
    }
  };

  changeOptionListPosition = (): void => {
    const optionBoxBoundingClientRect = this.menuRef.current.getBoundingClientRect();
    if (optionBoxBoundingClientRect.x > window.innerWidth / 2) {
      this.setState({ shouldChangeOptionListPosition: true });
    } else {
      this.setState({ shouldChangeOptionListPosition: false });
    }
  };

  handleSearch = (evt): void => {
    const searchText = evt.target.value;
    this.setState({
      searchText
    });
  };

  handleOptionChange = (option: MenuOption): void => {
    this.setState({
      tempCheckedKey: option.key
    });
    this.props.onOptionChange(option.key);
  };

  openOptionByKeyboard = () => {
    this.setState({
      opened: true,
      optionIdxSelectedByKey: 0,
      searchText: ''
    });
  };

  applyDataWhenHideRadioModeByKeyCode = () => {
    const selectedIdx = this.state.optionIdxSelectedByKey;
    if (selectedIdx !== initialOptionIdx) {
      const checkedKey = this.props.options[selectedIdx].key;
      this.setState({
        tempCheckedKey: checkedKey
      });
      this.props.onOptionChange(checkedKey);
    }
    this.toggleOptions();
  };

  // radio =>  SPACE: Choose option, ENTER: Apply the option
  // hide radio ==> SPACE & ENTER: Choose and apply option
  onSpaceKeyOpenMenu = optionIdxSelectedByKey => {
    const { options } = this.props;
    if (optionIdxSelectedByKey !== initialOptionIdx) {
      this.setState({
        tempCheckedKey: options[optionIdxSelectedByKey].key
      });
    }
  };

  onEnterKeyOpenMenu = optionIdxSelectedByKey => {
    const { onOptionChange } = this.props;
    const { tempCheckedKey } = this.state;
    if (optionIdxSelectedByKey !== initialOptionIdx) {
      onOptionChange(tempCheckedKey);
    }
    this.toggleOptions();
  };

  handleMenuKeyDown = (evt): void => {
    const key = evt.keyCode;
    if (key !== KEY_CODES.TAB) {
      evt.preventDefault();
    }
    const max = this.props.options.length - 1;
    const { hideRadio, checkedKey } = this.props;
    const { opened, optionIdxSelectedByKey } = this.state;

    switch (key) {
      case KEY_CODES.ENTER: {
        if (hideRadio && this.state.opened) {
          this.applyDataWhenHideRadioModeByKeyCode();
          break;
        }
        if (opened) {
          this.onEnterKeyOpenMenu(optionIdxSelectedByKey);
        } else {
          this.openOptionByKeyboard();
        }
        break;
      }
      case KEY_CODES.SPACE: {
        if (hideRadio && opened) {
          this.applyDataWhenHideRadioModeByKeyCode();
          break;
        }
        if (opened) {
          this.onSpaceKeyOpenMenu(optionIdxSelectedByKey);
        } else {
          this.openOptionByKeyboard();
        }
        break;
      }
      case KEY_CODES.DOWN:
      case KEY_CODES.UP: {
        this.setState(prevState => ({
          optionIdxSelectedByKey: getIndexByKeyCode(key, prevState.optionIdxSelectedByKey, max)
        }));
        break;
      }
      case KEY_CODES.ESC: {
        this.hideOptions();
        this.setState({
          tempCheckedKey: checkedKey
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  hideOptions = (): void => {
    this.setState({
      opened: false,
      optionIdxSelectedByKey: initialOptionIdx,
      searchText: ''
    });
  };

  toggleOptions = (): void => {
    if (!this.props.disabled) {
      this.setState(prevState => ({
        opened: !prevState.opened,
        optionIdxSelectedByKey: initialOptionIdx,
        searchText: ''
      }));
    }
  };

  renderDisplayName = (selectedOption: MenuOption, renderDisplayItem?: (option: MenuOption) => ReactNode) => {
    if (renderDisplayItem) {
      return renderDisplayItem({ ...selectedOption });
    }
    return selectedOption.displayName || selectedOption.name;
  };

  render() {
    const {
      options,
      checkedKey,
      inline,
      disabled,
      hideRadio,
      searchable,
      searchPlaceholder,
      expandWidth,
      renderItem,
      renderDisplayItem,
      activeItemClass = '',
      itemClass = '',
      className = '',
      listClass = ''
    } = this.props;
    const { opened, shouldChangeOptionListPosition, optionIdxSelectedByKey, searchText, tempCheckedKey } = this.state;
    const selectedOption = options.find(option => option.key === this.props.checkedKey) || ({} as MenuOption);
    const displayOptions = options.filter(option => option.name.toLowerCase().includes(searchText.toLowerCase()));
    return (
      <div ref={this.menuRef} className={cx({ 'u-els-display-inline-block': inline }, 'c-select-menu c-radio-menu', { 'c-select-menu--disabled': disabled }, className)}>
        <div
          role="button"
          tabIndex={0}
          className={cx({ 'u-els-display-inline-block': inline, 'c-select-menu--opened': opened }, 'c-select-menu__trigger')}
          onClick={this.toggleOptions}
          onKeyDown={this.handleMenuKeyDown}
        >
          <span title={selectedOption.name} className="c-select-menu__display-label">
            {this.renderDisplayName(selectedOption, renderDisplayItem)}
          </span>
          <ELSIcon name="chevron-down" size="1x" customClass="u-els-margin-left-1o2 c-select-menu__rotate-arrow" />
        </div>

        {opened && (
          <div
            className={cx(
              'c-select-menu__options',
              {
                'c-select-menu__options--position-changed': shouldChangeOptionListPosition,
                'c-select-menu__options--expand-width': expandWidth
              },
              listClass
            )}
          >
            <div className="c-select-menu__options-wrapper">
              {searchable && <SearchBar placeholder={searchPlaceholder} value={this.state.searchText} handleSearch={this.handleSearch} />}
              <ul className="c-select-menu__options-list">
                {displayOptions.map((option, idx) => {
                  const changeHandler = evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.handleOptionChange(option);
                    this.hideOptions();
                  };
                  const { name, key } = option;
                  return (
                    <li
                      ref={key === checkedKey ? this.selectedOptionRef : null}
                      role="option"
                      aria-selected
                      title={name}
                      key={`option-${key}`}
                      className={cx(
                        'o-els-flex-layout__item--grow-1 c-select-menu__option',
                        {
                          'c-select-menu__option--selected-by-key': optionIdxSelectedByKey === idx
                        },
                        itemClass
                      )}
                    >
                      {hideRadio ? (
                        <div
                          role="button"
                          tabIndex={0}
                          className={cx(
                            { 'u-els-text-decoration-underline u-els-color-secondary': key === tempCheckedKey && optionIdxSelectedByKey !== idx },
                            { [`${activeItemClass}`]: key === tempCheckedKey }
                          )}
                          onClick={changeHandler}
                          onKeyDown={evt => onKeyDownHandler(evt, changeHandler)}
                        >
                          {renderItem ? renderItem({ ...option }) : name}
                        </div>
                      ) : (
                        <ELSRadio name={name} value={key} checked={key === tempCheckedKey} changeHandler={changeHandler}>
                          {renderItem ? renderItem({ ...option }) : name}
                        </ELSRadio>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RadioMenu;
