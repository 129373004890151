export default {
  courseSectionId: 523822,
  contentType: 'LESSONS',
  metrics: [
    {
      courseSectionId: 523822,
      assignmentId: 2532073,
      assignmentName: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9345238095238095,
      percentAvgScore: 93.45238095238095,
      avgTimeSpent: 'PT7M49S',
      avgTimeSpentPerQuestion: 'PT29S',
      avgQuestionsAnsweredCorrect: 14.952380952380953,
      avgQuestionsAnsweredIncorrect: 1.0476190476190474,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 21,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: '2023-10-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532074,
      assignmentName: 'Urinary Elimination: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9883040935672515,
      percentAvgScore: 98.83040935672514,
      avgTimeSpent: 'PT3M38S',
      avgTimeSpentPerQuestion: 'PT24S',
      avgQuestionsAnsweredCorrect: 8.894736842105264,
      avgQuestionsAnsweredIncorrect: 0.10526315789473684,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-16T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532083,
      assignmentName: 'Medication Administration: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9789473684210526,
      percentAvgScore: 97.89473684210526,
      avgTimeSpent: 'PT8M38S',
      avgTimeSpentPerQuestion: 'PT27S',
      avgQuestionsAnsweredCorrect: 18.599999999999998,
      avgQuestionsAnsweredIncorrect: 0.40000000000000124,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-10-31T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532095,
      assignmentName: 'Fluid Balance: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9785714285714286,
      percentAvgScore: 97.85714285714286,
      avgTimeSpent: 'PT6M38S',
      avgTimeSpentPerQuestion: 'PT28S',
      avgQuestionsAnsweredCorrect: 13.700000000000001,
      avgQuestionsAnsweredIncorrect: 0.29999999999999893,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-30T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532130,
      assignmentName: 'Principles of Safe Medication Administration',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9541666666666666,
      percentAvgScore: 95.41666666666666,
      avgTimeSpent: 'PT6M13S',
      avgTimeSpentPerQuestion: 'PT31S',
      avgQuestionsAnsweredCorrect: 11.45,
      avgQuestionsAnsweredIncorrect: 0.5500000000000007,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-10-26T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532100,
      assignmentName: 'Nutrition: Assess and Recognize Cues',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9543478260869568,
      percentAvgScore: 95.43478260869568,
      avgTimeSpent: 'PT12M36S',
      avgTimeSpentPerQuestion: 'PT33S',
      avgQuestionsAnsweredCorrect: 21.950000000000006,
      avgQuestionsAnsweredIncorrect: 1.049999999999994,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-13T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532105,
      assignmentName: 'Safety in Nursing Practice',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.990909090909091,
      percentAvgScore: 99.0909090909091,
      avgTimeSpent: 'PT6M8S',
      avgTimeSpentPerQuestion: 'PT33S',
      avgQuestionsAnsweredCorrect: 10.9,
      avgQuestionsAnsweredIncorrect: 0.09999999999999942,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-10-26T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532111,
      assignmentName: 'Overview of Surgery',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.8590909090909091,
      percentAvgScore: 85.9090909090909,
      avgTimeSpent: 'PT9M18S',
      avgTimeSpentPerQuestion: 'PT51S',
      avgQuestionsAnsweredCorrect: 9.450000000000001,
      avgQuestionsAnsweredIncorrect: 1.5499999999999996,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-07T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532120,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9500000000000001,
      percentAvgScore: 95.0,
      avgTimeSpent: 'PT4M40S',
      avgTimeSpentPerQuestion: 'PT14S',
      avgQuestionsAnsweredCorrect: 19.0,
      avgQuestionsAnsweredIncorrect: 0.9999999999999987,
      percentComplete: 0.2857142857142857,
      totalStudentsNotStarted: 7,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 6,
      totalStudentsPastDue: 15,
      totalStudents: 21,
      dueDate: '2023-11-05T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532132,
      assignmentName: 'Electrolyte Imbalance',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532134,
      assignmentName: 'Overview of Fluid Balance',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532113,
      assignmentName: 'Pain Management: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9557823129251701,
      percentAvgScore: 95.57823129251702,
      avgTimeSpent: 'PT6M23S',
      avgTimeSpentPerQuestion: 'PT27S',
      avgQuestionsAnsweredCorrect: 13.380952380952381,
      avgQuestionsAnsweredIncorrect: 0.6190476190476182,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 21,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: '2023-10-30T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532060,
      assignmentName: 'Activity and Movement: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9714285714285715,
      percentAvgScore: 97.14285714285715,
      avgTimeSpent: 'PT8M43S',
      avgTimeSpentPerQuestion: 'PT25S',
      avgQuestionsAnsweredCorrect: 20.400000000000002,
      avgQuestionsAnsweredIncorrect: 0.5999999999999979,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-09T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532067,
      assignmentName: 'Nutrition: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9757085020242916,
      percentAvgScore: 97.57085020242916,
      avgTimeSpent: 'PT7M45S',
      avgTimeSpentPerQuestion: 'PT36S',
      avgQuestionsAnsweredCorrect: 12.684210526315791,
      avgQuestionsAnsweredIncorrect: 0.3157894736842092,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-13T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532071,
      assignmentName: 'Bowel Elimination: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9666666666666668,
      percentAvgScore: 96.66666666666669,
      avgTimeSpent: 'PT14M40S',
      avgTimeSpentPerQuestion: 'PT37S',
      avgQuestionsAnsweredCorrect: 23.200000000000003,
      avgQuestionsAnsweredIncorrect: 0.7999999999999972,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-20T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532133,
      assignmentName: 'Sleep: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9875,
      percentAvgScore: 98.75,
      avgTimeSpent: 'PT3M52S',
      avgTimeSpentPerQuestion: 'PT29S',
      avgQuestionsAnsweredCorrect: 7.9,
      avgQuestionsAnsweredIncorrect: 0.09999999999999964,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-12-07T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532079,
      assignmentName: 'Cognition and Sensation: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9903381642512079,
      percentAvgScore: 99.03381642512079,
      avgTimeSpent: 'PT10M28S',
      avgTimeSpentPerQuestion: 'PT27S',
      avgQuestionsAnsweredCorrect: 22.777777777777782,
      avgQuestionsAnsweredIncorrect: 0.2222222222222181,
      percentComplete: 0.8571428571428571,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 18,
      totalStudentsPastDue: 3,
      totalStudents: 21,
      dueDate: '2023-12-04T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532089,
      assignmentName: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9597523219814241,
      percentAvgScore: 95.97523219814241,
      avgTimeSpent: 'PT11M39S',
      avgTimeSpentPerQuestion: 'PT41S',
      avgQuestionsAnsweredCorrect: 16.31578947368421,
      avgQuestionsAnsweredIncorrect: 0.6842105263157898,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-23T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532070,
      assignmentName: 'Overview of Electrolyte Balance',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532066,
      assignmentName: 'Spread of Infection',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9888888888888889,
      percentAvgScore: 98.88888888888889,
      avgTimeSpent: 'PT2M23S',
      avgTimeSpentPerQuestion: 'PT16S',
      avgQuestionsAnsweredCorrect: 8.9,
      avgQuestionsAnsweredIncorrect: 0.09999999999999964,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-02T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532080,
      assignmentName: 'Infection and Infection Control: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9649122807017545,
      percentAvgScore: 96.49122807017545,
      avgTimeSpent: 'PT9M24S',
      avgTimeSpentPerQuestion: 'PT24S',
      avgQuestionsAnsweredCorrect: 23.15789473684211,
      avgQuestionsAnsweredIncorrect: 0.842105263157892,
      percentComplete: 0.9047619047619048,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 19,
      totalStudentsPastDue: 2,
      totalStudents: 21,
      dueDate: '2023-11-02T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2573030,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9775,
      percentAvgScore: 97.75,
      avgTimeSpent: 'PT6M38S',
      avgTimeSpentPerQuestion: 'PT20S',
      avgQuestionsAnsweredCorrect: 19.55,
      avgQuestionsAnsweredIncorrect: 0.4499999999999993,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-02T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532131,
      assignmentName: 'Fluid Imbalance',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      avgTimeSpentPerQuestion: 'PT0S',
      avgQuestionsAnsweredCorrect: 0.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.0,
      totalStudentsNotStarted: 21,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 0,
      totalStudentsPastDue: 0,
      totalStudents: 21,
      dueDate: null,
      isHaveScore: false
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532104,
      assignmentName: 'Electrolyte Balance: Implement and Take Action; Evaluate',
      assignmentGradeType: 'SCORED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.9666666666666666,
      percentAvgScore: 96.66666666666666,
      avgTimeSpent: 'PT4M11S',
      avgTimeSpentPerQuestion: 'PT21S',
      avgQuestionsAnsweredCorrect: 11.599999999999998,
      avgQuestionsAnsweredIncorrect: 0.40000000000000124,
      percentComplete: 0.9523809523809523,
      totalStudentsNotStarted: 1,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 20,
      totalStudentsPastDue: 1,
      totalStudents: 21,
      dueDate: '2023-11-30T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532084,
      assignmentName: 'Overview of Loss and Grief (Recommended for HESI prep)',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.8916666666666666,
      percentAvgScore: 89.16666666666666,
      avgTimeSpent: 'PT21M33S',
      avgTimeSpentPerQuestion: 'PT1M5S',
      avgQuestionsAnsweredCorrect: 17.833333333333332,
      avgQuestionsAnsweredIncorrect: 2.166666666666668,
      percentComplete: 0.2857142857142857,
      totalStudentsNotStarted: 13,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 6,
      totalStudentsPastDue: 15,
      totalStudents: 21,
      dueDate: '2023-12-14T04:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 523822,
      assignmentId: 2532081,
      assignmentName: 'Overview of Stress and Coping (Recommended for HESI prep)',
      assignmentGradeType: 'NOT_GRADED',
      assignmentType: 'LESSONS',
      gradePoints: 100,
      avgScore: 0.8666666666666666,
      percentAvgScore: 86.66666666666666,
      avgTimeSpent: 'PT14M17S',
      avgTimeSpentPerQuestion: 'PT57S',
      avgQuestionsAnsweredCorrect: 12.999999999999998,
      avgQuestionsAnsweredIncorrect: 2.0000000000000013,
      percentComplete: 0.3333333333333333,
      totalStudentsNotStarted: 12,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 7,
      totalStudentsPastDue: 14,
      totalStudents: 21,
      dueDate: '2023-12-14T04:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 19,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 26,
    classAvgScore: 0.9647002784185903,
    totalStudents: 21,
    pastDueStudents: 18,
    belowClassAvgStudents: 18,
    inProgressAssignment: 3
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'AUTHESS']
};
