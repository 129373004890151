import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { AppConstant } from 'constants/app.constant';

const initialState = Immutable({
  locale: '',
  formattedMessages: {}
});

const actions = {
  setLocale: createAction('SET_LOCALE'),
  setFormattedMessages: createAction('SET_FORMATTED_MESSAGES')
};

const reducer = handleActions(
  {
    [actions.setLocale]: (state, { payload }) => state.set('locale', payload),
    [actions.setFormattedMessages]: (state, { payload }) => state.set('formattedMessages', Immutable.merge(state.formattedMessages, payload))
  },
  initialState
);

const getLanguageState = state => state[AppConstant.reduxResources.LANGUAGE_STATE];

const selectors = {
  getLocale: createSelector(getLanguageState, state => state.locale),
  getUsingFormattedMessages: createSelector(getLanguageState, state => state.getIn(['formattedMessages', state.locale]) || Immutable({}))
};

export { actions as languageActions, reducer as languageReducer, selectors as languageSelectors };
export { initialState as languageInitialState };
