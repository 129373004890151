import { SORT_DIRECTION } from 'constants/app.constant';

const ehrBasePath = '/eaqHesiReadiness';

export const ehrPath = {
  ehrHome: ehrBasePath,
  ehrHomeByTopicAndChapter: `${ehrBasePath}#byTopicAndChapter`,
  ehrHomeByStudent: `${ehrBasePath}#byStudent`,
  ehrStudentDetail: `${ehrBasePath}/studentDetail/:studentId`
};

export const EHR_HOME_TAB_INDEXES = {
  byTopicAndChapter: 0,
  byStudent: 1
};

export const MASTERY_LEVELS_CONFIGS = {
  proficient: {
    key: 'proficient',
    field: 'proficient',
    color: 'extended-purple-9',
    label: 'Proficient',
    scoreRange: '(3.0-3.9)'
  },
  intermediate: {
    key: 'intermediate',
    field: 'intermediate',
    color: 'extended-purple-5',
    label: 'Intermediate',
    scoreRange: '(2.0-2.9)'
  },
  novice: {
    key: 'novice',
    field: 'novice',
    color: 'extended-purple-1',
    label: 'Novice',
    scoreRange: '(1.0-1.9)'
  },
  preNovice: {
    key: 'preNovice',
    field: 'preNovice',
    color: 'n1',
    label: 'Incomplete',
    scoreRange: '(0-0.9)'
  }
};

export const MASTERY_MAX_POINT = 4;

export const CHAPTER = 'Chapter';

export const TAXONOMY = 'TAXONOMY';

export const TABLE_SETTINGS = {
  MAX_HEIGHT: '502px',
  DEFAULT_SORT: 'desc',
  SORT_ICON_SIZE: '3o4',
  DEFAULT_SORT_FIELD: 'students',
  // Minumin row to show scroll down
  SHOW_ICON: 14
};

export const StudentPerformanceLevels = Object.freeze({
  Incomplete: 0,
  Novice: 1,
  Intermediate: 2,
  Proficient: 3
});

export const EHR_NA_VALUE = '-';

export const STUDENT_PERFORMANCE_LEVEL_CHART_ID = {
  studentsWithZeroSubmissions: 'STUDENTS_WITH_ZERO_SUBMISSION',
  studentsBelowNovice: 'STUDENTS_BELOW_NOVICE'
};

export const EHR_STUDENT_SORT_FIELD = {
  studentNameColumn: 'fullName',
  studentMasteryLevelColumn: 'masteryLevel',
  percentCorrectColumn: 'percentCorrect',
  numberOfQuestionsAnsweredColumn: 'questionsAnswered',
  avgTimeSpentPerQuestionColumn: 'avgTimeSpentPerQuestion',
  lastDateTakenColumn: 'lastUpdatedDate'
};

export const EHR_STUDENT_TABLE_SELECTOR = {
  studentMasteryLevelColumn: 'c-ehr-student-table__student-performance-level-header-column',
  numberOfQuestionsAnsweredColumn: 'c-ehr-student-table__number-of-questions-answered-header-column',
  percentCorrectColumn: 'c-ehr-table__percent-correct-header-column'
};

export const EHR_STUDENT_DETAIL_CARD_ID = {
  studentMasteryLevel: 'EHR_STUDENT_DETAIL_MASTERY_LEVEL',
  questionsAnswered: 'EHR_STUDENT_DETAIL_QUESTIONS_ANSWERED',
  avgPercentCorrect: 'EHR_STUDENT_DETAIL_AVG_PERCENT_CORRECT'
};

export const EHR_STUDENT_DETAIL_TABLE_SELECTOR = {
  studentMasteryLevelColumn: 'c-ehr-student-detail-table__student-mastery-level-header-column',
  avgPercentCorrectColumn: 'c-ehr-table__avg-percent-correct-header-column'
};

export const EHR_STUDENT_DETAIL_SORT_FIELD = {
  topic: 'chapter',
  studentMasteryLevel: 'masteryLevel',
  avgPercentCorrect: 'avgPercentCorrect',
  numberQuestionsAnswered: 'questionsAnswered',
  avgTimePerQuestion: 'avgTimePerQuestion'
};

export const EHR_INTERACTION_BETWEEN_CHART_AND_TABLE = {
  [STUDENT_PERFORMANCE_LEVEL_CHART_ID.studentsWithZeroSubmissions]: {
    tableColumn: {
      headerSelector: EHR_STUDENT_TABLE_SELECTOR.studentMasteryLevelColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [STUDENT_PERFORMANCE_LEVEL_CHART_ID.studentsBelowNovice]: {
    tableColumn: {
      headerSelector: EHR_STUDENT_TABLE_SELECTOR.numberOfQuestionsAnsweredColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [EHR_STUDENT_DETAIL_CARD_ID.studentMasteryLevel]: {
    tableColumn: {
      headerSelector: EHR_STUDENT_DETAIL_TABLE_SELECTOR.studentMasteryLevelColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [EHR_STUDENT_DETAIL_CARD_ID.avgPercentCorrect]: {
    tableColumn: {
      headerSelector: EHR_STUDENT_DETAIL_TABLE_SELECTOR.avgPercentCorrectColumn
    },
    sortDirection: SORT_DIRECTION.DESC
  }
};
