import { configureHandler } from 'mocks/helpers';

import courseSection622453Student5167001 from './_data/622453-5167001';
import courseSection523822Student5846998 from './_data/523822-5846998';
import courseSection516809Student6037438 from './_data/516809-6037438';
import courseSection622453Student6952847 from './_data/622453-6952847';
import courseSection516809Student3047711 from './_data/516809-3047711';
import courseSection516809Student6952847 from './_data/516809-6952847';
import courseSection622453Student6037438 from './_data/622453-6037438';
import courseSection516809Student5167001 from './_data/516809-5167001';
import courseSection516809Student5846998 from './_data/516809-5846998';
import courseSection622453Student3047711 from './_data/622453-3047711';
import courseSection523822Student6037438 from './_data/523822-6037438';
import courseSection523822Student6308003 from './_data/523822-6308003';
import courseSection523822Student6952847 from './_data/523822-6952847';
import courseSection523822Student5167001 from './_data/523822-5167001';
import courseSection622453Student5846998 from './_data/622453-5846998';

const data = {
  // Course (568749): GSM Demo - Sherpath Vantage for Potter Fundamentals of Nursing
  '568749-5167001': courseSection622453Student5167001,
  '568749-5846998': courseSection622453Student5846998,
  '568749-3047711': courseSection622453Student3047711,
  '568749-6037438': courseSection622453Student6037438,
  '568749-6952847': courseSection622453Student6952847,

  // Course (554121):  (DEMO) Spring 2024 - Sherpath Vantage for Potter Fundamentals of Nursing
  '554121-6037438': courseSection523822Student6037438,
  '554121-6308003': courseSection523822Student6308003,
  '554121-6952847': courseSection523822Student6952847,
  '554121-5167001': courseSection523822Student5167001,
  '554121-5846998': courseSection523822Student5846998,

  // Course (471204): Sherpath Vantage for Harding Lewis's Medical-Surgical Nursing, 12th Edition
  '471204-6037438': courseSection516809Student6037438,
  '471204-3047711': courseSection516809Student3047711,
  '471204-6952847': courseSection516809Student6952847,
  '471204-5167001': courseSection516809Student5167001,
  '471204-5846998': courseSection516809Student5846998
};

export default configureHandler(async (req, res, ctx) => {
  const queryParams = req.url.searchParams;
  const courseSectionId = queryParams.get('courseSectionId');
  const studentId = queryParams.get('userId');
  const key = `${courseSectionId}-${studentId}`;
  if (!data[key]) {
    return req.passthrough();
  }

  return res(ctx.status(200), ctx.json(data[key]));
});
