import { configureHandler } from 'mocks/helpers';
import courseAggregate471204 from './_data/471204';
import courseAggregate554121 from './_data/554121';
import courseAggregate568749 from './_data/568749';

export default configureHandler(async (req, res, ctx) => {
  const courseSectionId = req.url.searchParams.get('courseSectionId');

  if (courseSectionId === '471204') {
    return res(ctx.status(200), ctx.json(courseAggregate471204));
  }

  if (courseSectionId === '554121') {
    return res(ctx.status(200), ctx.json(courseAggregate554121));
  }

  if (courseSectionId === '568749') {
    return res(ctx.status(200), ctx.json(courseAggregate568749));
  }

  return req.passthrough();
});
