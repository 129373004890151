import { configureHandler } from 'mocks/helpers';
import examCategoryForJallbright7 from './_data/jallbright7';
import examCategoryForBfactor from './_data/bfactor';
import examCategoryForJpayeur4 from './_data/jpayeur4';
import examCategoryForTushishvili from './_data/atushishvili';
import examCategoryForMwagner512 from './_data/mwagner512';

const data = {
  jallbright7: examCategoryForJallbright7,
  bfactor: examCategoryForBfactor,
  jpayeur4: examCategoryForJpayeur4,
  atushishvili: examCategoryForTushishvili,
  mwagner512: examCategoryForMwagner512
};

export default configureHandler(async (req, res, ctx) => {
  const evolveUserName = req.url.searchParams.get('evolveUserName');
  const categoryGroupId = req.url.searchParams.get('categoryGroupId');

  const categoryData = data[evolveUserName];
  if (categoryGroupId === '14' && categoryData) {
    return res(ctx.status(200), ctx.json(categoryData));
  }

  return req.passthrough();
});
