export default {
  courseSectionId: 622453,
  contentType: 'MASTERY',
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597491,
      assignmentName: 'Safety & Infection Control EAQ (Intermediate)',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT29M',
      avgTimeSpentPerQuestion: 'PT44S',
      avgQuestionsAnsweredCorrect: 40.06666666666667,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597498,
      assignmentName: 'Psychosocial Integrity EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2H56M10S',
      avgTimeSpentPerQuestion: 'PT56S',
      avgQuestionsAnsweredCorrect: 188.13333333333333,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597500,
      assignmentName: 'Pharmacological & Parenteral Therapies EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H14M11S',
      avgTimeSpentPerQuestion: 'PT1M15S',
      avgQuestionsAnsweredCorrect: 155.6875,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597502,
      assignmentName: 'Physiological Adaptation EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT8M43S',
      avgTimeSpentPerQuestion: 'PT1M45S',
      avgQuestionsAnsweredCorrect: 5.3125,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597508,
      assignmentName: 'Reduction of Risk Potential EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H8M12S',
      avgTimeSpentPerQuestion: 'PT1M14S',
      avgQuestionsAnsweredCorrect: 152.5625,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-28T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597518,
      assignmentName: 'Health Promotion & Maintenance EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3H6M59S',
      avgTimeSpentPerQuestion: 'PT1M6S',
      avgQuestionsAnsweredCorrect: 170.33333333333334,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597481,
      assignmentName: 'Management of Care EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'MASTERY',
      gradePoints: 1,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT4H32M15S',
      avgTimeSpentPerQuestion: 'PT56S',
      avgQuestionsAnsweredCorrect: 292.75,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-06-30T03:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 7,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 7,
    classAvgScore: 1.0,
    totalStudents: 16,
    pastDueStudents: 3,
    belowClassAvgStudents: 3,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
