import { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { appSelectors } from 'redux/ducks/app';
import { ANALYTIC_FEATURES } from 'constants/app.constant';
import { EXTERNAL_APP_INFO } from 'constants/externalApp.constant';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { isEABAppByAppName } from 'services/app-linking.service';
import { trackAAFeature } from 'services/adobe-analytics.service';
import { isEnabledMockService } from 'helpers/app.helper';
import withRedirectToOutsideApp from '../with-redirect-to-outside-app/withRedirectToOutsideApp';

interface CoursePickerProps {
  pendoClass?: string;
  currentCourseName: string;
  hidePendoLink?: boolean;
  onRedirectToOutsideApp?: Function;
}

const CoursePicker = ({ pendoClass, currentCourseName, onRedirectToOutsideApp }: CoursePickerProps) => {
  const history = useHistory();
  const breadcrumbBackTitle: string = useSelector(appSelectors.getBreadcrumbBackTitle);
  const isMockServiceEnabled = isEnabledMockService();

  const handleViewAllCourse = () => {
    trackAAFeature(ANALYTIC_FEATURES.CW_INSTRUCTOR_HOME_VIEW_ALL_COURSES_CLICK);
    history.push(PerformancePath.courseAggregate);
  };

  const handleViewMyCoursePlan = () => {
    if (!isMockServiceEnabled) {
      trackAAFeature(ANALYTIC_FEATURES.CW_INSTRUCTOR_HOME_VIEW_MY_COURSE_PLAN_CLICK);
      const linkConfig = {
        appName: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.APP_NAME,
        action: EXTERNAL_APP_INFO.APP_LINKING.SHER_EVOL.ACTION.PERFORMANCE_VIEW
      };
      onRedirectToOutsideApp(linkConfig);
    }
  };

  const onKeyDownTitleHandler = event => {
    if (!isMockServiceEnabled) {
      onKeyDownHandler(event, handleViewMyCoursePlan);
    }
  };

  const isEABApp = isEABAppByAppName(breadcrumbBackTitle);

  return (
    <div className="u-els-background-color-n0 c-course-picker">
      <div className="u-els-display-flex o-els-flex-layout--middle u-max-width-1400 u-els-margin-auto u-els-padding-left-1x1o2 u-els-padding-right-1x1o2">
        <div className="u-els-font-size-base u-els-font-family-bold u-els-padding-right-1o2">Viewing Course:</div>
        <div
          role="button"
          tabIndex={0}
          onClick={handleViewMyCoursePlan}
          onKeyDown={onKeyDownTitleHandler}
          className="u-els-padding-top u-els-padding-bottom u-cursor-pointer c-course-picker__title"
        >
          {currentCourseName}
        </div>
        {!isEABApp && (
          <button
            type="button"
            disabled={isMockServiceEnabled}
            className="u-els-margin-left-2x u-els-font-size-base u-els-anchorize c-els-link c-course-picker__view-my-course-plan"
            onClick={handleViewMyCoursePlan}
          >
            View My Course Plan
          </button>
        )}
        {pendoClass && (
          <span className="u-els-font-size-base c-course-picker__view-all-courses">
            <button
              type="button"
              className={classNames('u-els-anchorize c-els-link c-course-picker__view-all-courses-button', `js-pendo-${pendoClass}`)}
              onClick={handleViewAllCourse}
            >
              View all Courses
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export { CoursePicker as CoursePickerBase };

export default memo(withRedirectToOutsideApp(CoursePicker));
