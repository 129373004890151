export default {
  courseSectionId: 622453,
  contentType: 'AUTHESS',
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597521,
      assignmentName: 'Critical Care Practice HESI',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'AUTHESS',
      gradePoints: 12,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT22M42S',
      avgTimeSpentPerQuestion: 'PT1M54S',
      avgQuestionsAnsweredCorrect: 12.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 1,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 1,
    classAvgScore: 1.0,
    totalStudents: 16,
    pastDueStudents: 1,
    belowClassAvgStudents: 1,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
