import dataOf516809 from './_data/516809';
import dataOf516809Mastery from './_data/516809_MASTERY';
import dataOf516809OsmosisVideo from './_data/516809_OSMOSIS_VIDEO';
import dataOf523822 from './_data/523822';
import dataOf523822Mastery from './_data/523822_MASTERY';
import dataOf523822Authess from './_data/523822_AUTHESS';
import dataOf523822Lessons from './_data/523822_LESSONS';
import dataOf622453 from './_data/622453';
import dataOf622353Authess from './_data/622453_AUTHESS';
import dataOf622453Lessons from './_data/622453_LESSONS';
import dataOf622453Mastery from './_data/622453_MASTERY';
import dataOf622453QuizByQuestion from './_data/622453_QUIZ_BY_QUESTION';
import dataOf622453Standard from './_data/622453_STANDARD';
import { configureHandler } from 'mocks/helpers';

export const assignmentEngagementReportV2Map = {
  568749: dataOf622453,
  554121: dataOf523822,
  471204: dataOf516809
};

export const assignmentEngagementReportV2MapByContentType = {
  '568749_QUIZ_BY_QUESTION': dataOf622453QuizByQuestion,
  '568749_MASTERY': dataOf622453Mastery,
  '568749_STANDARD': dataOf622453Standard,
  '568749_AUTHESS': dataOf622353Authess,
  '568749_LESSONS': dataOf622453Lessons,
  '554121_MASTERY': dataOf523822Mastery,
  '554121_AUTHESS': dataOf523822Authess,
  '554121_LESSONS': dataOf523822Lessons,
  '471204_MASTERY': dataOf516809Mastery,
  '471204_OSMOSIS_VIDEO': dataOf516809OsmosisVideo
};

const getAssignmentData = (courseSectionId: string, contentType?: string) => {
  if (contentType) {
    const contentTypeWithCourseSectionId = `${courseSectionId}_${contentType}`;
    return assignmentEngagementReportV2MapByContentType[contentTypeWithCourseSectionId];
  }
  return assignmentEngagementReportV2Map[courseSectionId];
};

export default configureHandler(async (req, res, ctx) => {
  const courseSectionId = req.url.searchParams.get('courseSectionId');
  const contentType = req.url.searchParams.get('contentType');

  if (!courseSectionId) {
    return req.passthrough();
  }

  const assignmentData = getAssignmentData(courseSectionId, contentType);

  if (!assignmentData) {
    return req.passthrough();
  }

  return res(ctx.status(200), ctx.json(assignmentData));
});
