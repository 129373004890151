export const cwRoutePrefix = {
  instructor: '/courseware/instructor',
  student: '/courseware/student'
};

export const PerformancePath = {
  oldHomepage: '/report-menu',
  newHomePage: `${cwRoutePrefix.instructor}/new`,
  engagement: {
    home: `${cwRoutePrefix.instructor}/engagement`,
    student: `${cwRoutePrefix.instructor}/engagement/student`,
    assignment: `${cwRoutePrefix.instructor}/engagement/assignment`
  },
  engagementSummary: `${cwRoutePrefix.instructor}/engagementSummary`,
  assignmentDetail: `${cwRoutePrefix.instructor}/assignmentDetail/:assignmentId`,
  assignmentEngagement: {
    home: `${cwRoutePrefix.instructor}/assignmentEngagement`,
    byAssignment: `${cwRoutePrefix.instructor}/assignmentEngagement/byAssignment`,
    byStudent: `${cwRoutePrefix.instructor}/assignmentEngagement/byStudent/:studentId`
  },
  assignmentSummary: `${cwRoutePrefix.instructor}/assignmentSummary`,
  studentEngagement: {
    home: `${cwRoutePrefix.instructor}/studentEngagement`,
    summary: `${cwRoutePrefix.instructor}/studentEngagement/summary`,
    gradebook: `${cwRoutePrefix.instructor}/studentEngagement/gradebook`,
    studentDetail: `${cwRoutePrefix.instructor}/studentEngagement/studentDetail/:studentId`
  },
  gradeBook: {
    home: `${cwRoutePrefix.instructor}/gradeBook/home`,
    studentDetail: `${cwRoutePrefix.instructor}/gradeBook/studentDetail/:studentId`
  },
  disengagedStudent: {
    home: `${cwRoutePrefix.instructor}/disengagedStudent`,
    studentDetail: `${cwRoutePrefix.instructor}/disengagedStudent/studentDetail/:studentId`
  },
  studentDetail: `${cwRoutePrefix.instructor}/studentDetail/:studentId`,
  hesiReadinessReport: {
    home: '/eaqHesiReadiness'
  },
  courseAggregate: '/courseAggregate'
};

export const DEFAULT_ASSIGNMENT_COMPLETED_CHART_CONFIG = {
  completed: {
    label: 'completed',
    color: 'info',
    value: 0
  },
  inProgress: {
    label: 'in progress',
    color: 'secondary-on-dark',
    value: 0
  },
  pastDue: {
    label: 'past due',
    color: 'warn',
    value: 0
  },
  notStarted: {
    label: 'not started',
    color: 'n3',
    value: 0
  }
};

export const DEFAULT_ASSIGNMENT_COMPLETED_CHART_DATA = {
  completed: 0,
  inProgress: 0,
  pastDue: 0,
  notStarted: 0
};

export const ASSIGNMENT_DUE_MENU_LAST_7_DAYS = {
  key: 'last_7_days',
  name: 'in the last 7 days',
  value: 7
};

export const ASSIGNMENT_DUE_MENU_NEXT_7_DAYS = {
  key: 'next_7_days',
  name: 'in the next 7 days',
  value: 7
};

export const ASSIGNMENT_DUE_MENU_LAST_30_DAYS = {
  key: 'last_30_days',
  name: 'in the last 30 days',
  value: 30
};

export const ASSIGNMENT_DUE_MENU_SEMESTER = {
  key: 'this_semester',
  name: 'this semester',
  value: 0
};

export const ASSIGNMENT_GRADE_TYPES = {
  PASS_FAIL: {
    key: 'PASS_FAIL',
    label: 'Pass/Fail'
  },
  NOT_GRADED: {
    key: 'NOT_GRADED',
    label: 'Not Graded'
  },
  SCORED: {
    key: 'SCORED',
    label: 'Scored'
  }
};

export const ASSIGNMENT_STATUS = {
  NOT_STARTED: {
    key: 'NOT_STARTED',
    label: 'Not Started'
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    label: 'In Progress'
  },
  COMPLETED: {
    key: 'COMPLETED',
    label: 'Completed'
  }
};

export const ASSIGNMENT_DUE_MENU_OPTIONS = [ASSIGNMENT_DUE_MENU_SEMESTER, ASSIGNMENT_DUE_MENU_LAST_30_DAYS, ASSIGNMENT_DUE_MENU_LAST_7_DAYS, ASSIGNMENT_DUE_MENU_NEXT_7_DAYS];

export const ASSIGNMENT_PERFORMANCE_SCORE_EIGHTY_FIVE = 85;

export const DISPLAY_PASS_FAIL = {
  PASS: 'Pass',
  FAIL: 'Fail'
};

export const CW_TABLE_SELECTOR = {
  assignmentEngagement: '.c-cw-assignment-engagement',
  assignmentSummaryDetail: '.c-cw-assignment-summary-detail',
  studentAssignmentSummary: '.c-cw-student-detail-assignment-summary-tab',
  studentEngagement: '.c-cw-student-engagement',
  engagementSummary: '.c-cw-engagement-summary-sort'
};

export const TOGGLE_TITLE_GRADED_ASSIGNMENTS_ONLY = 'Graded Assignments Only';

export const CW_ASSIGNMENT_TABLE_NAME = {
  STUDENT_HOME_ASSIGNMENT_SUMMARY: 'STUDENT_HOME_ASSIGNMENT_SUMMARY',
  STUDENT_HOME_ASSIGNMENT_ENGAGEMENT: 'STUDENT_HOME_ASSIGNMENT_ENGAGEMENT',
  STUDENT_DETAIL_ASSIGNMENT_SUMMARY: 'STUDENT_DETAIL_ASSIGNMENT_SUMMARY',
  STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT: 'STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT',
  ASSIGNMENT_SUMMARY: 'ASSIGNMENT_SUMMARY'
};

export const EAQ_TOPIC_REPORT = 'EAQ Topic Report';
export const COURSE_TYPE: Record<string, { value: string; display: string; displayIcon: string }> = {
  EAQ: {
    value: 'EAQ',
    display: 'EAQ',
    displayIcon: 'icon__eaq'
  },
  MASTERY: {
    value: 'MASTERY',
    display: 'EAQ - Mastery',
    displayIcon: 'icon__eaq'
  },
  STANDARD: {
    value: 'STANDARD',
    display: 'EAQ - Standard',
    displayIcon: 'icon__eaq'
  },
  QUIZ_BY_QUESTION: {
    value: 'QUIZ_BY_QUESTION',
    display: 'EAQ - Quiz By Question',
    displayIcon: 'icon__eaq'
  },
  LESSON: {
    value: 'lesson',
    display: 'Lesson',
    displayIcon: 'els-hmds-icon-lesson'
  },
  STUDY_LESSON: {
    value: 'study-lesson',
    display: 'Study Lesson',
    displayIcon: 'els-hmds-icon-lesson'
  },
  CASE_STUDY: {
    value: 'case-study',
    display: 'Case Study',
    displayIcon: 'els-hmds-icon-case-study'
  },
  EBOOK: {
    value: 'ebook',
    display: 'EBook',
    displayIcon: 'els-hmds-icon-ebook-reading'
  },
  POWERPOINT: {
    value: 'powerpoint',
    display: 'Powerpoint',
    displayIcon: 'els-hmds-icon-ppt'
  },
  SIMULATION: {
    value: 'simulation',
    display: 'Simulation',
    displayIcon: 'els-hmds-icon-simulation-nursing'
  },
  SKILL: {
    value: 'skill',
    display: 'Skill',
    displayIcon: 'els-hmds-icon-skills-checklist'
  },
  AFFECTIVE_COMPETENCY: {
    value: 'affective competency',
    display: 'Affective Competency',
    displayIcon: 'els-hmds-icon-coffee'
  },
  NAQ: {
    value: 'naq',
    display: 'NAQ',
    displayIcon: 'els-hmds-icon-exam'
  },
  ACTIVITY: {
    value: 'activity',
    display: 'Activity',
    displayIcon: 'els-hmds-icon-activity'
  },
  GROUP_ACTIVITY: {
    value: 'group-activity',
    display: 'Group Activity',
    displayIcon: 'els-gizmo-icon-group-of-people'
  },
  CHART: {
    value: 'CHART',
    display: 'SimChart',
    displayIcon: 'els-hmds-icon-simulation-nursing'
  },
  AUTHESS: {
    value: 'AUTHESS',
    display: 'EAQ Case Study',
    displayIcon: 'els-gizmo-icon-strawberry'
  }
};

export const EAQ_TOPIC_REPORT_BTN_TEXT = 'Add Adaptive Quiz (EAQ)';
export const EAQ_TOPIC_REPORT_BTN_TEXT_STUDENT_FACING = 'Start a self-study quiz (EAQ)';
