import cx from 'classnames';

import { BentoBoxProps, ReportConfig } from 'components/common/bento-box/BentoBox';
import { ELSIconV2 } from 'components/common';
import { WELCOME_HEADER } from 'constants/app.constant';
import CentralizedLandingPageCards from 'components/features/centralized-landing-page/centralized-landing-page-cards/CentralizedLandingPageCards';
import Pill from 'components/common/pill/Pill';

import { PS_PILL_TITLES } from 'reports/ps/constants';

export const ProgramSolutionsNavMenu = ({ PS, onClickOutSide }: BentoBoxProps) => {
  const handleOnNavigation = (config: ReportConfig) => {
    if (!config.disabled) {
      config.onNavigation();
      onClickOutSide();
    }
  };

  return (
    <div className="c-ps-nav-menu">
      <div className="c-ps-nav-menu__header-container">
        <div className="c-ps-nav-menu__header-image c-ps-nav-menu__header-image--left" />
        <div className="c-ps-nav-menu__header-title-container">
          <p className="c-ps-nav-menu__header-title">{WELCOME_HEADER}</p>
          <p className="c-ps-nav-menu__header-subtitle u-els-font-size-base">Your hub for essential performance analytics dashboards for Sherpath, HESI and Shadow Health</p>
        </div>
        <div className="c-ps-nav-menu__header-image c-ps-nav-menu__header-image--right" />
      </div>

      <>
        {!PS.disabled && (
          <div className="c-ps-nav-menu__card-row-container">
            <button type="button" className={cx('c-ps-nav-menu-card', { 'c-ps-nav-menu-card--selected': PS.isSelected })} onClick={() => handleOnNavigation(PS)}>
              <div className="c-ps-nav-menu-card__title c-ps-nav-menu-card__title--top">
                <span>{PS_PILL_TITLES.PS}</span>
              </div>
              <p className="u-els-margin-bottom-1x">
                A holistic view of the nursing education journey, synthesizing insights from Sherpath, HESI, and Shadow Health to drive both program and student success.
              </p>
              <div className="c-ps-nav-menu__pill-row-container">
                <div className="c-ps-nav-menu__pill c-ps-nav-menu__pill--row-item">Connected insights from:</div>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--green c-ps-nav-menu__pill--row-item">
                  <ELSIconV2 id="Neuroimagery" sprite="Neuroimagery" size="xs" className="c-ps-nav-menu__pill-icon u-custom-color-green-1" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHERPATH}</span>
                </Pill>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--orange c-ps-nav-menu__pill--row-item">
                  <ELSIconV2 id="Stethoscope" sprite="Stethoscope" size="xs" className="c-ps-nav-menu__pill-icon u-custom-color-orange-1" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHADOW_HEALTH}</span>
                </Pill>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--blue">
                  <ELSIconV2 id="Statistics" sprite="Statistics" size="xs" className="c-ps-nav-menu__pill-icon u-els-color-secondary" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.HESI}</span>
                </Pill>
              </div>
            </button>
          </div>
        )}
        <CentralizedLandingPageCards onClickOutSide={onClickOutSide} />
      </>
    </div>
  );
};
