import { isEqual } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { TableProps } from '../responsive-table/ResponsiveTable';

export interface ExpandableRow<T> {
  id: T[keyof T];
  childNodes?: ExpandableRow<T>[];
  isExpanded: boolean;
}

interface UseExpandRowReturnType<T> {
  expandState: boolean;
  rowState: ExpandableRow<T>[];
  handleCollapseAllRows: () => void;
  handleExpandAllRows: () => void;
  changeExpandStateById: (id: T[keyof T], customExpandState: boolean) => void;
  checkExpandStateById: (id: T[keyof T], currentRowState?: ExpandableRow<T>[]) => boolean;
}

const buildRowState = <T>(data: T[], props: TableProps<T>, expandState = false): ExpandableRow<T>[] => {
  const { recordIdField, recordChildrenField } = props;
  return data.map((element: T) => {
    const childNodes = (element[recordChildrenField] as unknown) as T[];
    const tempChildNodes = childNodes?.length ? buildRowState(childNodes, props, expandState) : undefined;
    return {
      id: element[recordIdField],
      isExpanded: tempChildNodes?.length ? expandState : false,
      childNodes: tempChildNodes
    };
  });
};

const useExpandAllRow = <T>(records: T[], tableProps: TableProps<T>): UseExpandRowReturnType<T> => {
  const { isDefaultExpandAll = false } = tableProps;
  const [expandState, setExpandState] = useState(false);
  const [rowState, setRowState] = useState<ExpandableRow<T>[]>(() => buildRowState(records, tableProps, isDefaultExpandAll));

  const expandAllRows = useMemo(() => buildRowState(records, tableProps, true), [records, tableProps]);
  const collapseAllRows = useMemo(() => buildRowState(records, tableProps), [records, tableProps]);

  useEffect(() => {
    setRowState(buildRowState(records, tableProps, isDefaultExpandAll));
  }, [records, tableProps, isDefaultExpandAll]);

  const handleCollapseAllRows = (): void => {
    setRowState(collapseAllRows);
  };

  const handleExpandAllRows = (): void => {
    setRowState(expandAllRows);
  };

  const changeExpandStateById = useCallback(
    (id: T[keyof T], customExpandState: boolean, currentRowState: ExpandableRow<T>[] = rowState): ExpandableRow<T>[] => {
      setExpandState(false);
      return currentRowState.map(row => {
        if (row.id === id) {
          return { ...row, isExpanded: customExpandState };
        }
        if (row.childNodes) {
          return { ...row, childNodes: changeExpandStateById(id, customExpandState, row.childNodes) };
        }
        return row;
      });
    },
    [rowState]
  );

  const handleChangeExpandStateById = useCallback(
    (id: T[keyof T], customExpandState: boolean): void => {
      setRowState(changeExpandStateById(id, customExpandState));
    },
    [changeExpandStateById]
  );

  const checkExpandStateById = useCallback(
    (id: T[keyof T], currentRowState: ExpandableRow<T>[] = rowState): boolean => {
      const foundRow = currentRowState.find(row => row.id === id);
      if (foundRow) {
        return foundRow.isExpanded;
      }
      return currentRowState.some(row => row.childNodes && checkExpandStateById(id, row.childNodes));
    },
    [rowState]
  );

  useEffect(() => {
    setExpandState(isEqual(rowState, expandAllRows));
  }, [expandAllRows, rowState]);

  return {
    expandState,
    rowState,
    handleCollapseAllRows,
    handleExpandAllRows,
    changeExpandStateById: handleChangeExpandStateById,
    checkExpandStateById
  };
};

export default useExpandAllRow;
