import { createSelector } from 'reselect';
import { AppConstant } from 'constants/app.constant';
import { createAction, handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  selectedStudent: {}
});

const prefixActionName = 'EHR';
const restoreState = createAction(`${prefixActionName}/RESTORE`);
const setSelectedStudent = createAction(`${prefixActionName}/SET_SELECTED_STUDENT`);

const actions = {
  restoreState,
  setSelectedStudent
};

const reducer = handleActions(
  {
    [actions.setSelectedStudent]: (state, { payload }) => Immutable.set(state, 'selectedStudent', payload)
  },
  initialState
);

const getEhrState = state => state[AppConstant.reduxResources.EHR_STATE];

const selectors = {
  getSelectedStudent: createSelector(getEhrState, state => state.selectedStudent)
};

export { actions as ehrActions, reducer as ehrReducer, selectors as ehrSelectors };
export { initialState as ehrInitialState };
