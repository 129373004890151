export default {
  courseSectionId: 523822,
  contentType: '',
  avgScoreOfClass: 0.9866793503466378,
  avgTimeOfClassOnQuestions: 'PT18M35S',
  metrics: [
    {
      courseSectionId: 523822,
      userId: 3047711,
      firstName: 'Bennie',
      lastName: 'Factor',
      contentType: '',
      totalScore: 47.34408934718532,
      totalGradePoints: 1830,
      percentAvgScore: 89.32847046638739,
      avgScore: 0.8932847046638739,
      totalAssignmentQuestionsAnswered: 1341,
      totalSelfStudyQuestionsAnswered: 0,
      avgTimeSpentOnQuestions: 'PT20M31S',
      avgTimeSpent: 'PT20M31S',
      avgTimeSpentOnQuestionsDelta: 'PT20M31S',
      totalAssignmentsPastDue: 5,
      totalAssignmentsComplete: 48,
      totalAssignments: 65,
      lastLogin: '2023-12-11T03:46:14.627Z',
      totalTimeSpent: 'PT18H7M48S',
      totalAssignmentsBelowClassAvg: 13,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 523822,
      userId: 5846998,
      firstName: 'Alexander',
      lastName: 'Tushishvili',
      contentType: '',
      totalScore: 49.25809566250742,
      totalGradePoints: 2030,
      percentAvgScore: 92.93980313680646,
      avgScore: 0.9293980313680645,
      totalAssignmentQuestionsAnswered: 1281,
      totalSelfStudyQuestionsAnswered: 91,
      avgTimeSpentOnQuestions: 'PT15M47S',
      avgTimeSpent: 'PT15M47S',
      avgTimeSpentOnQuestionsDelta: 'PT15M47S',
      totalAssignmentsPastDue: 3,
      totalAssignmentsComplete: 50,
      totalAssignments: 65,
      lastLogin: '2024-01-11T18:19:38.464Z',
      totalTimeSpent: 'PT13H56M50S',
      totalAssignmentsBelowClassAvg: 8,
      isAtRiskStudent: true,
      isHighPerformingStudent: true
    },
    {
      courseSectionId: 523822,
      userId: 5167001,
      firstName: 'Jacqueline',
      lastName: 'Payeur',
      contentType: '',
      totalScore: 51.935841616654976,
      totalGradePoints: 2033,
      percentAvgScore: 97.99215399368863,
      avgScore: 0.9799215399368864,
      totalAssignmentQuestionsAnswered: 1843,
      totalSelfStudyQuestionsAnswered: 2517,
      avgTimeSpentOnQuestions: 'PT8M8S',
      avgTimeSpent: 'PT8M8S',
      avgTimeSpentOnQuestionsDelta: 'PT8M8S',
      totalAssignmentsPastDue: 0,
      totalAssignmentsComplete: 53,
      totalAssignments: 65,
      lastLogin: '2024-02-19T14:15:01.585Z',
      totalTimeSpent: 'PT7H11M20S',
      totalAssignmentsBelowClassAvg: 11,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 523822,
      userId: 6037438,
      firstName: 'MacKenzie',
      lastName: 'Wagner',
      contentType: '',
      totalScore: 48.3972843405977,
      totalGradePoints: 1930,
      percentAvgScore: 91.31563083131641,
      avgScore: 0.9131563083131642,
      totalAssignmentQuestionsAnswered: 1105,
      totalSelfStudyQuestionsAnswered: 55,
      avgTimeSpentOnQuestions: 'PT14M39S',
      avgTimeSpent: 'PT14M39S',
      avgTimeSpentOnQuestionsDelta: 'PT14M39S',
      totalAssignmentsPastDue: 4,
      totalAssignmentsComplete: 49,
      totalAssignments: 65,
      lastLogin: '2023-12-13T02:04:50.15Z',
      totalTimeSpent: 'PT12H56M22S',
      totalAssignmentsBelowClassAvg: 13,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    },
    {
      courseSectionId: 523822,
      userId: 6952847,
      firstName: 'Jessica',
      lastName: 'Allbright',
      contentType: '',
      totalScore: 48.49823524727617,
      totalGradePoints: 1930,
      percentAvgScore: 91.50610424014371,
      avgScore: 0.9150610424014372,
      totalAssignmentQuestionsAnswered: 1122,
      totalSelfStudyQuestionsAnswered: 415,
      avgTimeSpentOnQuestions: 'PT22M58S',
      avgTimeSpent: 'PT22M58S',
      avgTimeSpentOnQuestionsDelta: 'PT22M58S',
      totalAssignmentsPastDue: 4,
      totalAssignmentsComplete: 49,
      totalAssignments: 65,
      lastLogin: '2024-02-29T02:48:41.35Z',
      totalTimeSpent: 'PT20H17M21S',
      totalAssignmentsBelowClassAvg: 10,
      isAtRiskStudent: true,
      isHighPerformingStudent: false
    }
  ]
};
