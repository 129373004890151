export default {
  courseSectionId: 523822,
  studentId: 3047711,
  firstName: 'Bennie',
  lastName: 'Factor',
  topicCount: 131,
  noviceOrBelowTopicCount: 104,
  metrics: [
    {
      assignmentId: 2532133,
      assignmentName: 'Sleep: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-12-07T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9880952380952381,
      timeSpent: 'PT2M12S',
      classAvgTimeSpent: 'PT3M52.476190476S',
      totalQuestionsAnswered: 8
    },
    {
      assignmentId: 2532130,
      assignmentName: 'Principles of Safe Medication Administration',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-10-26T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9166666666666666',
      classAvgScore: 0.01027777777777781,
      timeSpent: 'PT1M33S',
      classAvgTimeSpent: 'PT9M15.428571428S',
      totalQuestionsAnswered: 12
    },
    {
      assignmentId: 2532128,
      assignmentName: 'EAQ Cognition & Sensation Alterations Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-04T04:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT9M2S',
      classAvgTimeSpent: 'PT14M17.476190476S',
      totalQuestionsAnswered: 17
    },
    {
      assignmentId: 2532102,
      assignmentName: 'EAQ Intermediate Safety',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.8095238095238095,
      timeSpent: 'PT48M7S',
      classAvgTimeSpent: 'PT51M44.285714285S',
      totalQuestionsAnswered: 77
    },
    {
      assignmentId: 2532103,
      assignmentName: 'EAQ Nutrition Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-13T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT3M17S',
      classAvgTimeSpent: 'PT10M6.714285714S',
      totalQuestionsAnswered: 16
    },
    {
      assignmentId: 2532100,
      assignmentName: 'Nutrition: Assess and Recognize Cues',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-13T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9565217391304334,
      timeSpent: 'PT9M5S',
      classAvgTimeSpent: 'PT12M36.904761904S',
      totalQuestionsAnswered: 23
    },
    {
      assignmentId: 2532098,
      assignmentName: 'EAQ Intermediate Sleep',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-11T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT9M9S',
      classAvgTimeSpent: 'PT15M59.952380952S',
      totalQuestionsAnswered: 48
    },
    {
      assignmentId: 2532099,
      assignmentName: 'EAQ Novice Pain Management',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-10-30T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT55M10S',
      classAvgTimeSpent: 'PT18M49.476190476S',
      totalQuestionsAnswered: 27
    },
    {
      assignmentId: 2532110,
      assignmentName: 'EAQ Intermediate Perioperative Nursing',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-11T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT1H27M23S',
      classAvgTimeSpent: 'PT42M0.333333333S',
      totalQuestionsAnswered: 48
    },
    {
      assignmentId: 2532111,
      assignmentName: 'Overview of Surgery',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-12-07T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.8181818181818182',
      classAvgScore: 0.011125541125541,
      timeSpent: 'PT3M46S',
      classAvgTimeSpent: 'PT14M41.761904761S',
      totalQuestionsAnswered: 11
    },
    {
      assignmentId: 2532106,
      assignmentName: 'EAQ Sleep Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-07T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT7M14S',
      classAvgTimeSpent: 'PT18M8.238095238S',
      totalQuestionsAnswered: 25
    },
    {
      assignmentId: 2532104,
      assignmentName: 'Electrolyte Balance: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-30T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9166666666666666',
      classAvgScore: 0.9563492063492143,
      timeSpent: 'PT7M11S',
      classAvgTimeSpent: 'PT4M6.476190476S',
      totalQuestionsAnswered: 12
    },
    {
      assignmentId: 2532105,
      assignmentName: 'Safety in Nursing Practice',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-10-26T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.010735930735930715,
      timeSpent: 'PT40M4S',
      classAvgTimeSpent: 'PT8M10.904761904S',
      totalQuestionsAnswered: 11
    },
    {
      assignmentId: 2532118,
      assignmentName: 'EAQ Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-02T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT9M24S',
      classAvgTimeSpent: 'PT14M33.476190476S',
      totalQuestionsAnswered: 23
    },
    {
      assignmentId: 2532119,
      assignmentName: 'EAQ Intermediate Nutrition',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-27T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT47M43S',
      classAvgTimeSpent: 'PT27M0.238095238S',
      totalQuestionsAnswered: 30
    },
    {
      assignmentId: 2532116,
      assignmentName: 'EAQ Intermediate Bowel',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-27T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT10M16S',
      classAvgTimeSpent: 'PT20M49.047619047S',
      totalQuestionsAnswered: 38
    },
    {
      assignmentId: 2532112,
      assignmentName: 'EAQ Elimination Bowel Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-20T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT12M30S',
      classAvgTimeSpent: 'PT12M19.285714285S',
      totalQuestionsAnswered: 15
    },
    {
      assignmentId: 2532113,
      assignmentName: 'Pain Management: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-10-30T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.01023809523809519,
      timeSpent: 'PT1M4S',
      classAvgTimeSpent: 'PT7M57.714285714S',
      totalQuestionsAnswered: 14
    },
    {
      assignmentId: 2532126,
      assignmentName: 'EAQ Fluid, Electrolytes & Acid-Base Balance Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-30T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT26M54S',
      classAvgTimeSpent: 'PT17M54.476190476S',
      totalQuestionsAnswered: 35
    },
    {
      assignmentId: 2532125,
      assignmentName: 'EAQ Novice Safety',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-10-26T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT9M23S',
      classAvgTimeSpent: 'PT10M52.19047619S',
      totalQuestionsAnswered: 14
    },
    {
      assignmentId: 2532122,
      assignmentName: 'EAQ Activity, Immobility, & Safe Movement-Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-09T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT18M14S',
      classAvgTimeSpent: 'PT14M59.857142857S',
      totalQuestionsAnswered: 25
    },
    {
      assignmentId: 2532123,
      assignmentName: 'EAQ Proficient Pain Management',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.14285714285714285,
      timeSpent: 'PT2H25M',
      classAvgTimeSpent: 'PT16M14S',
      totalQuestionsAnswered: 149
    },
    {
      assignmentId: 2532120,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-05T03:59:00Z',
      pastDue: true,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.4619047619047619,
      timeSpent: 'PT9M4S',
      classAvgTimeSpent: 'PT2M45.857142857S',
      totalQuestionsAnswered: 20
    },
    {
      assignmentId: 2573030,
      assignmentName: 'Skin Integrity and Wound Care: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-02T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9642857142857143,
      timeSpent: 'PT6M49S',
      classAvgTimeSpent: 'PT6M32.095238095S',
      totalQuestionsAnswered: 20
    },
    {
      assignmentId: 2532071,
      assignmentName: 'Bowel Elimination: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-20T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9662698412698428,
      timeSpent: 'PT17M33S',
      classAvgTimeSpent: 'PT14M58.476190476S',
      totalQuestionsAnswered: 24
    },
    {
      assignmentId: 2532068,
      assignmentName: 'EAQ Oxygenation & Tissue Perfusion Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-23T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT24M24S',
      classAvgTimeSpent: 'PT13M26S',
      totalQuestionsAnswered: 27
    },
    {
      assignmentId: 2532069,
      assignmentName: 'EAQ Intermediate Urinary',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-27T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT16M15S',
      classAvgTimeSpent: 'PT17M22.476190476S',
      totalQuestionsAnswered: 33
    },
    {
      assignmentId: 2532066,
      assignmentName: 'Spread of Infection',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-02T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.012275132275132333,
      timeSpent: 'PT37S',
      classAvgTimeSpent: 'PT3M33.095238095S',
      totalQuestionsAnswered: 9
    },
    {
      assignmentId: 2591202,
      assignmentName: 'EAQ intermediate Medication Administration',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT6M57S',
      classAvgTimeSpent: 'PT33M5.523809523S',
      totalQuestionsAnswered: 23
    },
    {
      assignmentId: 2532067,
      assignmentName: 'Nutrition: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-13T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9230769230769231',
      classAvgScore: 0.9706959706959667,
      timeSpent: 'PT7M4S',
      classAvgTimeSpent: 'PT8M43.523809523S',
      totalQuestionsAnswered: 13
    },
    {
      assignmentId: 2532065,
      assignmentName: 'EAQ Intermediate Fluid, Electrolyes, Acid-Base Balance',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-11T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT1H2M16S',
      classAvgTimeSpent: 'PT34M33.476190476S',
      totalQuestionsAnswered: 73
    },
    {
      assignmentId: 2532078,
      assignmentName: 'EAQ Novice Hygiene & Personal Care',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-10-30T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT9M4S',
      classAvgTimeSpent: 'PT12M30.666666666S',
      totalQuestionsAnswered: 18
    },
    {
      assignmentId: 2532079,
      assignmentName: 'Cognition and Sensation: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-12-04T04:59:00Z',
      pastDue: true,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9420289855072429,
      timeSpent: 'PT4M47S',
      classAvgTimeSpent: 'PT9M18.095238095S',
      totalQuestionsAnswered: 23
    },
    {
      assignmentId: 2532074,
      assignmentName: 'Urinary Elimination: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-16T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9417989417989429,
      timeSpent: 'PT8M9S',
      classAvgTimeSpent: 'PT3M30.476190476S',
      totalQuestionsAnswered: 9
    },
    {
      assignmentId: 2532075,
      assignmentName: 'EAQ Intermediate Hygiene & Personal Care',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9047619047619048,
      timeSpent: 'PT18M55S',
      classAvgTimeSpent: 'PT20M8.666666666S',
      totalQuestionsAnswered: 26
    },
    {
      assignmentId: 2532073,
      assignmentName: 'Integumentary System and Mucous Membranes: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-10-30T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.01306547619047619,
      timeSpent: 'PT1M6S',
      classAvgTimeSpent: 'PT17M7.38095238S',
      totalQuestionsAnswered: 16
    },
    {
      assignmentId: 2532087,
      assignmentName: 'EAQ Intermediate Activity, Immobility, & Safe Movement',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-27T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT13M30S',
      classAvgTimeSpent: 'PT22M11.095238095S',
      totalQuestionsAnswered: 46
    },
    {
      assignmentId: 2532085,
      assignmentName: 'EAQ Novice Medication Administration',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-10-26T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT51M35S',
      classAvgTimeSpent: 'PT21M13.19047619S',
      totalQuestionsAnswered: 33
    },
    {
      assignmentId: 2532083,
      assignmentName: 'Medication Administration: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-10-31T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9473684210526315',
      classAvgScore: 0.010225563909774334,
      timeSpent: 'PT22M31S',
      classAvgTimeSpent: 'PT9M10.238095238S',
      totalQuestionsAnswered: 19
    },
    {
      assignmentId: 2532080,
      assignmentName: 'Infection and Infection Control: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-02T03:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '1.0',
      classAvgScore: 0.9543650793650762,
      timeSpent: 'PT29M23S',
      classAvgTimeSpent: 'PT9M2.904761904S',
      totalQuestionsAnswered: 24
    },
    {
      assignmentId: 2591231,
      assignmentName: 'EAQ intermediate Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT33M46S',
      classAvgTimeSpent: 'PT30M58.857142857S',
      totalQuestionsAnswered: 44
    },
    {
      assignmentId: 2532094,
      assignmentName: 'EAQ Intermediate Medication Administration',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'IN_PROGRESS',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: 'null',
      classAvgScore: 0.09523809523809523,
      timeSpent: 'PT14M21S',
      classAvgTimeSpent: 'PT11M23S',
      totalQuestionsAnswered: 22
    },
    {
      assignmentId: 2532095,
      assignmentName: 'Fluid Balance: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-30T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9285714285714286',
      classAvgScore: 0.9659863945578238,
      timeSpent: 'PT5M18S',
      classAvgTimeSpent: 'PT6M28.19047619S',
      totalQuestionsAnswered: 14
    },
    {
      assignmentId: 2532092,
      assignmentName: 'EAQ Intermediate Cognition & Sensory Alteration',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-11T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT47M57S',
      classAvgTimeSpent: 'PT30M17S',
      totalQuestionsAnswered: 47
    },
    {
      assignmentId: 2532093,
      assignmentName: 'EAQ Asepsis & Infection Control',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-02T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT27M35S',
      classAvgTimeSpent: 'PT15M28.523809523S',
      totalQuestionsAnswered: 24
    },
    {
      assignmentId: 2591227,
      assignmentName: 'EAQ intermediate Pain Management',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9047619047619048,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT19M25.142857142S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532090,
      assignmentName: 'EAQ Intermediate Oxygenation & Tissue Perfusion',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-11T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT6M10S',
      classAvgTimeSpent: 'PT16M53.333333333S',
      totalQuestionsAnswered: 30
    },
    {
      assignmentId: 2532088,
      assignmentName: 'EAQ Perioperative Nursing Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-12-07T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 1,
      timeSpent: 'PT4M57S',
      classAvgTimeSpent: 'PT21M13.619047619S',
      totalQuestionsAnswered: 22
    },
    {
      assignmentId: 2532089,
      assignmentName: 'Oxygenation and Perfusion: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-23T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9411764705882353',
      classAvgScore: 0.9131652661064381,
      timeSpent: 'PT22M26S',
      classAvgTimeSpent: 'PT11M11.714285714S',
      totalQuestionsAnswered: 17
    },
    {
      assignmentId: 2532063,
      assignmentName: 'EAQ Intermediate Asepsis & Infection Control',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9047619047619048,
      timeSpent: 'PT45M36S',
      classAvgTimeSpent: 'PT16M0.571428571S',
      totalQuestionsAnswered: 26
    },
    {
      assignmentId: 2532060,
      assignmentName: 'Activity and Movement: Implement and Take Action; Evaluate',
      assignmentType: 'LESSONS',
      gradeType: 'SCORED',
      status: 'COMPLETED',
      dueDate: '2023-11-09T04:59:00Z',
      pastDue: false,
      gradePoints: 100,
      score: '0.9523809523809523',
      classAvgScore: 0.9251700680272144,
      timeSpent: 'PT18M23S',
      classAvgTimeSpent: 'PT8M17.714285714S',
      totalQuestionsAnswered: 21
    },
    {
      assignmentId: 2532059,
      assignmentName: 'EAQ Elimination Urinary Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2023-11-16T04:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.9523809523809523,
      timeSpent: 'PT24M53S',
      classAvgTimeSpent: 'PT21M12.428571428S',
      totalQuestionsAnswered: 22
    },
    {
      assignmentId: 2532076,
      assignmentName: 'Chapter 40, Bowel Elimination: pp. 1022-1050; Chapter 41, Urinary Elimination: pp. 1051-1085 (64 pages, est. 3 hr 12 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 1,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532091,
      assignmentName: 'Chapter 29, Skin Integrity and Wound Care: pp. 602-656 (55 pages, est. 2 hr 45 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532096,
      assignmentName: 'Chapter 32, Stress and Coping: pp. 716-734; Chapter 42, Death and Loss: pp. 1086-1108 (42 pages, est. 2 hr 6 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 1,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532107,
      assignmentName: 'NG Bowel- not for a grade',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532109,
      assignmentName: 'Chapter 36, Pain Management: pp. 867-890 (24 pages, est. 1 hr 12 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532114,
      assignmentName: 'Chapter 28, Activity, Immobility, and Safe Movement: pp. 555-601 (47 pages, est. 2 hr 21 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532124,
      assignmentName: 'Chapter 30, Nutrition: pp. 657-696 (40 pages, est. 2 hr)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532129,
      assignmentName:
        'Chapter 31, Cognitive and Sensory Alterations: pp. 697-715; Chapter 33, Sleep: pp. 735-754; Chapter 37, Perioperative Nursing Care: pp. 891-927 (76 pages, est. 3 hr 48 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 3,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532135,
      assignmentName: 'Chapter 39, Fluid, Electrolyte, and Acid-Base Balance: pp. 966-1021 (56 pages, est. 2 hr 48 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532061,
      assignmentName: 'EAQ Intermediate Skin Integrity & Wound Care',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'NOT_STARTED',
      dueDate: '2023-11-06T04:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: 'null',
      classAvgScore: 0.09523809523809523,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT7M51.619047619S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532058,
      assignmentName: 'Mobility In class activity- not for a grade',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532064,
      assignmentName: 'Chapter 33, Sleep: pp. 735-754 (20 pages, est. 1 hr)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532070,
      assignmentName: 'Overview of Electrolyte Balance',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532072,
      assignmentName: 'Class activity: Next Gen Safety Case study (not for a grade)',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532077,
      assignmentName: 'Chapter 42, Death and Loss: pp. 1086-1108 (23 pages, est. 1 hr 9 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532082,
      assignmentName: 'Chapter 26, Asepsis and Infection Control: pp. 479-508 (30 pages, est. 1 hr 30 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532084,
      assignmentName: 'Overview of Loss and Grief (Recommended for HESI prep)',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: '2023-12-14T04:59:00Z',
      pastDue: true,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0.2976190476190476,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT7M9.952380952S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532101,
      assignmentName: 'Chapter 38, Oxygenation and Tissue Perfusion: pp. 928-965 (38 pages, est. 1 hr 54 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532117,
      assignmentName: 'Chapter 35, Medication Administration: pp. 798-866 (69 pages, est. 3 hr 27 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532131,
      assignmentName: 'Fluid Imbalance',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532132,
      assignmentName: 'Electrolyte Imbalance',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532134,
      assignmentName: 'Overview of Fluid Balance',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532062,
      assignmentName: 'Class activity- Pain NG- Not for a grade',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532086,
      assignmentName: 'Nutrition- NG - not for a grade',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532097,
      assignmentName: 'Chapter 27, Hygiene and Personal Care: pp. 509-554 (46 pages, est. 2 hr 18 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532108,
      assignmentName: 'NG urinary- Not for a grade',
      assignmentType: 'AUTHESS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 7,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532115,
      assignmentName: 'Chapter 41, Urinary Elimination: pp. 1051-1085 (35 pages, est. 1 hr 45 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532121,
      assignmentName: 'Chapter 25, Safety: pp. 449-478 (30 pages, est. 1 hr 30 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532127,
      assignmentName:
        'Chapter 32, Stress and Coping: pp. 716-734; Chapter 34, Diagnostic Testing: pp. 755-797; Chapter 42, Death and Loss: pp. 1086-1108 (85 pages, est. 4 hr 15 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 3,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2592448,
      assignmentName: 'Chapter 37, Perioperative Nursing Care: pp. 891-927 (37 pages, est. 1 hr 51 min)',
      assignmentType: 'EBOOK_READING',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 0,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 2532081,
      assignmentName: 'Overview of Stress and Coping (Recommended for HESI prep)',
      assignmentType: 'LESSONS',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: '2023-12-14T04:59:00Z',
      pastDue: true,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0.32063492063492094,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT4M53.142857142S',
      totalQuestionsAnswered: 0
    }
  ]
};
