import React from 'react';

export interface ColumnProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  field: string;
  sortField?: string;
  header: React.ReactNode;
  align?: string;
  sortable?: boolean;
  customRender?: Function;
  customSort?: Function;
  handleHeaderClick?: Function;
  cssModifier?: string;
  sticky?: boolean;
  dropDownOption?: string;
}

export interface User {
  firstName: string;
  lastName?: string;
}

export interface WindowMode {
  mobile: boolean;
  tablet: boolean;
  bigTablet: boolean;
  desktop: boolean;
  wide: boolean;
}

export interface DropdownOption {
  name: string;
  value: string;
  hidden?: boolean;
  icon?: string;
  iconPrefix?: string;
  dependedOptions?: Array<DropdownOption>;
}

export interface MenuOption {
  key: string;
  name: string;
  displayName?: string;
}

export interface DependentOption {
  parentOption: string;
  dependentOptionlist: string[];
}

export enum TableFilterRowType {
  RANGE,
  MULTI_SELECT,
  SLIDE_SWITCH,
  DATE_RANGE,
  MONTH_RANGE,
  DECIMAL_RANGE,
  DROPDOWN,
  CHECKBOX_MENU
}

export interface TableFilterRowConfig {
  key: string;
  field: string;
  label: string;
  tagLabel: string;
  type: TableFilterRowType;
  tagUnit?: string;
  min?: string;
  max?: string;
  isPercent?: boolean;
  options?: MenuOption[];
  dropdownOptions?: DropdownOption[];
  isChecked?: boolean;
  toField?: string;
  placeHolder?: string;
}

export interface TableFilterRowState {
  type: TableFilterRowType;
  hasData: boolean;
  checkedKeys: string[];
  from: number | string;
  to: number | string;
  isChecked: boolean;
  selectedOptionValue: string;
}

export interface TableFilterRowsState {
  [key: string]: TableFilterRowState;
}

export interface AssignmentTypeOptions {
  key: string;
  name: string;
  icon: string;
}

export interface NavItem {
  name: string;
  paths?: Array<string>;
  icon?: string;
  isAlwaysActive?: boolean;
  onClickCustomLink?: Function;
}

export interface PageNavigation {
  default: NavItem[];
  expand: NavItem[];
  extraActive: NavItem[];
}

export interface CardAndTableSortInteraction {
  sortDirection: string;
  headerSelector: string;
}

export interface ToggleButtonOption {
  value: number;
  label: string;
  icon?: string;
  iconPrefix?: string;
}

export interface DynamicScoreScaleProps {
  atRisk: {
    max: number;
    label: string;
  };
  belowAcceptable: {
    max: number;
    label: string;
  };
  acceptable: {
    max: number;
    label: string;
  };
}

export interface HesiNGExamResultFilter {
  examGroups?: string[];
  examType?: string;
  studentId?: number;
  setParamsFromHesiApp?: boolean;
}

export interface HesiNGExamResultDateFilter {
  fromDate?: number;
  toDate?: number;
  selectedDate?: string;
}

export interface StudentHesiNGExamResultFilter {
  examId: number;
  examGroupId: number;
  studentId: number;
  setParamsFromHesiApp?: boolean;
}

export interface ChartPosition {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
}
