import { SORT_DIRECTION } from 'constants/app.constant';
import { PSSherpathCourse } from 'reports/ps/models';

export const getSortedCourseByAverageScore = (a: PSSherpathCourse, b: PSSherpathCourse, direction: string): number => {
  if (direction === SORT_DIRECTION.ASC) {
    return a.assignmentAverageScore - b.assignmentAverageScore;
  }
  return b.assignmentAverageScore - a.assignmentAverageScore;
};

export const getCoursesFromSearchKeyword = (searchKeyword: string, courseList: PSSherpathCourse[] = []): PSSherpathCourse[] => {
  return courseList.filter(course => course.courseTitle.toLowerCase().includes(searchKeyword.toLowerCase()));
};
