import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { ELSLoggingService } from '@els/els-ui-common-react';
import { AppConstant, SORT_DIRECTION_ENUM, FeatureFlagNames } from 'constants/app.constant';
import { appActions } from 'redux/ducks/app';
import {
  fetchAssignmentDetail,
  fetchAssignmentInfo,
  fetchCourseSectionRoles,
  fetchEAQFeatures,
  fetchAssignmentEngagements,
  fetchStudentEngagements,
  fetchEngagementSummary,
  fetchCourseAggregate,
  fetchCWCourseList,
  fetchCourseAggregates
} from 'reports/cw/services/report.service';
import { ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { getAssignmentTypeOptions } from 'reports/cw/components/pages/engagement/Engagement';
import { filterActions } from 'redux/ducks/filter';
import { engagementSummarySortOption, getSortedEngagementSummary } from 'reports/cw/components/pages/engagement-summary/engagement-summary-sort/EngagementSummarySort';
import { getSortedCourseAggregate } from 'reports/cw/helpers/report.helper';
import { buildCourseData } from 'reports/cw/helpers/course-aggregate.helper';
import { isFeatureEnabled } from 'services/feature-flag.service';

const fileName = 'Courseware redux';

const initialState = Immutable({
  studentEngagement: null,
  assignmentEngagement: null,
  assignmentReport: null,
  assignmentTypeOptions: [],
  engagementSummary: null,
  courseAggregateData: [],
  courseAggregateOriginalData: [],
  isEngagementSummaryLoading: true,
  isReportLoading: false,
  isAssignmentDetailLoading: false,
  isAssignmentEngagementReportLoading: true,
  isStudentEngagementReportLoading: true,
  isCourseAggregateReportLoading: true,
  featureItems: [],
  isAssignmentReportFeatureShown: null,
  assignmentInfo: null,
  assignmentSummaryCardAndTableSortInteraction: null,
  assignmentEngagementCardAndTableSortInteraction: null,
  engagementSummaryCardAndTableSortInteraction: null,
  studentEngagementCardAndTableSortInteraction: null,
  studentHomeCardAndAssignmentSummaryTableSortInteraction: null,
  studentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction: null,
  studentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction: null,
  studentDetailCardAndAssignmentSummaryTableSortInteraction: null,
  selectedStudentId: '',
  totalCompletedAssignments: 0,
  assignmentSummaryCard: {
    totalAssignments: 0,
    totalCompletedAssignments: 0,
    highPerformanceStudents: 0,
    totalStudents: 0,
    disengagedStudents: 0
  },
  engagementSummaryCard: {
    classAvgScore: 0,
    totalAssignments: 0,
    totalCompletedAssignments: 0,
    totalStudents: 0,
    totalStudentsPastDue: 0
  }
});

const prefixActionName = 'CW';
const setStudentEngagement = createAction(`${prefixActionName}/SET_STUDENT_ENGAGEMENT`);
const setAssignmentEngagement = createAction(`${prefixActionName}/SET_ASSIGNMENT_ENGAGEMENT`);
const setEngagementSummary = createAction(`${prefixActionName}/SET_ENGAGEMENT_SUMMARY`);
const setIsEngagementSummaryLoading = createAction(`${prefixActionName}/SET_IS_ENGAGEMENT_SUMMARY_LOADING`);
const setAssignmentSummaryCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_ASSIGNMENT_SUMMARY_CARD_AND_TABLE_SORT_INTERACTION`);
const setAssignmentInfo = createAction(`${prefixActionName}/SET_ASSIGNMENT_INFO`);
const setAssignmentTypeOptions = createAction(`${prefixActionName}/SET_ASSIGNMENT_TYPE_OPTIONS`);
const setAssignmentDetail = createAction(`${prefixActionName}/SET_ASSIGNMENT_DETAIL`);
const setCourseAggregateData = createAction(`${prefixActionName}/SET_COURSE_AGGREGATE_DATA`);
const setCourseAggregateOriginalData = createAction(`${prefixActionName}/SET_COURSE_AGGREGATE_ORIGINAL_DATA`);
const setIsAssignmentDetailLoading = createAction(`${prefixActionName}/SET_IS_ASSIGNMENT_DETAIL_LOADING`);
const setIsAssignmentEngagementReportLoading = createAction(`${prefixActionName}/SET_IS_ASSIGNMENT_ENGAGEMENT_REPORT_LOADING`);
const setIsStudentEngagementReportLoading = createAction(`${prefixActionName}/SET_IS_STUDENT_ENGAGEMENT_REPORT_LOADING`);
const setReportLoading = createAction(`${prefixActionName}/SET_REPORT_LOADING`);
const setIsCourseAggregateReportLoading = createAction(`${prefixActionName}/SET_IS_COURSE_AGGREGATE_REPORT_LOADING`);
const setFeatureItems = createAction(`${prefixActionName}/SET_FEATURE_ITEMS`);
const setStudentEngagementCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_STUDENT_ENGAGEMENT_CARD_AND_TABLE_SORT_INTERACTION`);
const setAssignmentEngagementCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_ASSIGNMENT_ENGAGEMENT_CARD_AND_TABLE_SORT_INTERACTION`);
const setEngagementSummaryCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_ENGAGEMENT_SUMMARY_CARD_AND_TABLE_SORT_INTERACTION`);
const setStudentHomeCardAndAssignmentEngagementTableSortInteraction = createAction(`${prefixActionName}/SET_STUDENT_HOME_CARD_AND_ASSIGNMENT_ENGAGEMENT_TABLE_INTERACTION`);
const setStudentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction = createAction(
  `${prefixActionName}/SET_STUDENT_HOME_CARD_PERFORM_LINK_AND_ASSIGNMENT_SUMMARY_TABLE_INTERACTION`
);
const setStudentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction = createAction(
  `${prefixActionName}/SET_STUDENT_HOME_CARD_PERFORM_LINK_AND_ASSIGNMENT_ENGAGEMENT_TABLE_INTERACTION`
);
const setStudentDetailCardAndAssignmentSummaryTableSortInteraction = createAction(`${prefixActionName}/SET_STUDENT_DETAIL_CARD_AND_ASSIGNMENT_SUMMARY_TABLE_INTERACTION`);
const setSelectedStudentId = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_ID`);
const setTotalCompletedAssignments = createAction(`${prefixActionName}/SET_TOTAL_COMPLETED_ASSIGNMENTS`);
const setAssignmentSummaryCard = createAction(`${prefixActionName}/SET_ASSIGNMENT_SUMMARY_CARD`);

const fetchAssignmentDetailReport = assignmentID => dispatch => {
  dispatch(setIsAssignmentDetailLoading(true));
  Promise.all([fetchAssignmentInfo(assignmentID), fetchAssignmentDetail(assignmentID)])
    .then(results => {
      dispatch(setAssignmentInfo(results[0]));
      dispatch(setAssignmentDetail(results[1]));
    })
    .catch(err => {
      ELSLoggingService.error(fileName, `Error while attempting to fetch data of assignment with id=${assignmentID}, error: ${err.message}`);
    })
    .finally(() => {
      dispatch(setIsAssignmentDetailLoading(false));
    });
};

const fetchStudentEngagementReport = (courseId: number, assignmentType: string) => async dispatch => {
  dispatch(setIsStudentEngagementReportLoading(true));
  try {
    let studentEngagementResponse = await fetchStudentEngagements(courseId, assignmentType);
    if (assignmentType === ASSIGNMENT_TYPES.LESSONS.id) {
      const adaptiveLessonStudentEngagementResponse = await fetchStudentEngagements(courseId, ASSIGNMENT_TYPES.ADAPTIVE_LESSON.id);
      if (adaptiveLessonStudentEngagementResponse.data?.metrics?.length > studentEngagementResponse.data?.metrics?.length) {
        studentEngagementResponse = adaptiveLessonStudentEngagementResponse;
      }
    }
    dispatch(setStudentEngagement(studentEngagementResponse));
  } catch (err) {
    ELSLoggingService.error(fileName, `Failed to fetch student engagement data: ${err.message}`);
  } finally {
    dispatch(setIsStudentEngagementReportLoading(false));
  }
};

const buildAssignmentTypeOptions = (listContentTypeAvailable: string[], dispatch, getState): void => {
  const assignmentTypeOptions = getAssignmentTypeOptions(listContentTypeAvailable || []);
  if (assignmentTypeOptions.findIndex(typeOption => typeOption?.key === getState()[AppConstant.reduxResources.FILTER_STATE].cwEngagementAssignmentTypeDropdownId) === -1) {
    dispatch(filterActions.setCWEngagementAssignmentTypeDropdownId(assignmentTypeOptions[0]?.key));
  }
  dispatch(setAssignmentTypeOptions(assignmentTypeOptions));
};

const fetchAssignmentEngagementReport = (courseId: number, assignmentType = null) => async (dispatch, getState) => {
  dispatch(setIsAssignmentEngagementReportLoading(true));
  try {
    const { data } = await fetchAssignmentEngagements(courseId, assignmentType);
    const { listContentTypeAvailable } = data;
    buildAssignmentTypeOptions(listContentTypeAvailable, dispatch, getState);
    dispatch(setAssignmentEngagement(data));
  } catch (err) {
    ELSLoggingService.error(fileName, `Failed to fetch assignment engagement data: ${err.message}`);
  } finally {
    dispatch(setIsAssignmentEngagementReportLoading(false));
  }
};

const initializeCourseSectionRoles = userId => dispatch => {
  fetchCourseSectionRoles(userId).then(courseRolesData => {
    dispatch(appActions.setCourseSectionRoles(courseRolesData));
  });
};

const fetchFeatureItems = () => dispatch => {
  fetchEAQFeatures().then(data => {
    dispatch(setFeatureItems(data));
  });
};

const fetchEngagementSummaryReport = (courseSectionId: number) => async dispatch => {
  dispatch(setIsEngagementSummaryLoading(true));
  try {
    const { data: engagementSummary } = await fetchEngagementSummary(courseSectionId);
    const filteredTypeCardsListByType = (engagementSummary.typeCardsList || []).filter(card => ASSIGNMENT_TYPES[card.assignmentType]);
    const filteredEngagementSummaryAssignmentByType = {
      ...engagementSummary,
      typeCardsList: filteredTypeCardsListByType
    };
    const sortedEngagementSummary = getSortedEngagementSummary(filteredEngagementSummaryAssignmentByType, SORT_DIRECTION_ENUM.desc, engagementSummarySortOption[0].value);
    dispatch(setEngagementSummary(sortedEngagementSummary));
  } catch (err) {
    ELSLoggingService.error(`${fileName} - Failed to fetch engagement summary data. Message: `, err.message);
  } finally {
    dispatch(setIsEngagementSummaryLoading(false));
  }
};

const fetchCourseAggregateReport = () => async (dispatch, getState) => {
  const appState = getState()[AppConstant.reduxResources.APP_STATE];
  const { featureFlags = [], courses = [], loggedInUser } = appState;
  const isCWActiveCourseEnable = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_ACTIVE_COURSE_ENABLED);
  dispatch(setIsCourseAggregateReportLoading(true));
  try {
    let result = [];
    if (isCWActiveCourseEnable) {
      const { id, evolveUser, userId } = loggedInUser;
      const instructorId = id || evolveUser?.eolsId || userId;
      const { data } = await fetchCourseAggregates(instructorId);
      result = data;
    } else {
      const {
        data: { courseIds = [] }
      } = await fetchCWCourseList();
      const courseAggregateReportRes = await fetchCourseAggregate(courseIds);
      const sortedData = getSortedCourseAggregate(courseAggregateReportRes || [], SORT_DIRECTION_ENUM.desc, 'averageScore');
      const remapCourses = buildCourseData(sortedData, courses);
      result = remapCourses;
    }
    dispatch(setCourseAggregateData(result));
    dispatch(setCourseAggregateOriginalData(result));
  } catch (err) {
    dispatch(setCourseAggregateData([]));
    dispatch(setCourseAggregateOriginalData([]));
    ELSLoggingService.error(`${fileName} - Failed to fetch course aggregate data. Message: `, err.message);
  } finally {
    dispatch(setIsCourseAggregateReportLoading(false));
  }
};

const actions = {
  setAssignmentSummaryCardAndTableSortInteraction,
  setAssignmentEngagementCardAndTableSortInteraction,
  setEngagementSummaryCardAndTableSortInteraction,
  setStudentEngagementCardAndTableSortInteraction,
  setStudentHomeCardAndAssignmentEngagementTableSortInteraction,
  setStudentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction,
  setStudentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction,
  setStudentDetailCardAndAssignmentSummaryTableSortInteraction,
  setAssignmentInfo,
  setAssignmentTypeOptions,
  setAssignmentDetail,
  setIsAssignmentDetailLoading,
  setIsAssignmentEngagementReportLoading,
  setIsStudentEngagementReportLoading,
  setTotalCompletedAssignments,
  setAssignmentSummaryCard,
  setReportLoading,
  setIsCourseAggregateReportLoading,
  setFeatureItems,
  setStudentEngagement,
  setAssignmentEngagement,
  setCourseAggregateData,
  setCourseAggregateOriginalData,
  fetchAssignmentDetailReport,
  fetchAssignmentEngagementReport,
  fetchCourseAggregateReport,
  fetchStudentEngagementReport,
  fetchFeatureItems,
  fetchEngagementSummaryReport,
  initializeCourseSectionRoles,
  setSelectedStudentId,
  setEngagementSummary,
  setIsEngagementSummaryLoading
};

const reducer = handleActions(
  {
    [actions.setStudentEngagement]: (state, { payload }) => Immutable.set(state, 'studentEngagement', payload.data),
    [actions.setAssignmentEngagement]: (state, { payload }) => Immutable.set(state, 'assignmentEngagement', payload),
    [actions.setAssignmentTypeOptions]: (state, { payload }) => Immutable.set(state, 'assignmentTypeOptions', payload),
    [actions.setEngagementSummary]: (state, { payload }) => Immutable.set(state, 'engagementSummary', payload),
    [actions.setCourseAggregateData]: (state, { payload }) => Immutable.set(state, 'courseAggregateData', payload),
    [actions.setCourseAggregateOriginalData]: (state, { payload }) => Immutable.set(state, 'courseAggregateOriginalData', payload),
    [actions.setIsEngagementSummaryLoading]: (state, { payload }) => Immutable.set(state, 'isEngagementSummaryLoading', payload),
    [actions.setIsAssignmentEngagementReportLoading]: (state, { payload }) => Immutable.set(state, 'isAssignmentEngagementReportLoading', payload),
    [actions.setIsStudentEngagementReportLoading]: (state, { payload }) => Immutable.set(state, 'isStudentEngagementReportLoading', payload),
    [actions.setIsCourseAggregateReportLoading]: (state, { payload }) => Immutable.set(state, 'isCourseAggregateReportLoading', payload),
    [actions.setAssignmentEngagementCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'assignmentEngagementCardAndTableSortInteraction', payload),
    [actions.setEngagementSummaryCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'engagementSummaryCardAndTableSortInteraction', payload),
    [actions.setStudentEngagementCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'studentEngagementCardAndTableSortInteraction', payload),
    [actions.setAssignmentSummaryCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'assignmentSummaryCardAndTableSortInteraction', payload),
    [actions.setStudentHomeCardAndAssignmentEngagementTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'studentHomeCardAndAssignmentEngagementTableSortInteraction', payload),
    [actions.setStudentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'studentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction', payload),
    [actions.setStudentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'studentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction', payload),
    [actions.setStudentDetailCardAndAssignmentSummaryTableSortInteraction]: (state, { payload }) =>
      Immutable.set(state, 'studentDetailCardAndAssignmentSummaryTableSortInteraction', payload),
    [actions.setAssignmentInfo]: (state, { payload }) => Immutable.set(state, 'assignmentInfo', payload.data),
    [actions.setAssignmentDetail]: (state, { payload }) => Immutable.set(state, 'assignmentDetail', payload.data),
    [actions.setReportLoading]: (state, { payload }) => Immutable.set(state, 'isReportLoading', payload),
    [actions.setFeatureItems]: (state, { payload }) => Immutable.set(state, 'featureItems', payload.data),
    [actions.setIsAssignmentDetailLoading]: (state, { payload }) => Immutable.set(state, 'isAssignmentDetailLoading', payload),
    [actions.setSelectedStudentId]: (state, { payload }) => Immutable.set(state, 'selectedStudentId', payload),
    [actions.setTotalCompletedAssignments]: (state, { payload }) => Immutable.set(state, 'totalCompletedAssignments', payload),
    [actions.setAssignmentSummaryCard]: (state, { payload }) => Immutable.set(state, 'assignmentSummaryCard', payload)
  },
  initialState
);

const getCwState = state => state[AppConstant.reduxResources.CW_STATE];

const selectors = {
  getStudentEngagement: createSelector(getCwState, state => Immutable.asMutable(state.studentEngagement || {}, { deep: true })),
  getAssignmentEngagement: createSelector(getCwState, state => Immutable.asMutable(state.assignmentEngagement || {}, { deep: true })),
  getAssignmentTypeOptions: createSelector(getCwState, state => state.assignmentTypeOptions),
  getEngagementSummary: createSelector(getCwState, state => state.engagementSummary),
  getCourseAggregateData: createSelector(getCwState, state => state.courseAggregateData),
  getCourseAggregateOriginalData: createSelector(getCwState, state => state.courseAggregateOriginalData),
  getIsEngagementSummaryLoading: createSelector(getCwState, state => state.isEngagementSummaryLoading),
  getAssignmentSummaryCardAndTableSortInteraction: createSelector(getCwState, state => state.assignmentSummaryCardAndTableSortInteraction),
  getStudentEngagementCardAndTableSortInteraction: createSelector(getCwState, state => state.studentEngagementCardAndTableSortInteraction),
  getAssignmentEngagementCardAndTableSortInteraction: createSelector(getCwState, state => state.assignmentEngagementCardAndTableSortInteraction),
  getEngagementSummaryCardAndTableSortInteraction: createSelector(getCwState, state => state.engagementSummaryCardAndTableSortInteraction),
  getStudentHomeCardAndAssignmentEngagementTableSortInteraction: createSelector(getCwState, state => state.studentHomeCardAndAssignmentEngagementTableSortInteraction),
  getStudentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction: createSelector(
    getCwState,
    state => state.studentHomeCardPerformLinkAndAssignmentSummaryTableSortInteraction
  ),
  getStudentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction: createSelector(
    getCwState,
    state => state.studentHomeCardPerformLinkAndAssignmentEngagementTableSortInteraction
  ),
  getStudentDetailCardAndAssignmentSummaryTableSortInteraction: createSelector(getCwState, state => state.studentDetailCardAndAssignmentSummaryTableSortInteraction),
  getAssignmentReport: createSelector(getCwState, state => state.assignmentReport),
  getReportLoading: createSelector(getCwState, state => state.isReportLoading),
  getAssignmentEngagementReportLoading: createSelector(getCwState, state => state.isAssignmentEngagementReportLoading),
  getStudentEngagementReportLoading: createSelector(getCwState, state => state.isStudentEngagementReportLoading),
  getIsAssignmentDetailLoading: createSelector(getCwState, state => state.isAssignmentDetailLoading),
  getIsCourseAggregateReportLoading: createSelector(getCwState, state => state.isCourseAggregateReportLoading),
  getAssignmentInfo: createSelector(getCwState, state => state.assignmentInfo),
  getAssignmentDetail: createSelector(getCwState, state => state.assignmentDetail),
  getSelectedStudentId: createSelector(getCwState, state => state.selectedStudentId),
  getTotalCompletedAssignments: createSelector(getCwState, state => state.totalCompletedAssignments),
  getAssignmentSummaryCard: createSelector(getCwState, state => state.assignmentSummaryCard)
};

export { actions as cwActions, reducer as cwReducer, selectors as cwSelectors };
export { initialState as cwInitialState };
