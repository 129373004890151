import React, { Component, ReactNode } from 'react';

interface PageWrapperProps {
  children: ReactNode;
}

class PageWrapper extends Component<PageWrapperProps> {
  render() {
    return <div className="c-page-wrapper">{this.props.children}</div>;
  }
}

export default PageWrapper;
