import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { NavItem } from 'models';
import { ELSIcon } from '..';

interface ButtonMenuProps {
  menuItems: Array<NavItem>;
}

const MenuItem = ({ name, paths, icon }: NavItem) => (
  <span key={name}>
    <Link className="c-button-menu__list-item" to={paths[0]}>
      {name}
      {icon && <ELSIcon name="new-window" size="1x" />}
    </Link>
  </span>
);

const ButtonMenu = ({ menuItems }: ButtonMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="c-button-menu">
      <div
        className={classNames('c-button-menu__button', { 'c-button-menu__button-active': open })}
        role="button"
        tabIndex={0}
        onKeyDown={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        More...
      </div>
      {open && (
        <div className="c-button-menu__list-wrapper" onMouseLeave={() => setOpen(!open)}>
          <div className="c-button-menu__list">
            {menuItems.map(item => (
              <MenuItem {...item} key={item.name} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { MenuItem };
export default ButtonMenu;
