/* eslint-disable react/react-in-jsx-scope */
import { RoutePath, ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';

const { INSTRUCTOR, STUDENT } = ROUTE_ROLES;

export const AppRoutes = {
  getRoutes: () => [
    ...[RoutePath.cwStu, RoutePath.student.home].map(route => ({
      path: route,
      component: lazy(() => import('reports/cw/components/pages/student-role/student-home/StudentHome')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.home,
          pageName: `${analyticBaseConfig.edApp}:cw-student-home`
        }
      },
      isPrivate: true,
      roles: STUDENT
    })),
    ...[RoutePath.cwIns, RoutePath.home].map(route => ({
      path: route,
      component: lazy(() => import('reports/cw/components/pages/instructor-home/InstructorHomeComponent')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.home,
          pageName: `${analyticBaseConfig.edApp}:cw-instructor-home`
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }))
  ]
};
