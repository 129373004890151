import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withHTMLHeadSEO, withLoading } from 'components/common';
import { filterActions, filterSelectors } from 'redux/ducks/filter';
import { cwSelectors } from 'reports/cw/redux/ducks/cw';
import { AssignmentEngagementMetricDTO, StudentEngagementDTO, StudentEngagementRecord } from 'reports/cw/models';
import { remapFieldsForEngagementReport } from 'reports/cw/services/report.service';
import { ASSIGNMENT_TYPES, ASSIGNMENT_TYPE_GROUPS } from 'reports/cw/constants/report.constant';
import { getIsOsmosisAssignment } from 'reports/cw/helpers/report.helper';
import { pluralizeUnit, renderNoDataFound, renderNoResultsFound } from 'helpers/ui.helper';
import TableFilter from 'components/common/table-filter/TableFilter';
import withResizeScreen from 'components/common/with-resize-screen/withResizeScreen';
import { WindowMode } from 'models';
import StudentEngagementTable from './student-engagement-table/StudentEngagementTable';
import StudentEngagementTableMobile from './student-engagement-table-mobile/StudentEngagementTableMobile';

export interface StudentEngagementProps {
  searchKeyword: string;
  assignmentTypeId: string;
  filteredAssignmentEngagement: AssignmentEngagementMetricDTO[];
  studentEngagement: StudentEngagementDTO;
  setSearchKeyword: Function;
  windowMode: WindowMode;
  isIncorrectDataHidden?: boolean;
  isGradePointsHidden?: boolean;
}

export class StudentEngagementComponent extends Component<StudentEngagementProps> {
  onSearch = (searchKeyword: string): void => {
    this.props.setSearchKeyword(searchKeyword);
  };

  filterTableData = (isEAQAssignmentType: boolean): StudentEngagementRecord[] => {
    const {
      studentEngagement: { avgTimeOfClassOnQuestions, metrics = [] },
      searchKeyword
    } = this.props;
    return metrics
      .map(item => remapFieldsForEngagementReport(item, avgTimeOfClassOnQuestions, isEAQAssignmentType))
      .filter(item => item.studentName.toLowerCase().includes(searchKeyword.toLowerCase().trim()));
  };

  render() {
    const { studentEngagement, assignmentTypeId, filteredAssignmentEngagement, searchKeyword, windowMode, isIncorrectDataHidden, isGradePointsHidden } = this.props;

    if ((studentEngagement?.metrics || []).length === 0) {
      return renderNoDataFound();
    }
    const { contentType, avgScoreOfClass } = studentEngagement;
    const isEAQAssignmentType = ASSIGNMENT_TYPES[contentType]?.group === ASSIGNMENT_TYPE_GROUPS.EAQ;
    const isOsmosisAssignmentType = getIsOsmosisAssignment(contentType);
    const tableData = this.filterTableData(isEAQAssignmentType);
    const numberOfStudents = tableData.length;
    const numberOfAssignments = filteredAssignmentEngagement.length;
    const studentEngagementTableProps = {
      avgScoreOfClass,
      tableData,
      isEAQAssignmentType,
      isIncorrectDataHidden,
      isGradePointsHidden,
      isOsmosisAssignmentType
    };

    return (
      <div className="c-cw-student-engagement">
        <TableFilter
          filterHidden
          searchKeyword={searchKeyword}
          onSearch={this.onSearch}
          searchPlaceholder="Search by student name"
          customClass="u-els-margin-top-2x u-els-margin-bottom-2x u-els-margin-bottom-1x@mobile"
        >
          <h4 className="c-cw-student-engagement__header">
            <span>{`Showing `}</span>
            <strong>{numberOfStudents}</strong>
            <span>{` student${numberOfStudents === 1 ? "'s" : "s'"} engagement in `}</span>
            <strong>{`${numberOfAssignments} ${ASSIGNMENT_TYPES[assignmentTypeId].name} `}</strong>
            <span>{`${pluralizeUnit(numberOfAssignments, 'assignment')}, including self-study`}</span>
          </h4>
        </TableFilter>
        {windowMode.mobile ? <StudentEngagementTableMobile {...studentEngagementTableProps} /> : <StudentEngagementTable {...studentEngagementTableProps} />}
        {tableData.length === 0 && renderNoResultsFound()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  studentEngagement: cwSelectors.getStudentEngagement(state),
  searchKeyword: filterSelectors.getCWStudentEngagementTableSearch(state)
});

const mapDispatchToProps = dispatch => ({
  setSearchKeyword: (searchKeyword: string) => dispatch(filterActions.setCWStudentEngagementTableSearch(searchKeyword))
});

const enhancers = [withHTMLHeadSEO({ title: 'Student Engagement & Grades' }), withLoading, withRouter, connect(mapStateToProps, mapDispatchToProps), withResizeScreen];

export { StudentEngagementComponent as StudentEngagementComponentBase };
export default compose(...enhancers)(StudentEngagementComponent);
