export default {
  courseSectionId: 622453,
  contentType: 'STANDARD',
  metrics: [
    {
      courseSectionId: 622453,
      assignmentId: 4597520,
      assignmentName: 'Antibiotic Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M45S',
      avgTimeSpentPerQuestion: 'PT33S',
      avgQuestionsAnsweredCorrect: 5.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-08-04T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597495,
      assignmentName: 'Antiviral Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M27S',
      avgTimeSpentPerQuestion: 'PT29S',
      avgQuestionsAnsweredCorrect: 5.066666666666666,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-07T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597499,
      assignmentName: 'Coagulation Modifier Drugs Pharm EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT2M',
      avgTimeSpentPerQuestion: 'PT24S',
      avgQuestionsAnsweredCorrect: 5.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 0.9375,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 15,
      totalStudentsPastDue: 1,
      totalStudents: 16,
      dueDate: '2024-07-14T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597505,
      assignmentName: 'Respiratory Drugs Pharm EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT3M31S',
      avgTimeSpentPerQuestion: 'PT42S',
      avgQuestionsAnsweredCorrect: 5.1875,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-21T03:59:00Z',
      isHaveScore: true
    },
    {
      courseSectionId: 622453,
      assignmentId: 4597509,
      assignmentName: 'Antidysrhythmic Drugs EAQ ',
      assignmentGradeType: 'PASS_FAIL',
      assignmentType: 'STANDARD',
      gradePoints: 5,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT1M50S',
      avgTimeSpentPerQuestion: 'PT22S',
      avgQuestionsAnsweredCorrect: 5.0,
      avgQuestionsAnsweredIncorrect: 0.0,
      percentComplete: 1.0,
      totalStudentsNotStarted: 0,
      totalStudentsInProgress: 0,
      totalStudentsCompleted: 16,
      totalStudentsPastDue: 0,
      totalStudents: 16,
      dueDate: '2024-07-28T03:59:00Z',
      isHaveScore: true
    }
  ],
  cardData: {
    completedAssignments: 5,
    below85percentAssignments: 0,
    pastDueAssignments: 0,
    totalAssignments: 5,
    classAvgScore: 1.0,
    totalStudents: 16,
    pastDueStudents: 2,
    belowClassAvgStudents: 2,
    inProgressAssignment: 0
  },
  listContentTypeAvailable: ['MASTERY', 'LESSONS', 'SHERPATH_CASE_STUDY', 'QUIZ_BY_QUESTION', 'STANDARD', 'AUTHESS']
};
