export const DISTRIBUTION_OF_SCORES_RANGES = {
  FROM_0_TO_54: {
    label: 'Below 54%',
    value: {
      min: 0,
      max: 54
    }
  },
  FROM_55_TO_64: {
    label: '55-64%',
    value: {
      min: 55,
      max: 64
    }
  },
  FROM_65_TO_74: {
    label: '65-74%',
    value: {
      min: 65,
      max: 74
    }
  },
  FROM_75_TO_84: {
    label: '75-84%',
    value: {
      min: 75,
      max: 84
    }
  },
  FROM_85_TO_100: {
    label: 'Above 85%',
    value: {
      min: 85,
      max: 100
    }
  }
};
