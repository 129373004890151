export default {
  id: 471204,
  courseId: '106ZVKPQ1NC',
  externalId: '4c48ed7c78538bbef6bde2755de2a1c0cc274a6e6fc6b6f358cac53d0b2b02fe-25927464660',
  lmsClientId: '100',
  consumerKey: 'keyevolve',
  lmsExternalId: '167824_sguven4_1002',
  courseName: "Sherpath Vantage for Harding Lewis's Medical-Surgical Nursing, 12th Edition",
  sectionName: null,
  duration: null,
  institution: {
    id: 5619,
    name: 'Evolve',
    externalId: 'Evolve'
  },
  courseActive: true,
  schedules: null,
  entitlements: [
    {
      id: 86399662,
      isbn: '9780443109775',
      type: 'EBOOK',
      data:
        '{"vbId":"9780323792332","components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780443109775","eeoISBN":"9780323792332","productTypeName":"Sherpath - eBook - Component - NSS","productTypeKey":"sherpath_ebook_component_nss","title":"PART - Sherpath - Lewis\' Medical-Surgical Nursing - Elsevier eBook on VitalSource","type":"ebook"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'sherpath_ebook_component_nss',
      parentIsbn: '9780443110054'
    },
    {
      id: 86399665,
      isbn: '9780323715607',
      type: 'COLLECTION',
      data:
        '{"components":[{"vbId":"9780323931274","components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780443126376","eeoISBN":"9780323931274","productTypeName":"Sherpath - eBook - Component - NSS","productTypeKey":"sherpath_ebook_component_nss","title":"PART -Sherpath - Medical-Surgical Nursing - Elsevier E-Book on VitalSource","type":"ebook"}],"isProtected":true,"isCourseware":false,"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780323715607","productTypeName":"Shadow Health","productTypeKey":"shadow_health","title":"Medical-Surgical Digital Clinical Experiences","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'shadow_health',
      parentIsbn: null
    },
    {
      id: 86451402,
      isbn: '9780323715607',
      type: 'EPRODUCT',
      data:
        '{"components":[],"isCourseware":false,"isProtected":true,"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780323715607","productTypeName":"Shadow Health","productTypeKey":"shadow_health","title":"Medical-Surgical Digital Clinical Experiences","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'shadow_health',
      parentIsbn: null
    },
    {
      id: 86399661,
      isbn: '9780443109911',
      type: 'EPRODUCT',
      data:
        '{"components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780443109911","productTypeName":"Sherpath - Component - NSS","productTypeKey":"sherpath_component_nss","title":"PART - BD: Sherpath for Harding Lewis\'s Medical-Surgical Nursing","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'sherpath_component_nss',
      parentIsbn: '9780443110054'
    },
    {
      id: 86399660,
      isbn: '9780443110054',
      type: 'COLLECTION',
      data:
        '{"components":[{"components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780443109911","productTypeName":"Sherpath - Component - NSS","productTypeKey":"sherpath_component_nss","title":"PART - BD: Sherpath for Harding Lewis\'s Medical-Surgical Nursing","type":"eproduct"},{"vbId":"9780323792332","components":[],"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780443109775","eeoISBN":"9780323792332","productTypeName":"Sherpath - eBook - Component - NSS","productTypeKey":"sherpath_ebook_component_nss","title":"PART - Sherpath - Lewis\' Medical-Surgical Nursing - Elsevier eBook on VitalSource","type":"ebook"}],"isCourseware":true,"isProtected":true,"bundleMemberProduct":[{"isbn":"9780323789615","productTypeName":"Hardcover","productTypeKey":"hardcover","title":"Lewis\'s Medical-Surgical Nursing"}],"realVantageComponent":[],"isbn":"9780443110054","productTypeName":"Sherpath Book-Organized","productTypeKey":"sherpath_book_organized","title":"Sherpath for Harding Lewis\'s Medical-Surgical Nursing","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'sherpath_book_organized',
      parentIsbn: null
    },
    {
      id: 86405395,
      isbn: '9780323883559',
      type: 'EPRODUCT',
      data:
        '{"components":[],"isCourseware":true,"isProtected":true,"bundleMemberProduct":[],"realVantageComponent":[],"isbn":"9780323883559","productTypeName":"Simulations - Course-Based SimChart","productTypeKey":"simulation_sim_chart_ng","title":"SimChart for Medical-Surgical Nursing","type":"eproduct"}',
      appId: 'sher_evol',
      evolveProductTypeKey: 'simulation_sim_chart_ng',
      parentIsbn: null
    }
  ],
  users: null,
  role: 'ACTIVE',
  locked: null,
  copied: null,
  copiedFromId: null,
  migrated: false,
  gradeSyncVersion: 2,
  createdAt: '2023-06-26T13:32:19.637+00:00',
  updatedAt: '2023-10-16T21:24:57.665+00:00'
};
