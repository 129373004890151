export default [
  {
    examType: 'Specialty',
    exams: [
      {
        programId: 203294,
        examId: 14105,
        examName: 'Demo NG Demo 1',
        dateTaken: '2024-06-12',
        cohortId: 12633,
        categories: [
          {
            categoryId: 10,
            subCategoryChildName: 'Safety & Infect Control',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 3,
            categoryScore: 0.0
          },
          {
            categoryId: 13,
            subCategoryChildName: 'Reduce Risk Potential',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 12,
            categoryScore: 854.0
          },
          {
            categoryId: 16,
            subCategoryChildName: 'Physio Adaptation',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 2,
            categoryScore: 616.0
          },
          {
            categoryId: 20,
            subCategoryChildName: 'Health Promotion & Maintenance',
            subCategoryParentName: 'Client Needs',
            numOfItems: 4,
            categoryScore: 601.0
          },
          {
            categoryId: 12,
            subCategoryChildName: 'Physiological Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 25,
            categoryScore: 688.0
          },
          {
            categoryId: 11,
            subCategoryChildName: 'Mgmt of Care (RN)',
            subCategoryParentName: 'Safe/Effective Environment',
            numOfItems: 6,
            categoryScore: 440.0
          },
          {
            categoryId: 14,
            subCategoryChildName: 'Pharm & Parenteral Tx',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 8,
            categoryScore: 453.0
          },
          {
            categoryId: 9,
            subCategoryChildName: 'Safe/Effective Environment',
            subCategoryParentName: 'Client Needs',
            numOfItems: 9,
            categoryScore: 278.0
          },
          {
            categoryId: 15,
            subCategoryChildName: 'Basic Care/Comfort',
            subCategoryParentName: 'Physiological Integrity',
            numOfItems: 4,
            categoryScore: 290.0
          },
          {
            categoryId: 17,
            subCategoryChildName: 'Psychosocial Integrity',
            subCategoryParentName: 'Client Needs',
            numOfItems: 1,
            categoryScore: 1052.0
          }
        ]
      }
    ]
  }
];
