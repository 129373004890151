export default {
  params: {
    programId: 201710,
    programName: 'UNIVERSITY-REXBURG-BSN'
  },
  exams: [
    {
      examId: 15089,
      examName: 'RN Med Surg V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 1004.8333333333334,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-07-23',
      lastDateTaken: '2024-07-24',
      studentsCount: 12,
      meanNationalScore: 836.0
    },
    {
      examId: 15087,
      examName: 'RN Mat-Pedi V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 969.25,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-07-24',
      lastDateTaken: '2024-07-24',
      studentsCount: 4,
      meanNationalScore: 828.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1017.7213114754098,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-06-01',
      lastDateTaken: '2024-07-24',
      studentsCount: 61,
      meanNationalScore: 893.0
    },
    {
      examId: 15081,
      examName: 'RN Health Assessment V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 884.625,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-07-19',
      lastDateTaken: '2024-07-20',
      studentsCount: 24,
      meanNationalScore: 820.0
    },
    {
      examId: 15074,
      examName: 'RN Critical Care V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1049.7966101694915,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-07-19',
      lastDateTaken: '2024-07-20',
      studentsCount: 59,
      meanNationalScore: 839.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 926.9574468085107,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-07-15',
      lastDateTaken: '2024-07-18',
      studentsCount: 47
    },
    {
      examId: 15077,
      examName: 'RN Fundamentals V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 890.9090909090909,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-07-15',
      lastDateTaken: '2024-07-17',
      studentsCount: 33,
      meanNationalScore: 819.0
    },
    {
      examId: 15062,
      examName: 'RN Exit V-3',
      assessmentType: 'Exit',
      meanHesiScore: 931.2333333333333,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-07-11',
      lastDateTaken: '2024-07-14',
      studentsCount: 60,
      meanNationalScore: 844.0
    },
    {
      examId: 15088,
      examName: 'RN Med Surg V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1058.6949152542372,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-07-12',
      lastDateTaken: '2024-07-13',
      studentsCount: 59,
      meanNationalScore: 836.0
    },
    {
      examId: 15099,
      examName: 'RN Psychiatric V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 1013.047619047619,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-07-12',
      lastDateTaken: '2024-07-13',
      studentsCount: 21,
      meanNationalScore: 848.0
    },
    {
      examId: 15086,
      examName: 'RN Mat-Pedi V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1120.2203389830509,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-07-09',
      lastDateTaken: '2024-07-12',
      studentsCount: 59,
      meanNationalScore: 828.0
    },
    {
      examId: 15080,
      examName: 'RN Health Assessment V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 966.1896551724138,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-07-05',
      lastDateTaken: '2024-07-06',
      studentsCount: 58,
      meanNationalScore: 820.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 891.875,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-07-01',
      lastDateTaken: '2024-07-04',
      studentsCount: 64
    },
    {
      examId: 15076,
      examName: 'RN Fundamentals V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 947.7457627118644,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-07-01',
      lastDateTaken: '2024-07-03',
      studentsCount: 59,
      meanNationalScore: 819.0
    },
    {
      examId: 15098,
      examName: 'RN Psychiatric V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 994.9206349206349,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-06-28',
      lastDateTaken: '2024-06-29',
      studentsCount: 63,
      meanNationalScore: 848.0
    },
    {
      examId: 15061,
      examName: 'RN Exit V-2',
      assessmentType: 'Exit',
      meanHesiScore: 944.1166666666667,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-06-27',
      lastDateTaken: '2024-06-29',
      studentsCount: 60,
      meanNationalScore: 844.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 978.1111111111111,
      cohortId: 25555,
      cohortName: 'BSN Program Start Spring 24 July 2025 Grad Q-34746 NG',
      cohortGraduationDate: '2025-07-31',
      firstDateTaken: '2024-06-15',
      lastDateTaken: '2024-06-17',
      studentsCount: 9,
      meanNationalScore: 893.0
    },
    {
      examId: 15052,
      examName: 'RN Exit V-1',
      assessmentType: 'Exit',
      meanHesiScore: 929.7333333333333,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-06-12',
      lastDateTaken: '2024-06-15',
      studentsCount: 60,
      meanNationalScore: 844.0
    },
    {
      examId: 15087,
      examName: 'RN Mat-Pedi V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 986.2222222222222,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-04-09',
      lastDateTaken: '2024-04-10',
      studentsCount: 18,
      meanNationalScore: 828.0
    },
    {
      examId: 15089,
      examName: 'RN Med Surg V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 920.0,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-04-05',
      lastDateTaken: '2024-04-06',
      studentsCount: 14,
      meanNationalScore: 836.0
    },
    {
      examId: 15081,
      examName: 'RN Health Assessment V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 916.2,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-04-04',
      lastDateTaken: '2024-04-06',
      studentsCount: 25,
      meanNationalScore: 820.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 930.5454545454545,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-04-01',
      lastDateTaken: '2024-04-05',
      studentsCount: 44
    },
    {
      examId: 15074,
      examName: 'RN Critical Care V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1000.9137931034483,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-04-02',
      lastDateTaken: '2024-04-04',
      studentsCount: 58,
      meanNationalScore: 839.0
    },
    {
      examId: 15077,
      examName: 'RN Fundamentals V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 993.3243243243244,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-04-01',
      lastDateTaken: '2024-04-03',
      studentsCount: 37,
      meanNationalScore: 819.0
    },
    {
      examId: 15062,
      examName: 'RN Exit V-3',
      assessmentType: 'Exit',
      meanHesiScore: 928.5714285714286,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2024-03-27',
      lastDateTaken: '2024-04-01',
      studentsCount: 42,
      meanNationalScore: 844.0
    },
    {
      examId: 15099,
      examName: 'RN Psychiatric V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 1005.1764705882352,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-03-29',
      lastDateTaken: '2024-03-30',
      studentsCount: 17,
      meanNationalScore: 848.0
    },
    {
      examId: 15086,
      examName: 'RN Mat-Pedi V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 951.2982456140351,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-03-26',
      lastDateTaken: '2024-03-28',
      studentsCount: 57,
      meanNationalScore: 828.0
    },
    {
      examId: 15080,
      examName: 'RN Health Assessment V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 981.53125,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-03-15',
      lastDateTaken: '2024-03-23',
      studentsCount: 64,
      meanNationalScore: 820.0
    },
    {
      examId: 15088,
      examName: 'RN Med Surg V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 994.8448275862069,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2024-03-22',
      lastDateTaken: '2024-03-23',
      studentsCount: 58,
      meanNationalScore: 836.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 892.7540983606557,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-03-18',
      lastDateTaken: '2024-03-20',
      studentsCount: 61
    },
    {
      examId: 15076,
      examName: 'RN Fundamentals V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 933.203125,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-03-18',
      lastDateTaken: '2024-03-20',
      studentsCount: 64,
      meanNationalScore: 819.0
    },
    {
      examId: 15098,
      examName: 'RN Psychiatric V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 978.3448275862069,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2024-03-15',
      lastDateTaken: '2024-03-16',
      studentsCount: 58,
      meanNationalScore: 848.0
    },
    {
      examId: 15061,
      examName: 'RN Exit V-2',
      assessmentType: 'Exit',
      meanHesiScore: 993.0,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2024-03-13',
      lastDateTaken: '2024-03-16',
      studentsCount: 42,
      meanNationalScore: 844.0
    },
    {
      examId: 15052,
      examName: 'RN Exit V-1',
      assessmentType: 'Exit',
      meanHesiScore: 913.6190476190476,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2024-02-28',
      lastDateTaken: '2024-03-02',
      studentsCount: 42,
      meanNationalScore: 844.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1011.9166666666666,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-03-02',
      lastDateTaken: '2024-03-02',
      studentsCount: 12,
      meanNationalScore: 893.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1043.0,
      cohortId: 25100,
      cohortName: 'BSN Program Start Winter 2024 Q-23284 NG',
      cohortGraduationDate: '2025-04-30',
      firstDateTaken: '2024-02-17',
      lastDateTaken: '2024-02-17',
      studentsCount: 64,
      meanNationalScore: 893.0
    },
    {
      examId: 15087,
      examName: 'RN Mat-Pedi V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 966.9090909090909,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-12-08',
      lastDateTaken: '2023-12-14',
      studentsCount: 11,
      meanNationalScore: 828.0
    },
    {
      examId: 15074,
      examName: 'RN Critical Care V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 987.8222222222222,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-12-08',
      lastDateTaken: '2023-12-09',
      studentsCount: 45,
      meanNationalScore: 839.0
    },
    {
      examId: 15089,
      examName: 'RN Med Surg V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 943.8260869565217,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-12-04',
      lastDateTaken: '2023-12-07',
      studentsCount: 23,
      meanNationalScore: 836.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 941.578947368421,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-12-04',
      lastDateTaken: '2023-12-06',
      studentsCount: 38
    },
    {
      examId: 15077,
      examName: 'RN Fundamentals V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 853.5,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-12-04',
      lastDateTaken: '2023-12-06',
      studentsCount: 14,
      meanNationalScore: 819.0
    },
    {
      examId: 15062,
      examName: 'RN Exit V-3',
      assessmentType: 'Exit',
      meanHesiScore: 889.425925925926,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-11-30',
      lastDateTaken: '2023-12-04',
      studentsCount: 54,
      meanNationalScore: 844.0
    },
    {
      examId: 15099,
      examName: 'RN Psychiatric V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 918.2777777777778,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-12-01',
      lastDateTaken: '2023-12-02',
      studentsCount: 18,
      meanNationalScore: 848.0
    },
    {
      examId: 15081,
      examName: 'RN Health Assessment V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 931.3157894736842,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-12-02',
      lastDateTaken: '2023-12-02',
      studentsCount: 19,
      meanNationalScore: 820.0
    },
    {
      examId: 15086,
      examName: 'RN Mat-Pedi V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 997.2,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-11-30',
      lastDateTaken: '2023-12-02',
      studentsCount: 45,
      meanNationalScore: 828.0
    },
    {
      examId: 15080,
      examName: 'RN Health Assessment V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 985.0350877192982,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-11-17',
      lastDateTaken: '2023-11-27',
      studentsCount: 57,
      meanNationalScore: 820.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 878.9,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-11-20',
      lastDateTaken: '2023-11-22',
      studentsCount: 60
    },
    {
      examId: 15088,
      examName: 'RN Med Surg V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 939.0,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-11-20',
      lastDateTaken: '2023-11-22',
      studentsCount: 45,
      meanNationalScore: 836.0
    },
    {
      examId: 15076,
      examName: 'RN Fundamentals V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 991.6779661016949,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-11-20',
      lastDateTaken: '2023-11-22',
      studentsCount: 59,
      meanNationalScore: 819.0
    },
    {
      examId: 15061,
      examName: 'RN Exit V-2',
      assessmentType: 'Exit',
      meanHesiScore: 933.2777777777778,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-11-16',
      lastDateTaken: '2023-11-19',
      studentsCount: 54,
      meanNationalScore: 844.0
    },
    {
      examId: 15098,
      examName: 'RN Psychiatric V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 994.7118644067797,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-11-17',
      lastDateTaken: '2023-11-18',
      studentsCount: 59,
      meanNationalScore: 848.0
    },
    {
      examId: 15052,
      examName: 'RN Exit V-1',
      assessmentType: 'Exit',
      meanHesiScore: 946.925925925926,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-11-02',
      lastDateTaken: '2023-11-05',
      studentsCount: 54,
      meanNationalScore: 844.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1174.8,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-11-04',
      lastDateTaken: '2023-11-04',
      studentsCount: 15,
      meanNationalScore: 893.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 976.9649122807018,
      cohortId: 24140,
      cohortName: 'BSN Program start Fall 2023 Q-21725 NG',
      cohortGraduationDate: '2024-12-31',
      firstDateTaken: '2023-10-21',
      lastDateTaken: '2023-10-26',
      studentsCount: 57,
      meanNationalScore: 893.0
    },
    {
      examId: 15074,
      examName: 'RN Critical Care V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 921.4629629629629,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-07-13',
      lastDateTaken: '2023-07-15',
      studentsCount: 54,
      meanNationalScore: 839.0
    },
    {
      examId: 15087,
      examName: 'RN Mat-Pedi V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 958.8,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-07-14',
      lastDateTaken: '2023-07-15',
      studentsCount: 15,
      meanNationalScore: 828.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 898.8125,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-07-13',
      lastDateTaken: '2023-07-14',
      studentsCount: 32
    },
    {
      examId: 15081,
      examName: 'RN Health Assessment V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 964.4444444444445,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-07-13',
      lastDateTaken: '2023-07-14',
      studentsCount: 27,
      meanNationalScore: 820.0
    },
    {
      examId: 15077,
      examName: 'RN Fundamentals V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 912.0222222222222,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-07-11',
      lastDateTaken: '2023-07-12',
      studentsCount: 45,
      meanNationalScore: 819.0
    },
    {
      examId: 15062,
      examName: 'RN Exit V-3',
      assessmentType: 'Exit',
      meanHesiScore: 959.2786885245902,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-07-07',
      lastDateTaken: '2023-07-10',
      studentsCount: 61,
      meanNationalScore: 844.0
    },
    {
      examId: 15089,
      examName: 'RN Med Surg V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 945.0,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-07-07',
      lastDateTaken: '2023-07-08',
      studentsCount: 24,
      meanNationalScore: 836.0
    },
    {
      examId: 15099,
      examName: 'RN Psychiatric V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 879.2173913043479,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-07-06',
      lastDateTaken: '2023-07-07',
      studentsCount: 23,
      meanNationalScore: 848.0
    },
    {
      examId: 15086,
      examName: 'RN Mat-Pedi V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1042.0188679245282,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-06-30',
      lastDateTaken: '2023-07-01',
      studentsCount: 53,
      meanNationalScore: 828.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 881.4,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-06-29',
      lastDateTaken: '2023-06-30',
      studentsCount: 50
    },
    {
      examId: 15080,
      examName: 'RN Health Assessment V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 922.4426229508197,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-06-29',
      lastDateTaken: '2023-06-30',
      studentsCount: 61,
      meanNationalScore: 820.0
    },
    {
      examId: 15076,
      examName: 'RN Fundamentals V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 852.2950819672132,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-06-27',
      lastDateTaken: '2023-06-28',
      studentsCount: 61,
      meanNationalScore: 819.0
    },
    {
      examId: 15061,
      examName: 'RN Exit V-2',
      assessmentType: 'Exit',
      meanHesiScore: 957.344262295082,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-06-23',
      lastDateTaken: '2023-06-26',
      studentsCount: 61,
      meanNationalScore: 844.0
    },
    {
      examId: 15088,
      examName: 'RN Med Surg V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1024.2407407407406,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-06-23',
      lastDateTaken: '2023-06-24',
      studentsCount: 54,
      meanNationalScore: 836.0
    },
    {
      examId: 15098,
      examName: 'RN Psychiatric V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 958.0816326530612,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-06-21',
      lastDateTaken: '2023-06-23',
      studentsCount: 49,
      meanNationalScore: 848.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1108.4,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-06-15',
      lastDateTaken: '2023-06-16',
      studentsCount: 5,
      meanNationalScore: 893.0
    },
    {
      examId: 15052,
      examName: 'RN Exit V-1',
      assessmentType: 'Exit',
      meanHesiScore: 925.0491803278688,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-06-09',
      lastDateTaken: '2023-06-12',
      studentsCount: 61,
      meanNationalScore: 844.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1042.8032786885246,
      cohortId: 22455,
      cohortName: 'BSN Program start Spring 2023 Q-19438 NG',
      cohortGraduationDate: '2024-07-31',
      firstDateTaken: '2023-06-01',
      lastDateTaken: '2023-06-02',
      studentsCount: 61,
      meanNationalScore: 893.0
    },
    {
      examId: 15074,
      examName: 'RN Critical Care V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 927.8474576271186,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-03-31',
      lastDateTaken: '2023-04-03',
      studentsCount: 59,
      meanNationalScore: 839.0
    },
    {
      examId: 15087,
      examName: 'RN Mat-Pedi V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 930.7777777777778,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-03-31',
      lastDateTaken: '2023-04-01',
      studentsCount: 18,
      meanNationalScore: 828.0
    },
    {
      examId: 15091,
      examName: 'RN Mgmt-Community V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 859.6,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2023-03-30',
      lastDateTaken: '2023-03-31',
      studentsCount: 30,
      meanNationalScore: 806.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 876.6590909090909,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-03-30',
      lastDateTaken: '2023-03-31',
      studentsCount: 44
    },
    {
      examId: 15081,
      examName: 'RN Health Assessment V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 967.5588235294117,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-03-30',
      lastDateTaken: '2023-03-31',
      studentsCount: 34,
      meanNationalScore: 820.0
    },
    {
      examId: 15089,
      examName: 'RN Med Surg V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 981.0882352941177,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-03-28',
      lastDateTaken: '2023-03-29',
      studentsCount: 34,
      meanNationalScore: 836.0
    },
    {
      examId: 15077,
      examName: 'RN Fundamentals V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 877.0,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-03-28',
      lastDateTaken: '2023-03-29',
      studentsCount: 45,
      meanNationalScore: 819.0
    },
    {
      examId: 15062,
      examName: 'RN Exit V-3',
      assessmentType: 'Exit',
      meanHesiScore: 976.3818181818182,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2023-03-24',
      lastDateTaken: '2023-03-27',
      studentsCount: 55,
      meanNationalScore: 844.0
    },
    {
      examId: 15099,
      examName: 'RN Psychiatric V-2',
      assessmentType: 'Specialty',
      meanHesiScore: 844.4642857142857,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-03-22',
      lastDateTaken: '2023-03-24',
      studentsCount: 28,
      meanNationalScore: 848.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 837.2641509433962,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-03-16',
      lastDateTaken: '2023-03-21',
      studentsCount: 53
    },
    {
      examId: 15080,
      examName: 'RN Health Assessment V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 893.8269230769231,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-03-16',
      lastDateTaken: '2023-03-18',
      studentsCount: 52,
      meanNationalScore: 820.0
    },
    {
      examId: 15086,
      examName: 'RN Mat-Pedi V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1019.0,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-03-16',
      lastDateTaken: '2023-03-18',
      studentsCount: 59,
      meanNationalScore: 828.0
    },
    {
      examId: 15090,
      examName: 'RN Mgmt-Community V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 853.709090909091,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2023-03-17',
      lastDateTaken: '2023-03-18',
      studentsCount: 55,
      meanNationalScore: 806.0
    },
    {
      examId: 15076,
      examName: 'RN Fundamentals V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 842.6415094339623,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-03-14',
      lastDateTaken: '2023-03-15',
      studentsCount: 53,
      meanNationalScore: 819.0
    },
    {
      examId: 15061,
      examName: 'RN Exit V-2',
      assessmentType: 'Exit',
      meanHesiScore: 941.2181818181818,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2023-03-10',
      lastDateTaken: '2023-03-13',
      studentsCount: 55,
      meanNationalScore: 844.0
    },
    {
      examId: 15088,
      examName: 'RN Med Surg V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 989.9152542372881,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2023-03-10',
      lastDateTaken: '2023-03-11',
      studentsCount: 59,
      meanNationalScore: 836.0
    },
    {
      examId: 15098,
      examName: 'RN Psychiatric V-1',
      assessmentType: 'Specialty',
      meanHesiScore: 1005.1296296296297,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2023-03-08',
      lastDateTaken: '2023-03-10',
      studentsCount: 54,
      meanNationalScore: 848.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1047.5,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-03-02',
      lastDateTaken: '2023-03-02',
      studentsCount: 2,
      meanNationalScore: 893.0
    },
    {
      examId: 15052,
      examName: 'RN Exit V-1',
      assessmentType: 'Exit',
      meanHesiScore: 923.3703703703703,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2023-02-24',
      lastDateTaken: '2023-03-02',
      studentsCount: 54,
      meanNationalScore: 844.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1061.851851851852,
      cohortId: 22020,
      cohortName: 'BSN Program start Winter 2023 Q- 18690 NG',
      cohortGraduationDate: '2024-05-31',
      firstDateTaken: '2023-02-16',
      lastDateTaken: '2023-02-24',
      studentsCount: 54,
      meanNationalScore: 893.0
    },
    {
      examId: 10505,
      examName: 'SP Mat/Pedi V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 834.3333333333334,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2022-12-14',
      lastDateTaken: '2022-12-15',
      studentsCount: 3,
      meanNationalScore: 828.0
    },
    {
      examId: 10228,
      examName: 'SP Critical Care  _D',
      assessmentType: 'Specialty',
      meanHesiScore: 951.5,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2022-12-09',
      lastDateTaken: '2022-12-10',
      studentsCount: 60,
      meanNationalScore: 839.0
    },
    {
      examId: 10311,
      examName: 'SP Mgmt-Community V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 971.575,
      cohortId: 18562,
      cohortName: 'BSN Program start Fall 2021 Q-13227 NG',
      cohortGraduationDate: '2022-12-01',
      firstDateTaken: '2022-12-09',
      lastDateTaken: '2022-12-10',
      studentsCount: 40,
      meanNationalScore: 806.0
    },
    {
      examId: 13524,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 889.3055555555555,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2022-12-08',
      lastDateTaken: '2022-12-09',
      studentsCount: 36
    },
    {
      examId: 11869,
      examName: 'SP Health Assessment V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 967.8,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-12-03',
      lastDateTaken: '2022-12-09',
      studentsCount: 40,
      meanNationalScore: 820.0
    },
    {
      examId: 10350,
      examName: 'SP MS V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 933.8965517241379,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2022-12-06',
      lastDateTaken: '2022-12-08',
      studentsCount: 29,
      meanNationalScore: 836.0
    },
    {
      examId: 10433,
      examName: 'SP Fund V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 910.2413793103449,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-12-06',
      lastDateTaken: '2022-12-07',
      studentsCount: 29,
      meanNationalScore: 819.0
    },
    {
      examId: 10971,
      examName: 'RN Exit V-3_D',
      assessmentType: 'Exit',
      meanHesiScore: 967.609375,
      cohortId: 18562,
      cohortName: 'BSN Program start Fall 2021 Q-13227 NG',
      cohortGraduationDate: '2022-12-01',
      firstDateTaken: '2022-12-02',
      lastDateTaken: '2022-12-05',
      studentsCount: 64,
      meanNationalScore: 844.0
    },
    {
      examId: 1236,
      examName: 'SP Mat/Pedi_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1061.0666666666666,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2022-12-01',
      lastDateTaken: '2022-12-03',
      studentsCount: 60,
      meanNationalScore: 828.0
    },
    {
      examId: 10313,
      examName: 'SP Psy V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 942.5833333333334,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2022-11-30',
      lastDateTaken: '2022-12-02',
      studentsCount: 36,
      meanNationalScore: 848.0
    },
    {
      examId: 13521,
      examName: 'Custom Exam',
      assessmentType: 'Custom',
      meanHesiScore: 959.5737704918033,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2022-11-22',
      lastDateTaken: '2022-11-23',
      studentsCount: 61
    },
    {
      examId: 1225,
      examName: 'SP Fund_D',
      assessmentType: 'Specialty',
      meanHesiScore: 956.8148148148148,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-11-22',
      lastDateTaken: '2022-11-23',
      studentsCount: 54,
      meanNationalScore: 819.0
    },
    {
      examId: 1231,
      examName: 'SP Mgmt-Community_D',
      assessmentType: 'Specialty',
      meanHesiScore: 884.0952380952381,
      cohortId: 18562,
      cohortName: 'BSN Program start Fall 2021 Q-13227 NG',
      cohortGraduationDate: '2022-12-01',
      firstDateTaken: '2022-11-21',
      lastDateTaken: '2022-11-22',
      studentsCount: 63,
      meanNationalScore: 806.0
    },
    {
      examId: 10509,
      examName: 'SP Health Assessment_D',
      assessmentType: 'Specialty',
      meanHesiScore: 891.7777777777778,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-11-19',
      lastDateTaken: '2022-11-21',
      studentsCount: 54,
      meanNationalScore: 820.0
    },
    {
      examId: 1219,
      examName: 'SP MS_D',
      assessmentType: 'Specialty',
      meanHesiScore: 969.4333333333333,
      cohortId: 19298,
      cohortName: 'BSN Program start Winter 2022 Q-14204 NG',
      cohortGraduationDate: '2023-04-30',
      firstDateTaken: '2022-11-18',
      lastDateTaken: '2022-11-19',
      studentsCount: 60,
      meanNationalScore: 836.0
    },
    {
      examId: 1195,
      examName: 'SP Psy_D',
      assessmentType: 'Specialty',
      meanHesiScore: 966.9833333333333,
      cohortId: 19922,
      cohortName: 'BSN Program start Spring 2022 Q-15409 NG',
      cohortGraduationDate: '2023-07-31',
      firstDateTaken: '2022-11-16',
      lastDateTaken: '2022-11-19',
      studentsCount: 60,
      meanNationalScore: 848.0
    },
    {
      examId: 11164,
      examName: 'SP Dosage Calculation V-1_D',
      assessmentType: 'Specialty',
      meanHesiScore: 1027.0363636363636,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-10-20',
      lastDateTaken: '2022-11-18',
      studentsCount: 55,
      meanNationalScore: 893.0
    },
    {
      examId: 11165,
      examName: 'SP Dosage Calculation V-2_D',
      assessmentType: 'Specialty',
      meanHesiScore: 988.6923076923077,
      cohortId: 20929,
      cohortName: 'BSN Program start Fall 2022 Q-16997 NG',
      cohortGraduationDate: '2023-12-31',
      firstDateTaken: '2022-11-03',
      lastDateTaken: '2022-11-03',
      studentsCount: 13,
      meanNationalScore: 893.0
    },
    {
      examId: 10970,
      examName: 'RN Exit V-2_D',
      assessmentType: 'Exit',
      meanHesiScore: 960.9375,
      cohortId: 18562,
      cohortName: 'BSN Program start Fall 2021 Q-13227 NG',
      cohortGraduationDate: '2022-12-01',
      firstDateTaken: '2022-10-28',
      lastDateTaken: '2022-10-31',
      studentsCount: 64,
      meanNationalScore: 844.0
    },
    {
      examId: 10969,
      examName: 'RN Exit V-1_D',
      assessmentType: 'Exit',
      meanHesiScore: 899.265625,
      cohortId: 18562,
      cohortName: 'BSN Program start Fall 2021 Q-13227 NG',
      cohortGraduationDate: '2022-12-01',
      firstDateTaken: '2022-09-29',
      lastDateTaken: '2022-10-03',
      studentsCount: 64,
      meanNationalScore: 844.0
    }
  ]
};
