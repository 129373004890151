export default {
  courseSectionId: 516809,
  studentId: 5846998,
  firstName: 'Alexander',
  lastName: 'Tushishvili',
  topicCount: 238,
  noviceOrBelowTopicCount: 203,
  metrics: [
    {
      assignmentId: 3678953,
      assignmentName: 'NUR 398A Immunologic System and Infectious Diseases - Intermediate ',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'IN_PROGRESS',
      dueDate: '2024-04-21T03:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: 'null',
      classAvgScore: 0.01368421052631579,
      timeSpent: 'PT2M',
      classAvgTimeSpent: 'PT1H44M26.631578947S',
      totalQuestionsAnswered: 2
    },
    {
      assignmentId: 3645907,
      assignmentName: 'NUR 398A Pediatric Drugs - Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-08T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.017894736842105262,
      timeSpent: 'PT16M',
      classAvgTimeSpent: 'PT59M55.263157894S',
      totalQuestionsAnswered: 69
    },
    {
      assignmentId: 3645906,
      assignmentName: "NUR 398A Women's Health and Maternal/Newborn Drugs - Novice",
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-04T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.012105263157894737,
      timeSpent: 'PT52M7S',
      classAvgTimeSpent: 'PT56M19.842105263S',
      totalQuestionsAnswered: 86
    },
    {
      assignmentId: 3645904,
      assignmentName: 'NUR 398A Psychiatric/Mental Health Drugs - Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-04T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.01263157894736842,
      timeSpent: 'PT1H15M14S',
      classAvgTimeSpent: 'PT59M51.789473684S',
      totalQuestionsAnswered: 86
    },
    {
      assignmentId: 3678945,
      assignmentName: 'NUR 398A Renal - Novice, recommend Intermediate',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-14T03:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.01105263157894737,
      timeSpent: 'PT7M31S',
      classAvgTimeSpent: 'PT35M15.789473684S',
      totalQuestionsAnswered: 86
    },
    {
      assignmentId: 3645916,
      assignmentName: 'NUR 398A Endocrine - Intermediate',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-18T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.015263157894736843,
      timeSpent: 'PT44M41S',
      classAvgTimeSpent: 'PT1H11M40.631578947S',
      totalQuestionsAnswered: 139
    },
    {
      assignmentId: 3645914,
      assignmentName: 'NUR 398A Gastrointestinal - Intermediate',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-14T03:59:00Z',
      pastDue: true,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.010526315789473684,
      timeSpent: 'PT1H51S',
      classAvgTimeSpent: 'PT2H13M17.526315789S',
      totalQuestionsAnswered: 331
    },
    {
      assignmentId: 3678951,
      assignmentName: 'NUR 398A Cardiovascular, Hematologic, and Lymphatic Systems - Intermediate',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-18T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.011578947368421053,
      timeSpent: 'PT3H20M45S',
      classAvgTimeSpent: 'PT3H21M34.157894736S',
      totalQuestionsAnswered: 225
    },
    {
      assignmentId: 3645912,
      assignmentName: 'NUR 398A Medical-Surgical Drugs - Novice',
      assignmentType: 'MASTERY',
      gradeType: 'PASS_FAIL',
      status: 'COMPLETED',
      dueDate: '2024-04-08T03:59:00Z',
      pastDue: false,
      gradePoints: 1,
      score: '1.0',
      classAvgScore: 0.014736842105263158,
      timeSpent: 'PT3H15M10S',
      classAvgTimeSpent: 'PT1H59M46.368421052S',
      totalQuestionsAnswered: 205
    },
    {
      assignmentId: 3734860,
      assignmentName: 'CNS Depressant & Skeletal Muscle Relaxant Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 3734857,
      assignmentName: 'Pharmacokinetics',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 3734858,
      assignmentName: 'Pharmacodynamics',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 3734859,
      assignmentName: 'Analgesics',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 3734861,
      assignmentName: 'Anticonvulsant Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    },
    {
      assignmentId: 3734862,
      assignmentName: 'Antidepressant and Mood Stabilizer Drug Therapy',
      assignmentType: 'OSMOSIS_VIDEO',
      gradeType: 'NOT_GRADED',
      status: 'NOT_STARTED',
      dueDate: null,
      pastDue: false,
      gradePoints: 100,
      score: 'null',
      classAvgScore: 0,
      timeSpent: 'PT0S',
      classAvgTimeSpent: 'PT0S',
      totalQuestionsAnswered: 0
    }
  ]
};
